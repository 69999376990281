import React, { forwardRef } from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  logo,
  loginLeftLogo,
  mircosoftLogo,
  calendarLogo,
  imgPasswordInVisible,
  imgPasswordVisible,
  MaleGenderLogo,
  MaleGenderActiveLogo,
  FemaleGenderLogo,
  FemaleGenderLogoActive,
  NonBinayLogoActive,
  NonBinayLogo,
  NOtherLogoActive,
  OtherLogo
} from "./assets";
import { Formik } from "formik";
import { theme, webStyles } from '../../../components/src/styles';
import { KeyboardArrowDown } from "@material-ui/icons";
import PrivacyPolicy from "../../user-profile-basic/src/PrivacyPolicy.web";
import { MsalContext } from '@azure/msal-react';
import i18n from "i18next"

// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import Loader from "../../../components/src/Loader.web";

// Customizable Area Start

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  ExampleCustomInput = (value: any, onClick: any, onChange: any) => {
    return (
      <Input
        placeholder="Date of Birth"
        value={value}
        onClick={onClick}
        fullWidth={true}
        disableUnderline
        data-testid="textInputTest"
        name="dateTest"
        onChange={onChange}
        defaultValue={""}
        endAdornment={
          <div>
            <InputAdornment position="end">
              <img src={calendarLogo} style={{ width: 28, height: 28, cursor: 'pointer' }} />
            </InputAdornment>
          </div>
        }
      />
    );
  };

  SignupDetails = (handleChange: any | undefined, handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement> | undefined, values: { employee_id: any; full_name: any; country_code?: any; mobile_number: any; official_email_address: any; personal_email_address?: string; password?: string; dob?: null; gender?: string; }
    , errors: any, touched: any) => {
    return (
      <>
        <Box>
          <label style={webStyles.textFieldLabel}>Employee ID</label>
          <Input
            placeholder="Employee ID"
            fullWidth={true}
            type="text"
            disableUnderline
            name="employee_id"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.employee_id}
            data-test-id="employeeId"
          />
          {errors.employee_id != "" && <Typography style={webStyles.errorStyle}>
            {touched.employee_id &&
              errors.employee_id}</Typography>}
        </Box>
        <Box>
          <label style={webStyles.textFieldLabel}>Full Name</label>
          <Input
            placeholder="Full Name"
            fullWidth={true}
            type="text"
            disableUnderline
            name="full_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.full_name}
            data-test-id="full-name"
          />
          <Typography style={webStyles.errorStyle}>
            {errors.full_name != "" &&
              touched.full_name &&
              errors.full_name}
          </Typography>
        </Box>
        <Box>
          <label style={webStyles.textFieldLabel}>
            Mobile Number
          </label>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={5}>
              <Select
                data-test-id="country_code"
                IconComponent={KeyboardArrowDown}
                displayEmpty
                disableUnderline
                name="country_code"
                value={values.country_code}
                onChange={handleChange}
                onBlur={handleBlur}
                MenuProps={{
                  getContentAnchorEl: null,
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: "200px",
                      width: "250px",
                    },
                  },
                }}
              >
                <MenuItem value={""}>
                  <em style={{ color: "#DCDCDC", fontFamily: 'ClotherRegular', fontStyle: "normal" }}>Code</em>
                </MenuItem>
                {this.state.countrieCode.map((code: any, index: any) => (
                  <MenuItem value={code.attributes.country_code} key={index}><img src={`https://flagcdn.com/48x36/${code.id.toLowerCase()}.png`} style={{ height: '18px', width: '24px', marginRight: '7px' }} />{`+ ${code.attributes.country_code} ${code.attributes.name}`}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Input
                placeholder="Mobile Number"
                fullWidth={true}
                type="text"
                disableUnderline
                name="mobile_number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile_number}
              />
            </Grid>
          </Grid>
          <Typography style={webStyles.errorStyle}>
            {errors.mobile_number != "" &&
              touched.mobile_number &&
              errors.mobile_number}
          </Typography>
        </Box>
        <Box>
          <label style={webStyles.textFieldLabel}>
            Official Email Address
          </label>
          <Input
            data-test-id="official-email"
            placeholder="Official Email Address"
            fullWidth={true}
            type="text"
            disableUnderline
            name="official_email_address"
            onChange={(event: any) => {
              if (!(/[A-Z]/g).test(event.target.value)) {
                handleChange(event)
              }
            }}
            value={values.official_email_address}
            onBlur={handleBlur}
          />
          <Typography style={webStyles.errorStyle}>
            {errors.official_email_address != "" &&
              touched.official_email_address &&
              errors.official_email_address}
          </Typography>
        </Box>
      </>
    )
  }

  getColor = () => {
    return this.state.loader ? "rgb(185 179 179)" : "#DCDCDC";
  }

  displayApiErrorMessage = () => {
    return (
      <Box color={"red"} fontSize={"12px"} display={"none"}>
        <Typography>Failed to signup! Please try again.</Typography>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks 
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loader} />
        <Grid style={webStyles.loginSec} container>
          <Grid style={webStyles.loginLeft} item xl={6} lg={7} md={7} sm={6} component={Box} display={{ xs: "none", lg: "block", md: "block", sm: "block" }}>
            <img src={loginLeftLogo} alt="" style={{ height: '70%' }} />
          </Grid>

          <Grid style={{ ...webStyles.loginRight, overflowY: 'auto' }} item xl={6} lg={5} md={5} sm={6} xs={12}>
            <Box style={webStyles.loginRightInner}>
              <Box sx={webStyles.loiginHeader}>
                <Box sx={webStyles.loginLogo}>
                  <img src={logo} alt="" style={{ width: "191px", height: "106px" }} />
                </Box>
                <Typography variant="h2" style={webStyles.signupWelcomeText}>Welcome to U1st!</Typography>
                <Typography variant="body2" style={webStyles.signupSubHeader}>
                  Please enter your details to Sign Up
                </Typography>
              </Box>

              <Formik
                initialValues={{
                  employee_id: "",
                  full_name: "",
                  country_code: "",
                  mobile_number: "",
                  official_email_address: "",
                  personal_email_address: "",
                  password: "",
                  dob: null,
                  gender: "",
                }}
                data-test-id={"formikTest"}
                onSubmit={this.handleSignup}
                validationSchema={this.signupSchema}
              >
                {({
                  handleChange,
                  handleBlur,
                  submitForm,
                  errors,
                  values,
                  touched,
                  setFieldValue,
                }) => (
                  <Box>
                    {this.SignupDetails(handleChange, handleBlur, values, errors, touched)}
                    <Box>
                      <label style={webStyles.textFieldLabel}>
                        Personal Email Address
                      </label>
                      <Input
                        placeholder="Personal Email Address"
                        fullWidth={true}
                        type="text"
                        disableUnderline
                        name="personal_email_address"
                        data-test-id="personal-email"
                        onChange={(event: any) => {
                          if (!(/[A-Z]/g).test(event.target.value)) {
                            handleChange(event)
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.personal_email_address}
                      />
                      <Typography style={webStyles.errorStyle}>
                        {errors.personal_email_address != "" &&
                          touched.personal_email_address &&
                          errors.personal_email_address}
                      </Typography>
                    </Box>
                    <Box>
                      <label style={webStyles.textFieldLabel}>Gender</label>
                      <Grid style={webStyles.radioButtonContainer} container spacing={3}>
                        <Grid item lg={6} sm={6} xs={12} style={{ paddingBottom: '10px' }}>
                          <Button
                            variant="outlined"
                            data-test-id="male"
                            style={webStyles.genderBtn}
                            className={values.gender === "Male" ? "active" : ""}
                            onClick={() => setFieldValue("gender", "Male")}
                          >
                            <img src={values.gender === "Male" ? MaleGenderActiveLogo : MaleGenderLogo} style={webStyles.genderImages} />
                            Male
                          </Button>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12} style={{ paddingBottom: '10px' }}>
                          <Button
                            variant="outlined"
                            data-test-id="female"
                            style={webStyles.genderBtn}
                            className={values.gender === "Female" ? "active" : ""}
                            onClick={() => setFieldValue("gender", "Female")}
                          >
                            <img src={values.gender === "Female" ? FemaleGenderLogoActive : FemaleGenderLogo} style={webStyles.genderImages} />
                            Female
                          </Button>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12} style={{ paddingBottom: '10px', paddingTop: 0 }}>
                          <Button
                            variant="outlined"
                            data-test-id="non_binary"
                            style={webStyles.genderBtn}
                            className={
                              values.gender === "Non_Binary" ? "active" : ""
                            }
                            onClick={() => setFieldValue("gender", "Non_Binary")}
                          >
                            <img style={webStyles.genderImages} src={values.gender === "Non_Binary" ? NonBinayLogoActive : NonBinayLogo} />
                            Non Binary
                          </Button>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12} style={{ paddingBottom: '10px', paddingTop: 0 }}>
                          <Button
                            className={values.gender === "Others" ? "active" : ""}
                            variant="outlined"
                            data-test-id="others"
                            style={webStyles.genderBtn}
                            onClick={() => setFieldValue("gender", "Others")}
                          >
                            <img src={values.gender === "Others" ? NOtherLogoActive : OtherLogo} style={webStyles.genderImages} />
                            Others
                          </Button>
                        </Grid>
                      </Grid>
                      <Typography style={webStyles.errorStyle}>
                        {errors.gender != "" && touched.gender && errors.gender}
                      </Typography>
                    </Box>
                    <Box>
                      <label style={webStyles.textFieldLabel}>
                        Date of Birth
                      </label>
                      <Box sx={webStyles.dateFieldWrapper}>
                        <style>
                          {`.react-datepicker-popper {
                      z-index: 4 !important;
                    }`}
                        </style>
                        <DatePicker
                          scrollableYearDropdown
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          dateFormat="dd/MM/yyyy"
                          dateFormatCalendar="MMMM"
                          data-test-id="dob"
                          name="dob"
                          placeholderText="Date of Birth"
                          wrapperClassName="date-picker"
                          data-testId="date-picker-inline-todate"
                          customInput={this.ExampleCustomInput(values.dob, {}, setFieldValue)}
                          popperClassName="example-datepicker-class"
                          selected={values.dob}
                          onChange={(date) => {
                            this.handleDobChange(date, setFieldValue)
                          }}
                          onBlur={handleBlur}
                          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                        />
                      </Box>
                      <Typography style={webStyles.errorStyle}>
                        {errors.dob != "" && touched.dob && errors.dob}
                      </Typography>
                    </Box>
                    <Box>
                      <label style={webStyles.textFieldLabel}>Password</label>
                      <Input
                        data-test-id="password-input"
                        placeholder="Password"
                        fullWidth={true}
                        type={this.state.enablePasswordField ? "password" : "text"}
                        disableUnderline
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                              data-test-id="eyeBtn"
                            >
                              <img
                                src={
                                  this.state.enablePasswordField
                                    ? imgPasswordInVisible
                                    : imgPasswordVisible
                                }
                                alt="eye"
                                defaultValue={""}
                              />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Typography style={webStyles.errorStyle}>
                        {errors.password != "" &&
                          touched.password &&
                          errors.password}
                      </Typography>
                    </Box>
                    <Button style={webStyles.signInButton}
                      variant="contained"
                      type="submit"
                      onClick={submitForm}
                      data-test-id="submit-button"
                    >
                      Sign Up
                    </Button>
                  </Box>
                )}
              </Formik>
              <Box style={webStyles.loginWith}>
                <Box style={{ ...webStyles.loginWithContainer as React.CSSProperties, color: this.getColor() }}>
                  <Typography variant="caption" component={"p"} style={webStyles.orSignUpText}>
                    <Box style={{ background: '#fff', ...webStyles.lineStyle as React.CSSProperties }} component={"span"}>
                      or Sign Up with
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Button className="microsoft-btn" variant="contained" onClick={this.handleLoginPopup} >
                <i style={webStyles.iconStyle}>
                  <img src={mircosoftLogo} alt="" />
                </i>
                Microsoft
              </Button>
              <Box sx={webStyles.microSoftBtn}>
                <Typography style={webStyles.linkLableStyle}>
                  Already have an account?
                  <Typography
                    style={webStyles.linkStyle}
                    component={"span"}
                    data-test-id="sign-in"
                    onClick={() => this.goToSignIn()}
                  >
                    Sign In
                  </Typography>
                </Typography>
                <Box sx={webStyles.linkLableStyle}>
                  By Signing up, you agree to our
                  <Typography
                    style={webStyles.linkStyle}
                    component={"span"}
                    data-test-id="privacy-policy"
                    onClick={this.setPrivacyPolicyModal}
                  >
                {i18n.t('Privacy Policy')}  </Typography>
                </Box>
                <CustomDialog open={this.state.isPrivacyPolicy}>
                  <PrivacyPolicy
                    navigation={this.props.navigation}
                    id={""}
                    setPrivacyPolicyModal={this.setPrivacyPolicyModal}
                  />
                </CustomDialog>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {this.state.dialogModalOpen &&
          <Dialog
            maxWidth="md"
            open={this.state.dialogModalOpen}
          >
            <DialogContent style={{ fontSize: '20px' }}>
              {this.state.errorMessage}
            </DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}> <Button onClick={this.handleClose} data-test-id="handleClose" style={{ width: '100px', background: '#673AB7', color: '#fff', height: '35px' }}>
              OK
            </Button>
            </div>
          </Dialog>}
          {this.displayApiErrorMessage()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const CustomDialog = withStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '600px',
      paddingBottom: '30px',
    },
  }
})(Dialog)
// Customizable Area End
