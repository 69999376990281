import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { defaultPostImage, profileIcon } from "./assets";
import { Breadcrumbs } from "../../../components/src/types";
export const configJSON = require("../config.json");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  post: any;
  From: string;
  token: string;
  breadcrumbData: Breadcrumbs[];
  notificationBreadcrumbData: Breadcrumbs[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewPostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPostDetailByIdApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      post: {},
      From: "",
      token: "",
      breadcrumbData: [
        {
          title: 'My Posts',
          isActive: false,
          navigate: true,
          url: 'MyPosts',
        },
        {
          title: 'Un Actioned',
          isActive: false,
          navigate: true,
          url: 'MyPosts',
        },
        {
          title: 'Post',
          isActive: true,
          navigate: false,
          url: '',
        },
      ],
      notificationBreadcrumbData: [
        {
          title: 'Home',
          isActive: false,
          navigate: true,
          url: 'Dashboard',
        },
        {
          title: 'Notifications',
          isActive: false,
          navigate: true,
          url: 'Notifications',
        },
        {
          title: 'Post',
          isActive: true,
          navigate: false,
          url: '',
        },
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(getName(MessageEnum.Props));
      if (responseData?.attributes) {
        this.setState({ post: responseData.attributes });
        setStorageData("post", JSON.stringify(responseData.attributes));
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let token = await getStorageData("authToken", false);
    this.setState({ token });
    const postData = await getStorageData("post");
    const From = await getStorageData("from");
    let post = JSON.parse(postData);
    this.setState({ post: post.attributes, From });
  }

  navigateToMyPosts = () => {
    this.props.navigation.goBack();
  };

  proceedPostHandler = () => {
    const postData = {
      id: this.state.post.id,
    };
    const message: Message = new Message(
      getName(MessageEnum.SupervisorPostCreation)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.Props), postData); // this line is only needed when props has to be sent
    this.send(message);
  };

  goToUserProfile = (id: number | undefined) => {
    setStorageData("otherUserId", id)
    this.props.navigation.navigate("Profile")
  }

  handlePostImageError = () => {
    const { post } = this.state;
    if (post) {
      post.image_url = defaultPostImage;
    }
  }

  handlePostAvatarError = () => {
    const { post } = this.state;
    if (post) {
      post.profile_pic = profileIcon;
    }
  }
  // Customizable Area End
}
