import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { object } from "yup";
import { setStorageData } from "../../../framework/src/Utilities";
import { EmailListData, EmailListItem } from './types';
// Customizable Area End

// Customizable Area Start
export type NavObject = TypeNav
export interface TypeNav {
  addListener: Function
  goBack: Function,
  getParam:Function,
  navigate:Function
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation:NavObject ;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  message: string;
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  open: boolean;
  page: number;
  email: string,
  data: object,
  gettingTitleDataState: string,
  total_counts: number,
  addUserFullNameField: string,
  addUserEmailAddressField: string,
  editEmailListNameModalVisible: boolean;
  loader: boolean;
  isCampaignListEmpty: boolean;
  emailListData: EmailListItem[];
  editModalVisible: boolean;
  isEditMode: boolean;
  deleteModalVisible: boolean;
  pageNo: number;
  emailListNameForModal: string;
  addEmailListModalVisible: boolean;
  deleteEmailListModalVisible: boolean;
  emailListTitle: string;
  searchText: string;
  searchResults: EmailListItem[];
  searchResultMessage: string;
  emails: string[];
  emailListId: number;
  editedValue: string;
  editedItemId: number;
  emailText: string;
  nameText: string;
  showModal: boolean;
  showDialog: boolean;
  selectedEmailListId: number;
  totalPages: number;
  // Customizable Area End
}
interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class Emaillists2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmailListApiCallId = ""
  createEmailListApiCallId = ""
  searchByEmailListApiCallId = ""
  deleteEmailListApiCallId = ""
  editEmailListNameApiCallId = ""
  addEmailToListApiCallId = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.addEmailToListSuccessCallback = this.addEmailToListSuccessCallback.bind(this)
    this.getEmailListApiCall = this.getEmailListApiCall.bind(this)
    this.onPressSearch = this.onPressSearch.bind(this);
    this.editEmailListNameSuccessCallback = this.editEmailListNameSuccessCallback.bind(this);
    this.searchByEmailListSuccessCallback = this.searchByEmailListSuccessCallback.bind(this);
    this.createEmailListSuccessCallback = this.createEmailListSuccessCallback.bind(this);
    this.getEmailListSuccessCallback = this.getEmailListSuccessCallback.bind(this);
    this.addEmailToListApiCall = this.addEmailToListApiCall.bind(this);
    this.deleteEmailListApiCall = this.deleteEmailListApiCall.bind(this);
    this.editEmailListNameApiCall = this.editEmailListNameApiCall.bind(this);
    this.createEmailListApiCall = this.createEmailListApiCall.bind(this);
    this.apiCall = this.apiCall.bind(this);
    this.onchangeSearchText = this.onchangeSearchText.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.deleteEmailListSuccessCallback = this.deleteEmailListSuccessCallback.bind(this)
    this.handleNextPage = this.handleNextPage.bind(this);
    this.gettingTitleData = this.gettingTitleData.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleTitleClick = this.handleTitleClick.bind(this);
    this.Logoutcall = this.Logoutcall.bind(this);
    this.submitEmailData = this.submitEmailData.bind(this);
    this.editEmailListNameApiCall = this.editEmailListNameApiCall.bind(this);
    this.emailListAPICalls = this.emailListAPICalls.bind(this);
    this.deleteEmailListSuccessCallback = this.deleteEmailListSuccessCallback.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.apiCall = this.apiCall.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      // Customizable Area End
    ];
    this.state = {
      message: "",
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      open: false,
      page: 1,
      email: "",
      data: object,
      gettingTitleDataState: "",
      total_counts: 0,
      addUserFullNameField: "",
      addUserEmailAddressField: "",
      loader: false,
      isCampaignListEmpty: false,
      emailListData: [],
      editModalVisible: false,
      editEmailListNameModalVisible: false,
      deleteEmailListModalVisible: false,
      deleteModalVisible: false,
      pageNo: 1,
      emailListNameForModal: '',
      addEmailListModalVisible: false,
      emailListTitle: '',
      searchText: '',
      searchResults: [],
      searchResultMessage: '',
      emails: [],
      emailListId: 0,
      editedValue: '',
      editedItemId: 0,
      emailText: '',
      nameText: '',
      showModal: false,
      showDialog: false,
      selectedEmailListId: 0,
      totalPages: 0,
      isEditMode: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (message.id === getName(MessageEnum.RestAPIResponceMessage) && getName(MessageEnum.RestAPIResponceMessage) === message.id  && responseJson) {
      this.setState({ loader: false })
      if (apiRequestCallId === this.getEmailListApiCallId) {
        this.getEmailListSuccessCallback(responseJson)
      } else if (apiRequestCallId === this.createEmailListApiCallId) {
        this.createEmailListSuccessCallback()
      } else if (apiRequestCallId === this.editEmailListNameApiCallId) {
        this.editEmailListNameSuccessCallback()
      } else if (apiRequestCallId === this.addEmailToListApiCallId) {
        this.addEmailToListSuccessCallback()
      } else if (apiRequestCallId === this.searchByEmailListApiCallId) {
        this.searchByEmailListSuccessCallback(responseJson)
      } else if (apiRequestCallId === this.deleteEmailListApiCallId) {
        this.deleteEmailListSuccessCallback()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  Logoutcall = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getEmailListApiCall(this.state.totalPages)
  }

  handleTitleClick = async (element: { id: number }) => {
    let parsedId = element.id.toString();
    await setStorageData('emailListId',parsedId )
    this.props.navigation.navigate('EmailListDataListing');
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false, emailListTitle: "" });
  }

  gettingTitleData = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailListTitle: event?.target?.value })
  }

  handleNextPage = () => {
    this.setState({ pageNo: this.state.pageNo + 1 }, () => {
      this.getEmailListApiCall(this.state.pageNo);
    });
  };

  handlePreviousPage = () => {
    this.setState(prevState => {
      if (prevState.pageNo > 1) {
        return { pageNo: prevState.pageNo - 1 };
      }
      return null; 
    }, () => {
      if (this.state.pageNo !== null) {
        this.getEmailListApiCall(this.state.pageNo);
      }
    });
  };

  toggleEditMode = (index: number) => {
    this.setState({
      isEditMode: true,
      open: true,
      emailListId: this.state.emailListData[index].attributes.id,
      emailListTitle: this.state.emailListData[index].attributes.title
    });
  }

  toggleDialog = (emailListId: number) => {
    this.setState((prevState) => ({
      showDialog: !prevState.showDialog,
      selectedEmailListId: emailListId !== null ? emailListId : prevState.selectedEmailListId,
    }));
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  onchangeSearchText = (event: {target: {value: string} }) => {
    this.setState({ searchText: event?.target?.value })
  }

  getEmailListApiCall = async (pageNumber: number)  => {
    this.getEmailListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getAllEmailList2ApiEndPoint}/?page=${pageNumber}`
    });
  }

  emailListAPICalls = () => {
    if(this.state.isEditMode) {
      this.editEmailListNameApiCall()
    } else {
      this.createEmailListApiCall()
    }
  }

  createEmailListApiCall = async () => {
    let body = {
      "title": this.state.emailListTitle
    }
    this.createEmailListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.createEmailListApiEndPoint}`,
      body: body
    });
  }

  editEmailListNameApiCall = async () => {
    let body = {
      "title": this.state.emailListTitle
    }
    this.editEmailListNameApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.putApiMethodType,
      endPoint: `${configJSON.editEmailListApiEndPoint}${this.state.emailListId}`,
      body: body
    });
  }

  deleteEmailListApiCall = async (emailListId: number) => {
    this.deleteEmailListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethodType,
      endPoint: `${configJSON.deleteEmailListApiEndPoint}${emailListId}`,
    });
  }

  addEmailToListApiCall = async (idtobeadded: number) => {
    let body = {
      "email": this.state.emailText,
      "full_name": this.state.nameText
    }
    this.addEmailToListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.addEmailToListApiEndPoint}${idtobeadded}${configJSON.addEmailToListApiEndPoint2}`,
      body: body
    });
  }

  onPressSearch = async () => {
    this.searchByEmailListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.searchByEmailListApiEndPoint}${this.state.searchText}`,
    });
  }

  apiCall = async (data:{[key: string]: string |number |object|[] | boolean}) => {
    const { contentType, method, endPoint, body, type } = data
    const header = {
      'Content-Type': contentType,
      token: configJSON.token
    }
    const dataRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    dataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    dataRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    dataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
    dataRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : dataRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(dataRequestMessage.id, dataRequestMessage);
    return dataRequestMessage.messageId;
  }

  getEmailListSuccessCallback = (response: EmailListItem): void => {
     if (response?.data?.length <= 0) {
      this.setState({ emailListData: [], isCampaignListEmpty: true })
    } else {
      this.setState({ loader: false }, () => {
        if (response?.data?.length > 0) {
          this.setState({ emailListData: response.data, isCampaignListEmpty: false })
        }
      })
    }
  }

  createEmailListSuccessCallback = () => {
    alert("Email List Created Successfully")
    this.setState({
      loader: false,
      emailListTitle: '',
      open: false,
    }, () => {
      this.getEmailListApiCall(this.state.pageNo)
    })
  }

  searchByEmailListSuccessCallback = (response: EmailListData) => {
    if (this.state.searchText === '') {
      this.getEmailListApiCall(this.state.pageNo)
      this.setState({ searchResultMessage: ""})
    } else if (response.data.length <= 0 || response.message === "Records Not Found") {
      this.setState({ emailListData: [] , searchResultMessage: "Records Not Found"})
    } else {
      this.setState({ emailListData: response.data, searchResultMessage: "Here are your search results"})
    }
  }

  editEmailListNameSuccessCallback = () => {
    if (this.state.emailListId !== 0) {
      alert("Email List Name Changed Successfully")
    }
    this.setState({ open: false, emailListTitle: '', emailListId: 0, isEditMode: false}, () => {
      if (this.state.searchText == '') {
        this.getEmailListApiCall(this.state.pageNo)
      } else {
        this.onPressSearch()
      }
    })
  }

  addEmailToListSuccessCallback = () => {
    this.setState({ emailListId: 0, nameText: '', emailText: '' }, () => {
      alert('Email Added to list successfully');
      this.setState({ showDialog: !this.state.showDialog });
    })
  }

  deleteEmailListSuccessCallback = () => {
    this.setState({ deleteEmailListModalVisible: false, emailListId: 0, loader: false, pageNo: 1, emailListData: [] }, () => {
      alert("Email List Deleted Successfully")
      this.getEmailListApiCall(this.state.pageNo)
    })
  }

  submitEmailData = () => {
    if (this.state.selectedEmailListId !== null) {
      this.addEmailToListApiCall(this.state.selectedEmailListId);
      this.editEmailListNameApiCall()
    }
  }
}
  // Customizable Area End