import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";

interface MeasurementUnitResponse {
    data: MeasurementUnit[];
    error: string;
}

interface MeasurementUnit {
    id: string;
    name: string;
}

interface SelectedMachineResponseData {
    data: {
        id: string;
        attributes: SelectedMachineData;
    }[];
    error: string;
}

interface SelectedMachineData {
    id: string;
    equipment_name?: string;
    equipment_number?: number;
    due_date?: string;
    account_id?: number;
    location_id?: number;
    category?: string;
    audit_category_id?: number;
    location?: string;
};

interface CheckpointResponseData {
    data: CheckpointData;
    error: string;
}

interface CheckpointData {
    id?: string;
    attributes?: {
        id?: string;
        name?: string;
        standard_reference?: null;
        inspection_frequency?: string;
        recommended_check?: string;
        action_required?: string;
        audit_sub_category_id?: string;
        referencestandard_id?: string;
        reference_image?: string;
        sub_category?: string;
        reference_standard?: string;
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    errorMsg: string;
    loading: boolean;
    disableSubmitButton: boolean;
    checkpointId: string;
    machineId: string;
    submitClicked: boolean;
    remarks: string;
    measurementUnit: MeasurementUnit[];
    selectedMachineData: SelectedMachineData;
    checkpointData: CheckpointData;
    nonCompiled: string;
    selectedMeasurementUnit: string;
    totalNumber: string;
    compiled: string;
    targetedDate: Date | null;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WorkerCheckpointController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUnitOfMeasurementApiCallId: string = "";
    updateWorkerCheckpointApiCallId: string = "";
    getWorkerMachineApiCallId: string = "";
    getShowCheckpointApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            errorMsg: "",
            loading: false,
            disableSubmitButton: false,
            checkpointId: "",
            machineId: "",
            submitClicked: false,
            remarks: "",
            measurementUnit: [],
            selectedMachineData: { id: "" },
            checkpointData: {},
            nonCompiled: "",
            selectedMeasurementUnit: "",
            totalNumber: "",
            compiled: "",
            targetedDate: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getUnitOfMeasurementApiCallId) {
                this.handleUnitOfMeasurementApiResponse(responseJson)
            }

            if (apiRequestCallId === this.updateWorkerCheckpointApiCallId) {
                this.handleUpdateWorkerCheckpointApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getWorkerMachineApiCallId) {
                this.handleShowMachinesApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getShowCheckpointApiCallId) {
                this.handleShowCheckpointApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        let checkpointId = await getStorageData("checkpointdetailid", false);
        let machineId = await getStorageData("machineid", false);
        this.setState({ token, checkpointId, machineId }, () => {
            this.getWorkerMachineApiCall();
            this.getShowCheckpointApiCall();
        });
        this.getUnitOfMeasurementApiCall();
    }

    handleShowMachinesApiResponse = (responseJson: SelectedMachineResponseData) => {
        if (responseJson && !responseJson.error) {
            const selectedMachine = responseJson.data.filter((value: SelectedMachineData) => {
                return (parseInt(value.id) === parseInt(this.state.machineId))
            })[0]?.attributes;

            this.setState({ selectedMachineData: selectedMachine })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleShowCheckpointApiResponse = (responseJson: CheckpointResponseData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ checkpointData: responseJson.data })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleSubmitButton = () => {
        this.setState({ submitClicked: true })

        const { selectedMeasurementUnit, totalNumber, compiled, nonCompiled, targetedDate, remarks } = this.state;
        const errorInSelectedMeasurementUnit = !selectedMeasurementUnit;
        const errorInTotalNumber = !totalNumber || !(/^\d*$/.test(totalNumber));
        const errorInCompiled = !compiled || !(/^\d*$/.test(compiled));
        const errorInnonCompiled = !nonCompiled;
        const errorIntargetedDate = !targetedDate;
        const errorInRemarks = !remarks


        if (errorInSelectedMeasurementUnit ||
            errorInTotalNumber ||
            errorInCompiled ||
            errorInnonCompiled ||
            errorIntargetedDate ||
            errorInRemarks) {
            return;
        }
        this.updateWorkerCheckpointApiCall()
    }

    handleUpdateWorkerCheckpointApiResponse = (responseJson: { error: string; }) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                loading: false,
                disableSubmitButton: false
            });

            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loading: false,
                disableSubmitButton: false
            });
        }
    }

    navigateToAuditScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    updateWorkerCheckpointApiCall = () => {
        this.setState({
            loading: true,
            disableSubmitButton: true
        });

        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const formattedDate = moment(this.state.targetedDate?.toISOString()).format('YYYY-MM-DD');

        const body = {
            unit_of_measurement_id: this.state.selectedMeasurementUnit,
            total_target: this.state.totalNumber,
            compiled: this.state.compiled,
            non_compiled: this.state.nonCompiled,
            target_date: formattedDate,
            remark: this.state.remarks
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateWorkerCheckpointApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.patchWorkerCheckpointApiEndPoint}?id=${this.state.checkpointId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    navigateTomachinecheckpointScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    handleUnitOfMeasurementApiResponse = (responseJson: MeasurementUnitResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ measurementUnit: responseJson.data })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    getUnitOfMeasurementApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getUnitOfMeasurementRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUnitOfMeasurementApiCallId = getUnitOfMeasurementRequestMsg.messageId;
        getUnitOfMeasurementRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getUnitOfMeasurementApiEndPoint
        );
        getUnitOfMeasurementRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getUnitOfMeasurementRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getUnitOfMeasurementRequestMsg.id, getUnitOfMeasurementRequestMsg);
    }

    getWorkerMachineApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getWorkerMachineRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getWorkerMachineApiCallId = getWorkerMachineRequestMsg.messageId;
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMachinesApiEndPoint
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getWorkerMachineRequestMsg.id, getWorkerMachineRequestMsg);

    }

    getShowCheckpointApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getAllRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getShowCheckpointApiCallId = getAllRequestMsg.messageId;
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getShowCheckpointApiEndPoint}?id=${this.state.checkpointId}`
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
    }

    handlePreviousButton = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CheckpointDetail");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    handleChangeMeasurementUnit = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = event.target.value as string;
        this.setState({ selectedMeasurementUnit: newValue });
    };

    handleChangeTotalNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value);
        const compiled = parseInt(this.state.compiled);

        this.setState({
            totalNumber: event.target.value,
            nonCompiled: newValue >= compiled && compiled > -1 ? (newValue - compiled).toString() : ""
        });
    };

    handleChangeRemarks = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        this.setState({ remarks: newValue });
    };

    handleChangeCompiled = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value);
        const totalNumber = parseInt(this.state.totalNumber);
        this.setState({
            compiled: event.target.value,
            nonCompiled: totalNumber >= newValue && newValue > -1 ? (totalNumber - newValue).toString() : ""
        });
    };

    handleChangeTargetedDatePicker = (SelectedDate: Date | null) => {
        if (SelectedDate) {
            SelectedDate?.setHours(SelectedDate?.getHours() + 5);
            SelectedDate?.setMinutes(SelectedDate?.getMinutes() + 30);
            const formatedDate = SelectedDate?.toISOString().replace(/(,|\s)+/g, 'T');
            this.setState({ targetedDate: new Date(formatedDate) })
        }
    }
    handleChangeTargetedDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ targetedDate: new Date(event.target.value) });
    }
    // Customizable Area End
}
