import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Grid,
  Card,
  CardContent,
  Chip,
  Modal,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { buttonFilter, buttonFilterActive, location, calender, edit } from "./assets";
import { Done, Add } from "@material-ui/icons";
import moment from "moment";
import { MachineData} from "./Audittrail2Controller";
export const closeIcon = require("../assets/close-icon.png");

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Audittrail2Controller, {
  Props,
  configJSON,
} from "./Audittrail2Controller";
import { webStyles } from "../../../components/src/styles";

export default class Audittrail2 extends Audittrail2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderFilterModal = () => {
    const { openFilterModal, selectedDepartment, } = this.state;
    return (
      <Modal open={openFilterModal} onClose={this.closeFilterModal}>
        <Filters>
          <Box className="filters-modal-header">
            <Typography>Filters</Typography>
            <img
              style={{ cursor: "pointer" }}
              data-test-id="close_filter"
              src={closeIcon}
              alt="close"
              onClick={this.closeFilterModal}
            />
          </Box>

          <Box className="filters-modal-body">
            <Box className="risk-card">
              <Typography className="status-card-title">
                Departments
              </Typography>
              <Button
                data-test-id="departments_clear"
                className="clear-btn"
                onClick={this.handleDepartmentClear}
              >
                clear
              </Button>
            </Box>
            <span>
              {this.state.departments.map((value, index) => (
                <label key={index} className="filters-label">
                  <input
                    data-test-id="status-options"
                    id={`checkbox_${value}`}
                    onChange={(event) =>
                      this.handleDepartmentChange(event, value.id)
                    }
                    type="checkbox"
                    name={value.name}
                    checked={selectedDepartment.includes(value.id)}
                  />
                  <span>
                    {selectedDepartment.includes(value.id) ? (
                      <Done className="selectedIcon" />
                    ) : (
                      <Add className="selectedIcon" />
                    )}{" "}
                    {value.name}{" "}
                  </span>
                </label>
              ))}
            </span>
          </Box>

          <Box className="bottom-wrapper">
            <Button
              data-test-id="clear_all"
              variant="contained"
              className="clear-all-btn"
              onClick={this.handleClearAll}
            >
              Clear All
            </Button>
            <Button
              data-test-id="apply_filter"
              variant="contained"
              className="apply-btn"
              onClick={this.applyFiters}
            >
              Apply
            </Button>
          </Box>
        </Filters>
      </Modal>
    )
  }
  auditCard = (value: MachineData) => {
    const { role } = this.state;
    const machineId = value.id;
    return (<Grid item xs={12} sm={6} md={4}>
      <Card className="cardStyle"
        onClick={() => this.navigateToAddCheckpoint(machineId)}
        data-test-id="checkpoint_card">
        <CardContent>
          <Box className="cardContentMainBox">
            <Typography className="cardMainHeading">{value.attributes.equipment_name}</Typography>
            {role !== 'Worker' && <img src={edit} className="editClass" alt="Edit" onClick={(event) => this.navigateToEditMachine(event, machineId)} />}
            {role === 'Worker' &&
              <Chip className="chipClass workerChipClass" label={value.attributes.category} style={{ marginTop: 0, justifyContent: "center" }} />
            }
          </Box>

          <Box className="imageAndText">
            <img src={location} alt="Profile" height={23} width={23} />
            <Typography className="cardText">{value.attributes.location}</Typography>
          </Box>

          <Box>
            <Grid container justifyContent="space-between" spacing={1} alignItems="center">
              <Grid item>
                <Box className="imageAndText">
                  <img src={calender} alt="Profile" height={23} width={23} />
                  <Typography className="cardText">Due Date: {moment(value.attributes.due_date).format('DD/MM/YYYY')}</Typography>
                </Box>
              </Grid>
              {role !== 'Worker' &&
                <Grid item>
                  <Box>
                    <Chip className="chipClass workerChipClass" label={value.attributes.category} style={{ justifyContent: "center" }} />
                  </Box>
                </Grid>}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid >)
  }
  // Customizable Area End

  render() {
    const { role, loading, machineData, openFilterModal, applyFilter } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CardWrapper>
            <Box style={webStyles.breadcrumbWrapper}>
              <Typography style={webStyles.breadcrumbItem}>Audit</Typography>
            </Box>
            <Grid container className="myPostsTitle" spacing={0} alignItems="center">
              <Grid item>
                <Typography className="mainHeading">Audit</Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  {role !== 'Worker' && role !== "" &&
                    <Grid item>
                      <Button
                        data-test-id="add_new_machine"
                        className="addMachineButtonStyle"
                        type="submit"
                        onClick={() => this.handleAddNewMachine()}
                      >
                        Add New Machine
                      </Button>
                    </Grid>}
                  <Grid item>
                    <img
                      data-test-id="filter"
                      style={{ cursor: 'pointer' }}
                      height="36px"
                      width="36px"
                      src={openFilterModal || applyFilter ? buttonFilterActive : buttonFilter}
                      alt="filter"
                      onClick={this.handleOpenFilterModal}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          <Box>
            <Grid container spacing={2}>
              {machineData?.length > 0 && machineData?.map((value) => {
                return this.auditCard(value);
              })}
            </Grid>
          </Box>
          <Box >
            {machineData?.length === 0 && !loading &&
              <Box className="noData">
                <Typography>No Data available.</Typography>
              </Box>
            }
          </Box>

          {loading && (
            <Box data-test-id={"activityLoader"} className="loader-outer">
              <Box className="loader-inner">
                <CircularProgress />
              </Box>
            </Box>
          )}

          {this.renderFilterModal()}
        </CardWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const CardWrapper = styled(Box)({
  width: "100%",
  backgroundColor: "rgb(238, 238, 238)",
  padding: "30px",
  boxSizing: "border-box",
  height: "100%",
  overflow: "auto",
  position: "relative",
  "& .loader-outer": {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "100%",
    minHeight: "100vh",
    top: 0,
    right: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  "& .loader-inner": {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "& .editClass" : {
    zIndex: 1
  },
  "& .headingWrapper": {
    display: "flex",
    gap: "10px",
    marginTop: '10px'
  },
  "& .auditCardGrid": {
    display: "flex",
  },
  "& .subHeadingText": {
    color: '#18181E',
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherRegular",
  },
  "& .myPostsTitle": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    paddingBottom: "27px",
  },
  "& .mainHeading": {
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "25px",
    fontFamily: "ClotherBold",
  },
  "& .filter-wrapper": {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  "& .noData": {
    textAlign: "center",
    justifyContent: "center",
    margin: "20px 12px",
    fontSize: "18px"
  },
  "& .addMachineButtonStyle": {
    border: "1px solid #8735E1",
    textTransform: "none",
    padding: "18px 10px",
    fontSize: "16px",
    fontFamily: "ClotherBold",
    height: "30px",
    color: "#8735E1",
    borderRadius: "8px",
    marginRight: "10px",
    "@media(max-width:601px)": {
      lineHeight: "15px",
      height: "45px"
    },
  },
  "& .cardContentMainBox": {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px"
  },
  "& .MuiCardContent-root:last-child": {
    padding: "12px",
  },
  "& .cardStyle": {
    cursor: "pointer",
    padding: "4px 2px",
    background: "#fff",
    border: "1.5px solid lightgray",
    boxShadow: "none",
    borderRadius: "8px",
  },
  "& .cardMainHeading": {
    fontSize: "16px",
    fontFamily: "ClotherBold",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
    maxWidth: '100%'
  },
  "& .cardText": {
    color: "#A1A1A1",
    fontWeight: 400,
    fontSize: "17px",
    fontFamily: "ClotherRegular",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
    maxWidth: '100%'
  },
  "& .imageAndText": {
    display: "flex",
    gap: 15,
    marginTop: 10,
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
    maxWidth: '100%'
  },
  "& .chipClass": {
    backgroundColor: "white",
    color: "#A1A1A1",
    border: "1px solid",
    borderRadius: "15px",
    height: "25px",
    marginTop: 10,
    width: "100px",
    "& .MuiChip-label": {
      padding: "0px 5px",
      fontWeight: 400
    }
  },
  "& .workerChipClass": {
    backgroundColor: "#DDF6FF",
    color: "#00b0f0",
    borderRadius: "15px",
    border: "#DDF6FF",
    fontFamily: "ClotherBold",
    fontSize: "16px",
    padding: "5px",
    minWidth: "100px",
    
    "& .MuiChip-label": {
        padding: "0px 5px",
        fontWeight: 600,
        marginBottom: "2px"
    }
  },
})
export const Filters = styled(Box)({
  maxWidth: "720px",
  width: "80%",
  height: "auto",
  overflow: "auto",
  background: "#fff",
  maxHeight: "90vh",
  borderRadius: "8px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "& .filters-modal-header": {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 30px 0px",
    alignItems: "center",
    "& p": {
      color: "#2B2A29",
      fontFamily: "ClotherBold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      textTransform: "capitalize",
    },
  },
  "& .filters-modal-body": {
    padding: "15px 15px 15px 30px",
    maxHeight: '280px',
    overflow: 'auto'
  },
  "& .filters-label span": {
    cursor: 'pointer',
    borderRadius: "8px",
    border: "1px solid #DCDCDC",
    background: "#FFF",
    padding: "12px",
    color: "#A1A1A1",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    display: "inline-block",
    margin: "0 10px 10px 0",
  },
  "& .Location-chip span": {
    padding: "9px 14px 11px 9px",
    color: "#FFF",
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    borderRadius: "10px",
    border: "1px solid #DCDCDC",
    backgroundColor: "rgb(135, 53, 225)",
    fontStyle: "normal",
    fontWeight: 700,
    display: "inline-block",
    margin: "0 10px 10px 0",
  },
  "& input:checked+span": {
    background: "#8735E1 !important",
    borderColor: "#8735E1 !important",
    color: "#fff !important",
  },
  "& .filters-label": {
    position: "relative",
  },
  "& .filters-modal-body input": {
    position: "absolute",
    visibility: "hidden",
  },
  "& .status-card-title": {
    fontSize: "18px",
    color: "#000",
    fontFamily: "ClotherRegular",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .risk-card": {
    paddingBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .borderRed": {
    borderColor: "red !important",
    color: "red !important",
  },
  "& .risk-level-body": {
    border: "0",
    paddingTop: "20px",
  },
  "& .borderYellow": {
    borderColor: "#FFD541 !important",
    color: "#FFD541 !important",
  },
  "& .borderGreen": {
    borderColor: "#21C274 !important",
    color: "#21C274 !important",
  },
  "& .clear-btn": {
    fontSize: "14px",
    fontStyle: "normal",
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },
  "& .bottom-wrapper": {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    padding: "20px 30px 30px 30px",
    boxSizing: "border-box",
  },
  "& .apply-btn": {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "ClotherRegular",
    borderRadius: "8px",
    background: "#8735E1",
    width: "160px",
    height: "50px",
    padding: "10px",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    boxShadow: "none",
  },
  "& .location-dropdown": {
    marginBottom: "20px",
    border: '1px solid lightgray',
    fontFamily: 'ClotherRegular',
    width: "97%",
    borderRadius: "8px",
    backgroundColor: 'white',
    height: '50px',
    padding: '10px 15px'
  },
  "& .clear-all-btn": {
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontStyle: "normal",
    borderRadius: "8px",
    border: "1px solid #8735E1",
    textTransform: "capitalize",
    marginRight: "10px",
    background: "#FFF",
    color: "#8735E1",
    textAlign: "center",
    fontWeight: 700,
    padding: "10px",
    width: "160px",
    height: "50px",
    boxShadow: "none",
  },
  "& .selectedIcon": {
    fontSize: "15px",
    verticalAlign: "middle",
  },
});
// Customizable Area End
