import React from "react";
import {
    Box,
    Typography,
    Grid,
    Button,
    Paper,
    Avatar,
} from "@material-ui/core";
// Customizable Area Start
import FollowingController, { FollowingData, Props, configJSON } from "./FollowingController";
import Loader from "../../../components/src/Loader.web";
import { Check, NavigateNext } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";

export default class Help extends FollowingController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Grid container style={webStyles.mainContainer as React.CSSProperties}>
                <Grid item lg={12} sm={12}>
                    <Box style={webStyles.headerBox}>
                        <Typography data-test-id="account-text" style={webStyles.accountText} onClick = {this.goToHome} >{configJSON.ProfileText}</Typography>
                        <NavigateNext />
                        <Typography data-test-id="account-text" style={webStyles.currentPage}>{configJSON.FollowingText}</Typography>
                    </Box>
                    <Typography style={webStyles.accoundHeading} data-test-id="account-delete-header">{configJSON.FollowingText} ({this.state.totalCount})</Typography>
                    <Grid container style={webStyles.mainGrid as React.CSSProperties}>
                        <Grid item lg={12} sm={12}>
                            {this.state.followings.map((data: FollowingData) => {
                                return (
                                    <Grid item lg={12} md={12} sm={12} container style={webStyles.paperContainer} alignItems="center" justifyContent="space-between">
                                        <Typography style={webStyles.NameFlex}><Avatar src={data.attributes.following.data.attributes.profile_image} />
                                            <span style={webStyles.userName as React.CSSProperties}>{data.attributes.following.data.attributes.full_name}</span></Typography>
                                        <Button style={webStyles.followBtn as React.CSSProperties} onClick={() => this.unFollowUser(data)}>
                                            <Check style={webStyles.ChecksIcon}></Check>
                                            {configJSON.FollowingBtnText}
                                        </Button>
                                    </Grid>
                                )
                            })}
                            {(!this.state.loader && this.state.followings.length === 0) &&
                                <Typography style={webStyles.noTextDataText}>{configJSON.NoDataText}</Typography>}
                        </Grid>
                        <Grid container justifyContent="flex-end" alignItems="flex-end">
                            <Pagination
                                count={Math.ceil(this.state.totalCount / 5)}
                                page={this.state.currentPage}
                                variant="outlined"
                                style={webStyles.paginationBox}
                                onChange={this.handlePageChange}
                                data-test-id="handlePageChange"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Loader loading={this.state.loader} data-test-id="loader" />
            </Grid>
        );
    }
}
// Customizable Area End

// Customizable Area Start

const webStyles = {
    paginationBox: {
        margin: "20px"
    },
    noTextDataText: {
        height: '50vh',
        width: "100%",
        fontFamily: 'clotherBold',
        fontSize: '20px',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center"
    },
    NameFlex: {
        display: 'flex'
    },
    ChecksIcon: {
        fill: "#FFFFFF",
        marginRight: '2px'
    },
    followBtn: {
        minWidth: '86px',
        height: '30px',
        padding: '14px 12px 14px 12px',
        borderRadius: '8px',
        background: '#8735E1',
        color: '#FFFFFF',
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        lineHeight: '22px',
        textTransform: 'capitalize'
    },
    userName: {
        fontFamily: 'ClotherBold',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#2B2A29',
        paddingLeft: '12px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '12px',
    },
    profilePic: {
        width: '34px',
        height: '34px',
    },
    paperContainer: {
        padding: '20px 30px'
    },
    currentPage: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#A1A1A1',
        fontWeight: 600,
        cursor: 'pointer',

    },
    mainGrid: {
        width: '100%',
        background: "#FFFFFF",
        minHeight: '50vh',
    },
    accountButtons: {
        width: "100%",
        height: "52px",
        backgroundColor: "#FFFFFF",
        marginBottom: "18px",
        borderRadius: "8px",
        textTransform: "none",
        padding: "18px 20px 18px 20px",
        fontFamily: "ClotherRegular",
        fontSize: "18px",
        lineHeight: "20px",
        justifyContent: 'space-between',
        color: '#2B2A29',
    },
    mainContainer: {
        background: 'rgb(237, 238, 238)',
        padding: '30px',
    },
    headerBox: {
        fontSize: '0.875rem',
        color: '#A1A1A1',
        display: 'flex',
        gap: '0.3rem',
        alignItems: 'center',
        marginBottom: '1.25rem'
    },
    accountText: {
        fontFamily: 'ClotherRegular',
        fontSize: "14px",
        lineHeight: "22px",
        color: '#A1A1A1',
    },
    accoundHeading: {
        fontFamily: "ClotherBold",
        fontSize: '24px',
        lineHeight: "24px",
        paddingBottom: '25px'
    },
};

// Customizable Area End

