Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Credential must be minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.registerApiEndPoint="account_block/accounts";

exports.employeeIdErrMsg = "Employee id has already been taken";
exports.emailAlreadyExistErrMsg = "Email id has already been used"
exports.empIDErrMsg ="Please enter valid Employee ID";
exports.fullNameErrMsg ="Please enter valid Name";
exports.MobileErrMsg ="Please enter valid Mobile Number";
exports.emailErrMsg ="Please enter valid email";
exports.genderErrMsg ="Please Select Gender";
exports.dobErrMsg ="Please Select Date of Birth";
exports.sendCodeAPIEndPoint = "account_block/delete_account_send_code";
exports.yesDeleteMyAccAPIEndPoint = "account_block/yes_delete_my_account";
exports.verifyOTPAPIEndPoint = "account_block/delete_account_verify_code";
exports.AccountText = "Account";
exports.PrivcyPolicyText = "Privacy Settings";
exports.DeleteAccountText = "Delete Account";
exports.deleteApiMethod = "Delete";
exports.SurelyDeleteText = "Are you sure want to delete your account?";
exports.clientID = "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
exports.OTPErrorMsg = "Please enter correct verification code.";
exports.VerificationCodeHeader = "Verification Code to Delete Account";
exports.OtpDigitMsg = "Please enter 6 digit code sent to";
exports.verifiCationSubHeaderText = "Verification Code to Delete Account";
exports.EmailOtionsText = "Please choose appropriate option to receive a verification code";
exports.ResendOTPBtnText = "Resend OTP";
exports.ResendText = "Resend OTP";
exports.InText = "in";
exports.DeleteAccNote = "If you delete your account, you will permanently lose all your data including earned points and rewards. Tap on below button to proceed with account deletion."
// Customizable Area End
