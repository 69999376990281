// Customizable Area Start
//@ts-nocheck
import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


import FormApprovalWorkflowController, {
  Props,
} from "./FormApprovalWorkflowController.web";

export default class FormApprovalWorkflow extends FormApprovalWorkflowController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <ThemeProvider theme={theme}>
      </ThemeProvider>
    );
  }
}


// Customizable Area End
