import React from 'react';

// Customizable Area Start
import {
    Grid,
    TextField,
    Box,
    Button,
    Typography,
    Breadcrumbs,
    Link,
    createTheme,
    FormGroup,
    FormHelperText,
    Select,
    MenuItem,
    Input,
    Paper,
    Avatar
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Formik, FormikErrors, FormikTouched } from "formik";
import { webStyles } from '../../../components/src/styles';
import * as Yup from "yup";
import { filterIcon, locationIcon, timeIcon, postMainImage } from "./assets";
import moment from 'moment';

const theme = createTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: "black"
                }
            },
        },
        MuiInputBase: {
            root: {
                fontWeight: 700,
                fontSize: '16px',
                fontFamily: 'Clother'
            },
            input: {
                "&::placeholder": {
                    fontFamily:'Clother'
                }
            }
        },
        MuiFilledInput: {
            input: {
                "&::placeholder": {
                    // color: "black",
                    fontFamily:'Clother'
                }
            }
        },
        MuiFormControl: {
            root: {
                width: '100%'
            }
        },
        MuiInput: {
            input: {
                "&::placeholder": {
                    color: "#DCDCDC",
                    opacity: 1,
                    fontSize: '16px',
                    fontWeight: 400,
                    fontFamily: 'Clother',
                    lineHeight: '18px'
                },
                color: "rgb(130, 130, 130)",
                fontWeight: 400,
                fontSize: '16px',
                fontFamily: 'Clother'
            }
        },
        MuiSelect:{
            select:{
                paddingLeft:'10px'
            }
        },
        MuiInputLabel: {
            filled: {
                color: 'black'
            }
        }
    }
});

// Customizable Area End
import ActionTrackerControllerForWorker, {
    Props
} from './ActionTrackerForWorkerController';

export default class ActionTrackerForWorker extends ActionTrackerControllerForWorker {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.workerFormSchema = Yup.object({
            status: Yup.string().required("Please enter valid status"),
            due_date: Yup.string().nullable().required("Please select valid date"),
            action_taken: Yup.string().required("Please enter action taken"),
            remarks: Yup.string().required("Please enter remarks"),
            reply: Yup.string().required("Please enter reply"),
        })
        // Customizable Area End
    }

    // Customizable Area Start
    workerFormSchema: object;
    statusTextColor = (status: string) => {
        switch (status) {
            case 'completed':
                return '#009A4F';
            case 'planned':
                return '#00B0F0'
            case 'overdue':
                return '#FA0000'
            case 'cancelled':
                return '#9A1C00'
            case 'due':
                return '#DF9300'
            default:
                return 'grey'
        }
    }

    statusBackgroundColor = (status: string) => {
        switch (status) {
            case 'completed':
                return '#DBFFED'
            case 'planned':
                return '#DDF6FF'
            case 'overdue':
                return '#FFEDED'
            case 'cancelled':
                return '#FFE8E3'
            case 'due':
                return '#FFF4DE'
            default:
                return 'grey'
        }
    }

    manageInputField = (handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
        handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
        values: { status: any; due_date: string; action_taken: string; remarks: any; reply: string; },
        errors: FormikErrors<{ status: boolean; due_date: boolean; action_taken: boolean; remarks: boolean; reply?: boolean; }>,
        touched: FormikTouched<{ status: string; due_date: string; action_taken: string; remarks: string; reply?: string; }>) => {
        if (values.status) {
            if (values.status == 'completed') {
                if (values.action_taken) {
                    return this.filledDataInput('action_taken', values.action_taken)
                } else { return this.renderEmptyInput(handleChange, handleBlur, values, errors, touched) }
            } else {
                if (values.remarks) {
                    return this.filledDataInput('remarks', values.remarks)
                } else { return this.renderEmptyInput(handleChange, handleBlur, values, errors, touched) }
            }
        }
    }

    renderPostList = () => {
        return (
            <Paper style={webStyle.postListWrapper}>
                    <Typography style={webStyle.subHeading}>Action Tracker</Typography>
                    <div style={webStyle.headingAction}>
                        <Typography style={webStyle.headingStyle}>
                            Action Tracker
                        </Typography>
                        <img src={filterIcon} height={'28px'} width={'28px'} alt="filter" />
                    </div>
                <Grid container spacing={3} style={webStyle.postListContainer}>
                    {this.state.postListData?.map((post, i) => <Grid item xs={12} sm={6} md={4}>
                        <Paper key={i} style={{ ...webStyle.postGridStyle as React.CSSProperties, ...{ borderColor: this.statusTextColor(post.status) } }} onClick={()=>this.onPostClick(post?.id)} data-test-id={`itemID_${i}`}>
                            <Grid
                                style={webStyle.headingAction}
                            >
                                <Grid
                                    item
                                    sm={6}
                                    md={6}
                                    style={webStyle.avatarWrapper}
                                >
                                    <Avatar />
                                    {post?.heading && <Typography
                                        style={webStyle.headingTxt as React.CSSProperties}
                                    >
                                        {post?.heading.slice(0, 16)}
                                        {post?.heading.length < 16 ? "" : "..."}
                                    </Typography>}
                                </Grid>
                                <Grid
                                    item
                                    style={{ ...webStyle.statusGridStyle as React.CSSProperties, ...{ color: this.statusTextColor(post.status), backgroundColor: this.statusBackgroundColor(post.status) } }}
                                >
                                    {post?.status}
                                </Grid>
                            </Grid>
                            {post?.description && <Typography style={webStyle.contentTxt}>
                                {post?.description.slice(0, 80)} {post?.description && post?.description.length < 80 ? "" : "..."}
                            </Typography>}
                            <Grid style={webStyle.locationWrapper as React.CSSProperties}>
                                {post?.location1 && <Typography
                                    style={webStyle.cardLocationStyle as React.CSSProperties}
                                >
                                    <img src={locationIcon} height={'14px'} width={'14px'} />
                                    {post?.location1.slice(0, 8)} {post?.location1 && post?.location1.length < 8 ? "" : "..."}
                                </Typography>}
                                {post?.location2 && <Typography
                                    style={webStyle.cardLocationStyle as React.CSSProperties}
                                >
                                    <img src={locationIcon} height={'14px'} width={'14px'} />
                                    {post?.location2.slice(0, 8)} {post?.location2 && post?.location2.length < 8 ? "" : "..."}
                                </Typography>}
                            </Grid>
                        </Paper>
                    </Grid>)}
                </Grid>
            </Paper>
        )
    }

    postDetails = () => {
        return (
            <Grid container style={{backgroundColor: '#f5f0f0'}}>
            <Breadcrumbs separator="›" aria-label="breadcrumb" style={webStyle.breadcrumbsSpacing}>
                    <Link style={webStyle.inactiveFontColor} data-test-id="phase-1-link" onClick={()=>{
                        this.setState({phase:1})
                    }}>
                        Action Tracker
                    </Link>
                    <Link style={webStyle.activeFontColor} data-test-id="phase-2-link"  onClick={()=>{
                        this.setState({phase:2})
                    }}>
                        Post
                    </Link>
            </Breadcrumbs>
                <Typography style={webStyle.heading}>Post</Typography>
                <Box style={webStyle.formWrapper}>
                <div role="presentation">
                <Box style={{ ...webStyle.postStyle as React.CSSProperties}}>
                            <Grid
                                style={webStyle.headingAction}
                            >
                                <Grid
                                    item
                                    sm={6}
                                    md={6}
                                    style={webStyle.avatarWrapper}
                                >
                                    <Avatar />
                                    {this.state.postDetails.heading && <Typography
                                        style={webStyle.headingTxt as React.CSSProperties}
                                    >
                                        {this.state.postDetails.heading.slice(0, 16)}
                                        {this.state.postDetails.heading.length < 16 ? "" : "..."}
                                    </Typography>}
                                </Grid>
                            </Grid>
                            <Box style={webStyle.postMainImage}>
                            <img src={postMainImage} height={'380px'} width={'100%'} />
                            </Box>
                            <Typography style={webStyle.postContent}>
                                {this.state.postDetails.content}
                            </Typography>
                            
                </Box>
                <Grid container style={webStyle.postDetailsLocationWrapper as React.CSSProperties}>
                                {this.state.postDetails.location1 && <Grid item xs={6} sm={3} md={2}>
                                <Typography
                                    style={webStyle.postLocationStyle as React.CSSProperties}
                                >
                                    <img src={locationIcon} height={'24px'} width={'24px'} />
                                    {this.state.postDetails.location1.slice(0, 8)} {this.state.postDetails.location1.length < 8 ? "" : "..."}
                                </Typography>
                                </Grid>}
                                <Grid item xs={6} sm={3} md={2} style={webStyle.postTimeStyle as React.CSSProperties}>
                                    <Avatar style={{ height: '24px', width: '24px' }}/>
                                    <Typography>John Doe</Typography>
                                </Grid>
                                <Grid item xs={6} sm={3} md={2}
                                    style={webStyle.postTimeStyle as React.CSSProperties}
                                >
                                    <img src={timeIcon} height={'20px'} width={'20px'} />
                                    <Typography>
                                        5 min ago
                                    </Typography>
                                </Grid>
                            </Grid>
            </div>
                </Box>
                <Box style={webStyle.buttonWrapper}>
                                <Button style={webStyle.verifyButton}
                                    variant="contained"
                                    type="submit"
                                    data-test-id="updateBtn"
                                    onClick={this.updateBtnClicked}
                                >
                                    <Typography style={{
                                        ...webStyle.btnText,
                                        textTransform: "none",
                                    }}>
                                        Update
                                    </Typography>
                                </Button>
            </Box>
            </Grid>
        )
    }

    filledDataInput = (fieldType: string, data: string) => {
        return (<Box style={webStyle.filledInputWrapper}><Box style={webStyle.actionHeading}>
            <Typography style={webStyle.actionWrapper}>{fieldType == "remarks" ? "Remarks" : [fieldType == "action_taken" ? "Action Taken": 'Your manager is following up with you']}</Typography>
            <Typography style={webStyle.filledInputTxt}>{data}</Typography>
        </Box>
        </Box>)
    }

    renderEmptyInput = (handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined, handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined, values: { status: any; due_date?: string; action_taken?: string; remarks: any; reply?: string; }, errors: FormikErrors<{ status: string; due_date: string; action_taken: string; remarks: string; reply?: string; }>, touched: FormikTouched<{ status: string; due_date: string; action_taken: string; remarks: string; reply?: string; }>) => {
        let { remarks, action_taken, status } = values;
        return (<>
            <Typography style={webStyle.label}>{status == "completed" ? "Action Taken" : 'Remarks'}</Typography>
            <TextField
                maxRows={5}
                minRows={5}
                variant="standard"
                placeholder={status == "completed" ? "Action Taken" : 'Remarks'}
                style={webStyle.txtInputStyle}
                multiline
                type="text"
                name={status == "completed" ? "action_taken" : 'remarks'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={(status == "completed") ? action_taken : remarks}
                InputProps={{ disableUnderline: true }}
            />
            <Typography style={webStyles.errorStyle}>
                {((status !== "completed") && errors.remarks != "" &&
                    touched.remarks &&
                    errors.remarks) || ((status == "completed") && errors.action_taken != "" &&
                        touched.action_taken &&
                        errors.action_taken)}
            </Typography></>
        )
    }
    
    workerForm = () => {
        return (<Grid container style={{backgroundColor: '#f5f0f0'}}>
                <Breadcrumbs separator="›" aria-label="breadcrumb" style={webStyle.breadcrumbsSpacing}>
                    <Link style={webStyle.inactiveFontColor} data-test-id="home-click" onClick={(()=> this.setState({phase: 1}))}>
                        Home
                    </Link>
                    <Link style={webStyle.inactiveFontColor} data-test-id="post-click" onClick={(()=> this.setState({phase: 2}))}>
                        Post
                    </Link>
                    <Typography style={webStyle.fontColor}>Form</Typography>
                </Breadcrumbs>
                <Typography style={webStyle.heading}>
                Form
            </Typography>
            <Box style={{width:'100%'}}>
                <Formik
                    initialValues={{
                        status: 'completed',
                        due_date: '',
                        action_taken: 'Action taken on the the post is approved by worker',
                        remarks: '',
                        reply: ''
                    }}
                    data-test-id={"formikTest"}
                    onSubmit={(daata) => console.log({ daata })}
                    validationSchema={this.workerFormSchema}
                >
                    {({
                        handleChange,
                        handleBlur,
                        submitForm,
                        errors,
                        values,
                        touched,
                        setFieldValue,
                    }) => (
                        <Box>
                            <div role="presentation">
                            <Box style={webStyle.formWrapper}>
                                <Grid container spacing={1}>
                                    {this.state.postDetails.concerned_location && <Grid item md={4} xs={12} sm={4}>
                                        <Box style={webStyle.detailBoxWrapper}>
                                            <Typography style={webStyle.formDataHeadingFontStyle as React.CSSProperties}>Concern Location:</Typography>
                                            <Typography style={webStyle.formDataFontStyle}>{this.state.postDetails.concerned_location}</Typography>
                                        </Box>
                                    </Grid>}
                                    {this.state.postDetails.assigned_due_date && <Grid item md={4} xs={12} sm={4}>
                                        <Box style={webStyle.detailBoxWrapper}>
                                            <Typography style={webStyle.formDataHeadingFontStyle as React.CSSProperties}>Approved On:</Typography>
                                            <Typography style={webStyle.formDataFontStyle}>{this.state.postDetails.assigned_due_date}</Typography>
                                        </Box>
                                    </Grid>}
                                    {this.state.postDetails.assigned_by && <Grid item md={4} xs={12} sm={4}>
                                        <Box style={webStyle.detailBoxWrapper}>
                                            <Typography style={webStyle.formDataHeadingFontStyle as React.CSSProperties}>Assigned By:</Typography>
                                            <Typography style={webStyle.formDataFontStyle}>{this.state.postDetails.assigned_by}</Typography>
                                        </Box>
                                    </Grid>}
                                </Grid>

                                <Grid container spacing={2}>

                                    {this.state.postDetails.assigned_to && <Grid item md={4} xs={12} sm={4}>
                                        <Box style={webStyle.detailBoxWrapper}>
                                            <Typography style={webStyle.formDataHeadingFontStyle as React.CSSProperties}>Assigned To:</Typography>
                                            <Typography style={webStyle.formDataFontStyle}>{this.state.postDetails.assigned_to}</Typography>
                                        </Box>
                                    </Grid>}
                                    {this.state.postDetails.employee_id && <Grid item md={4} xs={12} sm={4}>
                                        <Box style={webStyle.detailBoxWrapper}>
                                            <Typography style={webStyle.formDataHeadingFontStyle as React.CSSProperties}>Employee ID:</Typography>
                                            <Typography style={webStyle.formDataFontStyle}>{this.state.postDetails.employee_id}</Typography>
                                        </Box>
                                    </Grid>}
                                </Grid>

                                {this.state.postDetails.corrective_action_to_take && <Grid container spacing={2}>
                                    <Grid item md={4} xs={12} sm={8}>
                                        <Box style={webStyle.detailBoxWrapper}>
                                            <Typography style={webStyle.formDataHeadingFontStyle as React.CSSProperties}>Corrective Action to Take:</Typography>
                                            <Typography style={webStyle.formDataFontStyle}>{this.state.postDetails.corrective_action_to_take}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>}

                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12} sm={6}>
                                        <FormHelperText style={webStyle.label}>
                                            Due Date
                                        </FormHelperText>
                                        <Input
                                            style={{ ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties}
                                            data-test-id="txtInputDueDate"
                                            placeholder="Due Date"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.due_date}
                                            type="date"
                                            disableUnderline
                                            defaultValue={values?.due_date !=="" ? moment(values?.due_date).format('YYYY-MM-DD') : "2024-01-24"}

                                        />
                                        <Typography style={webStyles.errorStyle}>
                                            {errors.due_date != "" &&
                                                touched.due_date &&
                                                errors.due_date}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12} sm={6}>
                                        <FormGroup className='input-block'>
                                            <FormHelperText style={webStyle.label}>
                                                Status
                                            </FormHelperText>
                                            <Select
                                                name='operation_identification_name'
                                                data-test-id='status'
                                                label='Process And Operation'
                                                inputProps={{
                                                    icon: {
                                                        left: 0
                                                    }
                                                }}
                                                style={{ ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties}
                                                // IconComponent={KeyboardArrowDown}
                                                value={values.status.toLowerCase()}
                                                disableUnderline
                                                onChange={handleChange("status")}
                                                onBlur={handleBlur("status")}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}>
                                                {this.state.statusList.map((menuItem) => <MenuItem value={menuItem.toLowerCase()} key={menuItem.toLowerCase()}>{menuItem}</MenuItem>)}
                                            </Select>
                                        </FormGroup>
                                        <Typography style={webStyles.errorStyle}>
                                            {errors.status != "" &&
                                                touched.status &&
                                                errors.status}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    {this.manageInputField(handleChange, handleBlur, values, errors, touched)}
                                </Grid>

                                <Grid>
                                    {(this.state.managerReply) && this.filledDataInput("managerReply",this.state.managerReply)}
                                </Grid>

                            </Box>
                            </div>
                            <Box style={webStyle.buttonWrapper}>
                                <Button style={webStyle.verifyButton}
                                    variant="contained"
                                    type="submit"
                                    data-test-id="submitBtn"
                                    onClick={submitForm}
                                >
                                    <Typography style={{
                                        ...webStyle.btnText,
                                        textTransform: "none",
                                    }}>
                                        Save Changes
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Formik>
            </Box>
            </Grid>)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                {(this.state.phase === 1) ? this.renderPostList() : [(this.state.phase === 2) ? this.postDetails() : this.workerForm()]}       
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    actionHeading: {
        border: "1px solid #DCDCDC",
        borderRadius: '8px'
    },
    formDataHeadingFontStyle: {fontSize:'16px', fontFamily: "Clother", letterSpacing: '-0.41px'},
    formDataFontStyle: {fontSize:'16px', fontFamily: "Clother", fontWeight: 700},
    subHeading: { fontSize: "17px" },
    headingAction: { display: "flex", justifyContent: "space-between" },
    postListContainer: { marginTop: "10px" },
    avatarWrapper : { display: "flex", alignItems: "center" },
    headingTxt :{ fontFamily: "Clother", marginLeft: "10px", whiteSpace: "nowrap", fontSize: '16px', fontWeight: 700 },
    contentTxt: {lineHeight:'20px', paddingBlock: '10px', color: '#A1A1A1', fontSize: '16px', fontWeight: 400 },
    locationWrapper: { display: "flex", gap: "10px", bottom: '10px', left: '10px', position: 'absolute' },
    postDetailsLocationWrapper: { display: "flex", gap: "10px"},
    label: {
        color: 'var(--Black, #18181E)',
        fontSize: '1rem',
        fontFamily: 'Clother'
    },
    detailBoxWrapper: {margin: '2px', padding: '5px', backgroundColor:"#EFEFEF", borderRadius:'4px'},
    actionWrapper: {
        lineHeight: '22px',
        fontSize: '16px',
        fontWeight: 400,
        padding: '12px',
        borderBottom: '1px solid #DCDCDC'
    },
    breadcrumbsSpacing: {
        padding: '20px',
        width: '100%'
    },
    filledInputWrapper : {marginBottom:'15px'},
    filledInputTxt: { padding: '10px', color:'#828282' },
    txtInputStyle: { marginTop: '5px', padding: '10px', border: "1px solid rgb(220, 220, 220)", borderRadius: '0.5rem' },
    heading: {
        marginInline: "20px",
        color: '#2B2A29',
        fontWeight: 700,
        fontSize: '24px',
        width:'100%'
    },
    fontColor: {
        color: '#8735E1',
        fontSize: '14px'
    },
    inactiveFontColor: {
        color: '#A1A1A1',
        fontSize: '14px'
    },
    activeFontColor: {
        color: '#8735E1',
        fontSize: '14px'
    },
    postContent : { padding: '5px 10px', color: '#A1A1A1', fontSize: '16px', fontWeight: 400 },
    formWrapper: {
        backgroundColor: '#fff',
        margin: "20px",
        padding: "20px 15px",
        borderRadius: '8px'
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        marginInline: '-20px',
        justifyContent: 'end',
        marginBottom: "25px"
    },
    input: {
        borderRadius: '0.5rem',
        border: '1px solid #DCDCDC',
        fontFamily: 'Clother',
        backgroundColor: 'white'
    },
    inputStd: {
        height: '3.5rem',
        width: '100%',
        padding: 'inherit'
    },
    verifyButton: {
        backgroundColor: '#8735E1',
        color: '#fff',
        width: '160px',
        height: '50px',
        borderRadius: '8px',
    },
    btnText: {
        fontFamily: 'Clother',
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'none',
        letterSpacing: '-0.41px'
    },
    postListWrapper: {
        background: "#f5f0f0",
        height: "100%",
        padding: "20px",
        fontFamily: "Clother",
    },
    headingStyle: {
        fontSize: "22px",
        fontWeight: 600,
    },
    postMainImage: {
        margin: '8px',
        borderRadius: '10px',
        overflow: 'hidden'
    },
    cardLocationStyle: {
        color: '#A1A1A1',
        fontSize: '16px',
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        background: "#f5f0f0",
        borderRadius: "6px",
        padding: "5px 15px",
        whiteSpace: "nowrap",
    },
    postLocationStyle: {
        color: '#A1A1A1',
        fontSize: '16px',
        fontWeight: 400,
        display: "flex",
        alignItems: "center",
        background: "#f5f0f0",
        borderRadius: "6px",
        padding: "5px 15px",
        whiteSpace: "nowrap",
    },
    postTimeStyle: {
        gap: '5px',
        textTransfrom:'Capitalize',
        color: '#A1A1A1',
        fontSize: '16px',
        fontWeight: 400,
        display: "flex",
        alignItems: "center",
        padding: "5px 15px",
        whiteSpace: "nowrap",
    },
    postGridStyle: {
        cursor: 'pointer',
        background: "#fff",
        padding: "10px",
        borderRadius: '10px',
        borderWidth: '1px',
        borderStyle: 'solid',
        width: 'auto',
        height: '172px',
        position: 'relative'
    },
    postStyle: {
        width: 'auto',
        position: 'relative'
    },
    statusGridStyle: {
        textTransform: 'capitalize',
        lineHright: '20px',
        height: '20px',
        alignItems: 'center',
        display: 'flex',
        borderRadius: '10px',
        width: 'auto',
        padding: '2px 5px',
        fontSize: '12px',
        justifyContent: 'center'
    }
};
// Customizable Area End
