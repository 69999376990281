import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
interface MachinesResponseData {
  error: string;
  data: MachineData[];
  designation: string;
}
export interface MachineData {
  id: string;
  type: string;
  attributes: {
    id: number;
    equipment_name: string;
    equipment_number: number;
    due_date: string;
    account_id: number;
    location_id: number;
    audit_category_id: number;
    category: string;
    location: string;
  }
}
interface CategoryData {
  error: string;
  data: Category[];
}

interface Category {
  id: string;
  name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  errorMsg: string;
  loading: boolean;
  machineData: MachineData[];
  departments: Category[];
  role: string;
  openFilterModal: boolean;
  selectedDepartment: string[];
  finalSelectedDepartment: string[];
  applyFilter: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Audittrail2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMachinesApiCallId: string = "";
  getDepartmentsApiCallId: string = "";
  getFilterDepartmentApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      errorMsg: "",
      loading: true,
      machineData: [],
      role: '',
      openFilterModal: false,
      departments: [],
      selectedDepartment: [],
      finalSelectedDepartment: [],
      applyFilter: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getMachinesApiCallId) {
        this.handleMachinesApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getDepartmentsApiCallId) {
        this.handleDepartmentsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getFilterDepartmentApiCallId) {
        this.handleFilterDepartmentsApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("authToken", false);
    this.setState({ token });

    this.getDepartmentsApiCall();
    this.getMachinesApiCall();
  }

  handleMachinesApiResponse = (responseJson: MachinesResponseData) => {
    if (responseJson && !responseJson.error) {
      this.setState({ machineData: responseJson.data, role: responseJson.designation, loading: false })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        loading: false
      });
    }
  }
  handleDepartmentsApiResponse = (responseJson: CategoryData) => {
    if (responseJson && !responseJson.error) {
      this.setState({ departments: responseJson.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleFilterDepartmentsApiResponse = (responseJson: MachinesResponseData) => {
    if (responseJson && !responseJson.error) {
      this.setState({ machineData: responseJson.data, loading: false })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        loading: false
      });
    }
  }

  getDepartmentsApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getDepartmentRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDepartmentsApiCallId = getDepartmentRequestMsg.messageId;
    getDepartmentRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesApiEndPoint
    );
    getDepartmentRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDepartmentRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getDepartmentRequestMsg.id, getDepartmentRequestMsg);
  }

  getMachinesApiCall = () => {
    this.setState({ loading: true })
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getMachinesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMachinesApiCallId = getMachinesRequestMsg.messageId;
    getMachinesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMachinesApiEndPoint
    );
    getMachinesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMachinesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMachinesRequestMsg.id, getMachinesRequestMsg);
  }

  applyFiters = () => {

    const { selectedDepartment } = this.state;

    this.setState({
      openFilterModal: false,
      applyFilter: selectedDepartment.length > 0,
      finalSelectedDepartment: selectedDepartment
    }, () => {
      if (this.state.finalSelectedDepartment.length > 0) {
        this.getFilterDepartmentApiCall();
      } else {
        this.getMachinesApiCall();
      }
    })
  }
  getFilterDepartmentApiCall = () => {
    const filterEndpoint = this.state.finalSelectedDepartment.join(', ')
    this.setState({ loading: true })
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilterDepartmentApiCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFilterDepartmentApiEndPoint}?id=${filterEndpoint}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  closeFilterModal = () => {
    this.setState({
      openFilterModal: false
    });
  };

  handleOpenFilterModal = () => {
    this.setState({ openFilterModal: true, selectedDepartment: this.state.finalSelectedDepartment })
  }

  handleDepartmentClear = () => {
    this.setState({ selectedDepartment: [] });
  };

  handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { checked } = event.target;
    this.setState((prevState) => {
      let updatedSelectedDepartment: string[] = [...prevState.selectedDepartment];
      if (!checked) {
        updatedSelectedDepartment = updatedSelectedDepartment.filter((department: string) => department !== value)
      } else {
        updatedSelectedDepartment.push(value);
      }
      return {
        selectedDepartment: updatedSelectedDepartment,
      };
    });
  }

  handleClearAll = () => {
    this.setState({ selectedDepartment: [] })
  }
  navigateToAddCheckpoint = (machineId: string) => {
    setStorageData('machineid', machineId);
    const messasge = new Message(getName(MessageEnum.NavigationMessage));
    messasge.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
    messasge.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messasge);
  }

  navigateToEditMachine = async (event: React.MouseEvent, machineId: string) => {
    event.stopPropagation();
    await setStorageData('editmachineid', machineId);
    await setStorageData('editmachine', true);
    const messasge = new Message(getName(MessageEnum.NavigationMessage));
    messasge.addData(getName(MessageEnum.NavigationTargetMessage), "AddMachine");
    messasge.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messasge);
  }

  handleAddNewMachine = async () => {
    await removeStorageData('editmachine');
    await removeStorageData('editmachineid');
    const messasge = new Message(getName(MessageEnum.NavigationMessage));
    messasge.addData(getName(MessageEnum.NavigationTargetMessage), "AddMachine");
    messasge.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messasge);
  }
  // Customizable Area End
}
