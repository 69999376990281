import { Box, styled } from "@material-ui/core";

export const Wrapper = styled(Box)({
  width: "100%",
  backgroundColor: "#f7f5f5",
  padding: "30px",
  boxSizing: "border-box",
  height: "calc(100vh - 100px)",
  "& .headingWrapper": {
    display: "flex",
    gap: "10px",
  },
  "& .subHeadingText": {
    color: '#18181E',
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherRegular",
    cursor: "pointer"
  },
  "& .activeHeadingText": {
    color: "#8735E1",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    fontFamily: "ClotherBold",
    cursor: "pointer"
  },
  "& .navigateNext": {
    color: "#DCDCDC",
  },
  "& .mainHeading": {
    margin: "20px 0",
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    fontFamily: "ClotherBold",
  },
  "& .post-profile-left": {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },

  "& .time-icon": {
    width: "15px",
    height: "15px",
  },
  "& .buttonStyle": {
    background: "#EEEEEE",
    textTransform: "capitalize",
    padding: "10px 12px",
    fontSize: "14px",
    fontWeight: 400,
    height: "30px",
    color: "#828282",
    borderRadius: "8px",
    marginRight: "10px",

    "&.buttonStyleActive": {
      background: "#8735E1",
      color: "#fff",
      fontWeight: 700,
    },
  },
  "& .myPostsTitle": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },

  "& .noData": {
    textAlign: "center",
    marginTop: "20px",   
    fontSize: "18px" 
  },

  "& .loader-outer": {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },

  "& .loader-inner": {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },

  "& .card": {
    padding: "20px",
    background: "#fff",
    borderRadius: "8px",
    border: "1px solid #EEE",
    marginTop: "20px",
    marginBottom: "24px",
    cursor: "pointer",
    borderColor: "lightgrey",

    "&.cardRed": {
      borderColor: "red",
    },

    "&.cardYellow": {
      borderColor: "yellow",
    },

    "&.cardGreen": {
      borderColor: "green",
    },
  },
  "& .card_title_wrapper": {
    display: "flex",
    alignItems: "center",
    gap: 10,
    "&.inner-options": {
      justifyContent: "space-between",
    },
  },
  "& .filter-wrapper": {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  "& .card-post-name": {
    color: "#2B2A29",
    fontFamily: "ClotherBold",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  "& .card-post-detail": {
    color: "#828282",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    marginTop: "15px",
  },
  "& .card-image": {
    width: "100%",
    height: 300,
    objectFit: "cover",
    marginTop: 8,
    borderRadius: "8px",
    cursor: "pointer",
  },
  "& .Floor_btn": {
    borderRadius: "4px",
    background: "#EAE8E8",
    color: "#A1A1A1",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    padding: "5px",
    marginTop: "15px",
    textTransform: "capitalize",
  },
  "& .Post-time-detail": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .Post-time-detail .Floor_btn": {
    marginTop: "0px",
  },
  "& .Post-time-content": {
    color: "#A1A1A1",
    fontFamily: "ClotherRegular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .Post-time-inner": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginTop: "20px",
    width: "100%"
  },
  "& .Post-profile-img": {
    width: "46px",
    height: "46px",
    content: "",
    borderRadius: "46px",
    marginRight: 8,
    cursor: "pointer",
  },
  "& .Post-inner-profile-img": {
    width: "26px",
    height: "26px",
    content: "",
    borderRadius: "46px",
    border: "1px solid #8735E1",
  },
  "& .post-img": {
    width: "100%",
    borderRadius: "8px",
    height: "300px",
    background: "#ccc",
    content: "",
    marginTop: "20px",
  },
  "& .trimWords": {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
    maxWidth: '100%'
  },
  "& .process-wrapper": {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    marginBottom: "10px",
  },
  "& .process-btn": {
    borderRadius: "8px",
    background: "#8735E1",
    width: "160px",
    height: "50px",
    padding: "10px",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    boxShadow: "none",
  },
  "& .Ignore-btn": {
    borderRadius: "8px",
    border: "1px solid #8735E1",
    background: "#FFF",
    color: "#8735E1",
    textAlign: "center",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    marginRight: "10px",
    padding: "10px",
    width: "160px",
    height: "50px",
    boxShadow: "none",
  },
  "& .list-side-bar": {
    height: "98.8%",
    display: "flex",
    flexDirection: "column",
  },
  "& .logout-btn": {
    marginTop: "auto",
  },
});

export const Sorting = styled(Box)({
  background: "#fff",
  width: "389px",
  borderRadius: "8px",
  margin: "15% auto",
  "& .sorting-modal-header": {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px",
    alignItems: "flex-start",
    gap: "10px",
    "& p": {
      color: "var(--Black, #2B2A29)",
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "ClotherBold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "-0.41px",
      textTransform: "capitalize",
    },
    "& img": {
      width: "12px",
      height: "12px",
      flexShrink: 0,
    },
  },
  "& .sorting-modal-body": {
    padding: "30px",
    paddingTop: 0,

    "& .Mui-checked": {
      color: "#8735e1",
    },

    "& .radio-label": {
      color: "var(--Black, #18181E)",
      fontFamily: "Clother",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "-0.066px",
    },

    "& .selected-label span": {
      fontWeight: 700,
    },
  },
});

export const Filters = styled(Box)({
  background: "#fff",
  width: "720px",
  height: "auto",
  maxHeight:"90vh",
  borderRadius: "8px",
  overflow: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "& .Filters-modal-header": {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px",
    alignItems: "center",
    "& p": {
      color: "#2B2A29",
      fontFamily: "ClotherBold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      textTransform: "capitalize",
    },
  },
  "& .Filters-modal-body": {
    padding: "30px 15px 30px 30px",
    paddingTop: 0,
    borderBottom: "1px solid #EEE",
  },
  "& .Fillters-label span": {
    borderRadius: "8px",
    border: "1px solid #DCDCDC",
    background: "#FFF",
    padding: "14px 12px",
    color: "#A1A1A1",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    display: "inline-block",
    margin: "0 10px 10px 0",
  },
  "& .Fillters-label": {
    position: "relative",
    cursor: "pointer"
  },
  "& .Filters-modal-body input": {
    visibility: "hidden",
    position: "absolute",
  },
  "& input:checked+span": {
    background: "#8735E1 !important",
    borderColor: "#8735E1 !important",
    color: "#fff !important",
  },
  "& .risk-card": {
    display: "flex",
    justifyContent: "space-between",
    // paddingBottom: "15px",
  },
  "& .risk-card-title": {
    color: "#000",
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .risk-level-body": {
    border: "0",
    paddingTop: "20px",
  },
  "& .borderRed": {
    borderColor: "red !important",
    color: "red !important",
  },
  "& .borderGreen": {
    borderColor: "#21C274 !important",
    color: "#21C274 !important",
  },
  "& .borderYellow": {
    borderColor: "#FFD541 !important",
    color: "#FFD541 !important",
  },
  "& .clear-btn": {
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },

  "& .bottom-wrapper": {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    padding: "0px 30px 30px 30px",
    boxSizing: "border-box",
  },
  "& .apply-btn": {
    borderRadius: "8px",
    background: "#8735E1",
    width: "160px",
    height: "50px",
    padding: "10px",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    boxShadow: "none",
  },
  "& .clear-all-btn": {
    borderRadius: "8px",
    border: "1px solid #8735E1",
    background: "#FFF",
    color: "#8735E1",
    textAlign: "center",
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    marginRight: "10px",
    padding: "10px",
    width: "160px",
    height: "50px",
    boxShadow: "none",
  },

  "& .selectedIcon": {
    verticalAlign: "middle",
    fontSize: "15px",
  },
});

export const WrapperStepOne = styled(Box)({
  width: "100%",
  backgroundColor: "#f7f5f5",
  padding: "20px 0 0 0",
  fontFamily:'ClotherRegular',
  boxSizing: "border-box",
  overflow: "auto",
  position: "relative",
  "& .date-wrapper": {
    "& .react-datepicker-wrapper": {
      width: "100%",
    },
    "& .react-datepicker__triangle" : {
      marginLeft: "-20px"
    },
  },
  "& .custom-container": {
    padding: "0",
  },
  "& .MuiStepIcon-text": {
    fill: "#A1A1A1",
  },
  "& .MuiStepIcon-root": {
    color: "#fff",
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    color: "#21C274",
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed .MuiStepIcon-text": {
    fill: "#fff",
  },
  "& .MuiStepConnector-active .MuiStepConnector-line": {
    borderColor: "#21C274",
    margin: "0 10px 0 10px",
  },
  "& .MuiStepLabel-label.MuiStepLabel-completed": {
    color: "#21C274",
  },
  "& .MuiStepConnector-line": {
    margin: "0 10px 0 10px",
  },
  "& .MuiStepConnector-completed .MuiStepConnector-line": {
    borderColor: "#21C274",
    margin: "0 10px 0 10px",
  },
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "#8735E1",
  },
  "& .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text": {
    fill: "#fff",
  },
  "& .MuiStepLabel-label.MuiStepLabel-active": {
    color: "#8735E1",
  },

  "& .Mui-focused": {
    borderColor: "red",
  },
  "& .headingWrapper": {
    display: "flex",
    gap: "10px",
  },
  "& .subHeadingText": {
    color: '#18181E',
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherRegular"
  },
  "& .loader-outer": {
    position: "absolute",
    left: 0,
    top: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    right: 0,
    bottom: 0,
    zIndex: 1,
  },

  "& .loader-inner": {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  "& .activeHeadingText": {
    color: "#8735E1",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "ClotherRegular",
    cursor: "pointer"
  },
  "& .mainHeading": {
    margin: "20px 0",
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    fontFamily: "ClotherBold",
  },
  "& .time-icon": {
    width: "15px",
    height: "15px",
  },
  "& .navigateNext": {
    color: "#DCDCDC",
  },
  "& .buttonStyle": {
    background: "#EEEEEE",
    textTransform: "capitalize",
    padding: "10px 12px",
    fontSize: "14px",
    fontWeight: 400,
    height: "30px",
    color: "#828282",
    borderRadius: "8px",
    marginRight: "10px",
  },
  "& .myPostsTitle": {
    display: "flex",
    margin: "10px 0",
  },
  "& .card": {
    padding: "20px",
    background: "#fff",
    borderRadius: "8px",
    border: "1px solid #EEE",
    marginBottom: "24px",
  },
  "& .related-img": {
    width: "140px",
    height: "120px",
    content: "",
    borderRadius: "8px",
  },
  "& .related-title": {
    color: "#2B2A29",
    fontFamily: "ClotherRegular",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    marginBottom: "10px",
  },
  "& .MuiSelect-selectMenu":{
    fontFamily: "ClotherRegular",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "white"
  },
  "& .Describe_wrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .mikeIcon": {
    width: "25px",
    height: "25px",
    cursor: "pointer"
  },
  "& .locationWrapper": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  "& .ConcernWrapper": {
    margin: "20px 0",
    marginTop: "25px",
    marginBottom: '10px'
  },
  "& .auto_location": {
    display: "flex",
    alignItems: "center",
  },
  "& .Location-input": {
    width: "100%",
    color: "#2B2A29",
    fontFamily: "ClotherBold",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderColor: "#8735E1",
  },
  "& .MuiInputBase-root": {
    fontFamily: "ClotherBold",
    fontWeight: 500,
  },
  "& .riskLevelInput": {
    width: "100%",
    padding: "18.5px 14px",
    border: "solid 1px lightgray",
    borderRadius: "8px",
    "&.riskLevelInputRed": {
      color: "#F30202",
      backgroundColor: "rgba(243, 2, 2, 0.10)",
      borderColor: "#F30202",
    },
    "&.riskLevelInputYellow": {
      color: "#FFD541",
      backgroundColor: "#fbfbd3",
      borderColor: "#FFD541",
    },
    "&.riskLevelInputGreen": {
      color: "#21C274",
      backgroundColor: "#e6f7e6",
      borderColor: "#21C274",
    },
  },

  "& .Location-input .mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#8735E1",
  },
  "& .MuiInputLabel-root.mui-focused": {
    color: "#000",
  },
  "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    backgroundColor: "#F3F3F3",
  },
  "& .Location-area": {
    padding: "10px",
    border: "1px solid lightgray",
    width: "100%",
    borderRadius: "8px",
    color: "#2B2A29",
    fontFamily: "ClotherBold",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 500,
    resize: "none",
  },
  "& .Location-select": {
    padding: "10px",
    border: "1px solid lightgray",
    borderRadius: "8px",
    width: "100%",
    color: "#2B2A29",
    fontFamily: "ClotherBold",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .MuiMenuItem-root": {
      wordWrap: 'unset',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },

  "& .color-code-btn": {
    borderRadius: "8px",
    background: "#8735E1",
    padding: "14px 5px 14px 0",
    color: "#FFF",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    marginRight: "10px",
  },
  "& .customStepper": {
    background: "transparent",
    padding: "15px 0",
    cursor: "pointer",
    "& span, text": {
      fontFamily: "ClotherRegular",
      fontSize: "14px",
      "& .MuiStepLabel-active": {
        fontWeight: 700,
        fontFamily: "ClotherBold",
      }
    },
    "& .MuiStep-horizontal": {
      padding: "0",
    },
  },
  "& .MuiChip-deleteIcon": {
    fill: "#fff",
  },
  "& .post-btn": {
    borderRadius: "8px",
    background: "#8735E1",
    width: "160px",
    height: "50px",
    padding: "10px",
    color: "#FFF",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    marginRight: "10px",
    textTransform: "capitalize",
  },
  "& .next-btn": {
    borderRadius: "8px",
    border: "1px solid #8735E1",
    background: "#FFF",
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    width: "160px",
    textTransform: "capitalize",
  },
  "& .MuiSwitch-root .MuiSwitch-switchBase": {
    color: "white",
  },

  "& .MuiSwitch-root .Mui-checked": {
    color: "white",
  },
  "& .MuiSwitch-track": {
    background: "lightgray",
    opacity: 1,
    padding: "5px",
    marginLeft: "-5px",
    marginTop: "-5px",
    borderRadius: "50px",
    marginRight: "-5px",
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    background: "#8735E1",
    opacity: 1,
  },
  "& .add-action": {
    width: "100%",
    marginTop: "15px",
  },
  "& *::-ms-input-placeholder": {
    color: "#DCDCDC !important",
  },

  "& *::placeholder": {
    color: "#DCDCDC !important",
  },

  "& input::placeholder": {
    color: "#bbb !important",
    fontWeight: 600
  },
});
