import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

export interface DropdownValues {
  id: string;
  attributes: {
    name: string;
  }
}

interface CountryResponse {
  error: string;
  countries: {
    data: DropdownValues[];
  }
}

interface CityResponse {
  error: string;
  plants: {
    data: DropdownValues[];
  }
}

interface PlantResponse {
  error: string;
  business_units: {
    data: DropdownValues[];
  }
}

interface DepartmentResponse {
  error: string;
  designations: {
    data: DropdownValues[];
  }
}

interface AllWorkLocationResponse {
  error: string;
  work_locations: {
    data: DropdownValues[];
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  token: string;
  loading: boolean;
  isWorker: boolean;
  errorMsg: string;
  selectedYear: string;
  selectedCountry: DropdownValues[];
  country: DropdownValues[];
  selectedCities: DropdownValues[];
  cities: DropdownValues[];
  selectedPlants: DropdownValues[];
  plants: DropdownValues[];
  selectedDepartments: DropdownValues[];
  departments: DropdownValues[];
  allWorkLocation: DropdownValues[];
  selectedAllWorkLocation: DropdownValues[];
  roles: DropdownValues[];
  selectedRole: DropdownValues[];
  coursesAnalytics: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CoursesAnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAnalyticsCountryCallId: string = "";
  getAnalyticsCitiesCallId: string = "";
  getAnalyticsPlantsCallId: string = "";
  getAnalyticsDepartmentsCallId: string = "";
  getAnalyticsAllWorkLocationCallId: string = "";
  getRolesDataCallId: string = "";
  getCoursesAnalyticsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      token: "",
      loading: false,
      isWorker: false,
      errorMsg: "",
      selectedYear: "",
      selectedCountry: [],
      country: [],
      selectedCities: [],
      cities: [],
      selectedPlants: [],
      plants: [],
      selectedDepartments: [],
      departments: [],
      allWorkLocation: [],
      selectedAllWorkLocation: [],
      roles: [],
      selectedRole: [],
      coursesAnalytics: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getAnalyticsCountryCallId) {
        this.handleAnalyticsCountriesApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsCitiesCallId) {
        this.handleAnalyticsCitiesApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsPlantsCallId) {
        this.handleAnalyticsPlantsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsDepartmentsCallId) {
        this.handleAnalyticsDepartmentsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsAllWorkLocationCallId) {
        this.handleAnalyticsAllWorkLocationApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getRolesDataCallId) {
        this.handleGetRolesApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getCoursesAnalyticsCallId) {
        this.handleGetCoursesAnalyticsApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("authToken", false);
    this.setState({ token });
    this.getAnalyticsCountriesApiCall();
    this.getRolesAPI();
    this.getCoursesAnalyticsApiCall();
  }

  goToActionTrackerAnalytics = () => {
    const messasge = new Message(getName(MessageEnum.NavigationMessage));
    messasge.addData(getName(MessageEnum.NavigationTargetMessage), "Analytics");
    messasge.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messasge);
  }

  handleChangeCountry = (value: unknown) => {
    const currrentValue = value;
    if (currrentValue) {
      this.setState((prevState) => {

        let updatedSelectedCountry: DropdownValues[] = [...prevState.selectedCountry];
        const exists = updatedSelectedCountry.filter((country: DropdownValues) => currrentValue === country.id).length;

        if (exists) {
          updatedSelectedCountry = updatedSelectedCountry.filter((country: DropdownValues) => currrentValue !== country.id);
          this.setState({
            selectedCities: [],
            selectedPlants: [],
            selectedDepartments: [],
            selectedAllWorkLocation: [],
          });
        } else {
          updatedSelectedCountry.push(this.state.country.filter((country) => currrentValue === country.id)[0]);
        }

        return {
          selectedCountry: updatedSelectedCountry,
        };
      }, () => {
        this.getAnalyticsCitiesApiCall();
      });
    }
  }

  handleChangeCity = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedCity: DropdownValues[] = [...prevState.selectedCities];
      const exists = updatedSelectedCity.filter((city: DropdownValues) => currrentValue === city.id).length;

      if (exists) {
        updatedSelectedCity = updatedSelectedCity.filter((city: DropdownValues) => currrentValue !== city.id);
        this.setState({
          selectedPlants: [],
          selectedDepartments: [],
          selectedAllWorkLocation: [],
        });
      } else {
        updatedSelectedCity.push(this.state.cities.filter((city) => currrentValue === city.id)[0]);
      }

      return {
        selectedCities: updatedSelectedCity
      };
    }, () => {
      this.getAnalyticsPlantsApiCall();
    });
  }

  handleChangePlant = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedPlant: DropdownValues[] = [...prevState.selectedPlants];
      const exists = updatedSelectedPlant.filter((plant: DropdownValues) => currrentValue === plant.id).length;

      if (exists) {
        updatedSelectedPlant = updatedSelectedPlant.filter((plant: DropdownValues) => currrentValue !== plant.id);
        this.setState({
          selectedDepartments: [],
          selectedAllWorkLocation: [],
        });
      } else {
        updatedSelectedPlant.push(this.state.plants.filter((plant) => currrentValue === plant.id)[0]);
      }

      return {
        selectedPlants: updatedSelectedPlant
      };
    }, () => {
      this.getAnalyticsDepartmentsApiCall();
    });
  }

  handleChangeDepartment = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedDepartment: DropdownValues[] = [...prevState.selectedDepartments];
      const exists = updatedSelectedDepartment.filter((department: DropdownValues) => currrentValue === department.id).length;

      if (exists) {
        updatedSelectedDepartment = updatedSelectedDepartment.filter((department: DropdownValues) => currrentValue !== department.id);
        this.setState({
          selectedAllWorkLocation: [],
        });
      } else {
        updatedSelectedDepartment.push(this.state.departments.filter((department) => currrentValue === department.id)[0]);
      }

      return {
        selectedDepartments: updatedSelectedDepartment
      };
    }, () => {
      this.getAnalyticsAllWorkLocationApiCall();
    });
  }

  handleChangeAllWorkLocation = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedAllWorkLocation: DropdownValues[] = [...prevState.selectedAllWorkLocation];
      const exists = updatedSelectedAllWorkLocation.filter((all: DropdownValues) => currrentValue === all.id).length;

      if (exists) {
        updatedSelectedAllWorkLocation = updatedSelectedAllWorkLocation.filter((all: DropdownValues) => currrentValue !== all.id)
      } else {
        updatedSelectedAllWorkLocation.push(this.state.allWorkLocation.filter((all) => currrentValue === all.id)[0]);
      }

      return {
        selectedAllWorkLocation: updatedSelectedAllWorkLocation
      };
    });
  }

  handleChangeRole = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedRole: DropdownValues[] = [...prevState.selectedRole];
      const exists = updatedSelectedRole.filter((all: DropdownValues) => currrentValue === all.id).length;

      if (exists) {
        updatedSelectedRole = updatedSelectedRole.filter((all: DropdownValues) => currrentValue !== all.id)
      } else {
        updatedSelectedRole.push(this.state.roles.filter((all) => currrentValue === all.id)[0]);
      }

      return {
        selectedRole: updatedSelectedRole
      };
    });
  }


  renderSelectedValues = (items: DropdownValues[]): string => {
    let selectedValue: string = '';
    items.forEach((ddItem) => {
      selectedValue += ddItem.attributes.name + ', ';
    });
    selectedValue = selectedValue.trim().slice(0, -1);
    return selectedValue;
  }

  highLightSelectedValues = (selectedItems: DropdownValues[], id: string) => {
    return selectedItems.find((item) => item.id === id);
  }

  getAnalyticsCountriesApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsCountryCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAnalyticsCountryApiEndPoint
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  handleAnalyticsCountriesApiResponse = (responseJson: CountryResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ country: responseJson.countries.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  getAnalyticsCitiesApiCall = () => {
    const queryParam = this.state.selectedCountry.map((val) => `country_ids[]=${val.id}`).join('&')
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsCitiesCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsCitiesApiEndPoint}?${queryParam}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  handleAnalyticsCitiesApiResponse = (responseJson: CityResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ cities: responseJson.plants.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  getAnalyticsPlantsApiCall = () => {
    const queryParam = this.state.selectedCities.map((val) => `plant_ids[]=${val.id}`).join('&')
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsPlantsCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsPlantsApiEndPoint}?${queryParam}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  handleAnalyticsPlantsApiResponse = (responseJson: PlantResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ plants: responseJson.business_units.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  getAnalyticsDepartmentsApiCall = () => {
    const queryParam = this.state.selectedPlants.map((val) => `bu_ids[]=${val.id}`).join('&')
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsDepartmentsCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsDepartmentsApiEndPoint}?${queryParam}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  handleAnalyticsDepartmentsApiResponse = (responseJson: DepartmentResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ departments: responseJson.designations.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  getAnalyticsAllWorkLocationApiCall = () => {
    const queryParam = this.state.selectedDepartments.map((val) => `designation_ids[]=${val.id}`).join('&')
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsAllWorkLocationCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsAllApiEndPoint}?${queryParam}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  handleAnalyticsAllWorkLocationApiResponse = (responseJson: AllWorkLocationResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ allWorkLocation: responseJson.work_locations.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  getRolesAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesApiEndpoint
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getRolesDataCallId = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  handleGetRolesApiResponse = (responseJson: { error: string, roles: { data: DropdownValues[] } }) => {
    if (responseJson && !responseJson.error) {
      console.log('roles: ', responseJson.roles.data);
      this.setState({ roles: responseJson.roles.data });
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleSearch = () => {
    this.getCoursesAnalyticsApiCall();
  }

  getCoursesAnalyticsApiCall = () => {
    this.setState({ loading: true });
    const { selectedCountry, selectedCities, selectedPlants, selectedDepartments, selectedAllWorkLocation, selectedRole } = this.state;
    let queryParam = `page=1&per_page=10`;

    if (selectedCountry.length) {
      let contryIds = '';
      selectedCountry.forEach((value) => {
        contryIds += `${value.id},`;
      });
      queryParam += `&country_id[]=${contryIds}`;
    }

    if (selectedCities.length) {
      let cityIds = '';
      selectedCities.forEach((value) => {
        cityIds += `${value.id},`;
      });
      queryParam += `&location_id[]=${cityIds}`;
    }

    if (selectedPlants.length) {
      let plantIds = '';
      selectedPlants.forEach((value) => {
        plantIds += `${value.id},`;
      });
      queryParam += `&function_id[]=${plantIds}`;
    }

    if (selectedDepartments.length) {
      let departmentIds = '';
      selectedDepartments.forEach((value) => {
        departmentIds += `${value.id},`;
      });
      queryParam += `&sub_function_id[]=${departmentIds}`;
    }

    if (selectedAllWorkLocation.length) {
      let worklocationIds = '';
      selectedAllWorkLocation.forEach((value) => {
        worklocationIds += `${value.id},`;
      });
      queryParam += `&work_location_id[]=${worklocationIds}`;
    }

    if (selectedRole.length) {
      let roleIds = '';
      selectedRole.forEach((value) => {
        roleIds += `${value.id},`;
      });
      queryParam += `&role_id[]=${roleIds}`;
    }

    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsActionsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCoursesAnalyticsCallId = getAnalyticsActionsRequestMsg.messageId;
    getAnalyticsActionsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCoursesAnalyticsApiEndPoint}?${queryParam}`
    );
    getAnalyticsActionsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsActionsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsActionsRequestMsg.id, getAnalyticsActionsRequestMsg);
  }

  handleGetCoursesAnalyticsApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({ coursesAnalytics: responseJson });
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
    this.setState({ loading: false });
  }
  // Customizable Area End
}
