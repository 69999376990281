import React from "react";

// Customizable Area Start
import {
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NotificationsBlock from '../../../components/src/NotificationsBlock.web';
import { Link } from "react-router-dom";
import { chevronRight, refreshImg } from '../../../components/src/assets';
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box
          style={{ fontWeight: 'bold' }} p={3} pb={0} display='flex'>
          <Link to="/Dashboard" style={{ textDecoration: 'none' }}>
            <Typography variant='body2' style={{ ...webStyles.breadCrumbs, color: '#A1A1A1' } as React.CSSProperties}>
              {configJSON.home}
            </Typography>
          </Link>
          <img
            src={chevronRight}
            alt="chevron right"
            height='20'
            width='20'
            style={webStyles.breadCrumbs as React.CSSProperties} />
          <Typography
            variant='body2'
            style={{
              ...webStyles.breadCrumbs,
              color: '#8735E1'
            } as React.CSSProperties
            }>
            {configJSON.Notifications}
          </Typography>
        </Box>
        <Typography
          variant='h5'
          style={{
            fontWeight: 'bold',
            padding: '1.6rem'
          }}>{configJSON.Notifications}</Typography>
        <Box pb={10}>
          <NotificationsBlock
            data-test-id="notification-block"
            navigation={this.props.navigation}
            notifications={this.state.notifications}
            getNotifications={() => { this.handleLoadNotifications() }}
            fullScreen={true}
            totalPages={this.state.totalPages}
            redirect={(check: boolean) => this.postView(check)}
          />
        </Box>
        <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} position={'relative'} marginTop={'-60px'}>
          {this.state.loading ?
            <CircularProgress data-test-id='spinner' style={webStyles.notificationPendingBlock as React.CSSProperties} />
            :
            <>
              {!this.state.redirect && this.state.currentPage + 1 <= this.state.totalPages &&
                <Typography data-test-id="load-more-text" style={webStyles.loadMore} onClick={() => this.handleLoadNotifications()}>
                  {configJSON.Load_more} <img src={refreshImg} style={webStyles.loadMoreIcon} />
                </Typography>
              }
            </>
          }
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "ClotherRegular",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyles = {
  breadCrumbs: {
    cursor: 'pointer',
    paddingRight: '0.2rem',
    fontWeight: '700'
  },
  notificationPendingBlock: {
    width: '50px',
    height: '50px',
    color: '#8735E1',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  loadMore: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#8735E1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  loadMoreIcon: {
    width: '15px',
    height: '15px',
    marginLeft: '10px',
    marginRight: '10px'
  },
};
// Customizable Area End
