import React from "react";

import {
    Container,
    Box,
    InputAdornment,
    // Customizable Area Start
    TextField,
    CircularProgress,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Pagination from "@material-ui/lab/Pagination";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import SearchController, {
    Props,
    configJSON,
} from "./SearchController.web";

export default class Search extends SearchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { searchResults, page, rowsPerPage, loading, noData, errorText, searchText } = this.state;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={"md"}>
                    <Box sx={webStyle.mainWrapper}>
                        <Box sx={webStyle.topBackHearder}>
                            <Box sx={webStyle.ArrowBackIcon}><ArrowBackIcon onClick={this.onPressBackBtn} /></Box>
                            <Box sx={webStyle.ImgText}>{configJSON.labelTitleTextSearch}</Box>
                            <Box sx={webStyle.topMlauto} />
                        </Box>
                        <Box sx={webStyle.topHeaderSearch}>
                            <TextField
                                data-test-id="search-tags"
                                id="outlined-multiline-flexible"
                                onChange={this.handleSearchText}
                                value={searchText}
                                placeholder="Search..."
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    endAdornment: <InputAdornment position="end" ><ArrowForwardIosIcon onClick={this.getSearchResult} /></InputAdornment>
                                }}
                            />
                        </Box>
                        {searchResults?.length !== 0 && <Box sx={{ width: '100%', }}>
                            <Box sx={webStyle.searchedResultHeading}>{configJSON.relatedTag}:</Box>
                            {searchResults?.map(value => {
                                return (
                                    <Box data-test-id="individual-tag" key={value.tag_id} sx={webStyle.searchedResult} onClick={() => this.onPressTags(value)} >
                                        <Box className="tagName">{value.tag}</Box>
                                        <Box className="tagSubName">{value.no_of_images}</Box>
                                    </Box>)
                            })}
                        </Box>}
                        {searchResults?.length > 10 && <Pagination
                            data-test-id="pagination"
                            style={webStyle.pagination}
                            page={page + 1}
                            count={Math.ceil(searchResults?.length / rowsPerPage)}
                            onChange={this.onPageChangeHandler}
                            showFirstButton
                            showLastButton
                            variant="outlined"
                            shape="rounded"
                            siblingCount={0}
                            boundaryCount={1}
                        />
                        }
                        {loading && <Box data-test-id={"activityLoader"} sx={webStyle.loaderWrapper}>
                            <Box sx={webStyle.circularContainer}>
                                <CircularProgress />
                            </Box>
                        </Box>}
                        {noData && <Typography style={webStyle.errorStyle}>{errorText}</Typography>}
                    </Box>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    errorStyle: {
        color: 'red'
    },
    topBackHearder: {
        borderBottom: 'solid 1px #cbd5d5',
        width: '100%',
        textAlign: 'center',
        fontSize: '22px',
        color: '#000',
        fontWeight: ' 700',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 15px'
    },
    topHeaderSearch: {
        padding: '10px 15px',
        width: '100%',
        position: 'relative',
        '& .MuiFormControl-root.MuiTextField-root': {
            width: '100%',
            marginBottom: '20px',
        },
        '& .MuiInput-underline:before': {
            display: 'none',
        },
        '& .MuiInputAdornment-positionStart': {
            marginRight: '0px',
        },
        '& svg': {
            position: 'absolute',
            top: '8px',
            left: '16px',
            width: '20px',
        },
        '& input': {
            border: 'none',
            width: '100%',
            backgroundColor: '#a3a4a6',
            padding: '10px 15px',
            borderRadius: '4px',
            fontSize: '16px',
            color: '#000',
            paddingLeft: '35px',
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 'auto',
            position: 'absolute',
            right: '48px',
            maxHeight: '1px',
            height: 'auto',
            top: '0',
            color: '#fff',
        }
    },
    loaderWrapper: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(0, 0, 0, .4)",
        zIndex: 1,
    },
    circularContainer: {
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
    },
    topMlauto: {
        marginLeft: 'auto',
    },
    ImgText: {
        margin: '0 auto',
    },
    ArrowBackIcon: {
        marginRight: 'auto'
    },
    pagination: {
        marginTop: "15px",
        display: "flex",
        justifyContent: "center"
    },
    searchedResult: {
        border: 'solid 1px #a3a4a6',
        width: '100%',
        backgroundColor: '#fff',
        padding: '10px 15px',
        borderRadius: '8px',
        fontSize: '16px',
        color: '#000',
        marginBottom: '20px',
        boxSizing: 'border-box',
        '& .tagName': {
            width: '100%',
            fontSize: '16px',
            color: '#000',
            fontWeight: '600',
        },
        '& .tagSubName': {
            width: '100%',
            fontSize: '14px',
            color: '#000',
            marginTop: '10px',
        }
    },
    searchedResultHeading: {
        fontSize: '18px',
        color: '#000',
        fontWeight: '600',
        width: '100%',
        marginBottom: '20px',
    }
};
// Customizable Area End
