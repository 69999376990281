import React from "react";

import {
    Container,
    Box,
    Typography,
    // Customizable Area Start
    styled,
    Grid,
    Radio,
    FormGroup,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
    Paper,
    Button,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";
import { webStyles } from "../../../components/src/styles";
import moment from "moment";
import i18n from "i18next"

const today = new Date()

// Customizable Area End

import WorkerCheckpointDetailController, {
    Props,
} from "./WorkerCheckpointDetailController";

export default class WorkerCheckpointDetail extends WorkerCheckpointDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    workerCheckpointDetailForm = () => {
        const { checkpointData, assignPersonRadio } = this.state;
        return (
            <Box className="mainBox">
                <Grid container className="paperContent" spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Unit of Measurement:</Box>
                            <Box className="boxFont2 trimWords">{checkpointData?.attributes?.unit_of_measurement?.name ? checkpointData?.attributes?.unit_of_measurement?.name : 'NA'}</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Total Number(Target):</Box>
                            <Box className="boxFont2">{checkpointData?.attributes?.total_target ? checkpointData?.attributes?.total_target : 'NA'}</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Compiled:</Box>
                            <Box className="boxFont2">{checkpointData?.attributes?.compiled ? checkpointData?.attributes?.compiled : 'NA'}</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Non Compiled:</Box>
                            <Box className="boxFont2">{checkpointData?.attributes?.non_compiled != undefined ? checkpointData?.attributes?.non_compiled : 'NA'}</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Targeted Date:</Box>
                            <Box className="boxFont2">
                                {checkpointData?.attributes?.target_date
                                    ? moment(checkpointData?.attributes?.target_date).format('DD/MM/YYYY')
                                    : null}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="remarksGrid">
                            <Box className="remarksBox1">Remarks:</Box>
                            <Box className="remarksBoxFont2">
                                {checkpointData?.attributes?.remark && checkpointData?.attributes?.remark?.split('\n').map((value, index) => (
                                    <React.Fragment key={index}>
                                        {value}<br />
                                    </React.Fragment>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup className="footerWarning">
                            <Grid container>
                                <Grid>
                                    <FormHelperText className="radioButtonLabel">
                                        Assign person for Non Compiled Equipment?
                                    </FormHelperText>
                                </Grid>
                                <Grid>
                                    <RadioGroup
                                        aria-labelledby="hazard-radio-buttons-group-label"
                                        defaultValue={assignPersonRadio}
                                        name="radio-buttons-group"
                                        className="radiobuttons"
                                        data-test-id="assign_person_radio"
                                        onChange={this.handleAssignPersonRadio}
                                    >
                                        <FormControlLabel
                                            name="hazard"
                                            data-test-id="hazardFirstId"
                                            value="yes"
                                            control={<Radio color="primary" />}
                                            label={
                                                <Typography
                                                    className={assignPersonRadio === 'yes' ? "yesRadioTypo" : "noRadioTypo"}
                                                >
                                                    Yes
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            name="hazard"
                                            data-test-id="hazardSecondId"
                                            value="no"
                                            control={<Radio color="primary" />}
                                            label={
                                                <Typography
                                                    className={assignPersonRadio === 'no' ? "yesRadioTypo" : "noRadioTypo"}
                                                >
                                                    No
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <WorkerWrapper>
                        <Container maxWidth={"xl"}>

                            <Box style={webStyles.breadcrumbWrapper}>
                                <Typography style={webStyles.breadcrumbItem} data-test-id="navigate_to_audit_screen" onClick={this.navigateToAuditScreen}>{i18n.t('Audit')}</Typography>
                                <NavigateNext style={webStyles.navigateIcon} />
                                <Typography style={webStyles.breadcrumbItem} data-test-id="navigate_to_checkpoint_screen" onClick={this.navigateToCheckpointScreen}>{this.state.machineData?.equipment_name}</Typography>
                                <NavigateNext style={webStyles.navigateIcon} />
                                <Typography style={webStyles.breadcrumbActiveItem} >{this.state.checkpointData?.attributes?.name}</Typography>
                            </Box>
                            <Box className="checkpointTitle">
                                <Typography className="mainHeading trimWords">{this.state.checkpointData?.attributes?.name}</Typography>
                            </Box>

                            {this.workerCheckpointDetailForm()}

                            <Box className="submit-button">
                                <Button
                                    data-test-id="assign_btn"
                                    variant="contained"
                                    className="add-btn"
                                    disabled={this.state.checkpointData?.attributes?.total_target === null && this.state.assignPersonRadio === 'yes'}
                                    onClick={() => this.navigateToNonCompiledAssignment()}
                                >
                                    Assign
                                </Button>
                            </Box>
                            {this.state.loading && (
                                <Box data-test-id={"activityLoader"} className="loader-outer">
                                    <Box className="loader-inner">
                                        <CircularProgress />
                                    </Box>
                                </Box>
                            )}
                        </Container>
                    </WorkerWrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const WorkerWrapper = styled(Box)({
    padding: "20px 0",
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    "& .headingWrapper": {
        display: "flex",
    },
    "& .loader-outer": {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "100%",
        minHeight: "100vh",
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .loader-inner": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "40%",
        left: "50%",
    },
    "& .footerWarning": {
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    "& .radiobuttons": {
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    "& .radioButtonLabel": {
        fontFamily: 'ClotherBold',
        color: '#2B2A29',
        fontSize: '17px',
        marginRight: '20px',
    },
    "& .paperContent .MuiPaper-root": {
        backgroundColor: 'rgb(238, 238, 238)',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        height: '60px',
        padding: '10px 15px',
    },
    "& .boxFont2": {
        fontFamily: "clotherBold",
    },
    "& .remarksBoxFont2": {
        minHeight: "100px",
        fontFamily: "clotherRegular",
        fontSize: '17px',
        padding: '10px 15px',
        color: '#828282',
        wordBreak: "break-word"
    },
    "& .trimWords": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        maxWidth: '100%'
    },
    "& .subHeadingText": {
        fontWeight: 700,
        lineHeight: "22px",
        textEdge: "cap",
        fontStyle: "normal",
        cursor: "pointer",
        leadingTrim: "both",
        fontFamily: "ClotherRegular",
        color: "#A1A1A1",
        fontSize: "16px",
    },
    "& .remarksGrid": {
        border: '1px solid lightgrey',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    "& .enterInputField": {
        height: '60px',
        border: '1px solid lightgray',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        padding: '10px 15px',
        width: '96%'
    },
    "& .remarksBox1": {
        fontFamily: "clotherBold",
        height: '20px',
        borderBottom: "1px solid lightgrey",
        padding: '10px 15px'
    },
    "& .activeHeadingText": {
        fontWeight: 700,
        color: "#8735E1",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        leadingTrim: "both",
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
    },
    "& .mainHeading": {
        fontWeight: 700,
        fontStyle: "normal",
        fontSize: "26px",
        leadingTrim: "both",
        color: "#2B2A29",
        textEdge: "cap",
        fontFamily: "ClotherBold",
    },
    "& .checkpointTitle": {
        display: "flex",
        margin: "10px 0",
        justifyContent: "space-between",
    },
    "& .submit-button": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginBottom: "10px",
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .mainGrid": {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '20px 20px 0px 20px',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .mainBox": {
        padding: '20px 20px 20px 20px',
        backgroundColor: 'white',
        borderRadius: "8px",
        margin: "15px 0px"
    },
    "& .add-btn": {
        width: "160px",
        height: "50px",
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& .yesRadioTypo": {
        fontFamily: "ClotherBold",
        marginBottom: "4px"
    },
    "& .noRadioTypo": {
        fontFamily: "ClotherRegular",
        marginBottom: "6px"
    }
});

// Customizable Area End
