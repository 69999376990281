Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.getAssessementMethod = "GET";
  exports.submitAssessementTestAPiMethod = "POST";
  exports.allAssessementTestApiContentType = "application/json";
  exports.getAllAssessementTestUrl = `bx_block_assessmenttest/assessments`;
  exports.apiSubmitAssessmentQuestions = `bx_block_assessmenttest/submit_que_answer`;
  exports.submitAssessementTestUrl = `bx_block_assessmenttest/submit_assessment?assessment_id=`;
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "AssessmentTest";
  exports.labelBodyText = "AssessmentTest Body";
  exports.NoDataAvailableText = "No courses available at the moment. Please check back later for new content";

    // Customizable Area End