import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  FormControl,
  Input,
  FormGroup,
  FormLabel,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Button,
  InputLabel,
  MenuItem,
  Typography,
  Modal,
  Select,
} from '@material-ui/core';
import { IExam, IStudent } from './types/types';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#afe9ed',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End
import PtTestsForCourseScreenController, {
  Props,
  configJSON,
} from './PtTestsForCourseScreenController';
export default class PtTestsForCourseScreen extends PtTestsForCourseScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCourseExams = () => {
    const { courseExams } = this.state;
    return (
      <>
        <Button
          data-test-id={'createTestButton'}
          title={'Create Test'}
          variant="contained"
          onClick={() => this.showCreateTestModal()}
        >
          {configJSON.createTestText}
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{configJSON.examNameText}</TableCell>
              <TableCell>{configJSON.examIdText}</TableCell>
              <TableCell>{configJSON.creditsText}</TableCell>
              <TableCell>{configJSON.passPercentageText}</TableCell>
              <TableCell>{configJSON.addChangeTestScoreText}</TableCell>
              <TableCell>{configJSON.deleteTestText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseExams?.map((exam: IExam, index: number) => (
              <TableRow key={`exam-${index}`}>
                <TableCell>{exam.attributes.name}</TableCell>
                <TableCell>{exam.attributes.id}</TableCell>
                <TableCell>{exam.attributes.credits}</TableCell>
                <TableCell>{exam.attributes.pass_percentage}</TableCell>
                <TableCell>
                  <Button
                    data-test-id={`addScoreButton${index}`}
                    title={'Add Test Score'}
                    variant="contained"
                    onClick={() => this.showAddTestScoreModal(exam)}
                  >
                    {configJSON.addChangeTestScoreText}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    data-test-id={`deleteExamButton${index}`}
                    title={'Delete Exam'}
                    variant="contained"
                    onClick={() => this.deleteTest(exam.attributes.id)}
                  >
                    {configJSON.deleteTestText}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  };

  renderCreateTestModal = () => (
    <Modal
      onClose={this.hideCreateTestModal}
      open={this.state.createTestModalVisible}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={webStyle.modal}
    >
      <FormGroup>
        <FormControl>
          <FormLabel>{configJSON.testNameText}</FormLabel>
          <Input
            data-test-id="testNameInput"
            placeholder={'Enter Test Name'}
            onChange={(event) => this.handleTestNameChange(event.target.value ?? '')}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{configJSON.testCreditsText}</FormLabel>
          <Input
            data-test-id="testCreditsInput"
            placeholder={'Enter test Credits'}
            onChange={(event) => this.handleCreditsChange(event.target.value ?? '')}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{configJSON.passPercentageText}</FormLabel>
          <Input
            data-test-id="passPercentageInput"
            placeholder={'Enter pass percentage'}
            onChange={(event) =>
              this.handlePassPercentageChange(event.target.value ?? '')
            }
          />
        </FormControl>
        <Button
          data-test-id={'createTestSubmitButton'}
          title={'Create test'}
          variant="contained"
          onClick={() =>
            this.createTest(
              this.state.courseId,
              this.state.testName,
              this.state.credits,
              this.state.passPercentage,
            )
          }
        >
          {configJSON.createTestText}
        </Button>
        <Button
          data-test-id={'btnCloseModal'}
          onClick={this.hideCreateTestModal}
          variant="contained"
        >
          {configJSON.closeText}
        </Button>
      </FormGroup>
    </Modal>
  );

  renderAddTestScoreModal = () => {
    const { studentList } = this.state;
    return (
      <Modal
        onClose={this.hideAddTestScoreModal}
        open={this.state.addTestScoreModalVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={webStyle.modal}
      >
        <FormGroup>
          <FormControl>
            <InputLabel>{configJSON.selectStudentIdText}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Student ID"
              value={this.state.currentStudentId + '' ?? ''}
              style={webStyle.mainWrapper}
              onChange={(event) =>
                this.handleCurrentStudentIdChange(event.target.value + '')
              }
            >
              {studentList &&
                studentList.map((student: IStudent, index: number) => (
                  <MenuItem
                    value={student.attributes.account_id + ""}
                    key={index}
                  >
                    {student.attributes.account_id + ''}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{configJSON.newTestScoreText}</FormLabel>
            <Input
              data-test-id="newTestScoreInput"
              placeholder={'Enter New Test Score'}
              onChange={(event) =>
                this.handleNewTestScoreChange(event.target.value ?? '')
              }
            />
          </FormControl>
          <Button
            data-test-id={'changeTestScoreSubmitButton'}
            title={'Change test score'}
            onClick={() =>
              this.changeTestScore(
                (this.state.currentStudent &&
                  this.state.currentStudent.attributes.account_id) ??
                  1,
                (this.state.currentTest &&
                  this.state.currentTest.attributes.id) ??
                  -1,
                this.state.newTestScore,
              )
            }
          >
            {configJSON.changeTestScoreText}
          </Button>

          <Button
            data-test-id={'btnCloseModal'}
            onClick={() => {
              this.hideAddTestScoreModal();
            }}
          >
            {configJSON.closeText}
          </Button>
        </FormGroup>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={'md'}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <Box>
              {this.renderCourseExams()}
              {this.renderAddTestScoreModal()}
              {this.renderCreateTestModal()}
            </Box>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: 'Roboto-Medium',
    alignItems: 'center',
    paddingBottom: '30px',
    width: '100%',
    padding: 0,
    margin: 0,
    color: 'black',
    backgroundColor: 'white',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
  selectStyle: {
    color: '#303233',
    text: '#303233',
    backgroundColor: 'lightblue',
  },
  modal: {
    backgroundColor: 'lightblue',
    alignSelf: 'center',
    alignItems: 'center',
    width: '75%',
    justifyContent: 'space-between',
    margin: '2em',
  },
};
// Customizable Area End
