import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { configJSON } from "./ForgotPasswordController";

export const sendPostJSONRequest = (endpoint: string, payload: any,  setRequestId: (id: string) => void) => {
  const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

  const header = {
    "Content-Type": configJSON.forgotPasswordAPiContentType,
  };

  setRequestId(message.messageId);
  message.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint,
  );
  message.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  message.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );
  message.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );

  runEngine.sendMessage(message.id, message);
}

export const sendOtpRequest = (email: string, setRequestId: (id: string) => void) => {
  const body = {
    type: "email_account",
    data: {
      email,
    },
  };
  const enpoint = configJSON.emailOtpEndpoint;
  sendPostJSONRequest(enpoint, body, setRequestId);
};
