import React from "react";

import {
    Container,
    Box,
    // Customizable Area Start
    Chip,
    CircularProgress,
    Typography
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import ImageScreenController, {
    Props,
    configJSON,
} from "./ImageScreenController.web";

export default class ImageScreen extends ImageScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={"md"}>
                    <Box sx={webStyle.mainWrapper}>
                        <Box sx={webStyle.topBackHearder}>
                            <Box sx={webStyle.ArrowBackIcon}><ArrowBackIcon data-test-id="go-back" onClick={this.onPressBackBtn} /></Box>
                            <Box sx={webStyle.ImgText}>{configJSON.imageScreenTitle}</Box>
                            <Box sx={webStyle.topMlauto} />
                        </Box>
                        {this.state.imageUrl !== '' && <Box sx={webStyle.selectedImageBox}>
                            <img className="imagesListing" src={this.state.imageUrl} />
                            <Box sx={webStyle.tagsHeading}>{configJSON.relatedTag}:</Box>
                            <Box>{this.state.tagsArray?.map(tags => <Chip data-test-id="associated-tag" onClick={() => this.onPressTags(tags)} label={tags.title} />)}</Box>
                        </Box>}
                        {this.state.loading && <Box data-test-id={"activityLoader"} sx={webStyle.loaderWrapper}>
                            <Box sx={webStyle.circularContainer}>
                                <CircularProgress />
                            </Box>
                        </Box>}
                        {this.state.noRecordFound && <Typography style={webStyle.errorText}>{configJSON.recordNotFound}</Typography>}
                    </Box>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    topBackHearder: {
        borderBottom: 'solid 1px #cbd5d5',
        width: '100%',
        textAlign: 'center',
        fontSize: '22px',
        color: '#000',
        fontWeight: ' 700',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 15px'
    },
    topMlauto: {
        marginLeft: 'auto',
    },
    ImgText: {
        margin: '0 auto',
    },
    ArrowBackIcon: {
        marginRight: 'auto'
    },
    tagsHeading: {
        fontSize: '18px',
        color: '#000',
        fontWeight: '600',
        width: '100%',
        marginBottom: '20px',
        textAlign: 'center',
    },
    loaderWrapper: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(0, 0, 0, .4)",
        zIndex: 1,
    },
    circularContainer: {
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
    },
    errorText: {
        marginTop: '56px',
        fontSize: '19px',
        color: 'red'
    },
    selectedImageBox: {
        width: '100%',
        display: 'flex',
        marginBottom: '20px',
        gap: '20px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        paddingTop: '40px',
        '& .imagesListing': {
            width: '210px',
            border: 'solid 1px #a3a4a6',
            height: '250px',
            borderRadius: '6px',
        },
        '& .imagesListing img': {
            width: '100%',
            height: '100%',
            borderRadius: '6px',
        },
        '& .MuiChip-root': {
            backgroundColor: '#141414',
            margin: '0 6px 6px 6px',
            borderRadius: '8px',
            color: '#fff',
        }
    },
};
// Customizable Area End
