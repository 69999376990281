import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

export interface DropdownValues {
  id: string;
  attributes: {
    name: string;
  }
}

interface ActionsCount {
  total_courses_count: number,
  completed_course_count: number,
  pending_course_count: number,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  isWorker: boolean;
  tableText: string;
  coursesCounts: ActionsCount;
  categoryWiseCourses: Array<any>;
  pendingCourses: Array<any>;
  completedCourses: Array<any>;
  loadSeeAllView: boolean;
  currentSeeAllTable: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HsePassportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCoursesCountsApiId: string = "";
  getCategoryWiseCoursesApiId: string = "";
  getPendingCoursesApiId: string = "";
  getRecentlyCompletedCoursesApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      loading: false,
      isWorker: false,
      tableText: "Total Assigned Courses",
      coursesCounts: {
        total_courses_count: 0,
        completed_course_count: 0,
        pending_course_count: 0,
      },
      categoryWiseCourses: [],
      pendingCourses: [],
      completedCourses: [],
      loadSeeAllView: false,
      currentSeeAllTable: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getCoursesCountsApiId) {
        this.handleGetCoursesCountsApiResponse(responseJson)
      }

      if (apiRequestCallId === this.getCategoryWiseCoursesApiId) {
        this.handleGetCategoryWiseCoursesApiResponse(responseJson)
      }

      if (apiRequestCallId === this.getPendingCoursesApiId) {
        this.handleGetPendingCoursesApiResponse(responseJson)
      }

      if (apiRequestCallId === this.getRecentlyCompletedCoursesApiId) {
        this.handleGetRecentlyCompletedCoursesApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("authToken", false);
    this.setState({ token });
    this.getCoursesCountsApi();
    this.getCategoryWiseCoursesApi();
    this.getPendingCoursesApi();
    this.getRecentlyCompletedCoursesApi();
  }

  stopLoader = () => {
    this.setState({ loading: false });
  }

  handleGetCoursesCountsApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({ coursesCounts: responseJson })
    }
    this.stopLoader();
  }

  handleTableandText = (text: string) => {
    this.setState({ tableText: text });
  }

  getCoursesCountsApi = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const getCoursesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCoursesCountsApiEndpoint}`
    );

    this.getCoursesCountsApiId = getCoursesRequestMsg.messageId;
    getCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(getCoursesRequestMsg.id, getCoursesRequestMsg);
  }

  getCategoryWiseCoursesApi = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const getCategoryWiseCoursesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getCategoryWiseCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCategoryWiseCoursesApiEndpoint}`
    );

    this.getCategoryWiseCoursesApiId = getCategoryWiseCoursesRequestMsg.messageId;
    getCategoryWiseCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCategoryWiseCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(getCategoryWiseCoursesRequestMsg.id, getCategoryWiseCoursesRequestMsg);
  }

  handleGetCategoryWiseCoursesApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      const courses = responseJson.map((item: any, index: number) => {
        return {
          id: index,
          category: item.category,
          progress: `${item.completed_course_count}/${item.total_course_count}`,
        }
      });
      this.setState({ categoryWiseCourses: courses });
    }
    this.stopLoader();
  }

  getPendingCoursesApi = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const getPendingCoursesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getPendingCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPendingCoursesApiEndpoint}`
    );

    this.getPendingCoursesApiId = getPendingCoursesRequestMsg.messageId;
    getPendingCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPendingCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(getPendingCoursesRequestMsg.id, getPendingCoursesRequestMsg);
  }

  handleGetPendingCoursesApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      const courses = responseJson.map((item: any, index: number) => {
        return {
          id: index,
          title: item.title,
          category: item.category,
          dueDate: item.due_date,
        }
      });
      this.setState({ pendingCourses: courses });
    }
    this.stopLoader();
  }

  getRecentlyCompletedCoursesApi = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const getCompletedCoursesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getCompletedCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRecentlyCompletedCoursesApiEndpoint}`
    );

    this.getRecentlyCompletedCoursesApiId = getCompletedCoursesRequestMsg.messageId;
    getCompletedCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCompletedCoursesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(getCompletedCoursesRequestMsg.id, getCompletedCoursesRequestMsg);
  }

  handleGetRecentlyCompletedCoursesApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      const courses = responseJson.map((item: any, index: number) => {
        return {
          id: index,
          title: item.title,
          category: item.category,
          pointsEarned: '0',
        }
      });
      this.setState({ completedCourses: courses });
    }
    this.stopLoader();
  }

  openTableFullViewMode = (tableName: string) => {
    this.setState({ loadSeeAllView: true, currentSeeAllTable: tableName });
  }

  closeTableFullViewMode = () => {
    this.setState({ loadSeeAllView: false, currentSeeAllTable: '' });
  }
  // Customizable Area End
}
