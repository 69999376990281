import React from 'react'
// Customizable Area Start
import { Button, Col, Form, Modal, Row, Select } from 'antd'
import QuestionBankController from "./QuestionBankController.web"
import "./QuestionBank.web.css"
import ReactPaginate from "react-paginate";
import ProminLoader from "../../../components/src/ProminLoader.web"
// Customizable Area End

class QuestionBank extends QuestionBankController {
  // Customizable Area Start
  renderDownloadTemplate = () => {
    return (
      <>
        <Modal
          closable={false}
          open={this.state.downloadChangeModal}
          onCancel={this.downloadChangeModalCancel}
          footer={null}
          centered
          className='main-modal'
        >
          <div className='modal-content'>
            <h3 className="modal-heading">Select Grade and Age</h3>
          </div>
          <Form
            name='basic'
            layout='vertical'
            initialValues={{ remember: false }}
            onFinish={this.onFinish}
            autoComplete='off'
            className='login-indv-form'
          >
            <Form.Item
              label=''
              name='grade'
              rules={[
                {
                  required: true,
                  message: 'Please Select Grade'
                },
              ]}
            >
              <Select
                placeholder={"Select Grade"}
                className="InputFields"
              >
                {this.state.gradeList.map((item) => (
                  <Select.Option
                    value={item.attributes.id} key={item.attributes.id}
                  >{item.attributes.name}</Select.Option>
                ))}
              </Select>

            </Form.Item>

            <Form.Item
              label=''
              name='age'
              rules={[
                {
                  required: true,
                  message: 'Please Select Age'
                },
              ]}
            >
              <Select
                placeholder={"Select Age"}
                className="InputFields"
              >
                {this.state.ageList.map((item) => (
                  <Select.Option
                    value={item.attributes.id} key={item.attributes.id}
                  >{item.attributes.name}</Select.Option>
                ))}
              </Select>

            </Form.Item>
            <Form.Item>
              <div className='pswrd-submit-btn'>
                <button type='submit' className='pswrd-btn save-btn close-btn Quetion_bank_btn' >
                  Submit
                </button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderShowQuetionList = () => {
    let orderPageCount = this.state.allData.meta.total_pages
    return (
      <>
        <Modal
          closable={false}
          open={this.state.quetionSeeModal}
          onCancel={this.quetionSeeModalCancel}
          footer={null}
          centered
          className='main-modal'
        >
          <div className='modal-content'>
            <h3 className="modal-heading">Question and Answers List</h3>
          </div>
          {this.state.allQuetionDataForShowButton.length > 0 &&
            <>
              {this.state.allQuetionDataForShowButton.map((item, index: number) => {
                return (
                  <div className='Question_answer_box' key={index}>
                    <div className='Question_box_view'>
                      <p className='Question_box'>
                        {item.attributes.question_no}. {item.attributes.question}
                      </p>
                    </div>
                    <Row gutter={[8, 8]}>
                      {item.attributes.options.data.map((optionItem, optionIndex: number) => {
                        return (
                          <Col lg={12} xl={12} md={12} sm={24} xs={24} key={optionIndex} >
                            <div className='Options_view_box'>
                              <p className="Options_view">
                                {optionItem.attributes.possible_answer}
                              </p>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                    <div className='modal-content'>
                      <h3 className="modal-heading description_heading">Answer Description:</h3>
                    </div>
                    <div className='Answer_description_box'>
                      <p className='Answer_description'>
                        {item.attributes.link_to_detail}
                      </p>
                      <p className='Answer_main_box'>
                        Answer : {item.attributes.correct_answer}
                      </p>
                    </div>
                  </div>
                )
              })}
            </>
          }
          <ReactPaginate
            className="class-pag"
            previousLabel={<i className="fa fa-angle-left" />}
            nextLabel={<i className="fa fa-angle-right" />}
            breakLabel={"..."}
            breakClassName={"page-item"}
            onPageChange={this.handlePageClick}
            pageRangeDisplayed={5}
            pageCount={orderPageCount}
            pageClassName={"page-item"}
            breakLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            previousLinkClassName={"previous page-link"}
            nextLinkClassName={"next page-link"}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </Modal>
      </>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <div>
        <Row justify={"center"}>
          <Col lg={20}>
            <h1 className='Quetion_bank_list_header_text'>Create Question Bank</h1>
            <Button data-testid={"buttonFileID"} type="primary" shape="round" className='Quetion_bank_btn' onClick={this.downloadChangeModal}>
              Download File
            </Button>
            <input
              data-testid={"textInputID"}
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              name="image-upload"
              id="input"
              onChange={this.onChangeCall}
            />
            <label
              className=" Profile_Upload_btn image-upload"
              htmlFor="input"
            >
              <div className="gallery_select">
                <p>
                  Upload File
                </p>
              </div>
            </label>
            <h1 className='Quetion_bank_list_header_text'>Question Bank List</h1>
            {this.state.quetionBankList.map((quetionBankListItem) => {
              return (
                <div className='Quetion_bank_list_header' key={quetionBankListItem.attributes.id}>
                  <h4>{quetionBankListItem.attributes.name}</h4>
                  <Button
                    data-testid={"buttonID"} type="primary" shape="round" className='Quetion_bank_btn' onClick={() => this.quetionSeeModal(quetionBankListItem)}>
                    Show File
                  </Button>
                </div>
              )
            })}
          </Col>
        </Row>
        {this.renderDownloadTemplate()}
        {this.renderShowQuetionList()}
      </div>
    )
  }
  // Customizable Area End
}

// Customizable Area Start
export default ProminLoader(QuestionBank);
export { QuestionBank }
// Customizable Area End