import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";

export interface RiskLevel {
  name: string;
  color: string;
}

export interface RiskLevelBG {
  name: string;
  backgroundColor: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  postData: any[];
  riskLevels: any[];
  actionPostData: any;
  locations: any[];
  personList: any[];
  selectedDate: "",
  totalCount: number;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyPostsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getMyPostsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      loading: false,
      riskLevels: [
        { name: "1A", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1B", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1C", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "1E", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2A", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "2B", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "2C", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2E", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3A", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3B", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3C", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3E", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4A", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4B", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4C", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4D", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4E", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5A", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5B", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5C", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5D", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5E", color: "#F30202", backgroundColor: "#FFEDED" },
      ],
      postData: [],
      locations: [],
      actionPostData: {
        attributes: {
          employee_id: "employee_id",
          action_taken: "action_taken",
          due_date: "",
          risk_count: '3A',
          location: "",
          worker_account: {
            profile_image: "",
            full_name: "ABC",
          },
          post: {}
        },
      },
      selectedDate: "",
      personList: [],
      totalCount: 0,
      currentPage: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (message.id === getName(MessageEnum.RestAPIResponceMessage) && getName(MessageEnum.RestAPIResponceMessage) === message.id && responseJson) {
      if (apiRequestCallId === this.getMyPostsApiCallId) {
        this.getPostsCallback(responseJson);
        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let token = await getStorageData("authToken", false);
    this.setState({ token });
    this.getPosts(1)
  }

  getPostsCallback(response: any) {
    const postData = response.safety_council_data.data;
    let totalCount = response.safety_council_data.meta.pagination.total_count
    this.setState({ postData, totalCount });
  }

  // Api Call Function
  checkborderColor = (itemRiskLevel: string) => {
    let colorChoose = "#DCDCDC";
    this.state.riskLevels.forEach((item: any) => {
      if (item.name == itemRiskLevel) {
        colorChoose = item.color;
      }
    });
    return colorChoose;
  };

  getPosts = (newPage: number) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.postsContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostAPIEndPoint + `?page=${newPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  SafetyCouncilPostDetail = (postData: any) => {
    setStorageData("postId", postData.attributes.id);
    removeStorageData("corrective_action");
    removeStorageData("preventive_action");
    if (postData.attributes.action_type && postData.attributes.description) {
      setStorageData(postData.attributes.action_type, postData.attributes.description);
    }
    this.props.navigation.navigate("SafetyCouncilPostDetail")
  }

  handlePageChange = (_e: any, newPage: number) => {
    this.setState({ currentPage: newPage });
    this.getPosts(newPage);
  };
  // Customizable Area End
}
