import { TextField, createTheme } from '@material-ui/core';
import { Shadows } from '@material-ui/core/styles/shadows';
import { withStyles } from '@material-ui/styles';

export const theme = createTheme({
    shadows: [...createTheme().shadows].map(() => 'none') as Shadows,
    overrides: {
        MuiSvgIcon: {
            root: {
                '&.MuiSelect-icon': {
                    marginRight: '1rem'
                }
            }
        },
        MuiFormControlLabel: {
            root: {
                marginRight: 0,
                marginLeft: 0
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none',
            }
        },
        MuiList: {
            root: {
                '&-padding': {
                    padding: 0
                }
            }
        },
        MuiInput: {
            root: {
                padding: '1rem',
                fontFamily: 'ClotherRegular',
                fontSize: '16px',
                placeholder: {
                    color: '#DCDCDC',
                }
            }
        },
        MuiSelect: {
            root: {
                fontFamily: 'ClotherRegular',
                fontSize: '16px',
                placeholder: {
                    color: '#DCDCDC',
                }
            },
            select: {
                '&:focus': {
                    backgroundColor: '#fff'
                },
                '&.MuiSelect-select': {
                    borderRadius: '0.5rem'
                }
            },
            selectMenu: {
                height: '100%',
                alignItems: 'center',
                display: 'inline-block'
            }
        },
        MuiMenu: {
            paper: {
                border: '1px solid #DCDCDC',
                borderRadius: '0.5rem',
                marginTop: '0.4rem',
                width: '20.1rem',
                marginLeft: '-1rem',
            },
            list: {
                padding: 0,
                maxHeight: '15rem'
            }
        },
        MuiOutlinedInput: {
            root: {
                border: '1px solid #DCDCDC',
                borderRadius: '0.5rem',
            }
        },
        MuiMenuItem: {
            root: {
                fontFamily: 'ClotherRegular',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                '&:hover': {
                    color: '#8735E1'
                },
                '&:focus': {
                    backgroundColor: '#fff'
                }
            },
        },
        MuiRadio: {
            root: {
                padding: 0
            },
            colorSecondary: {
                '&$checked': {
                    color: '#8735E1',
                },
            },
        },
    },
    palette: {
        action: {
            selected: '#F9F3FF',
            hover: '#F9F3FF'
        }
    },
});

export const webStyle = {
    refreshIcon: {
        width: '15px', 
        height: '15px', 
        marginLeft: '10px'
    },
    commentListItem: {
        padding: '10px',
        marginBottom: '10px',
        background: '#F8F8F8'
    },
    CommentBox: {
        width: '99%',
        marginLeft: '10px',
        marginTop: '10px',
    },
    loadMore: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#8735E1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    commentValue: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#A1A1A1',
        marginTop: '8px'
    },
    timeDotIcon: {
        width: '6px',
        height: '6px',
        background: '#D9D9D9',
        borderRadius: '50%',
        margin: '0px 6px',
    },
    commentTimeText: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: "#A1A1A1",
        display: 'flex',
        alignItems: 'center'
    },
    commentUserName: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: "#18181E",
    },
    commentImage: {
        width: '24px',
        height: '24px',
        marginRight: '5px'
    },
    commentsHeader: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: "#18181E",
        marginBottom: '12px'
    },
    textareaStyle: {
        padding: '15px',
        borderRadius: '8px',
        width: " 95%",
        minHeight: "100px",
        border: "1px solid #DCDCDC",
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
    },
    likeText: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        marginLeft: '6px',
    },
    pointerClass: {
        cursor: 'pointer',
    },
    inputAttachment: {
        borderRadius: '0.5rem',
        border: 'none',
        fontFamily: 'ClotherRegular',
    },
    uploadAttachment: {
        textTransform: 'none',
        display: 'block'
    },
    attachmentBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonsGrp2: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "space-between",
    },
    buttons2: {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginTop: "15px",
    },
    reportBtn: {
        marginRight: "1rem",
        color: "#8735e1",
        backgroundColor: "#fff",
        border: "1px solid #8735e1",
        borderRadius: "8px",
        fontFamily: "ClotherBold",
        fontSize: "20px",
    },
    loader: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    footerWrap: {
        marginTop: "200px"
    },
    hashTagChips: {
        borderRadius: "8px",
        color: "rgb(168 160 160)",
        border: "1px solid #DCDCDC",
        fontWeight: 400,
        fontSize: "16px",
    },
    hashTagBox: {
        display: "flex",
        flexFlow: "wrap",
        gap: "10px"
    },
    inputMargin: {
        marginTop: "5px"
    },
    buttonsGroup: {
        marginTop: "30px",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: `0px !important`,
    },
    footerContainer: {
        width: "100%",
        marginTop: "320px"
    },
    selectPlaceholder: {
        color: "#DCDCDC",
        fontFamily: 'ClotherRegular',
        fontSize: '16px'
    },
    fontBlock: {
        fontSize: '0.875rem',
        color: '#A1A1A1',
        fontFamily: 'ClotherRegular'
    },
    uploadImg: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    iploadedImg: {
        maxWidth: '5rem',
        maxHeight: '5rem',
        objectFit: 'contain'
    },
    footer: {
        position: 'absolute',
        bottom: '2rem',
        right: '2rem',
        width: 'calc(100% - 4rem)'
    },
    buttons: {
        marginTop: '1rem',
        marginLeft: 'auto',
        width: 'fit-content'
    },
    button: {
        width: '10rem',
        height: '3rem',
        borderRadius: '8px',
    },
    transButton: {
        marginRight: '1rem',
        color: '#8735E1',
        border: '1px solid var(--Purple, #8735E1)',
        fontFamily: 'ClotherBold',
        fontSize: '20px',
    },
    saveButton: {
        marginRight: '1rem',
        color: '#fff',
        backgroundColor: '#21C274'
    },
    fullButton: {
        color: '#fff',
        backgroundColor: '#8735E1',
        fontFamily: 'ClotherBold',
        fontSize: '20px'
    },
    upload: {
        height: '8rem',
        textTransform: 'none',
        display: 'block'
    },
    smallText: {
        fontSize: '0.75rem'
    },
    smalLabel: {
        marginLeft: '0.3rem'
    },
    switch: {
        paddingTop: '0.3rem',
        margin: 0
    },
    inLineFlex: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: "center"
    },
    inputBlock: {
        width: '48%'
    },
    wideInputBlock: {
        position: 'relative',
        width: '100%'
    },
    customTopIndent: {
        marginTop: '1rem',
    },
    header: {
        margin: 0
    },
    label: {
        color: 'var(--Black, #18181E)',
        fontSize: '1rem',
        fontFamily: 'ClotherRegular'
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        marginTop: '1rem'
    },
    input: {
        borderRadius: '0.5rem',
        border: '1px solid #DCDCDC',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white'
    },
    inputStd: {
        height: '3rem',
        width: '320px'
    },
    inputDisabled: {
        background: '#F3F3F3'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        height: '100%'
    },
    modalMaindivStyle: {
        background: '#fff',
        fontFamily: 'ClotherRegular',
        width: '42rem',
        borderRadius: '8px',
        padding: '30px',
        position: 'relative'
    },
    errorStyle: {
        color: "#F30202",
        marginBottom: "0.5rem",
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
    },
    RadioLabel: {
        fontFamily: 'ClotherRegular',
        color: '#2B2A29',
        fontSize: '0.825rem'
    },
    bold: {
        fontWeight: 'bold'
    },
    buttonLeft: {
        left: 0,
        position: 'absolute'
    },
    footerWarning: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between'
    },
    footerRadioG: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    footerRadioLabel: {
        margin: '0 0.5rem'
    },
    formControlLabel: {
        fontFamily: 'ClotherRegular',
        fontSize: '0.75rem'
    },
    closeBtn: {
        position: 'absolute',
        right: '0.5rem',
        top: '1rem'
    },
    mic: {
        position: 'absolute',
        right: 0,
        cursor: 'pointer'
    },
    mobileModal: {
        width: '85vw',
        minHeight: '115vh',
        left: 0,
        top: 0,
        position: 'absolute'
    },
    mobileButtons: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        margin: '1rem 0',
        width: '100%'
    },
    mobileButton: {
        position: 'relative',
        width: '100%',
        margin: 0
    },
    aiLoaderWrapper: {
        width: '100%',
        height: '92%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        background: '#fff',
        top: 60,
        left: 0,
    } as const,
    aiErrorWrapper: {
        width: '100%',
        height: '92%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        background: '#fff',
        top: 60,
        left: 0,
    } as const,
    aiLoaderText: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#18181E',
    },
    aiLoaderSubText: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#A1A1A1',
        fontStyle: 'italic'
    }
};

export const InputField = withStyles({
    root: {
        width: '100%',
        height: '50px',
        marginRight: '0px',
        borderRadius: '8px',
        background: '#F8F8F8',
        padding: "0px 0px 5px 0px",
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DCDCDC'
        },
        '& .MuiOutlinedInput-notchedOutline:hover': {
            borderColor: '#DCDCDC'
        },
        '& .PrivateNotchedOutline-root-14': {
            border: '1px solid #DCDCDC',
            borderRadius: '8px',
        },
    }
})(TextField)


