import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Reaptcha from "reaptcha";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import CaptchaController, { Props, configJSON } from "./CaptchaController.web";

export default class Captcha extends CaptchaController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <form>
            <Reaptcha
              ref={(e) => this.recaptchaRef(e)}
              sitekey={this.state.key}
              onVerify={(recaptchaResponse: any) =>
                this.onVerify(recaptchaResponse)
              }
            />
            <button
              data-test-id="btnSubmit"
              type="submit"
              disabled={!this.state.verified}
            >
              Submit
            </button>
            <button data-test-id="btnReset" onClick={() => this.reset()}>
              Reset
            </button>
          </form>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {};
// Customizable Area End
