import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
// Customizable Area End

import BreadcrumbController, {
  Props,
} from "./BreadcrumbController";
import { NavigateNext } from "@material-ui/icons";
import { webStyles } from "./styles";

export default class Breadcrumb extends BreadcrumbController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { data } = this.props;

    return (
      <>
        <Box style={webStyles.breadcrumbWrapper}>
          {data.map((item, index: number) => (
            <>
              <Typography
                style={item.isActive ? webStyles.breadcrumbActiveItem : webStyles.breadcrumbItem}
                onClick={() => this.handleNavigation(item.navigate, item.url)}
              >{item.title}</Typography>
              {index !== data.length - 1 && <NavigateNext style={webStyles.navigateIcon} />}
            </>
          ))}
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {};
// Customizable Area End
