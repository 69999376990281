export const calendarLogo = require('../assets/calendar.png');
export const congrats = require('../assets/congrats.png');
export const spots = require('../assets/spots-bg.png');
export const heartHands = require("../assets/heart-hands.png");
export const star = require("../assets/star.png");
export const profileIcon = require("../assets/profile.svg");
export const chevronRight = require("../assets/chevron_right.png");
export const loginBg = require("../assets/login-bg.svg");
export const profilePng = require("../assets/profile.png");
export const refreshImg = require("../assets/refresh.png");
export const errorIcon = require("../assets/error-icon.svg");
