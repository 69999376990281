import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { IRanking } from './types/types';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End
import PtStudentRankingScreenController, {
  Props,
  configJSON,
} from './PtStudentRankingScreenController';

export default class PtStudentRankingScreen extends PtStudentRankingScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStudentRankings = () => {
    const { studentRankings } = this.state;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{configJSON.rankingText}</TableCell>
            <TableCell>{configJSON.studentNameText}</TableCell>
            <TableCell>{configJSON.gpaText}</TableCell>
            <TableCell>{configJSON.viewStudentInfoText}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {studentRankings &&
            studentRankings.map((ranking: IRanking, index: number) => (
            <TableRow key={`student-${index}`}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{ranking.attributes.user_name}</TableCell>
              <TableCell>{ranking.attributes.gpa}</TableCell>
              <TableCell>
                <Button
                  data-test-id={`studentInfoButton${index}`}
                  title={'View Student Info'}
                  variant="contained"
                  onClick={() => this.navigateToStudentsInfoScreen(ranking)}
                >
                  {configJSON.viewStudentInfoText}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={'sm'}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <Box>{this.renderStudentRankings()}</Box>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
};
// Customizable Area End
