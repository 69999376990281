Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "audittrail2";
exports.labelBodyText = "audittrail2 Body";
exports.errorMsg = "This field is required";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethodType = "DELETE";
exports.getLocationsApiEndPoint = "bx_block_audittrail2/audits/get_locations";
exports.getCategoriesApiEndPoint = "bx_block_audittrail2/audits/get_categories";
exports.addNewMachineApiEndPoint = "bx_block_audittrail2/audits/create_new_machine";
exports.getMachinesApiEndPoint = "bx_block_audittrail2/audits/get_worker_machines";
exports.getFilterDepartmentApiEndPoint = "bx_block_audittrail2/audits/filter_by_category";
exports.getSubCategoryApiEndPoint = "bx_block_audittrail2/audits/get_sub_categories";
exports.getReferenceStandardApiEndPoint = "bx_block_audittrail2/audits/get_reference_standards";
exports.createCheckpointApiEndPoint = "bx_block_audittrail2/audits/create_checkpoint";
exports.getAllCheckpointApiEndPoint = "bx_block_audittrail2/audits/show_all_checkpoints";
exports.getShowCheckpointApiEndPoint = "bx_block_audittrail2/audits/show_checkpoint";
exports.getUnitOfMeasurementApiEndPoint = "bx_block_audittrail2/audits/get_unit_of_measurement";
exports.patchWorkerCheckpointApiEndPoint = "bx_block_audittrail2/audits/update_worker_checkpoint";
exports.getAssignPersonApiEndPoint = "bx_block_audittrail2/audits/get_users";
exports.createActionTrackerApiEndPoint = "bx_block_audittrail2/audits/create_audit_action_trackers";
exports.editMachineApiEndpoint = "bx_block_audittrail2/audits/update_machine";
exports.deleteCheckpointApiEndPoint="bx_block_audittrail2/audits/delete_checkpoint";
exports.editCheckpointApiEndpoint="bx_block_audittrail2/audits/update_supervisor_checkpoint";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End