import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {  AssessmentItemInterface, AttemptedAssessmentInterface } from './IAssessment'
import StorageProvider from "../../../framework/src/StorageProvider";
import { setStorageData } from "framework/src/Utilities";

interface CourseAttributes {
  id: number;
  course_name: string;
}

interface Course {
  id: string;
  type: string;
  attributes: CourseAttributes;
}

interface Response {
  courses: {
    data: Course[];
  };
}
export interface ApiCallInterface {
  contentType?:string,
  method?:string,
  endPoint?:string,
  body?:object,
  token?:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  assessmentList: AssessmentItemInterface[],
  assessmenttestList: AssessmentItemInterface[],
  isError: boolean;
  assessmentCategories: AssessmentItemInterface[];
  isSuccess: boolean;
  selectedCategory: string | undefined;
  selectedId: string | number | undefined;
  tabNum: number
  attemptedAssessment: AttemptedAssessmentInterface[];
  isLoading: boolean;
  token:string;
  courses:object[];
  loading:boolean;
  currentPage: number;
  totalCount: number;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentCategories: string = "";
  apiGetAssessmentByCategory: string = "";
  apiAttemptedAssessment: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    let authToken:any = localStorage.getItem('authToken')||"{}";
    this.state = {
      assessmentList: [],
      assessmenttestList: [],
      isError: false,
      isLoading: true,
      isSuccess: false,
      assessmentCategories: [],
      selectedCategory: '',
      selectedId: '0',
      tabNum: 1,
      attemptedAssessment: [],
      token: authToken,
      courses:[],
      loading:true,
      currentPage: 1,
      totalCount: 100,
    }
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAssessmentApi()
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.assessmentCategories !== this.state.assessmentCategories) {
      this.setState({
        selectedId: `${this.state?.assessmentCategories![0]?.id}`
      })
    }

  }

  apiAssessmentCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body ,token } = data;
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiStatement(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleApiStatement(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (responseJson) {
      if (apiRequestCallId === this.apiGetAssessmentByCategory) {
        this.getAssessmentByCategorySuccesscallBack(responseJson.data);
      }
      else if (apiRequestCallId === this.apiGetAssessmentCategories) {
        this.getCatSuccesscallBack(responseJson)
      }
      else if (apiRequestCallId === this.apiAttemptedAssessment) {
        this.getAttemptedAssessmentApiSuccesscallBack(responseJson.data)
      }
    }  else if (errorReponse) {
      this.setState({
        isLoading: false,
        isSuccess: false,
        isError: true,
      })
    }
  }


  getAssessmentApi = async () => {
    this.apiGetAssessmentCategories = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: `${configJSON.getAssessementTestCategoriesUrl}?page=${this.state.currentPage}&per_page=9`,
      token: this.state.token,
    });
  };
  getAssessmentByCategoryApi = async () => {
    this.apiGetAssessmentByCategory = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAllAssessementTestUrl,
    });
  };
  getAttemptedAssessmentApi = async () => {
    this.apiAttemptedAssessment = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAttemptedAssessmentsUrl,
    });
  };

  getAssessmentByCategorySuccesscallBack =  (data: AssessmentItemInterface[]) => {
    let filterData = data.filter((item: AssessmentItemInterface) => {
      {
        return item.attributes?.assessment_type === this.state.selectedCategory
      }
    })
    this.setState({
      isLoading: false,
      isError: false,
      isSuccess: true,
      assessmentList: data,
      assessmenttestList: filterData
    })
  };

  getCatSuccesscallBack =  (response:Response) => {
    const { courses: { data, meta } }:any = response;
    this.setState({
      courses:data,
      loading:false,
      totalCount:meta?.pagination?.total_count
    })
  };

  handlePageChange = (_e: any, newPage: number) => {
    this.setState({ currentPage: newPage },()=>this.getAssessmentApi())
  };

  getAttemptedAssessmentApiSuccesscallBack =  (data: AttemptedAssessmentInterface[]) => {
    this.setState({
      attemptedAssessment: data
    })
  };



  onSelected(item: AssessmentItemInterface) {
    this.props.navigation.navigate('AssessmentDetail', { id: item.id })
  }
  onAttemptedSelected(item: AttemptedAssessmentInterface) {
    this.props.navigation.navigate('AssessmentResult', { id: item.attributes?.assessment_id })
  }

  convertMinutesToHoursAndMinutes(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let result = hours + " hour";
    if (hours !== 1) {
      result += "s";

    }
    result += remainingMinutes + " minute";
    if (remainingMinutes !== 1) {
      result += "s";
    }
    return result;
  }


  onTabClick(numInput: number) {
    this.setState({
      tabNum: numInput
    })
  }
  handleCatSelected(item: AssessmentItemInterface) {
    this.setState({ selectedId: `${item?.id}` })
    let list = this.state.assessmentList?.filter((newItem: AssessmentItemInterface) => {
      return newItem?.attributes?.assessment_type === item.attributes?.name
    })

    this.setState({
      assessmenttestList: list
    })
  }

  handleSearch = (text: string) => {
    if (text.trim() === '') {
      let first = this.state.assessmentList.filter((item: AssessmentItemInterface) => {
        {
          return item?.attributes?.assessment_type === this.state.selectedCategory
        }
      })

      this.setState({
        assessmenttestList: first
      })
    } else {
      const filtered = this.state.assessmenttestList.filter((item: AssessmentItemInterface) => {
        const name = item.attributes?.name?.toLowerCase() ?? 'Unknown';
        return name?.includes(text.toLowerCase())
      });
      this.setState({
        assessmenttestList: filtered
      })
    }
  }

  unCompletedListAssessment(){
    let unCompletedList: AssessmentItemInterface[] = this.state.assessmenttestList.filter((item) => item.attributes?.is_completed !== true)
    return unCompletedList
  }

  navigateToAssessmentDetail = (courses_Id: number | string) => {
    setStorageData('courses_Id', courses_Id);
    const messasge = new Message(getName(MessageEnum.NavigationMessage));
    messasge.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentDetail");
    messasge.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messasge);
    // this.props.navigation.navigate("AssessmentDetail")
  }
  // Customizable Area End
}
