import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "framework/src/Utilities";

interface LocationData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    country_id: number;
  }
}
interface LocationResponseData {
  errors: string;
  locations: {
    data: LocationData[];
  }
}
interface MachinesData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  }
}
interface MachinesResponseData {
  errors: string;
  machine_tools: {
    data: MachinesData[];
  }
}
export interface ListScheduledInspectionData {
  id: string;
  attributes: {
    account_id: number;
    action_trackers: [];
    assigned_to: string;
    assigned_to_id: number;
    department: string;
    department_id: number;
    email: string;
    equipment_name: string;
    equipment_number: number;
    first_image: string;
    inspection_date: string;
    location: string;
    location_id: number;
    machine_tool: string;
    machine_tool_id: number;
    second_image: string;
    status: string;
    account: {
      full_name: string;
      profile_image: string;
    }
  }
}
interface ListScheduledInspectionResponse {
  error: string;
  inspections: {
    data: ListScheduledInspectionData[];
    meta: {
      pagination: {
        current_page: number;
        total_count: number;
      }
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  totalCount: number;
  currentPage: number;
  openFilterModal: boolean;
  selectedStatus: string[];
  finalSelectedStatus: string[];
  errorMsg: string;
  loading: boolean;
  scheduleInspectionData: ListScheduledInspectionData[];
  selectedMachine: MachinesData[];
  selectedLocation: LocationData[];
  finalSelectedMachine: MachinesData[];
  finalSelectedLocation: LocationData[];
  locations: LocationData[];
  machines: MachinesData[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Repeatingtasks2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLocationsApiCallId: string = "";
  getMachinesApiCallId: string = "";
  listScheduleInspectionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      totalCount: 0,
      currentPage: 1,
      openFilterModal: false,
      errorMsg: "",
      loading: false,
      selectedStatus: [],
      selectedLocation: [],
      selectedMachine: [],
      finalSelectedStatus: [],
      finalSelectedLocation: [],
      finalSelectedMachine: [],
      locations: [],
      machines: [],
      scheduleInspectionData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getLocationsApiCallId) {
        this.handleLocationApiResponse(responseJson)
      }

      if (apiRequestCallId === this.getMachinesApiCallId) {
        this.handleMachineApiResponse(responseJson)
      }

      if (apiRequestCallId === this.listScheduleInspectionApiCallId) {
        this.handleListScheduleInspection(responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("authToken", false);
    this.setState({ token });

    this.getLocationsApiCall();
    this.getMachinesApiCall();
    this.listScheduleInspectionApiCall();
  }

  handleLocationApiResponse = (responseJson: LocationResponseData) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ locations: responseJson.locations.data })
    }
    else if (responseJson && responseJson.errors) {
      this.setState({
        errorMsg: responseJson.errors,
      });
    }
  }

  handleMachineApiResponse = (responseJson: MachinesResponseData) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ machines: responseJson.machine_tools.data })
    } else if (responseJson && responseJson.errors) {
      this.setState({
        errorMsg: responseJson.errors,
      });

    }
  }

  getMachinesApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMachinesApiCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMachinesApiEndPoint}?per_page=200`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  getLocationsApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLocationsApiCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLocationsApiEndPoint}?per_page=200`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  handleScheduleInspection = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ScheduleInspection");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  navigateToDetail = (id: string) => {
    setStorageData('inspectionid', id);
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "DetailPlannedInspection");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), id);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  handleListScheduleInspection = (responseJson: ListScheduledInspectionResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        loading: false,
        scheduleInspectionData: responseJson.inspections.data,
        totalCount: responseJson.inspections.meta?.pagination?.total_count,
      })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        loading: false,
        scheduleInspectionData: [],
        totalCount: 0
      });
    }
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage }, () => {
      this.listScheduleInspectionApiCall();
    });
  };

  listScheduleInspectionApiCall = () => {
    this.setState({ loading: true })
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const locationIds: string[] = [];
    this.state.finalSelectedLocation.forEach((val) => locationIds.push(val.id));

    const machineIds: string[] = [];
    this.state.finalSelectedMachine.forEach((val) => machineIds.push(val.id));

    let endPoint = `${configJSON.listScheduleInspectionApiEndPoint}?page=${this.state.currentPage}&per_page=10`;

    const statuses = (this.state.finalSelectedStatus.length === 1 && this.state.finalSelectedStatus[0] === "Cancelled")
      ? this.state.finalSelectedStatus
      : this.state.finalSelectedStatus.filter(value => value !== "Cancelled");

    if (statuses.length > 0 || locationIds.length > 0 || machineIds.length > 0) {
      const queryParams1 = statuses.map((id) => `statuses[]=${id}`).join('&');
      const queryParams2 = locationIds.map((val) => `location_ids[]=${val}`).join('&');
      const queryParams3 = machineIds.map((val) => `machine_tool_ids[]=${val}`).join('&');

      endPoint += "&filters=true";

      if (queryParams1.length) {
        endPoint += `&${queryParams1}`
      }

      if (queryParams2.length) {
        endPoint += `&${queryParams2}`
      }

      if (queryParams3.length) {
        endPoint += `&${queryParams3}`
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listScheduleInspectionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  applyFiters = () => {
    this.closeFilterModal();
    this.setState({
      finalSelectedLocation: this.state.selectedLocation,
      finalSelectedStatus: this.state.selectedStatus,
      finalSelectedMachine: this.state.selectedMachine
    }, () => {
      this.listScheduleInspectionApiCall();
    });
  }

  handleOpenFilterModal = () => {
    this.setState({
      openFilterModal: true,
      selectedLocation: this.state.finalSelectedLocation,
      selectedStatus: this.state.finalSelectedStatus,
      selectedMachine: this.state.finalSelectedMachine
    })
  }

  closeFilterModal = () => {
    this.setState({ openFilterModal: false });
  };

  handleStatusClear = () => {
    this.setState({ selectedStatus: [] });
  };
  handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { checked } = event.target;
    this.setState((prevState) => {
      let updatedSelectedStatus: string[] = [...prevState.selectedStatus];
      if (!checked) {
        updatedSelectedStatus = updatedSelectedStatus.filter((status: string) => status !== value)
      } else {
        updatedSelectedStatus.push(value);
      }
      return {
        selectedStatus: updatedSelectedStatus,
      };
    });

  }

  handleSelectedLocationClear = () => {
    this.setState({ selectedLocation: [] })
  }

  handleLocationsChange = (value: unknown) => {
    const currrentValue = value;
    
    this.setState((prevState) => {
      let updatedSelectedLocation: LocationData[] = [...prevState.selectedLocation];
      const exists = updatedSelectedLocation.filter((location: LocationData) => currrentValue === location.id).length;

      if (exists) {
        updatedSelectedLocation = updatedSelectedLocation.filter((location: LocationData) => currrentValue !== location.id)
      } else {
        updatedSelectedLocation.push(this.state.locations.filter((location) => currrentValue === location.id)[0]);
      }

      return {
        selectedLocation: updatedSelectedLocation,
      };
    });
  }

  handleSelectedMachinesClear = () => {
    this.setState({ selectedMachine: [] })
  }

  handleMachineChange = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {
      let updatedSelectedMachine: MachinesData[] = [...prevState.selectedMachine];
      const exists = updatedSelectedMachine.filter((machine: MachinesData) => currrentValue === machine.id).length;

      if (exists) {
        updatedSelectedMachine = updatedSelectedMachine.filter((machine: MachinesData) => currrentValue !== machine.id)
      } else {
        updatedSelectedMachine.push(this.state.machines.filter((machine) => currrentValue === machine.id)[0]);
      }

      return {
        selectedMachine: updatedSelectedMachine,
      };
    });
  }
  handleClearAll = () => {
    this.setState({
      selectedStatus: [],
      selectedLocation: [],
      selectedMachine: []
    })
  }

  // Customizable Area End
}
