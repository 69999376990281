import React from "react";

import {
    Box,
    // Customizable Area Start
    Container,
    Grid,
    Typography,
    styled,
    Button,
    Select,
    InputBase,
    MenuItem,
    Input,
    InputAdornment,
    TextField,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import DetailPlannedInspectionController, { Props, configJSON } from "./DetailPlannedInspectionController";
import { NavigateNext, KeyboardArrowDown } from "@material-ui/icons";
import { calendarLogo, upload, mic, micStart } from "./assets";
import DatePicker from "react-datepicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextFieldProps } from '@material-ui/core/TextField';
import { webStyles } from "../../../components/src/styles";

const today = new Date();

type RenderInputParams = TextFieldProps & {
    InputProps: {
        className?: string;
        endAdornment?: React.ReactNode;
        startAdornment?: React.ReactNode;
        ref?: React.Ref<HTMLDivElement>;
    };
};
// Customizable Area End


export default class DetailPlannedInspection extends DetailPlannedInspectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderMicForDesc = () => {
        return this.state.listeningForDesc ? micStart : mic;
    }

    renderMicForCorrectiveActionDesc = (counter: number, currentCounter: number) => {
        return counter === currentCounter && this.state.listeningCorrectiveActionDesc ? micStart : mic;
    }

    CustomInputDate = (
        value: Date | null | undefined,
        onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
        onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, position: number) => void,
        position: number
    ) => {
        return (
            <Input
                placeholder="Select Date"
                value={value}
                onClick={onClick}
                fullWidth={true}
                disableUnderline
                data-test-id="date_test"
                name="date_test"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event, position)}
                defaultValue={""}
                endAdornment={
                    <div>
                        <InputAdornment position="end">
                            <img src={calendarLogo} style={{ width: 28, height: 28, cursor: 'pointer' }} />
                        </InputAdornment>
                    </div>
                }
            />
        );
    }



    detailsInspection = () => {
        const { area, serialNumber, assignPersonLoading, locationDescription, correctiveActions, submitClicked } = this.state;
        const areaDepartment = area.department.length > 20 ? `${area.department.substring(0, 20)}...` : area.department;
        const areaLocation = area.location.length > 20 ? `${area.location.substring(0, 20)}...` : area.location;
        const areaLabel = `${areaDepartment} / ${areaLocation}`;

        return (
            <Box className="mainBox">
                <Grid md={12} className="mainGrid">
                    <Grid className="inputBlock" md={6} xs={12}>
                        <Box> <Typography className="field-Label">Area</Typography> </Box>
                        <TextField
                            className="Location-input"
                            data-test-id="area"
                            id="outlined-basic"
                            label={areaLabel}
                            variant="outlined"
                            disabled
                        />
                        <Typography className="errorStyle">
                            {submitClicked && !area && configJSON.errorrMsg}
                        </Typography>
                    </Grid>
                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="field-Label">Sr Number</Typography>
                        <Input
                            name="serial_number"
                            data-test-id="serial_number"
                            className="field-input"
                            value={serialNumber}
                            disableUnderline
                            placeholder="Sr Number"
                            onChange={this.handleChangeSerialNumber}
                            autoComplete="off"
                        />
                        <Typography className="errorStyle">
                            {submitClicked && !serialNumber && configJSON.errorrMsg}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid md={12} className="textareaGrid">
                    <Grid md={12} xs={12}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography className="field-Label">Location - Description</Typography>
                            <img
                                src={this.renderMicForDesc()}
                                alt="mic icon"
                                className="mikeIcon"
                                data-test-id="description_speechtext"
                                onClick={this.handleDescriptionMic}
                            />
                        </Box>
                        <textarea
                            name="location_description"
                            data-test-id="location_description"
                            className="textarea-input"
                            value={locationDescription}
                            placeholder="Location - Description"
                            onChange={this.handleChangeLocationDescription}
                        />
                        <Typography className="errorStyle">
                            {submitClicked && !locationDescription && configJSON.errorrMsg}
                        </Typography>
                    </Grid>
                </Grid>
                {this.state.correctiveActions.map((value, index) => {
                    const displayError = submitClicked && value.errorState;
                    return (
                        <>
                            <Grid md={12} className="textareaGrid">
                                <Grid className="inputBlock" md={6} xs={12}>
                                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography className="field-Label">Corrective Action {index + 1}</Typography>
                                        <img
                                            src={this.renderMicForCorrectiveActionDesc(value.counter, this.state.currentCorrectiveActionSpeech)}
                                            className="mikeIcon"
                                            data-test-id="corrective_description_speechtext"
                                            onClick={() => this.handleCorrectiveActionMic(value, index)}
                                        />
                                    </Box>
                                    <textarea
                                        name="correctiveActions_descripetion"
                                        data-test-id="correctiveActions_descripetion"
                                        className="textarea-input"
                                        value={value.correctiveAction}
                                        placeholder="Corrective Action Description"
                                        onChange={(event) => this.handleChangeCorrectiveActions(event.target.value, index)}
                                    />
                                    <Typography className="errorStyle">
                                        {displayError && !correctiveActions[index].correctiveAction && configJSON.errorrMsg}
                                    </Typography>
                                </Grid>

                                <Grid className="inputBlock" md={6} xs={12}>
                                    <Box><Typography className="field-Label">{" "}</Typography></Box>
                                    <Box className="uploadImageBox"><Button component="label" className="uploadImage">
                                        <div className="upload-img">
                                            <img
                                                src={value.uploadedImagePreview || upload}
                                                alt="upload image"
                                                className="uploadedImg"
                                            />
                                        </div>
                                        {!value.uploadedImage && (
                                            <> <div className="uploadImageFont">Upload Image</div> </>
                                        )}
                                        <input
                                            data-test-id="upload_image"
                                            type="file"
                                            name="image2"
                                            accept="image/png, image/jpeg"
                                            onChange={(event) => {
                                                this.handleUploadedImage(event, index);
                                            }}
                                        />
                                    </Button></Box>
                                    <Typography className="errorStyle">
                                        {correctiveActions[index].errorMessageImage}
                                        {displayError && !correctiveActions[index].uploadedImage && configJSON.errorrMsg}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid md={12} className="textareaGrid">
                                <Grid className="inputBlock" md={6} xs={12}>
                                    <Input
                                        name="deficiency_number"
                                        data-test-id="deficiency_number"
                                        className="field-input"
                                        value={value.deficiencyNumber}
                                        disableUnderline
                                        placeholder="Deficiency Number"
                                        autoComplete="off"
                                        onChange={(event) => this.handleChangeDeficiencyNumber(event, index)}
                                    />
                                    <Typography className="errorStyle">
                                        {displayError && !correctiveActions[index].deficiencyNumber && configJSON.errorrMsg}
                                    </Typography>
                                </Grid>
                                <Grid className="inputBlock" md={6} xs={12}>
                                    <Typography className="field-Label"></Typography>
                                </Grid>
                            </Grid>

                            <Grid md={12} className="textareaGrid">
                                <Grid className="inputBlock" md={6} xs={12}>
                                    <Autocomplete
                                        options={this.getAssignPersonOptionsList()}
                                        loading={assignPersonLoading}
                                        noOptionsText=""
                                        popupIcon={<KeyboardArrowDown />}
                                        data-test-id="corrective_assigned_person"
                                        id={`assigned_person_for_CA_${index}`}
                                        getOptionLabel={(option) => `${option?.label}`}
                                        onChange={(event, newValue) => this.handleAssignPerson(newValue, index)}
                                        onInputChange={(event, newValue) => this.handleAssignPersonInputChange(newValue)}
                                        renderInput={(params: RenderInputParams) => (
                                            <TextField
                                                {...params}
                                                id={`assigned_person_for_CA_input_${index}`}
                                                placeholder={`Assign Person for the Corrective Action ${index + 1}`}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    <Typography className="errorStyle">
                                        {displayError && !correctiveActions[index].assignedPerson?.value && configJSON.errorrMsg}
                                    </Typography>
                                </Grid>
                                <Grid className="inputBlock" md={6} xs={12}>
                                    <DatePicker
                                        className="field-input"
                                        scrollableYearDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={100}
                                        dateFormat="dd/MM/yyyy"
                                        dateFormatCalendar="MMMM"
                                        data-test-id="due_date"
                                        name="due_date"
                                        placeholderText="Due Date"
                                        minDate={today}
                                        wrapperClassName="date-picker"
                                        customInput={this.CustomInputDate(value.dueDate, () => { }, this.handleChangeDueDate, index)}
                                        popperClassName="example-datepicker-class"
                                        selected={value.dueDate}
                                        onChange={(date) => {
                                            this.handleChangeDueDatePicker(date, index);
                                        }}
                                    />
                                    <Typography className="errorStyle">
                                        {displayError && !correctiveActions[index].dueDate && configJSON.errorrMsg}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {this.state.correctiveActions.length > 1 && (
                                <Grid md={12} className="textareaGrid">
                                    <Grid className="inputBlock" md={6} xs={12}>
                                        <Button
                                            data-test-id="remove_corrective"
                                            className="next-btn add-action"
                                            onClick={() => this.removeCorrectiveAction(index)}
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </>)
                })}
                <Grid md={12} className="textareaGrid">
                    <Grid className="inputBlock" md={6} xs={12}>
                        <Button
                            data-test-id="add_corrective"
                            className="next-btn add-action"
                            onClick={this.handleAddCorrectiveAction}
                        >
                            + Add Another Corrective Action
                        </Button>
                    </Grid>
                </Grid>
                <Grid md={12} className="textareaGrid"></Grid>
            </Box >
        )
    }
    checklistInspection = () => {
        const { inspectionDetailData } = this.state;
        return (
            <Grid container xs={12} className="checklist-image-card" spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box className="checklist-image-1">
                        <img
                            height="650px"
                            width="100%"
                            src={inspectionDetailData.attributes?.first_image} />
                    </Box>
                </Grid>
                {inspectionDetailData?.attributes?.second_image?.length ? <Grid item xs={12} md={6}>
                    <Box className="checklist-image-2">
                        <img
                            height="650px"
                            width="100%"
                            src={inspectionDetailData.attributes?.second_image} />
                    </Box>
                </Grid> : null}
            </Grid>
        )
    }
    // Customizable Area End

    render() {
        const { loading, area } = this.state;
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Wrapper>
                            <Box style={webStyles.breadcrumbWrapper}>
                                <Typography style={webStyles.breadcrumbItem} data-test-id="navigate_to_homescreen" onClick={this.navigateToHomeScreen}>Home</Typography>
                                <NavigateNext style={webStyles.navigateIcon} />
                                <Typography style={webStyles.breadcrumbItem} data-test-id="navigate_to_homescreen1" onClick={this.navigateToHomeScreen}>
                                    {area.department.length > 20 ? `${area.department.substring(0, 20)}...` : area.department}
                                    {` / `}
                                    {area.location.length > 20 ? `${area.location.substring(0, 20)}...` : area.location}
                                </Typography>
                                <NavigateNext style={webStyles.navigateIcon} />
                                <Typography style={webStyles.breadcrumbActiveItem}>
                                    {this.state.activeButton}
                                </Typography>
                            </Box>
                            <Box className="myPostsTitle">
                                <Typography className="mainHeading">
                                    {area.department.length > 20 ? `${area.department.substring(0, 20)}...` : area.department}
                                    {` / `}
                                    {area.location.length > 20 ? `${area.location.substring(0, 20)}...` : area.location}
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    data-test-id="detail_button"
                                    className={`buttonStyle ${this.state.activeButton === "Details" ? "buttonStyleActive" : ""
                                        }`}
                                    onClick={this.handleDetailsButtonClick}
                                >
                                    Details
                                </Button>
                                <Button
                                    data-test-id="checklist_button"
                                    className={`buttonStyle ${this.state.activeButton === "Checklist" ? "buttonStyleActive" : ""
                                        }`}
                                    onClick={this.handleChecklistButtonClick}
                                >
                                    CheckList
                                </Button>
                            </Box>

                            {this.state.activeButton === "Details" && this.detailsInspection()}
                            {!loading && this.state.activeButton === "Checklist" && this.checklistInspection()}

                            {!loading && this.state.activeButton === "Details" &&
                                <Box className="submit-button">
                                    <Button
                                        data-test-id="submit_btn"
                                        variant="contained"
                                        className="process-btn"
                                        disabled={this.state.disableSubmitButton}
                                        onClick={this.handleDetailSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Box>}

                            {loading && (
                                <Box data-test-id={"activityLoader"} className="loader-outer">
                                    <Box className="loader-inner">
                                        <CircularProgress />
                                    </Box>
                                </Box>
                            )}
                    </Wrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    padding: "30px",
    width: "100%",
    backgroundColor: "#f7f5f5",
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    "& .loader-outer": {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "100%",
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .loader-inner": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "40%",
        left: "50%",
    },
    "& .headingWrapper": {
        display: "flex",
    },
    "& .subHeadingText": {
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
        color: '#18181E',
        cursor: "pointer",
        fontSize: "14px",
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .activeHeadingText": {
        color: "#8735E1",
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: "ClotherBold",
    },
    "& .mainHeading": {
        leadingTrim: "both",
        fontWeight: 700,
        lineHeight: "28px",
        margin: "20px 0",
        color: "#2B2A29",
        textEdge: "cap",
        fontSize: "26px",
        fontStyle: "normal",
        fontFamily: "ClotherBold",
    },
    "& .Location-input": {
        borderRadius: '8px',
        borderColor: "#8735E1",
        backgroundColor: "lightgray",
        fontFamily: 'ClotherBold',
        height: '50px',
        width: '100%'
    },
    "& .Location-input .mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8735E1",
    },
    "& .post-profile-left": {
        display: "flex",
        gap: 10,
        alignItems: "center",
    },
    "& .time-icon": {
        height: "15px",
        width: "15px",
    },
    "& .buttonStyle": {
        height: "30px",
        color: "#828282",
        borderRadius: "8px",
        background: "#EEEEEE",
        textTransform: "capitalize",
        padding: "10px 12px",
        fontSize: "14px",
        fontWeight: 400,
        marginRight: "10px",
        "&.buttonStyleActive": {
            fontWeight: 700,
            background: "#8735E1",
            color: "#fff",
        },
    },
    "& .noData": {
        textAlign: "center",
        marginTop: "20px",
        fontSize: "18px"
    },
    "& .myPostsTitle": {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0",
    },
    "& .checklist-image-card": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "25px",
        background: "#fff",
        borderRadius: "8px",
        border: "1px solid #EEE",
        marginTop: "20px",
        marginBottom: "24px",
        height: "100%"
    },
    "& .checklist-image-1": {
        border: "1px solid lightgrey",
        borderRadius: "7px",
    },
    "& .checklist-image-2": {
        border: "1px solid lightgrey",
        borderRadius: "7px",
    },
    "& .detail-image-card": {
        display: "flex",
        padding: "25px",
        background: "#fff",
        borderRadius: "8px",
        border: "1px solid #EEE",
        marginTop: "20px",
        marginBottom: "24px",
    },
    "& .field-Label": {
        color: 'var(--Black, #18181E)',
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'ClotherRegular',
        height: "27px",
        marginBottom: '5px'
    },
    "& .field-input": {
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        height: '50px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .textarea-input, .textarea-input:focus-visible": {
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        height: '152px',
        padding: '10px 15px',
        width: '100%',
        outline: 'none',
        resize: 'none',
        userDrag: 'none'
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: '#fff'
    },
    "& .Location-input .MuiInputBase-input": {
        height: "22px",
    },
    "& .Location-input .MuiFormLabel-root": {
        "@media(max-width: 1260px)": {
            marginTop: "-10px",
            width: "90%",
            padding: "0px 5px"
        }
    },
    "& .MuiInputBase-input": {
        height: "15px",
    },
    "& .MuiOutlinedInput-input": {
        padding: "14px"
    },
    "& .MuiInputBase-root": {
        fontFamily: "ClotherBold",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px"
    },
    "& .add-action": {
        width: "100%",
        marginTop: "15px",
    },
    "& .next-btn": {
        color: "#8735E1",
        border: "1px solid #8735E1",
        fontSize: "20px"
        , background: "#FFF",
        fontFamily: "ClotherBold",
        fontWeight: 700,
        borderRadius: "8px",
        textTransform: "capitalize",
        margin: "0px",
    },
    "& .submit-button": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginBottom: "10px",
    },
    "& .errorStyle": {
        color: "#F30202",
        fontSize: "0.85rem",
        marginBottom: "0.5rem",
    },
    "& .uploadImageFont": {
        color: '#DCDCDC',
        fontSize: "16px",
        fontFamily: 'ClotherBold'
    },
    "& .react-datepicker-wrapper": {
        display: 'flex',
    },
    "& .uploadImage": {
        borderRadius: '8px',
        textTransform: 'capitalize',
        border: '1px solid lightgray',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        height: '150px',
        padding: '10px 15px',
        width: '100%',
        "& .MuiButton-label": {
            diplay: 'flex',
            flexDirection: 'column',
        },
    },
    "& .uploadedImg": {
        maxWidth: '5rem',
        maxHeight: '5rem',
        objectFit: 'contain'
    },
    "& .uploadImageBox": {
        display: " flex",
        alignItems: "flex-end"
    },
    "& .inputBlock": {
        maxWidth: '48%',
        "@media (max-width: 600px)": {
            maxWidth: '100%',

        }
    },
    "& .mainGrid": {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .textareaGrid": {
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 20px 20px 20px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .mainBox": {
        backgroundColor: 'white',
        width: "100%",
        margin: "15px 0px"
    },
    "& .filter-wrapper": {
        display: "flex",
        gap: 10,
        alignItems: "center",
    },
    "& .card_title_wrapper": {
        gap: 10,
        display: "flex",
        alignItems: "center",
        "&.inner-options": {
            justifyContent: "space-between",
        },
    },
    "& .card-post-name": {
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
        color: "#2B2A29",
        fontFamily: "ClotherBold",
        fontSize: "18px",
    },
    "& .card-post-detail": {
        fontSize: "16px",
        fontStyle: "normal",
        color: "#828282",
        fontFamily: "ClotherRegular",
        fontWeight: 400,
        marginTop: "15px",
    },
    "& .card-image": {
        objectFit: "cover",
        marginTop: 8,
        width: "100%",
        height: 300,
        borderRadius: "8px",
        cursor: "pointer",
    },
    "& .Floor_btn": {
        padding: "5px",
        marginTop: "15px",
        textTransform: "capitalize",
        borderRadius: "4px",
        background: "#EAE8E8",
        color: "#A1A1A1",
        fontFamily: "ClotherRegular",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    "& .mikeIcon": {
        width: "25px",
        height: "25px",
        cursor: "pointer"
    },
    "& .Post-time-detail .Floor_btn": {
        marginTop: "0px",
    },
    "& .Post-time-detail": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .Post-time-content": {
        fontStyle: "normal",
        fontWeight: 400,
        color: "#A1A1A1",
        fontFamily: "ClotherRegular",
        fontSize: "14px",
    },
    "& .Post-profile-img": {
        width: "46px",
        height: "46px",
        content: "",
        borderRadius: "46px",
        marginRight: 8,
        cursor: "pointer",
    },
    "& .Post-time-inner": {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        marginTop: "20px",
    },
    "& .Post-inner-profile-img": {
        content: "",
        borderRadius: "46px",
        width: "26px",
        height: "26px",
        border: "1px solid #8735E1",
    },
    "& .post-img": {
        width: "100%",
        borderRadius: "8px",
        height: "300px",
        background: "#ccc",
        content: "",
        marginTop: "20px",
    },
    "& .process-btn": {
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        width: "160px",
        height: "50px",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& .Ignore-btn": {
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "8px",
        border: "1px solid #8735E1",
        background: "#FFF",
        color: "#8735E1",
        textAlign: "center",
        marginRight: "10px",
        padding: "10px",
        width: "160px",
        height: "50px",
        boxShadow: "none",
    },
    "& .list-side-bar": {
        display: "flex",
        flexDirection: "column",
        height: "98.8%",
    },
    "& .logout-btn": {
        marginTop: "auto",
    },
    "& *::-ms-input-placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
    "& *::placeholder": {
        color: "#CDCDCD !important",
        fontSize: "16px"
    },
    "& input::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
});
// Customizable Area End
