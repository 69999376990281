import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";

interface MachinesResponse {
    id: string;
    attributes: {
        equipment_name: string;
        equipment_number: string;
        due_date: string;
        location_id: string;
        audit_category_id: string;
    }
}
interface MachinesResponseData {
    error: string;
    data: {
        id: string;
        attributes: {
            equipment_name: string;
            equipment_number: string;
            due_date: string;
            location_id: string;
            audit_category_id: string;
        }
    }[]
}
interface EditMachineResponse {
    error: string;
    data: {
        id: string;
        attributes: {
            id: number;
            equipment_name: string;
            equipment_number: string;
            due_date: string;
            location_id: number;
            audit_category_id: number;
            category: string;
            location: string;
        }
    }
}
interface LocationData {
    error: string;
    data: Location[];
}

interface Location {
    id: string;
    name: string;
}
interface CategoryData {
    error: string;
    data: Category[];
}

interface Category {
    id: string;
    name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    editMachine: boolean | null;
    editMachineId: string;
    loading: boolean;
    errorMsg: string;
    disableAddButton: boolean;
    selectedLocation: string;
    selectedCategory: string;
    equipmentName: string;
    equipmentNumber: string;
    dueDate: Date | null;
    addMachineClicked: boolean;
    locations: Location[];
    categories: Category[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddMachineController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getLocationsApiCallId: string = "";
    getCategoriesApiCallId: string = "";
    addNewMachineApiCallId: string = "";
    getWorkerMachineForEditApiCallId: string = "";
    editMachineApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            editMachine: null,
            editMachineId: "",
            errorMsg: "",
            loading: false,
            disableAddButton: false,
            selectedLocation: "",
            selectedCategory: "",
            equipmentName: "",
            equipmentNumber: "",
            addMachineClicked: false,
            locations: [],
            categories: [],
            dueDate: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getLocationsApiCallId) {
                this.handleLocationsApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getCategoriesApiCallId) {
                this.handleCategoriesApiResponse(responseJson)
            }
            if (apiRequestCallId === this.addNewMachineApiCallId) {
                this.handleAddNewMachineApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getWorkerMachineForEditApiCallId) {
                this.handleMachinesForEditApiResponse(responseJson)
            }
            if (apiRequestCallId === this.editMachineApiCallId) {
                this.handleEditMachineApiResponse(responseJson)
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        let editMachine = await getStorageData("editmachine", false);
        let editMachineId = await getStorageData("editmachineid", false);
        this.setState({ token, editMachine, editMachineId }, () => {
            if (this.state.editMachine !== null) {
                this.getWorkerMachineForEditApiCall();
            }
        });

        this.getLocationApiCall();
        this.getCategoriesApiCall();
    }

    handleMachinesForEditApiResponse = (responseJson: MachinesResponseData) => {
        if (responseJson && !responseJson.error) {
            const responseData = responseJson.data.filter((value: MachinesResponse) => {
                return (parseInt(value.id) === parseInt(this.state.editMachineId))
            })[0]?.attributes;
            const formattedDueDate = moment(responseData.due_date, 'YYYY-MM-DD').toDate();

            this.setState({
                equipmentName: responseData.equipment_name,
                equipmentNumber: responseData.equipment_number,
                dueDate: formattedDueDate,
                selectedLocation: responseData.location_id,
                selectedCategory: responseData.audit_category_id
            });
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }
    handleEditMachineApiResponse = (responseJson: EditMachineResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                disableAddButton: false,
                loading: false
            });
            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loading: false,
                disableAddButton: false
            });
        }
    }

    editMachineApiCall = () => {
        this.setState({
            loading: true,
            disableAddButton: true
        });

        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };

        const formattedDate = moment(this.state.dueDate?.toISOString()).format('DD/MM/YYYY');

        const body = {
            equipment_name: this.state.equipmentName,
            equipment_number: this.state.equipmentNumber,
            location_id: this.state.selectedLocation,
            due_date: formattedDate,
            audit_category_id: this.state.selectedCategory
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editMachineApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editMachineApiEndpoint}?id=${this.state.editMachineId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getWorkerMachineForEditApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getWorkerMachineRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getWorkerMachineForEditApiCallId = getWorkerMachineRequestMsg.messageId;
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMachinesApiEndPoint
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getWorkerMachineRequestMsg.id, getWorkerMachineRequestMsg);

    }

    getLocationApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getAllRequestMsgForLocation = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getLocationsApiCallId = getAllRequestMsgForLocation.messageId;
        getAllRequestMsgForLocation.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getLocationsApiEndPoint
        );
        getAllRequestMsgForLocation.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllRequestMsgForLocation.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllRequestMsgForLocation.id, getAllRequestMsgForLocation);
    }

    handleLocationsApiResponse = (responseJson: LocationData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ locations: responseJson.data })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    getCategoriesApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getAllRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCategoriesApiCallId = getAllRequestMsg.messageId;
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCategoriesApiEndPoint
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
    }

    handleCategoriesApiResponse = (responseJson: CategoryData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ categories: responseJson.data })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    handleAddButton = () => {
        this.setState({ addMachineClicked: true })
        const { equipmentName, equipmentNumber, selectedLocation, dueDate, selectedCategory } = this.state
        const errorInEquipmentName = !equipmentName;
        const errorInEquipmentNumber = !equipmentNumber;
        const errorInLocation = !selectedLocation;
        const errorInDueDate = !dueDate;
        const errorInCategory = !selectedCategory;

        if (errorInEquipmentName || errorInEquipmentNumber || errorInLocation || errorInDueDate || errorInCategory) {
            return;
        }

        if (this.state.editMachine !== null) {
            this.editMachineApiCall();
        } else {
            this.addNewMachinesApiCall();
        }
    }

    addNewMachinesApiCall = async () => {
        this.setState({
            loading: true,
            disableAddButton: true
        });

        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const formattedDate = moment(this.state.dueDate?.toISOString()).format('DD/MM/YYYY');

        const body = {
            equipment_name: this.state.equipmentName,
            equipment_number: this.state.equipmentNumber,
            location_id: this.state.selectedLocation,
            audit_category_id: this.state.selectedCategory,
            due_date: formattedDate
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addNewMachineApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addNewMachineApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleAddNewMachineApiResponse = (responseJson: MachinesResponseData) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                loading: false,
                disableAddButton: false
            });

            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        } else if (responseJson && responseJson.error) {
            this.setState({
                loading: false,
                errorMsg: responseJson.error,
                disableAddButton: false
            });
        }
    }

    navigateToAuditScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    handleChangeDueDatePicker = (SelectedDate: Date | null) => {
        if (SelectedDate) {
            SelectedDate?.setHours(SelectedDate?.getHours() + 5);
            SelectedDate?.setMinutes(SelectedDate?.getMinutes() + 30);
            const formatedDate = SelectedDate?.toISOString().replace(/(,|\s)+/g, 'T');
            this.setState({ dueDate: new Date(formatedDate) })
        }
    }
    handleChangeEuipmentNumber = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        this.setState({ equipmentNumber: (event.target.value) as string });
    }
    handleChangeEquipmentName = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        this.setState({ equipmentName: (event.target.value) as string });
    }
    handleChangeLocation = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        this.setState({ selectedLocation: (event.target.value) as string });
    }
    handleChangeCategory = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        this.setState({ selectedCategory: (event.target.value) as string });
    }
    handleChangeDueDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ dueDate: new Date(event.target.value) });
    }
    // Customizable Area End
}
