import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { sprinterIcon, teamPlayer, avidLearner } from "./assets";
import i18n from "i18next"

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

import MyBadgesController, { Props } from "./MyBadgesController";
// Customizable Area End

export default class MyBadges extends MyBadgesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
                <Box sx={webStyle.badgesWrapper}>
                    <Container style={webStyle.badgesMainWrapperAuto} maxWidth={"xl"}>
                        <Box style={webStyle.badgesSubHeading}><Typography style={webStyle.badgesSubHeadingText}>Profile</Typography> <Typography>{'>'}</Typography> <Typography style={webStyle.badgesSubHeadingText}>   {i18n.t(' Rewards')}  </Typography> <Typography>{'>'}</Typography> <Typography style={webStyle.badgesText}>My Badges</Typography> </Box>
                        <Typography style={webStyle.badgesHeading}>My Badges</Typography>

                        <Box sx={webStyle.badgesMainAutoBox}>
                            <Grid container spacing={7} style={webStyle.myBadgeCardContainer}>
                                <Grid item xs={2}>
                                    <Box sx={webStyle.myBadgeCardFirst}>
                                        <img src={sprinterIcon} alt="sprinter" style={webStyle.myBadgeCardImage} />
                                        <Typography style={webStyle.myBadgeCardTitleFirst} align="center">Sprinter</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={webStyle.pinkmyBadgeCard}>
                                        <img src={teamPlayer} alt="team-player" style={webStyle.myBadgeCardImage} />
                                        <Typography style={webStyle.myBadgeCardTitle} align="center">Team Player</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={webStyle.goldenBadgeCard}>
                                        <img src={sprinterIcon} alt="team-player" style={webStyle.myBadgeCardImage} />
                                        <Typography style={webStyle.myBadgeCardTitle} align="center">Warrior</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={webStyle.skyBadgeCard}>
                                        <img src={teamPlayer} alt="team-player" style={webStyle.myBadgeCardImage} />

                                        <Typography style={webStyle.myBadgeCardTitle} align="center">High Achiever</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={webStyle.myBadgeCardRed}>
                                        <img src={avidLearner} alt="sprinter" style={webStyle.myBadgeCardImage} />
                                        <Typography style={webStyle.myBadgeCardTitleFirst} align="center">Avid Learner</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={webStyle.greenBadgeCard}>
                                        <img src={teamPlayer} alt="team-player" style={webStyle.myBadgeCardImage} />
                                        <Typography style={webStyle.myBadgeCardTitle} align="center">Newcomer</Typography>
                                    <Box sx={webStyle.BadgeCardToggle}>                            
                                        You are a Comment King if you comment on 5 posts 7days in a badgesRow. </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    badgesWrapper: {
        width: '100%',
        backgroundColor: 'lightgray',
        padding: '20px 0',
        boxSizing: 'border-box',
        height: 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    badgesHeading: {
        margin: '20px 0',
        color: "#2B2A29",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "26px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        fontFamily: "ClotherBold"
    },
    badgesMainWrapperAuto: {
        padding: '0 !important'
    },
    badgesMainAutoBox: {
        background: '#fff',
        padding: '30px',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '8px',

    },
    badgesSubHeading: {
        display: 'flex',
        gap: '10px'
    },
    badgesSubHeadingText: {
        color: "#A1A1A1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: "ClotherRegular"
    },
    badgesText: {
        color: "#8735E1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "ClotherRegular"
    },
    myBadgeCard: {
        width: '100%',
        height: '150px',
        borderRadius: '8px',
        padding: '15px',
        background: '#F6F6F6',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    myBadgeCardImage: {
        height: '150px'
    },
    myBadgeCardFirst: {
        width: '100%',
        height: '150px',
        borderRadius: '8px',
        padding: '15px',
        background: '#92C8FF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    myBadgeCardRed: {
        width: '100%',
        height: '150px',
        borderRadius: '8px',
        padding: '15px',
        background: '#FF9292',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    myBadgeCardTitle: {
        color: "#2B2A29",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        marginTop: '15px'
    },
      myBadgeCardTitleFirst: {
        color: "#2B2A29",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        marginTop: '15px',
        fontFamily: 'ClotherBold',
    
      },
      myBadgeCardContainer: {
        width: "calc(100% + 20px)"
      },
      pinkmyBadgeCard: {
        background: '#DC92FF',
        width: '100%',
        height: '150px',
        flexDirection: 'column',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        padding: '15px',
        alignItems: 'center',
      },
      goldenBadgeCard:
      {
        background: '#FFD392',
        width: '100%',
        height: '150px',
        borderRadius: '8px',
        padding: '15px',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }, 
      skyBadgeCard:
      {
        background: '#00E0FF',
        width: '100%',
        height: '150px',
        borderRadius: '8px',
        padding: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      },
      greenBadgeCard: {
        background: "#92FFF2",
        width: '100%',
        height: '150px',
        borderRadius: '8px',
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      },
      BadgeCardToggle: {
        borderRadius: '8px',
        background: '#8735E1',
        display: 'block',
        padding: '16px',
        position: 'absolute',
        right: '0',
        width:'400px',
        height: '130px',
        bottom: '-180px'
      }
};
// Customizable Area End