import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area Start
export interface FollowersData {
  id: number,
  type: string,
  attributes: {
    id: number,
    current_user_id: number,
    account_id: number,
    you_also_following_to_him: boolean,
    created_at: string,
    account: {
      data: {
        id: number,
        type: string,
        attributes: {
          full_name: string,
          profile_image: string,
        }
      }
    }
  },
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean;
  followers: FollowersData[];
  totalCount: number;
  currentPage: number;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FollowersController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  FollowersApiCallId: string = "";
  followUserAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loader: false,
      followers: [],
      totalCount: 0,
      currentPage: 1,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive msg==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCalledId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCalledId && response) {
        if (apiRequestCalledId === this.FollowersApiCallId) {
          this.setState({
            followers: response.followers.data,
            totalCount: response.followers.meta.pagination.total_count,
            loader: false
          })
        }
        if (apiRequestCalledId === this.followUserAPICallId) {
          this.setState({ loader: false })
          this.getFollowersAPICall()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getFollowersAPICall();
  }

  getFollowersAPICall = async () => {
    this.setState({ loader: true })
    let token = await getStorageData("authToken", false);
    let SignUpID = await getStorageData("SignUpID", false);
    SignUpID = JSON.parse(SignUpID)
    const headers = {
      token: token,
      "Content-Type": configJSON.validationApiContentType
    };

    const getFollowersMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FollowersApiCallId = getFollowersMsg.messageId;

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyAPIEndPoint + `?account_id=${SignUpID}&per_page=${5}&page=${this.state.currentPage}`
    );

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getFollowersMsg.id, getFollowersMsg);
  }

  followerUser = async (userData: { attributes: { account_id: number } }) => {
    this.setState({ loader: true })
    let token = await getStorageData("authToken", false);
    const headers = {
      token: token,
      "Content-Type": configJSON.validationApiContentType
    };

    const requestData = {
      "follow_user_id": userData.attributes.account_id
    }

    const getFollowersMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followUserAPICallId = getFollowersMsg.messageId;

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FollowUserAPIEndPoint
    );

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    );
    runEngine.sendMessage(getFollowersMsg.id, getFollowersMsg);
  }

  goToHome = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MyProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  handlePageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage }, () => {
      this.getFollowersAPICall();
    });
  }
  // Customizable Area End

}
