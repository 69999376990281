// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  steps: any[];
  activeStep: number;
  post_concerns: any[];
  consequences: any[];
  frequencies: any[];
  hazards: any[];
  severities: any[];
  combineAndDetailsPost: any[];
  selectedImageIndex: any;
  selectedData: any;
  riskLevels: any[];
  activationIdentification: any[];
  TaskIdentification: any[];
  postingData: any;
  postingForData: any[];
  plantDropdownData: any[];
  buDropdownData: any[];
  countryDropDownData: any[];
  designationDropdownData: any[];
  selectedOptionsDesignation: any[];
  selectedOptionsBU: any[];
  selectedOptionsPlant: any[];
  selectedOptionsCountry: any[];
  postIds: any[];
  publishedPostSuccess: boolean;
  listening: boolean;
  stoppedByTimeout: boolean;
  transcription: string;
  listeningStatutory: boolean;
  stoppedByTimeoutStatutory: boolean;
  listeningSop: boolean;
  stoppedByTimeoutSop: boolean;
  listeningNogoViolation: boolean;
  stoppedByTimeoutNogoViolation: boolean;
  listeningSafetyViolation: boolean;
  stoppedByTimeoutSafetyViolation: boolean;
  listeningCorrective: boolean;
  stoppedByTimeoutCorrective: boolean;
  listeningPreventive: boolean;
  stoppedByTimeoutPreventive: boolean;
  loader: boolean;
  NOLevelForUser: string;
  errorModal: boolean;
}

interface RiskLevel {
  name: string;
  color: string;
}

interface RiskLevelBG {
  name: string;
  backgroundColor: string;
}

interface SS {
  id: any;
}

export default class SafetyInchargeApprovalController extends BlockComponent<
  Props,
  S,
  SS
> {
  getpostSettingCallID: string = "";
  getSelectedPostCallId: string = "";
  activityAndTaskIDentificationCallID: string = "";
  publishingPostBySafetyCallID: string = "";
  formik: any;
  getPlantLevelDataCallID: string = "";
  getDataPostingForCallID: string = "";
  recognition: any | null = null;
  getBULevelDataCallID: string = "";
  getDesigntionLevelDataCallID: string = "";
  getCountryLevelCallID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.setSevirityData = this.setSevirityData.bind(this);
    this.setFrequencyData = this.setFrequencyData.bind(this);
    this.getCombineAndAddDetailAPI = this.getCombineAndAddDetailAPI.bind(this);
    this.getDesignationLevelAPI = this.getDesignationLevelAPI.bind(this);
    this.getPageHiddenProp = this.getPageHiddenProp.bind(this);
    this.getDataPostingForAPI = this.getDataPostingForAPI.bind(this);
    this.getPlantLevelDataAPI = this.getPlantLevelDataAPI.bind(this);
    this.getPostSettings = this.getPostSettings.bind(this);
    this.getActivityAndTaskIDentification = this.getActivityAndTaskIDentification.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.postingForDropdown = this.postingForDropdown.bind(this);
    this.postingForSelectAPIRecive = this.postingForSelectAPIRecive.bind(this);
    this.publishingPostBySafetyAPI = this.publishingPostBySafetyAPI.bind(this);
    this.state = {
      steps: ["Step 1", "Step 2", "Step 3", "Step 4"],
      activeStep: 0,
      post_concerns: [],
      consequences: [],
      frequencies: [],
      hazards: [],
      severities: [],
      combineAndDetailsPost: [],
      selectedImageIndex: null,
      selectedData: {},
      riskLevels: [
        { name: "1A", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1B", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1C", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "1E", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2A", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "2B", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "2C", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2E", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3A", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3B", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3C", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3E", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4A", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4B", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4C", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4D", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4E", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5A", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5B", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5C", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5D", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5E", color: "#F30202", backgroundColor: "#FFEDED" },
      ],
      activationIdentification: [],
      TaskIdentification: [],
      postingData: {},
      postingForData: [],
      plantDropdownData: [],
      buDropdownData: [],
      designationDropdownData: [],
      countryDropDownData: [],
      selectedOptionsDesignation: [],
      selectedOptionsBU: [],
      selectedOptionsPlant: [],
      selectedOptionsCountry: [],
      postIds: [],
      publishedPostSuccess: false,
      listening: false,
      stoppedByTimeout: false,
      listeningStatutory: false,
      stoppedByTimeoutStatutory: false,
      listeningSop: false,
      stoppedByTimeoutSop: false,
      listeningNogoViolation: false,
      stoppedByTimeoutNogoViolation: false,
      listeningSafetyViolation: false,
      stoppedByTimeoutSafetyViolation: false,
      listeningCorrective: false,
      stoppedByTimeoutCorrective: false,
      listeningPreventive: false,
      stoppedByTimeoutPreventive: false,
      transcription: "",
      loader: false,
      NOLevelForUser: "",
      errorModal: false,
    };
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getpostSettingCallID) {
        this.setState({
          post_concerns: responseJson.post_concerns.data,
          consequences: responseJson.consequences.data,
          frequencies: responseJson.frequencies.data,
          hazards: responseJson.hazards.data,
          severities: responseJson.severities.data,
        });
      }
      if (apiRequestCallId === this.getSelectedPostCallId) {
        if (responseJson.data) {
          const concantDescribeData: any = responseJson.data
            ?.reduce((accumulator: any, currentItem: any) => {
              return (
                accumulator + " " + (currentItem.attributes.description || "")
              );
            }, "")
            .trim();

          const statutory_regulatory_legal_concern: any = responseJson.data
            ?.reduce((accumulator: any, currentItem: any) => {
              return (
                accumulator + " " + (currentItem.attributes.statutory_regulatory_legal_concern || "")
              );
            }, "")
            .trim();

          const Standard_Compliance_SOP_Compliance: any = responseJson.data
            ?.reduce((accumulator: any, currentItem: any) => {
              return (
                accumulator + " " + (currentItem.attributes.standard_sop_compliance || "")
              );
            }, "")
            .trim();

          const parameter_Violation: any = responseJson.data
            ?.reduce((accumulator: any, currentItem: any) => {
              return (
                accumulator + " " + (currentItem.attributes.no_go_parameter_violation || "")
              );
            }, "")
            .trim();

          const safety_Violation: any = responseJson.data
            ?.reduce((accumulator: any, currentItem: any) => {
              return (
                accumulator + " " + (currentItem.attributes.safety_absolute_violation || "")
              );
            }, "")
            .trim();

          const corrective_Action: any = responseJson.data
            ?.reduce((accumulator: any, currentItem: any) => {
              return (
                accumulator + " " + (currentItem.attributes.corrective_action || "")
              );
            }, "")
            .trim();

          const preventive_Acion: any = responseJson.data
            ?.reduce((accumulator: any, currentItem: any) => {
              return (
                accumulator + " " + (currentItem.attributes.preventive_action || "")
              );
            }, "")
            .trim();

          this.formik?.setFieldValue("describeIssue", concantDescribeData);
          this.formik?.setFieldValue("statutory_Concern", statutory_regulatory_legal_concern);
          this.formik?.setFieldValue("sop_Compliance", Standard_Compliance_SOP_Compliance);
          this.formik?.setFieldValue("parameter_Violation", parameter_Violation);
          this.formik?.setFieldValue("safety_Violation", safety_Violation);
          this.formik?.setFieldValue("corrective_Action", corrective_Action);
          this.formik?.setFieldValue("preventive_Acion", preventive_Acion);
          this.formik?.setFieldValue("work_location", responseJson.data[0].attributes.work_location);
          const setField = (fieldName: string, attribute: string) => {
            this.formik?.setFieldValue(
              fieldName,
              responseJson.data[0].attributes[attribute] || ""
            );
          };

          setField("consern_type", "post_concern_id");
          setField("hazard", "hazard_id");
          setField("frequency", "frequency_id");
          setField("sevirity", "severity_id");
          setField("potential_impact", "consequence_id");
          setField("risk_levels", "risk_level_name");
          setField("activityIdenty", "activity_identification_id");
          setField("TaskIdenty", "task_identification_id");
          setField("risk_count", "risk_count");

          this.setState({
            combineAndDetailsPost: responseJson.data,
            selectedImageIndex: responseJson?.data[0].attributes.id,
            selectedData: responseJson?.data[0].attributes,
            errorModal: false,
            NOLevelForUser: ""
          });
        } else {
          if (responseJson.message === "You are safety in charge but admin does not added posting level for you.") {
            this.setState({
              NOLevelForUser: responseJson.message,
              errorModal: true,
            })
          }
        }
      }
      this.reciveResonseFunc(apiRequestCallId, responseJson);
      this.publishPostRecivedFunc(apiRequestCallId, responseJson);
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getPostSettings();
    let postIds = JSON.parse(await getStorageData("selectedPost"));
    this.setState({ postIds });
    this.getCombineAndAddDetailAPI(postIds);
    this.getActivityAndTaskIDentification();
    this.getDataPostingForAPI();
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      this.setupSpeechRecognition();
    }
  }

  reciveResonseFunc = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.activityAndTaskIDentificationCallID) {
      this.setState({
        activationIdentification: responseJson.activity_identifications.data,
        TaskIdentification: responseJson.task_identification.data,
      });
    } else if (apiRequestCallId === this.getDataPostingForCallID) {
      if (responseJson.posting_for_data) {
        let data: any = [];
        const postingForData = responseJson.posting_for_data;

        if (postingForData) {
          const keys = [
            "global_level",
            "country_level",
            "plant_level",
            "bu_level",
            "designation",
          ];

          keys.forEach((key) => {
            if (postingForData.hasOwnProperty(key)) {
              data.push(postingForData[key]);
            }
          });
        }

        this.setState({ postingForData: data });
      }
    }
    this.postingForSelectAPIRecive(apiRequestCallId, responseJson);
  };

  postingForSelectAPIRecive = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getCountryLevelCallID) {
      if (responseJson.countries) {
        this.setState({ countryDropDownData: responseJson.countries.data, });
      } else {
        this.setState({ countryDropDownData: [] });
      }
    } else if (apiRequestCallId === this.getPlantLevelDataCallID) {
      if (responseJson.plants) {
        this.setState({ plantDropdownData: responseJson.plants.data });
      } else {
        this.setState({ plantDropdownData: [] });
      }
    } else if (apiRequestCallId === this.getBULevelDataCallID) {
      if (responseJson.business_units) {
        this.setState({ buDropdownData: responseJson.business_units.data });
      } else {
        this.setState({ buDropdownData: [] });
      }
    } else if (apiRequestCallId === this.getDesigntionLevelDataCallID) {
      if (responseJson.designations) {
        this.setState({ designationDropdownData: responseJson.designations.data });
      } else {
        this.setState({ designationDropdownData: [] });
      }
    }
  };

  publishPostRecivedFunc = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.publishingPostBySafetyCallID) {
      if (responseJson) {
        this.setState({ loader: false })
        if (responseJson.message == "The safety concern successfully published.") {
          this.setState({ publishedPostSuccess: true });
        }
      }
    }
  };

  goToNextStep = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  postingForDropdown = (e: any) => {
    this.setState({
      selectedOptionsBU: [],
      selectedOptionsPlant: [],
      selectedOptionsDesignation: [],
      selectedOptionsCountry: [],
      countryDropDownData: [],
      plantDropdownData: [],
      buDropdownData: [],
      designationDropdownData: [],
    }, () => {
      if (e.target.value == "Global Level") {
        this.getCountryLevelAPI();
      } else if (e.target.value == "Country Level") {
        this.getPlantLevelDataAPI();
      } else if (e.target.value == "Plant Level") {
        this.getBuLevelDataAPI();
      } else if (e.target.value == "BU Level") {
        this.getDesignationLevelAPI();
      }
    });

  };

  gorToPrevStep = () => {
    if (this.state.activeStep === 0) {
      this.setState({ listening: false });
      this.recognition?.stop();
      this.props.navigation.goBack();
    } else {
      this.setState({ activeStep: this.state.activeStep - 1 });
      this.setState({
        listeningStatutory: false,
        listeningSop: false,
        listeningNogoViolation: false,
        listeningSafetyViolation: false,
        listeningCorrective: false,
        listeningPreventive: false,
      });
      this.recognition?.stop();
    }
  };

  getPostSettings = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/get_post_creation_data"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getpostSettingCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCombineAndAddDetailAPI = async (postIds: any) => {
    let token = localStorage.getItem("authToken");
    const paramsQuery: string[] = [];
    postIds.forEach((value: any) => {
      paramsQuery.push(`&post_ids[]=${value.attributes.id}`);
    });

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/combine_and_add_details?${paramsQuery.join("")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getSelectedPostCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleImageSelect = (_e: any, index: number) => {
    const selectedData = this.state.combineAndDetailsPost.find(
      (item) => item.attributes.id === index
    );
    this.setState({
      selectedImageIndex: index,
      selectedData: selectedData.attributes,
    });

    const setField = (fieldName: string, attribute: string) => {
      this.formik.setFieldValue(
        fieldName,
        selectedData.attributes[attribute] || ""
      );
    };

    setField("consern_type", "post_concern_id");
    setField("hazard", "hazard_id");
    setField("frequency", "frequency_id");
    setField("sevirity", "severity_id");
    setField("potential_impact", "consequence_id");
    setField("risk_levels", "risk_level_name");
    setField("activityIdenty", "activity_identification_id");
    setField("TaskIdenty", "task_identification_id");
    setField("statutory_Concern", "statutory_regulatory_legal_concern");
    setField("sop_Compliance", "standard_sop_compliance");
    setField("parameter_Violation", "no_go_parameter_violation");
    setField("safety_Violation", "safety_absolute_violation");
    setField("corrective_Action", "corrective_action");
    setField("preventive_Acion", "preventive_action");
    setField("risk_count", "risk_count");
  };

  getActivityAndTaskIDentification = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/task_and_activity_identification"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.activityAndTaskIDentificationCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDataPostingForAPI = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/load_posting_for"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getDataPostingForCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  publishingPostBySafetyAPI = async () => {
    this.setState({ loader: true })
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      "Contant-Type": "multipart/form-data",
      token: tokenValue,
    };

    let formdata = new FormData();
    formdata.append("image_url_when_published", this.state.selectedData.image_url);
    formdata.append("description", this.state.postingData.describeIssue);
    formdata.append("work_location", this.state.postingData.work_location);
    formdata.append("location", this.state.postingData.work_location);
    formdata.append("post_concern_id", this.state.postingData.consern_type);
    formdata.append("hazard_id", this.state.postingData.hazard);
    formdata.append("frequency_id", this.state.postingData.frequency);
    formdata.append("severity_id", this.state.postingData.sevirity);
    formdata.append("consequence_id", this.state.postingData.potential_impact);
    formdata.append("risk_level_name", this.state.postingData.risk_levels);
    formdata.append(
      "activity_identification_id",
      this.state.postingData.activityIdenty
    );
    formdata.append(
      "task_identification_id",
      this.state.postingData.TaskIdenty
    );
    formdata.append(
      "statutory_regulatory_legal_concern",
      this.state.postingData.statutory_Concern
    );
    formdata.append(
      "standard_sop_compliance",
      this.state.postingData.sop_Compliance
    );
    formdata.append(
      "no_go_parameter_violation",
      this.state.postingData.parameter_Violation
    );
    formdata.append(
      "safety_absolute_violation",
      this.state.postingData.safety_Violation
    );
    formdata.append(
      "corrective_action",
      this.state.postingData.corrective_Action
    );
    formdata.append(
      "preventive_action",
      this.state.postingData.preventive_Acion
    );
    formdata.append("risk_count", this.state.postingData.risk_count);
    formdata.append("posting_for", this.state.postingData.postingFor);
    formdata.append("status", "publish");
    this.state.postIds.forEach((data: any) => {
      formdata.append("post_ids[]", data);
    });
    this.state.selectedOptionsCountry.forEach((data: any) => {
      formdata.append("country_ids[]", data);
    });
    this.state.selectedOptionsPlant.forEach((data: any) => {
      formdata.append("plant_ids[]", data);
    });
    this.state.selectedOptionsBU.forEach((data: any) => {
      formdata.append("bu_ids[]", data);
    });
    this.state.selectedOptionsDesignation.forEach((data: any) => {
      formdata.append("designation_ids[]", data);
    });
    this.state.selectedOptionsDesignation.forEach((data: any) => {
      formdata.append("status", "publish");
    });
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/publishing_the_posts`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.publishingPostBySafetyCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  handleChangeDesignation = (event: any) => {
    const optionName = event.target.value;
    const { selectedOptionsDesignation } = this.state;

    if (selectedOptionsDesignation.includes(optionName)) {
      this.setState({
        selectedOptionsDesignation: selectedOptionsDesignation.filter(
          (option) => option !== optionName
        ),
      });
    } else {
      this.setState({
        selectedOptionsDesignation: [...selectedOptionsDesignation, optionName],
      });
    }
  };

  handleChangeBULevel = (event: any) => {
    const optionName = event.target.value;
    this.setState(
      (prevState) => {
        const selectOptionBU = [...prevState.selectedOptionsBU];

        const selectedPost = selectOptionBU.indexOf(optionName);

        if (selectedPost === -1) {
          selectOptionBU.push(optionName);
        } else {
          selectOptionBU.splice(selectedPost, 1);
        }
        return { selectedOptionsBU: selectOptionBU };
      },
      () => {
        this.getDesignationLevelAPI();
      }
    );
  };

  handleChangePlantLevel = (event: any) => {
    const optionName = event.target.value;

    this.setState(
      (prevState) => {
        const { selectedOptionsPlant } = prevState;
        if (selectedOptionsPlant.includes(optionName)) {
          return {
            selectedOptionsPlant: selectedOptionsPlant.filter(
              (option) => option !== optionName
            ),
          };
        } else {
          return {
            selectedOptionsPlant: [...selectedOptionsPlant, optionName],
          };
        }
      },
      () => {
        this.getBuLevelDataAPI();
      }
    );
  };

  handleChangeCountry = (event: any) => {
    const optionName = event.target.value;
    const selectedOptions = [...this.state.selectedOptionsCountry];
    const statusSelected = selectedOptions.indexOf(optionName);
    if (statusSelected === -1) {
      selectedOptions.push(optionName)
    } else {
      selectedOptions.splice(statusSelected, 1)
    }
    this.setState({ selectedOptionsCountry: selectedOptions }, () => {
      this.getPlantLevelDataAPI();
      if (selectedOptions.length === 0) {
        this.setState({selectedOptionsBU: [], selectedOptionsDesignation: [], plantDropdownData: [], buDropdownData: [], designationDropdownData: []})
      }
    }
    );
  };
  handleNavigate = () => {
    this.props.navigation.navigate("PublishPost");
  };
  /* istanbul ignore next */
  setupSpeechRecognition() {
    this.recognition = new ((window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();

    if (this.recognition) {
      this.recognition.lang = "en-US";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = (event: any) => {
        const transcription = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join("");
        this.setState({ transcription });
      };

      const pageHiddenProp = this.getPageHiddenProp();
      if (pageHiddenProp) {
        this.addVisibilityChangeListener(pageHiddenProp, "listening");
        this.addVisibilityChangeListener(pageHiddenProp, "listeningStatutory");
        this.addVisibilityChangeListener(pageHiddenProp, "listeningSop");
        this.addVisibilityChangeListener(
          pageHiddenProp,
          "listeningNogoViolation"
        );
        this.addVisibilityChangeListener(
          pageHiddenProp,
          "listeningSafetyViolation"
        );
        this.addVisibilityChangeListener(pageHiddenProp, "listeningCorrective");
        this.addVisibilityChangeListener(pageHiddenProp, "listeningPreventive");
      }
    }
  }

  getPageHiddenProp() {
    const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
    for (const prop of hiddenProps) {
      if (prop in document) {
        return prop;
      }
    }
    return null;
  }

  addVisibilityChangeListener(pageHiddenProp: string, type: keyof S) {
    document.addEventListener(
      pageHiddenProp.replace(/[H|h]idden/, "") + "visibilitychange",
      () => {
        if (
          (document as any)[pageHiddenProp] &&
          this.recognition &&
          this.state[type]
        ) {
          this.recognition.stop();
          this.setState((prevState) => ({
            ...prevState,
            [type]: false,
          }));
        }
      }
    );
  }

  toggleListening = async (type: keyof S, stoppedByTimeout: keyof S) => {
    if (this.recognition) {
      if (this.state[type]) {
        this.setState((prevState) => ({
          ...prevState,
          [stoppedByTimeout]: true,
        }));
        this.recognition.stop();
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [stoppedByTimeout]: false,
          transcription: "",
        }));
        await this.recognition.start();
        setTimeout(() => {
          if (this.state[type] && !this.state[stoppedByTimeout]) {
            this.recognition.stop();
            this.setState((prevState) => ({
              ...prevState,
              [type]: false,
            }));
          }
        }, 30000);
      }
      this.setState((prevState) => ({
        ...prevState,
        [type]: !prevState[type],
      }));
    }
  };

  getCountryLevelAPI = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/select_countries`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getCountryLevelCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPlantLevelDataAPI = () => {
    let token = localStorage.getItem("authToken");
    const queryParams: string[] = [];
    this.state.selectedOptionsCountry.forEach((value: any) => {
      queryParams.push(`&country_ids[]=${value}`);
    });
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/select_plants?${queryParams.join("")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getPlantLevelDataCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBuLevelDataAPI = () => {
    let token = localStorage.getItem("authToken");
    const queryParams: any[] = [];
    this.state.selectedOptionsPlant.forEach((value: any) => {
      queryParams.push(`&plant_ids[]=${value}`);
    });
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/select_bus?${queryParams.join("")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getBULevelDataCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDesignationLevelAPI = () => {
    let token = localStorage.getItem("authToken");
    const queryParams: string[] = [];
    this.state.selectedOptionsBU.forEach((value: any) => {
      queryParams.push(`&bu_ids[]=${value}`);
    });
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/select_designation?${queryParams.join("")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getDesigntionLevelDataCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeCountry = (id: any) => {
    const updatedSelectedOptionsCountry =
      this.state.selectedOptionsCountry.filter((index) => index !== id);

    this.setState({
      selectedOptionsCountry: updatedSelectedOptionsCountry,
    }, () => {
      this.getPlantLevelDataAPI()
    });
  };

  removePlant = (index: any) => {
    const updatedSelectedOptionsPlant = this.state.selectedOptionsPlant.filter(
      (optionId) => optionId !== index
    );

    this.setState({ selectedOptionsPlant: updatedSelectedOptionsPlant },
      () => {
        this.getBuLevelDataAPI()
      });
  };

  removeBU = (id: any) => {
    const updatedSelectedOptionsBU = this.state.selectedOptionsBU.filter(
      (optionBUId) => optionBUId !== id
    );

    this.setState({ selectedOptionsBU: updatedSelectedOptionsBU },
      () => {
        this.getDesignationLevelAPI()
      });
  };

  removeDesignation = (id: any) => {
    const updatedSelectedOptionsDesignation =
      this.state.selectedOptionsDesignation.filter((item) => item !== id);

    this.setState({
      selectedOptionsDesignation: updatedSelectedOptionsDesignation,
    });
  };

  setFrequencyData = (event: any, values: any, setFieldValue: any) => {
    const riskDataSeverities = this.state.severities.find(
      (item) => item.attributes.id == values.sevirity
    );
    const riskDataFrequences = this.state.frequencies.find(
      (item) => item.attributes.id == event.target.value
    );
    setFieldValue(
      "risk_levels",
      riskDataSeverities?.attributes?.name +
      riskDataFrequences?.attributes?.name
    );
  }

  setSevirityData = (event: any, values: any, setFieldValue: any) => {
    const riskDataSeverities = this.state.severities.find(
      (item) => item.attributes.id == event.target.value
    );
    const riskDataFrequences = this.state.frequencies.find(
      (item) => item.attributes.id == values.frequency
    );
    setFieldValue(
      "risk_levels",
      riskDataSeverities?.attributes?.name +
      riskDataFrequences?.attributes?.name
    );
  }

  checkborderColor = (itemRiskLevel: string) => {
    let colorChoose = "#DCDCDC";
    this.state.riskLevels.forEach((item: RiskLevel) => {
      if (item.name == itemRiskLevel) {
        colorChoose = item.color;
      }
    });
    return colorChoose;
  };

  checkbackgroundColor = (itemRiskLevel: string) => {
    let colorChoose = "##FFFFFF";
    this.state.riskLevels.forEach((item: RiskLevelBG) => {
      if (item.name == itemRiskLevel) {
        colorChoose = item.backgroundColor;
      }
    });
    return colorChoose;
  };
}
// Customizable Area End
