import React from 'react';
import { Input, InputAdornment } from '@material-ui/core';
import { forwardRef } from 'react';
import { calendarLogo } from './assets';

export const ExampleCustomInput = forwardRef((payload: any, ref: any) => {
    let { value, onClick, onChange } = payload;
    return (
      <Input
        placeholder="Date of Birth"
        value={value}
        onClick={onClick}
        fullWidth={true}
        disableUnderline
        data-testid="textInputTest"
        name="dateTest"
        onChange={onChange}
        endAdornment={
          <div>
            <InputAdornment position="end">
              <img src={calendarLogo} style={{ width: 18 }} />
            </InputAdornment>
          </div>
        }
      />
    );
  });