import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";

interface AssignedPerson {
    label: string;
    value: string;
}

interface AddAnotherCheckpoint {
    counter: number;
    nonCompiledCheckpointName: string;
    uploadedImage: File | string;
    errorMessageImage: string;
    uploadedImagePreview: string | ArrayBuffer | null;
    description: string;
    assignedPerson: AssignedPerson,
    assignDate: Date | null | undefined;
    errorState: boolean;
}
interface MachineResponseData {
    error: string;
    data: {
        id: string;
        attributes: MachineData
    }[];
}
interface MachineData {
    id: string;
    equipment_name?: string;
    equipment_number?: number;
    due_date?: string;
    account_id?: number;
    audit_category_id?: number;
    location_id?: number;
    category?: string;
    location?: string;
}
interface CheckpointData {
    id?: string;
    attributes?: {
        id?: string;
        name?: string;
        recommended_check?: string;
        inspection_frequency?: string;
        action_required?: string;
        standard_reference?: null;
        audit_sub_category_id?: string;
        reference_image?: string;
        referencestandard_id?: string;
        sub_category?: string;
        reference_standard?: string;
        unit_of_measurement: {
            id: number;
            name: string;
        };
        total_target: string;
        compiled: string;
        non_compiled: string;
        target_date: string;
        remark: string;
    }
};
interface CheckpointResponseData {
    error: string;
    data: CheckpointData;
}
interface PersonListResponse {
    id: string;
    "full name": string;
}

interface PersonListOptions {
    label: string;
    value: string;
}

interface PersonList {
    id: string;
    full_name: string;
}

interface PersonResponseData {
    error: string;
    data: PersonListResponse[];
}

interface SpeechRecognition extends EventTarget {
    lang: string;
    continuous: boolean;
    interimResults: boolean;
    onresult: (event: SpeechRecognitionEvent) => void;
    start: () => void;
    stop: () => void;
}

interface SpeechRecognitionEvent {
    results: { transcript: string }[][];
    resultIndex?: number;
    emma?: Document;
}

interface SpeechRecognitionConstructor {
    new(): SpeechRecognition;
}

interface GlobalWebKit {
    webkitSpeechRecognition: SpeechRecognitionConstructor;
}

interface GlobalSpeech {
    SpeechRecognition: SpeechRecognitionConstructor;
}

interface DocumentWithVisibilityState extends Document {
    hidden: boolean;
    visibilityState: 'hidden' | 'visible';
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    assignButtonClicked: boolean;
    listeningForDesc: boolean;
    currentDescSpeech: number;
    stoppedByTimeoutDesc: boolean;
    transcription: string;
    checkpointId: string;
    errorMsg: string;
    loading: boolean;
    disableAssignButton: boolean;
    machineId: string;
    accountId: number | null;
    currentLoggedinUser: {
        full_name?: string;
    };
    machineData: MachineData;
    checkpointData: CheckpointData;
    personList: PersonList[];
    addAnotherCheckpoint: AddAnotherCheckpoint[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class NonCompiledAssignmentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    recognition: SpeechRecognition | null = null;
    getAssignPersonApiCallId: string = "";
    WorkerMachineForAssignPersonApiCallId: string = "";
    ShowCheckpointForAssignPersonApiCallId: string = "";
    createActionTrackerApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            assignButtonClicked: false,
            listeningForDesc: false,
            currentDescSpeech: 0,
            stoppedByTimeoutDesc: false,
            transcription: "",
            errorMsg: "",
            loading: false,
            disableAssignButton: false,
            machineId: "",
            accountId: null,
            machineData: { id: "" },
            checkpointData: {},
            currentLoggedinUser: {},
            checkpointId: "",
            personList: [],
            addAnotherCheckpoint: [{
                counter: 1,
                nonCompiledCheckpointName: "",
                uploadedImage: "",
                errorMessageImage: "",
                uploadedImagePreview: "",
                description: "",
                assignedPerson: {
                    label: "",
                    value: "",
                },
                assignDate: null,
                errorState: false
            }],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.WorkerMachineForAssignPersonApiCallId) {
                this.handleMachinesForAssignPersonResponse(responseJson)
            }
            if (apiRequestCallId === this.ShowCheckpointForAssignPersonApiCallId) {
                this.handleCheckpointForAssignPersonResponse(responseJson)
            }
            if (apiRequestCallId === this.getAssignPersonApiCallId) {
                this.handleAssignPersonApiResponse(responseJson)
            }
            if (apiRequestCallId === this.createActionTrackerApiCallId) {
                this.handleCreateActionTrackerApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        let checkpointId = await getStorageData("checkpointdetailid", false);
        let machineId = await getStorageData("machineid", false);
        let accountId = await getStorageData("SignUpID", false);

        this.setState({ token, checkpointId, machineId }, () => {
            this.getShowCheckpointForAssignPersonApiCall();
            this.getWorkerMachineForAssignPersonApiCall();
        });

        this.getAssignPersonApiCall();

        if ("SpeechRecognition" in global || "webkitSpeechRecognition" in global) {
            this.setupSpeechRecognition();
        }

        const currentLoggedinUser = JSON.parse(sessionStorage.getItem("dataObj") || "");
        this.setState({ currentLoggedinUser, accountId: parseInt(accountId.replace("\"", "")) });
    }

    setupSpeechRecognition() {
        this.recognition = new ((global as unknown as GlobalSpeech).SpeechRecognition ||
            (global as unknown as GlobalWebKit).webkitSpeechRecognition)();

        if (this.recognition) {
            this.recognition.lang = "en-US";
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.onresult = (event: SpeechRecognitionEvent) => {
                const transcription = Array.from(event.results)
                    .map((result: { transcript: string }[]) => result[0].transcript)
                    .join("");
                this.setState({ transcription });
            };
            const pageHiddenProp = this.getPageHiddenProp();
            if (pageHiddenProp) {
                this.addVisibilityChangeListener(pageHiddenProp);
            }
        }
    }

    getPageHiddenProp() {
        const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
        for (const prop of hiddenProps) {
            if (prop in document) {
                return prop;
            }
        }
        return null;
    }

    addVisibilityChangeListener(pageHiddenProp: string) {
        const customDocument: DocumentWithVisibilityState = document;
        customDocument.addEventListener(pageHiddenProp.replace(/[H|h]idden/, '') + 'visibilitychange', () => {
            if (customDocument["hidden"] && this.recognition) {
                this.stopRecognition();
            }
        });
    }

    stopRecognition() {
        if (this.state.listeningForDesc) {
            this.recognition?.stop();
            this.setState({ listeningForDesc: false });
        }
    }

    toggleListeningForDesc = async () => {
        if (this.recognition) {
            if (this.state.listeningForDesc) {
                this.setState({ stoppedByTimeoutDesc: true });
                this.recognition.stop();
            } else {
                this.setState({ stoppedByTimeoutDesc: false });
                await this.recognition.start();
                this.setState({ transcription: "" });
                setTimeout(() => {
                    if (this.state.listeningForDesc && !this.state.stoppedByTimeoutDesc) {
                        this.recognition?.stop();
                        this.setState({ listeningForDesc: false });
                    }
                }, 30000);

            }
            this.setState((prevState) => ({
                listeningForDesc: !prevState.listeningForDesc,
            }));
        }
    };

    getOptionsList = () => {
        const { personList } = this.state;
        const options: PersonListOptions[] = [];
        personList.length > 0 && personList?.forEach((item) => {
            if (item.full_name !== null || item.id !== null) {
                options.push({
                    label: `${item.full_name}(${item.id})`,
                    value: item.id
                })
            }
        });
        return options;
    }

    handleMic = async (value: AddAnotherCheckpoint, index: number) => {
        this.setState({ currentDescSpeech: value.counter });
        await this.toggleListeningForDesc();
        this.handleChangeDescription(value.description + " " + this.state.transcription, index);
    }

    handleAssignPersonApiResponse = (responseJson: PersonResponseData) => {

        if (responseJson && !responseJson.error) {
            const updatedData = responseJson.data.map((person: PersonListResponse) => {
                return {
                    ...person,
                    full_name: person['full name']
                };
            });
            this.setState({ personList: updatedData })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    handleMachinesForAssignPersonResponse = (responseJson: MachineResponseData) => {
        if (responseJson && !responseJson.error) {
            const responseData = responseJson.data.filter((value: MachineData) => {
                return (parseInt(value.id) === parseInt(this.state.machineId))
            })[0]?.attributes;

            this.setState({ machineData: responseData })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleCheckpointForAssignPersonResponse = (responseJson: CheckpointResponseData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ checkpointData: responseJson.data })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleCreateActionTrackerApiResponse = (responseJson: { error: string; }) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                loading: false,
                disableAssignButton: false
            });

            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loading: false,
                disableAssignButton: false
            });
        }
    }

    navigateToAuditScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToMachineCheckpointsScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    navigateToWorkerCheckpointDetailScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "WorkerCheckpointDetail");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    getAssignPersonApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getWorkerMachineRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAssignPersonApiCallId = getWorkerMachineRequestMsg.messageId;
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAssignPersonApiEndPoint
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getWorkerMachineRequestMsg.id, getWorkerMachineRequestMsg);

    }

    getWorkerMachineForAssignPersonApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getWorkerMachineRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.WorkerMachineForAssignPersonApiCallId = getWorkerMachineRequestMsg.messageId;
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMachinesApiEndPoint
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getWorkerMachineRequestMsg.id, getWorkerMachineRequestMsg);

    }

    getShowCheckpointForAssignPersonApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getAllRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ShowCheckpointForAssignPersonApiCallId = getAllRequestMsg.messageId;
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getShowCheckpointApiEndPoint}?id=${this.state.checkpointId}`
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
    }

    isAddAnotherCheckpointFilled() {
        return this.state.addAnotherCheckpoint.every(
            (action: AddAnotherCheckpoint) => {
                if (action.nonCompiledCheckpointName === "" ||
                    action.description === "" ||
                    action.uploadedImage === "" ||
                    (action.assignedPerson === null || action.assignedPerson?.value === "") ||
                    (action.assignDate === undefined || action.assignDate === null)) {
                    return false;
                }
                return true;
            }
        );
    }

    handleAssignButton = () => {
        this.setState({ assignButtonClicked: true })

        const errorInAddAnotherCheckpoint = this.isAddAnotherCheckpointFilled();

        if (!errorInAddAnotherCheckpoint) {
            const addAnotherCheckpoint = this.state.addAnotherCheckpoint.map((value) => ({ ...value, errorState: true }))
            this.setState({ addAnotherCheckpoint });
            return false;
        }
        this.createActionTrackerApiCall();
    }

    createActionTrackerApiCall = async () => {
        this.setState({ loading: true, disableAssignButton: true })
        const headers = {
            token: this.state.token,
        };

        let fullName = this.state.currentLoggedinUser.full_name ? this.state.currentLoggedinUser.full_name : "";

        const currentDate = moment().format('YYYY-MM-DD');
        let formData = new FormData();

        this.state.addAnotherCheckpoint.forEach((value, index) => {
            const formattedDate = moment(value?.assignDate?.toISOString()).format('YYYY-MM-DD');
            const currentData = `bx_block_posts_action_trackers_attributes[${index}]`;
            formData.append(`${currentData}[non_compiled_checkpoint_name]`, value.nonCompiledCheckpointName);
            formData.append(`${currentData}[description]`, value.description);
            formData.append(`${currentData}[action_type]`, "corrective_action");
            formData.append(`${currentData}[corrective_or_preventive_action_assigned_person_id]`, value.assignedPerson?.value);
            formData.append(`${currentData}[due_date]`, formattedDate);
            formData.append(`${currentData}[assigned_to]`, value.assignedPerson?.label);
            formData.append(`${currentData}[assinged_on]`, currentDate);
            formData.append(`${currentData}[assigned_by]`, fullName);
            formData.append(`${currentData}[is_audit_action_tracker]`, "true");
            formData.append(`${currentData}[account_id]`, `${this.state.accountId}`);
            if (value?.uploadedImage && typeof value?.uploadedImage !== "string") {
                formData.append(`${currentData}[action_image]`, (value?.uploadedImage), value?.uploadedImage?.name);
            }
        });

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createActionTrackerApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createActionTrackerApiEndPoint}?id=${this.state.checkpointId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    removeAddAnotherCheckpoint = (indexToRemove: number) => {
        const { addAnotherCheckpoint } = this.state;
        if (addAnotherCheckpoint.length > 1) {
            const updatedActions = addAnotherCheckpoint.filter(
                (_checkpoint, buttonIndex) => buttonIndex !== indexToRemove
            );
            this.setState({ addAnotherCheckpoint: updatedActions });
        }
    };


    handleAssignAnotherCheckpoint = () => {
        const { addAnotherCheckpoint } = this.state;
        const counter = addAnotherCheckpoint[addAnotherCheckpoint.length - 1].counter + 1;
        this.setState({
            addAnotherCheckpoint: [...addAnotherCheckpoint, { counter, nonCompiledCheckpointName: "", uploadedImage: "", errorMessageImage: "", uploadedImagePreview: "", description: "", assignedPerson: { label: "", value: "" }, assignDate: null, errorState: false }],
        });
    }

    handleChangenonCompiledCheckpointName = (value: string, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        addAnotherCheckpoint[index].nonCompiledCheckpointName = value;
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }

    handleChangeDescription = (value: string, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        addAnotherCheckpoint[index].description = value;
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }

    changeImg(event: React.ChangeEvent<HTMLInputElement>, index: number) {
        const files = event.target.files;
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];

        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            addAnotherCheckpoint[index].uploadedImagePreview = fileReader.result;
            this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });

        });
        files?.length && fileReader.readAsDataURL(files[0]);
    }

    handleUploadedImage = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files?.[0] || "";
        const acceptedFormats = ['image/png', 'image/jpeg'];

        if (file) {
            if (acceptedFormats.includes(file.type)) {
                const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
                addAnotherCheckpoint[index].uploadedImage = file;
                addAnotherCheckpoint[index].errorMessageImage = "";
                this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
                this.changeImg(event, index);
            } else {
                const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
                addAnotherCheckpoint[index].errorMessageImage = "Please select a valid image file (PNG, JPG or JPEG).";
                this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
            }
        }
    }

    handleAssignPerson = (newValue: AssignedPerson | null, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        if (newValue !== null) {
            addAnotherCheckpoint[index].assignedPerson = newValue;
        }
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }

    handleChangeAssignDatePicker = (selectedDate: Date | null, index: number) => {
        if (selectedDate && typeof selectedDate === 'object') {
            selectedDate?.setHours(selectedDate.getHours() + 5);
            selectedDate?.setMinutes(selectedDate.getMinutes() + 30);
            const formatedDate = selectedDate?.toISOString().replace(/(,|\s)+/g, 'T');

            const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
            addAnotherCheckpoint[index].assignDate = new Date(formatedDate);
            this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
        }
    }
    handleChangeAssignedDate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        addAnotherCheckpoint[index].assignDate = new Date(event.target.value);
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }
    // Customizable Area End
}
