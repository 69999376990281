import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { sendOtpRequest, sendPostJSONRequest } from "./utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  upperPasswordCheck: boolean;
  lowerPasswordCheck: boolean;
  lengthPasswordCheck: boolean;
  specialPasswordCheck: boolean;
  numberPasswordCheck: boolean;
  newPasswordFocus: boolean;
  confirmPasswordFocus: boolean;
  newPassword: string;
  confirmPassword:string;
  isConfirmPassword:boolean
  mobileOrEmail:string;
  isLoading: boolean;
  emailVerificationScreenError: string;
  changePasswordError: string;
  loader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  verificationCodeAPICallId = '';
  changePasswordCallId = "";

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      upperPasswordCheck: false,
      lowerPasswordCheck: false,
      lengthPasswordCheck: false,
      specialPasswordCheck: false,
      numberPasswordCheck: false,
      newPasswordFocus: false,
      confirmPasswordFocus: false,
      newPassword: "",
      confirmPassword:"",
      isConfirmPassword: false,
      mobileOrEmail:"",
      isLoading: false,
      emailVerificationScreenError: "",
      changePasswordError: '',
      loader: false,
    };
    this.receive = this.customReceive.bind(this);
    // eslint-disable-next-line no-undef
    sessionStorage.removeItem("timer");
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ token: otpAuthTkn });
        if (this.isChangePassword) {
          this.setState({ accountStatus: "ChangePassword" });
        }
        this.otpToken = this.state.token;
      } else {
        const accountType = message.getData(
          getName(MessageEnum.NavigationForgotPasswordPageInfo)
        );
        if (accountType) {
          this.startForgotPassword(accountType);
        }
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validationAPICallId &&
      this.validationAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data[0]) {
        const passRegex = RegExp(
          responseJson.data[0].password_validation_regexp
        );
        const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
        const passwordRulesFromValidation =
          responseJson.data[0].password_validation_rules;

        this.setState({
          //email schema
          emailSchema: {
            email: Yup.string()
              .email(configJSON.pleaseEnterAValidEmail)
              .required(configJSON.emailIsRequired)
              .matches(emailRegex, configJSON.invalidEmailAddress),
          },
          //password schema
          passwordSchema: {
            password: Yup.string()
              .required(configJSON.pleaseEnterAPassword)
              .matches(passRegex, configJSON.invalidPassword),

            confirmPassword: Yup.string()
              .required(configJSON.pleaseConfirmYourPassword)
              .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("password")],
                  configJSON.passwordsMustMatch
                ),
              }),
          },
          passwordRules: passwordRulesFromValidation,
        });
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestEmailOtpCallId !== null &&
      this.requestEmailOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered email!!!!");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
      ) {
        this.otpToken = responseJson.meta.token;

        this.setState({ token: this.otpToken });

        //navigate to OTP page
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );

        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msg.addData(
          getName(MessageEnum.AuthTokenEmailMessage),
          this.state.emailValue
        );

        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

        this.send(msg);
      }
      //error handling
      else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestPhoneOtpCallId !== null &&
      this.requestPhoneOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
      ) {
        this.otpToken = responseJson.meta.token;
        this.setState({ token: this.otpToken });

        const msg: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );
        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msg.addData(
          getName(MessageEnum.AuthTokenPhoneNumberMessage),
          this.state.phoneValue
        );

        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

        this.send(msg);
      }
      //error handling
      else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestGoToConfirmationCallId !== null &&
      this.requestGoToConfirmationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.data) {
        this.setState({
          accountStatus: "Confirmation",
        });
      } else if (responseJson !== undefined && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }
  

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
  async customReceive(from: string, message: Message) {
    const navigationPayloadMessage = getName(MessageEnum.NavigationPayLoadMessage);
    const restApiResponceMessage = getName(MessageEnum.RestAPIResponceMessage);
  
    if (navigationPayloadMessage === message.id) {
      const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      const isToken = otpAuthTkn && otpAuthTkn.length > 0;
  
      if (isToken) {
        this.setState({ token: otpAuthTkn });
        this.isChangePassword && this.setState({ accountStatus: "ChangePassword" });
        this.otpToken = this.state.token;
      } else {
        const accountType = message.getData(getName(MessageEnum.NavigationForgotPasswordPageInfo));
        accountType && this.startForgotPassword(accountType);
      }
    } else if (
      restApiResponceMessage === message.id &&
      this.validationAPICallId &&
      this.validationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const firstBlock = responseJson.data[0];
      if (responseJson === undefined) {
        return;
      } else if (responseJson && firstBlock) {
        const passRegex = RegExp(firstBlock.password_validation_regexp);
        const emailRegex = RegExp(firstBlock.email_validation_regexp);
        const passwordRulesFromValidation = firstBlock.password_validation_rules;
        this.setState({
          emailSchema: {
            email: Yup.string()
              .email(configJSON.pleaseEnterAValidEmail)
              .required(configJSON.emailIsRequired)
              .matches(emailRegex, configJSON.invalidEmailAddress),
          },
          passwordSchema: {
            password: Yup.string()
              .required(configJSON.pleaseEnterAPassword)
              .matches(passRegex, configJSON.invalidPassword),
            confirmPassword: Yup.string()
              .required(configJSON.pleaseConfirmYourPassword)
              .when("password", {
                is: (value) => (value && value.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("password")], configJSON.passwordsMustMatch),
              }),
          },
          passwordRules: passwordRulesFromValidation,
        });
      }
    } else if (
      restApiResponceMessage === message.id &&
      this.requestEmailOtpCallId !== null &&
      this.requestEmailOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (responseJson !== undefined && responseJson.meta && responseJson.meta.token) {
        this.otpToken = responseJson.meta.token;
        this.setState({ token: this.otpToken });
  
        const msg: Message = new Message(getName(MessageEnum.NavigationMobilePhoneOTPMessage));
        msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token);
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AuthTokenEmailMessage), this.state.emailValue);
        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);
        this.send(msg);
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      restApiResponceMessage === message.id &&
      this.requestPhoneOtpCallId !== null &&
      this.requestPhoneOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (responseJson !== undefined && responseJson.meta && responseJson.meta.token) {
        this.otpToken = responseJson.meta.token;
        this.setState({ token: this.otpToken });
  
        const msg: Message = new Message(getName(MessageEnum.NavigationMobilePhoneOTPMessage));
        msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token);
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AuthTokenPhoneNumberMessage), this.state.phoneValue);
        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);
        this.send(msg);
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      restApiResponceMessage === message.id &&
      this.requestGoToConfirmationCallId !== null &&
      this.requestGoToConfirmationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.data) {
        this.setState({
          accountStatus: "Confirmation",
        });
      } else if (responseJson !== undefined && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      restApiResponceMessage === message.id &&
      this.verificationCodeAPICallId &&
      this.verificationCodeAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({
        isLoading: false,
      });
      const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!successMessage) {
        this.setState({
          emailVerificationScreenError: "An unexpected error has occurred",
        });
        return;
      }
      if (successMessage.otp_id) {
        this.goToOtpPage();
        return;
      }
      this.setState({ emailVerificationScreenError: successMessage.message });
    } else if (
      restApiResponceMessage === message.id &&
      this.changePasswordCallId &&
      this.changePasswordCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ loader: false })
      const successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(successResponse, 'successResponse')
      if (!successResponse) {
        this.setState({
          changePasswordError: "An unexpected error has occurred",
        });
        return;
      }
      const isError = successResponse.message !== "Password updated successfully";
      if (isError || successResponse.errors) {
        this.setState({
          changePasswordError: successResponse.errors[0].message,
        });
      } else {
        this.setState({
          isConfirmPassword: true,
        });
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(getName(MessageEnum.CountyCodeDataMessage));
  
      selectedCode !== undefined &&
        this.setState({
          countryCodeSelected: selectedCode.indexOf("+") > 0 ? selectedCode.split("+")[1] : selectedCode,
        });
    }
  }

  backBtnClicked =()=>{
    sessionStorage.removeItem('timer');
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    }
  
  handleConfirmPassword = () => {
    this.setState({
      newPasswordFocus: true,
      confirmPasswordFocus: true
    }, () => {
      if (!this.hasConfirmPasswordError() && !this.hasNewPasswordHasError()) {
        this.setState({ loader: true })
        const body = {
          data: {
            // eslint-disable-next-line no-undef
            email: sessionStorage.getItem("reset-email"),
            new_password: this.state.newPassword,
          }
        }
        sendPostJSONRequest(configJSON.changePasswordEndpoint, body, (id) => this.changePasswordCallId = id);
      }
    })
  }

  CheckValidation(text: string) {
    let testLowerCase = /[a-z]/;
    let testUpperCase = /[A-Z]/;
    let spacialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let spacialNum = /\d/;
    this.setState({ newPassword: text });
    if (spacialChar.test(text)) {
      this.setState({
        specialPasswordCheck: true
      });
    } else {
      this.setState({
        specialPasswordCheck: false,
      });
    }

    if (spacialNum.test(text)) {
      this.setState({
        numberPasswordCheck: true
      });
    } else {
      this.setState({
        numberPasswordCheck: false,
      });
    }

    if (testUpperCase.test(text)) {
      this.setState({
        upperPasswordCheck: true
      });
    } else {
      this.setState({
        upperPasswordCheck: false,
      });
    }
    if (testLowerCase.test(text)) {
      this.setState({
        lowerPasswordCheck: true
      });
    } else {
      this.setState({
        lowerPasswordCheck: false,
      });
    }
    if (text.length < 8) {
      this.setState({
        lengthPasswordCheck: false
      });
    } else {
      this.setState({
        lengthPasswordCheck: true,
      });
    }
  }

  newPasswordHideAndShow() {
    this.setState({ btnConfirmPasswordShowHide: !this.state.btnConfirmPasswordShowHide })
  }

  confiremPasswordHideAndShow() {
    this.setState({ enablePasswordField: !this.state.enablePasswordField })
  }

  hasNewPasswordHasError = () => {
    if (this.state.newPasswordFocus) {
      if (this.state.numberPasswordCheck && this.state.specialPasswordCheck && this.state.lowerPasswordCheck && this.state.upperPasswordCheck && this.state.lengthPasswordCheck) {
        return false
      } else {
        return true
      }
    }
    else {
      return false
    }
  }

  newPasswordHandler = {
    onChange: (e: any) => {
      this.CheckValidation(e.target.value);
    },
    onBlur: () => {
      this.setState({ newPasswordFocus: true , changePasswordError: ""});
    }
  }
  hasConfirmPasswordError() {
    if (this.state.confirmPasswordFocus && this.state.newPassword !== this.state.confirmPassword) {
      return true
    }
    return false
  }

  confirmPasswordHandler = {
    onChange: (e: any) => {
      this.setState({
        confirmPassword: e.target.value
      })
    },
    onBlur: () => {
      this.setState({ confirmPasswordFocus: true });
    }
  }

  setForgotPassswordEmail(text:string){
    this.setState({
      mobileOrEmail: text
    })
  }
  goToOtpPage(){
    if(this.state.mobileOrEmail){
      const msg: Message = new Message(
        getName(MessageEnum.NavigationMobilePhoneOTPMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
      msg.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        this.state.token
      );
      msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);
      msg.addData(
        getName(MessageEnum.AuthTokenEmailMessage),
        this.state.mobileOrEmail
      );
      this.send(msg);
    }
  }

  sendVerificationCode() {
    if(!this.isValidEmail(this.state.mobileOrEmail)) {
      this.setState({
        emailVerificationScreenError: 'Invalid email format'
      });
      return;
    }

    this.setState({
      isLoading: true
    });

    sendOtpRequest(this.state.mobileOrEmail, (id) => this.verificationCodeAPICallId = id);
  }

  isValidEmail(email: string) {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  }

  handleSendCodeOnEnterKey = (event: { key: string }) => {
    if (event.key === 'Enter') {
      this.sendVerificationCode();
    }
  }

  handleConfirmPasswordOnEnterKey = (event: { key: string }) => {
    if (event.key === 'Enter') {
      this.handleConfirmPassword();
    }
  }
  // Customizable Area End
}
