import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData, removeStorageData } from "framework/src/Utilities";
import React from "react";
interface MachineResponseData {
    error: string;
    data: {
        id: string;
        attributes: MachineData;
    }[];
}
interface MachineData {
    id: string;
    equipment_name?: string;
    equipment_number?: number;
    due_date?: string;
    account_id?: number;
    location_id?: number;
    audit_category_id?: number;
    category?: string;
    location?: string;
}
interface CheckpointResponseData {
    error: string;
    data: CheckpointData[];
    designation: string;
}

export interface CheckpointData {
    id: number;
    type: string;
    attributes: {
        id: string;
        name: string;
        inspection_frequency: string;
        recommended_check: string;
        action_required: string;
        standard_reference: null;
        unit_of_measurement: null;
        total_target: null;
        compiled: null;
        non_compiled: null;
        target_date: null;
        remark: null;
        machine_id: string;
        audit_sub_category_id: string;
        referencestandard_id: string;
        status: string;
        due_date: string;
        reference_image: string;
        sub_category: string;
        reference_standard: string;
        last_checked_by: string;
        last_checked_on: string;
        action_trackers: [];
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    checkpoint: CheckpointData[];
    role: string;
    isEditDeleteOpen: number;
    token: string;
    loading: boolean;
    errorMsg: string;
    machineId: string;
    selectedMachineData: MachineData;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class MachineCheckpointsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getWorkerMachinesApiCallId: string = "";
    getAllCheckpointApiCallId: string = "";
    deleteCheckpointApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            checkpoint: [],
            role: "",
            isEditDeleteOpen: 0,
            token: "",
            loading: true,
            errorMsg: "",
            machineId: "",
            selectedMachineData: {id: ""}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getWorkerMachinesApiCallId) {
                this.handleMachinesApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getAllCheckpointApiCallId) {
                this.handleShowAllCheckpointApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteCheckpointApiCallId) {
                this.handleDeleteCheckpointApiResponse(responseJson)
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        let machineId = await getStorageData("machineid", false);
        this.setState({ token, machineId }, () => {
            this.getWorkerMachinesApiCall();
            this.getAllCheckpointApiCall();
        });
    }

    handleAddCheckpointBtn = () => {
        removeStorageData('checkpointdetailid');
        removeStorageData('editcheckpoint');
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ManagerCheckpoint");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    handleEditDeletePopup = (event: React.MouseEvent, checkpointId: number) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isEditDeleteOpen: checkpointId === this.state.isEditDeleteOpen ? 0 : checkpointId });
    }

    handleEdit = (event: React.MouseEvent, checkpointId: number) => {
        event.preventDefault();
        event.stopPropagation();
        setStorageData('checkpointdetailid', checkpointId);
        setStorageData('editcheckpoint', true);
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ManagerCheckpoint");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    } 

    handleDelete = (event: React.MouseEvent, checkpointId: number) => {
        event.preventDefault();
        event.stopPropagation();

        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const deleteCheckpointRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteCheckpointApiCallId = deleteCheckpointRequestMsg.messageId;
        deleteCheckpointRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteCheckpointApiEndPoint}?checkpoint_id=${checkpointId}`
        );
        deleteCheckpointRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        deleteCheckpointRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(deleteCheckpointRequestMsg.id, deleteCheckpointRequestMsg);
    }

    handleShowAllCheckpointApiResponse = (responseJson: CheckpointResponseData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ checkpoint: responseJson.data, role: responseJson?.designation, loading: false })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loading: false
            });
        }
    }

    handleMachinesApiResponse = (responseJson: MachineResponseData) => {
        if (responseJson && !responseJson.error) {
            const responseData = responseJson.data.filter((value: MachineData) => {
                return (parseInt(value.id) === parseInt(this.state.machineId))
            })[0]?.attributes;

            this.setState({ selectedMachineData: responseData })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleDeleteCheckpointApiResponse = (responseJson: CheckpointResponseData) => {
        if (responseJson && !responseJson.error) {
            this.getAllCheckpointApiCall();
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    navigateToCheckpointDetail = (checkpointId: number) => {
        const route = this.state.role === "Worker" ? "CheckpointDetail" : "WorkerCheckpointDetail"
        setStorageData('checkpointdetailid', checkpointId);
        const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
        navigationMsg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(navigationMsg);
    }

    navigateToAuditScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    getAllCheckpointApiCall = () => {
        this.setState({ loading: true })
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getcheckpointRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllCheckpointApiCallId = getcheckpointRequestMsg.messageId;
        getcheckpointRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAllCheckpointApiEndPoint}?id=${this.state.machineId}`
        );
        getcheckpointRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getcheckpointRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getcheckpointRequestMsg.id, getcheckpointRequestMsg);

    }

    getWorkerMachinesApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getWorkerMachineRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getWorkerMachinesApiCallId = getWorkerMachineRequestMsg.messageId;
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMachinesApiEndPoint
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getWorkerMachineRequestMsg.id, getWorkerMachineRequestMsg);

    }
    // Customizable Area End
}
