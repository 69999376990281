import React from "react";

import {
    // Customizable Area Start
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { amazonIcon, myntraIcon, flipkartIcon } from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import RedeemPointsController, { Props } from "./RedeemPointsController";
// Customizable Area End

export default class RedeemPoints extends RedeemPointsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.wrapper}>
                    <Box style={webStyle.pointsProfileWrapper}>
                        <Typography style={webStyle.pointsProfileText}>Profile</Typography>
                        <NavigateNext style={webStyle.navigateIcon} />
                        <Typography style={webStyle.pointsProfileText}>Rewards</Typography>
                        <NavigateNext style={webStyle.navigateIcon} />
                        <Typography style={webStyle.pointsRewardText}>Redeem Points</Typography> </Box>
                    <Typography style={webStyle.RewardsHeading}>Redeem Points</Typography>
                    <Box sx={webStyle.mainBox}>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <Box sx={webStyle.myContibutionBox}>
                                    <img src={amazonIcon} style={webStyle.myContibutionBoxImage} alt="amazon-icon" />
                                    <Typography style={webStyle.contriText}>Voucher valued at ₹200 or 5% off on Amazon</Typography>
                                    <Typography style={webStyle.contriDate}>Expires on 30 Nov 2023</Typography>
                                    <Button data-test-id="redeem-points" style={webStyle.redeemBtn} onClick={this.navigateToVoucherDescriptionScreen}>500 points</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={webStyle.myContibutionBoxYellow}>
                                    <img src={myntraIcon} style={webStyle.myContibutionBoxImage} alt="myntra-icon" />
                                    <Typography style={webStyle.contriText}>Voucher valued at ₹200 or 5% off on Amazon</Typography>
                                    <Typography style={webStyle.contriDate}>Expires on 30 Nov 2023</Typography>
                                    <Button style={webStyle.redeemBtn}>500 points</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={webStyle.myContibutionBoxGreen}>
                                    <img src={flipkartIcon} style={webStyle.myContibutionBoxImage} alt="flipkart-icon" />
                                    <Typography style={webStyle.contriText}>Voucher valued at ₹200 or 5% off on Amazon</Typography>
                                    <Typography style={webStyle.contriDate}>Expires on 30 Nov 2023</Typography>
                                    <Button style={webStyle.redeemBtn}>500 points</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={webStyle.myContibutionBox}>
                                    <img src={amazonIcon} style={webStyle.myContibutionBoxImage} alt="amazon-icon" />
                                    <Typography style={webStyle.contriText}>Voucher valued at ₹200 or 5% off on Amazon</Typography>
                                    <Typography style={webStyle.contriDate}>Expires on 30 Nov 2023</Typography>
                                    <Button style={webStyle.redeemBtn}>500 points</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    navigateIcon: {
        fill: '#DCDCDC'
    },
    wrapper: {
        width: '100%',
        backgroundColor: '#F7F1F1',
        padding: '30px',
        boxSizing: 'border-box',
        height: 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    RewardsHeading: {
        color: "#8735E1",
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: 'ClotherBold',
        margin: '0px 0px 20px 0px',
    },
    wrapperAuto: {
        padding: '0 !important'
    },
    mainBox: {
        background: '#fff',
        padding: '30px',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '8px',
        paddingBottom: '30px'
    },
    pointsProfileWrapper: {
        display: 'flex',
        gap: '10px'
    },
    pointsProfileText: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        paddingBottom: '17px',
        color: '#18181E',
    },
    pointsRewardText: {
        color: "#8735E1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "ClotherRegular"
    },
    myContibutionBox: {
        borderRadius: "8px",
        background:
            "#AEF5FF",
        padding: '20px',
        position: 'relative',
        height: '150px',
        borderRedius: '8px'
    },
    myContibutionBoxImage: {
        height: '30px'
    },
    myContibutionBoxYellow: {
        borderRadius: "8px",
        background: "#FEFFB7",
        padding: '20px',
        position: 'relative',
        height: '150px',
        borderRedius: '8px'
    },
    myContibutionBoxGreen: {
        borderRadius: "8px",
        background: "#C1FFE5",
        padding: '20px',
        position: 'relative',
        height: '150px',
        borderRedius: '8px'
    },
    contriText: {
        color: "#2B2A29",
        leadingTrim: "both",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        marginBottom: '6px',
        marginTop: '12px',
        fontFamily: "ClotherBold"

    },
    contriDate: {
        color: "#2B2A29",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        marginBottom: '10px',
        fontFamily: "ClotherRegular"

    },
    redeemBtn: {
        borderRadius: "8px",
        background: "#FFD541",
        marginTop: "15px",
        color: "#2B2A29",
        textAlign: "center" as "center",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        letterSpacing: "-0.41px",
        textTransform: "capitalize" as "capitalize",
        padding: '10px',
        fontFamily: "ClotherBold"

    },
};
// Customizable Area End