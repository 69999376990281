import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";

// Customizable Area Start
import { ChangeEvent } from "react";
import { ApiResponseForImages, ImageData } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tagName: string;
    tagId: number | null;
    associatedImagesData: ImageData[]
    token: string;
    page: number;
    rowsPerPage: number;
    loading: boolean;
    noRecord: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AssociatedImagesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start 
    getImagesApiCallId: string = '';

    // Customizable Area End 

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestBodyMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIRequestMethodMessage)

        ]
        // Customizable Area End 

        this.state = {
            // Customizable Area Start
            tagName: '',
            tagId: null,
            token: '',
            page: 0,
            associatedImagesData: [],
            rowsPerPage: 1,
            loading: false,
            noRecord: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const responseData = message.getData(getName(MessageEnum.Props));
            this.setState({ tagName: responseData.tag, tagId: responseData.tag_id })

            setStorageData('tagId', String(responseData.tag_id));
            setStorageData('tagName', responseData.tag);

            this.getAssociatedImages();
        }
        if (message.id === getName(MessageEnum.RestAPIResponceMessage) && getName(MessageEnum.RestAPIResponceMessage) === message.id && responseJson) {
            this.setState({ loading: false })
            if (apiRequestCallId === this.getImagesApiCallId) {
                this.associatedImagesSuccessCallback(responseJson)
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        let token = await getStorageData("authToken");

        const storedTagId = await getStorageData('tagId');
        const storedTagName = await getStorageData('tagName');

        this.setState({
            token: token,
            tagId: storedTagId ? +storedTagId : null,
            tagName: storedTagName || '',
        });
        this.getAssociatedImages();
    }

    associatedImagesSuccessCallback(response: ApiResponseForImages) {
        if (response?.error) {
            this.setState({ noRecord: true })
        }
        else {
            this.setState({ associatedImagesData: response?.data!, noRecord: false })
        }
    }

    getAssociatedImages = async () => {
        this.setState({ loading: true })
        this.getImagesApiCallId = await this.apiCallForAssociatedImages({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethodType,
            endPoint: `${configJSON.getImagesEndpoint}?page=${this.state.page}&tag_id=${this.state.tagId}`,
        });
    }

    // Api Call Function
    apiCallForAssociatedImages = async (data: { contentType: string, method: string, endPoint: string, body?: object, type?: string }) => {
        const { contentType, method, endPoint, body, type } = data
        const header = {
            "Content-Type": contentType,
            token: this.state.token,
        };

        const reqMsgAssociatedImg = new Message(getName(MessageEnum.RestAPIRequestMessage))

        reqMsgAssociatedImg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header)

        reqMsgAssociatedImg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint)

        reqMsgAssociatedImg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method)

        body && type != 'formData' ?
            reqMsgAssociatedImg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
            :
            reqMsgAssociatedImg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body)

        runEngine.sendMessage(reqMsgAssociatedImg.id, reqMsgAssociatedImg)

        return reqMsgAssociatedImg.messageId
    }

    onPressBackBtn = () => {
        this.props.navigation.goBack()
    }

    onPageChangeHandler = (event: ChangeEvent<unknown>, newPage: number) => {
        this.setState({ page: newPage - 1 }, () => {
            this.getAssociatedImages();
        });
    };

    onPressImages = (data: ImageData) => {
        const message: Message = new Message(
            getName(MessageEnum.ImageScreen)
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.Props), data);
        this.send(message);
    }
    // Customizable Area End
}