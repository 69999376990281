import React from "react";

import {
    Container,
    Box,
    // Customizable Area Start
    CircularProgress,
    Typography
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Pagination from "@material-ui/lab/Pagination";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import AssociatedImagesController, {
    Props,
    configJSON,
} from "./AssociatedImagesController.web";

export default class AssociatedImages extends AssociatedImagesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { associatedImagesData, page, rowsPerPage, loading, noRecord } = this.state;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={"md"}>
                    <Box sx={webStyle.mainWrapper}>
                        <Box sx={webStyle.topHeader}>
                            <Box sx={webStyle.ArrowBackIcon}><ArrowBackIcon data-test-id="navigate-back" onClick={this.onPressBackBtn} /></Box>
                            <Box sx={webStyle.ImgText}>{this.state.tagName}</Box>
                            <Box sx={webStyle.topMlauto} />
                        </Box>
                    </Box>

                    <Box sx={{ width: '100%', }}>
                        {!noRecord && <Box sx={webStyle.searchDataheading}>{configJSON.relatedTag}:</Box>}
                        <Box sx={webStyle.searchDataboxImg}>
                            {associatedImagesData.map(data => <img data-test-id="associated-image" onClick={() => this.onPressImages(data)} className="imagesListing" src={data.attributes.image} />)}
                        </Box>
                        {associatedImagesData.length > 10 && <Pagination
                            data-test-id="pagination"
                            style={webStyle.pagination}
                            page={page + 1}
                            count={Math.ceil(associatedImagesData?.length / rowsPerPage)}
                            onChange={this.onPageChangeHandler}
                            showFirstButton
                            showLastButton
                            variant="outlined"
                            shape="rounded"
                            siblingCount={0}
                            boundaryCount={1}
                        />}
                    </Box>

                    {loading && <Box data-test-id={"activityLoader"} sx={webStyle.loaderWrapper}>
                        <Box sx={webStyle.circularContainer}>
                            <CircularProgress />
                        </Box>
                    </Box>}
                    {noRecord && <Typography style={webStyle.noRecordStyle}>{configJSON.recordNotFound}</Typography>}

                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    topHeader: {
        borderBottom: 'solid 1px #cbd5d5',
        fontSize: '22px',
        color: '#000',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 15px',
        width: '100%',
        textAlign: 'center',
        fontWeight: ' 700',
        display: 'flex',
    },
    topMlauto: {
        marginLeft: 'auto',
    },
    noRecordStyle: {
        fontSize: '19px',
        color: 'red',
        textAlign: 'center' as const
    },
    ImgText: {
        margin: '0 auto',
    },
    ArrowBackIcon: {
        marginRight: 'auto'
    },
    pagination: {
        marginTop: "15px",
        display: "flex",
        justifyContent: "center"
    },
    selectedImage: {
        width: "100%",
        position: 'relative',
        '& .fullImg': {
            maxWidth: '100%',
        },
        '& .closeIcon': {
            maxWidth: '100%',
        },
    },
    searchData: {
        border: 'solid 1px #a3a4a6',
        width: '100%',
        backgroundColor: '#fff',
        padding: '10px 15px',
        borderRadius: '8px',
        fontSize: '16px',
        color: '#000',
        marginBottom: '20px',
        boxSizing: 'border-box',
        '& .tagName': {
            width: '100%',
            fontSize: '16px',
            color: '#000',
            fontWeight: '600',
        },
        '& .tagSubName': {
            width: '100%',
            fontSize: '14px',
            color: '#000',
            marginTop: '10px',
        }
    },
    searchDataheading: {
        fontSize: '18px',
        color: '#000',
        fontWeight: '600',
        width: '100%',
        marginBottom: '20px',
    },
    loaderWrapper: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(0, 0, 0, .4)",
        zIndex: 1,
    },
    circularContainer: {
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
    },
    searchDataboxImg: {
        width: '100%',
        display: 'flex',
        marginBottom: '20px',
        gap: '20px',
        flexWrap: 'wrap',
        '& .imagesListing': {
            width: '210px',
            border: 'solid 1px #a3a4a6',
            height: '250px',
            borderRadius: '6px',
        },
        '& .imagesListing img': {
            width: '100%',
            height: '100%',
            borderRadius: '6px',
        }
    },
};
// Customizable Area End
