import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { IStudent } from './types/types';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End

import PtStudentsOnCourseScreenController, {
  Props,
  configJSON,
} from './PtStudentsOnCourseScreenController';

export default class PtStudentsOnCourseScreen extends PtStudentsOnCourseScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStudentsOnCourse = () => {
    const { studentList } = this.state;
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{configJSON.studentAccountIdText}</TableCell>
              <TableCell>{configJSON.nameText}</TableCell>
              <TableCell>{configJSON.approvedText}</TableCell>
              <TableCell>{configJSON.approveStudentText}</TableCell>
              <TableCell>{configJSON.viewTestScoresText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentList?.map((student: IStudent, index: number) => (
              <TableRow key={`course-${index}`}>
                <TableCell>{student.attributes.account_id}</TableCell>
                <TableCell>{configJSON.studentNameText}</TableCell>
                <TableCell>{student.attributes.approved + ''}</TableCell>
                <TableCell>
                  {!student.attributes.approved ? (
                    <Button
                      data-test-id={'approveCourseButton'}
                      title={'Approve'}
                      variant="contained"
                      onClick={() =>
                        this.approveCourse(
                          student.attributes.account_id,
                          this.state.courseId,
                        )
                      }
                    >
                      {configJSON.approveText}
                    </Button>
                  ) : null}
                </TableCell>
                <TableCell>
                  <Button
                    data-test-id={`testscoresForStudentButton${index}`}
                    title={'View Test Scores'}
                    placeholder={'View Test Scores'}
                    variant="contained"
                    onClick={() => this.navigateToViewTestsForStudent(student)}
                  >
                    {configJSON.viewTestScoresText}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={'sm'}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <Box>{this.renderStudentsOnCourse()}</Box>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
};
// Customizable Area End
