import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon
} from "@material-ui/core";
import { arrowRightMobile } from './assets';

type Props = {
  navigation: any;
  items: {
    img: string;
    name: string;
    link?: string;
    style?: Record<string, string | number>;
  }[],
  isPhone?: boolean;
}

export default class MobileFooterMenu extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this)
  }
  onItemClick(name: string) {
    this.props.navigation.navigate(name);
  }

  render() {
    return (
      <List style={{ backgroundColor: '#fff' }} data-id='menu-list'>
        {this.props.items.map(({ img, name, link, style={}}) => 
          <ListItem button divider key={name} data-id='list-item'
            style={{...webStyles.menuButton, ...style}}
            onClick={() => this.onItemClick(String(link))}
            >
              <Box display='flex' alignItems='center' data-id='list-icon'>
                <ListItemIcon style={webStyles.imgWrpr}>
                  <img src={img} style={{ maxWidth: '1.2rem'}}/>
                </ListItemIcon>
                <p style={webStyles.menuText} >{name}</p>
              </Box>
              <img src={arrowRightMobile} alt='arrow' />
          </ListItem>    
        )}
      </List>
    );
  }
}

const webStyles = {
  imgWrpr: {
    backgroundColor: '#F8F8F8',
    borderRadious: '4px',
    minWidth: '1.75rem',
    height: '1.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '1rem'
  },
  sidebar: {
    background: "#FFF",
    boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)"
  },
  menuText: {
    color: '#727272',
    fontFamily: 'ClotherRegular',
    fontSize: '#F8F8F8',
  },
  active: {

    color: '#8735E1',
    fontSize: "20px",
    fontFamily: 'ClotherBold',
    fontWeight: 700,
    lineHeight: "8px",

  },
  menuButton: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 0,
  },
  listBox: {
    borderRadius: "8px",
    background: "#fff",
    marginBottom: "25px"
  },
  headB: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eee",
    lineHeight: '20px'
  },
  
};
