Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeCouponGet = "GET";
exports.couponAPiEndPoint =
  "coupon_cg/coupon_code_generator";

exports.createCouponAPiMethod = "POST";
exports.deleteCouponAPiMethod = "DELETE";
exports.patchCouponAPiMethod = "PATCH";
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.couponApiContentType = "application/json";
// Customizable Area End
