import React from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  styled,
  Backdrop,
  Button,
  Dialog,
} from "@material-ui/core";
import {
  iconPlus,
  iconTick,
  cardGroup,
  heartHandsGrad,
  iconSafety,
  location,
  time,
  Unlike,
  attachment1,
  attachment2,
  comment,
  like,
  refreshImg,
  addImg,
  checkImg,
  SuccessImage,
  svgcloud
} from "../assets";
import { profileIcon } from '../../../../components/src/assets';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MobileFooterMenu from "../MobileFooterMenu.web";
import ProfileController, { CommentData, PostData, Props, TagsData } from "../ProfileController.web";
import { account, posts, help, imgh, heartHands, star } from "../assets";
import { webStyles } from "../../../../components/src/styles";
import { getTimeFromNow } from '../../../../components/src/helpers/getTimeFromNow';
import { AccountTile } from "../../../../components/src/AccountTile";
import { InputField, webStyle } from '../postCreationPopUp/styles';
import { configJSON } from '../DashboardController';
import { webStylesLocal } from "../ReportPostModal.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomMessage from "../../../../components/src/CustomMessage.web";
import i18n from "i18next"

export default class MyProfile extends ProfileController {
  constructor(props: Props) {
    super(props);
  }

  postsCard = (postData: PostData, index: number) => {
    return (
      <Box
        data-test-id='post-view'
        display="flex"
        flexDirection="column"
        bgcolor="white"
        p={2}
        borderRadius={8}
        border='1px solid #d7d2d2'
        boxShadow={1}
        margin='20px'
        pt={2}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" >
          <Box display="flex" alignItems="center" style={webStyle.pointerClass} data-test-id={`user-names${index}`} className="flexCenter" onClick={() => this.goToUserProfile(postData.attributes?.account_id)}>
            <Avatar
              src={postData?.attributes?.profile_pic || profileIcon}
              data-test-id='profile-icon'
              alt="Custom Icon"
              style={webStyles.customIcon}
            />
            <Typography
              data-test-id='post-heading'
              variant="h6"
              style={webStyles.postUserName}
              component="div">
              {postData?.attributes?.user_name || configJSON.NoNameText} {configJSON.createdText}
            </Typography>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.openPostOption}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            onClose={this.handlePostOption}
            className='reportmenu'
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            style={{ width: '110px' }}
          >
            <MenuItem
              data-test-id={`report-btn${index}`}
              style={webStyles.reportMenuItem}
              onClick={() => this.handleReportForPost(postData?.attributes?.id)}>
              {configJSON.ReportText}
            </MenuItem>
          </Menu>
        </Box>
        <a href={postData?.attributes?.image_url} target='blank'>
          <img
            src={postData?.attributes?.image_url}
            alt={configJSON.YourImageText}
            style={webStyles.postImage as React.CSSProperties} />
        </a>
        <Box mt={2}>
          <Typography variant="body1" component="p" style={webStyles.descrp}>
            {postData?.attributes?.description || configJSON.NoDescrpitionText}
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"} style={webStyles.locationBox}>
          <IconButton>
            <img src={location} />
          </IconButton>
          <Typography
            variant="body2"
            component="div" style={webStyles.locationText}>
            {postData?.attributes?.work_location || postData?.attributes?.location || configJSON.NoLocationText}
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={'space-between'} flexWrap='wrap'>
          <Box display={"flex"} alignItems={'center'} justifyContent={'flex-start'}>
            <Box display={"flex"} alignItems={'center'}>
              <IconButton style={{paddingLeft: '0px'}}>
                <Avatar
                  src={postData?.attributes?.profile_pic || profileIcon}
                  alt="Custom Profile Icon"
                  style={webStyles.profilePic}
                />
              </IconButton>
              <Typography
                variant="body2"
                style={webStyles.userName}
                component="div">
                {postData?.attributes?.user_name?.slice(0, 12)}
                {postData?.attributes?.user_name?.length < 12 ? "" : "..."}
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={'center'}>
              <IconButton style={webStyles.iconCustomPadding}>
                <img src={time} />
              </IconButton>
              <Typography variant="body2" style={webStyles.userName} component="div">
                {getTimeFromNow(postData?.attributes?.created_at)} {configJSON.agoText}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            {this.state.postLikeLoader && this.state.currentPostId === postData.attributes.id ?
              <CircularProgress />
              :
              <Box display={"flex"} alignItems={"center"}>
                <IconButton style={webStyles.iconCustomPadding} onClick={() => this.proceedToLikePost(postData?.attributes)} data-test-id={`like-btn${index}`}>
                  <img src={postData?.attributes?.did_you_like ? like : Unlike} />
                </IconButton>
                <Typography variant="body2" component="div" style={webStyles.userName}>
                  {postData?.attributes?.likes_count} {configJSON.LikesText}
                </Typography>
              </Box>
            }
            <Box data-test-id={`show-commwnts${index}`} display={"flex"} alignItems={"center"} style={webStyle.pointerClass} onClick={() => this.showPostComments(postData, index)}>
              <IconButton style={webStyles.iconCustomPadding}>
                <img src={comment} />
              </IconButton>
              <Typography variant="body2" component="div" style={webStyles.userName}>
                {postData?.attributes?.comments_count} {configJSON.CommentsText}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"} mt={1} width='100%' position='relative'>
            <InputField
              placeholder="Write Your Comment"
              variant='outlined'
              value={this.state.currentPostId === postData?.attributes?.id ? this.state.postComment : ''}
              onFocus={() => this.setCurrentPostId(postData?.attributes?.id)}
              onChange={(event) => this.proceedToAddPostComment({ keyCode: 12, target: { value: event.target.value } }, postData?.attributes?.id)}
              onKeyDown={(event) => this.proceedToAddPostComment({ keyCode: event.keyCode, target: { value: this.state.postComment } }, postData?.attributes?.id)}
              data-test-id={`comment-btn${index}`}
            />
            <IconButton style={webStyles.iconBtn as React.CSSProperties}>
              <Button component="label" style={{ ...webStyle.inputAttachment, ...webStyle.uploadAttachment } as React.CSSProperties}>
                <div style={webStyle.attachmentBox as React.CSSProperties}>
                  <img src={attachment1} style={webStyles.attachments} />
                  <img src={attachment2} />
                </div>
                <input
                  type="file"
                  name="image"
                  id="user-image"
                  accept="image/png, image/jpeg"
                />
              </Button>
            </IconButton>
          </Box>
          {postData?.attributes?.openCommentsList && (
            <Box style={webStyle.CommentBox}>
              <Typography style={webStyle.commentsHeader}>Comments ({this.state.totalComment})</Typography>
              {!this.state.commentsloading && this.state.comments.map((data) => {
                return (
                  <div style={webStyle.commentListItem} key={data.id}>
                    <Grid container justifyContent="space-between">
                      <Grid item lg={7}>
                        <Grid container alignItems="center">
                          <Avatar src={data?.attributes?.account?.profile_image || profileIcon} style={webStyle.commentImage} />
                          <Typography style={webStyle.commentUserName}>{data?.attributes?.account?.full_name}</Typography>
                          <Typography style={webStyle.commentTimeText}>
                            <div style={webStyle.timeDotIcon}></div>
                            {this.timeFromSince(data?.attributes?.created_at)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item lg={4} style={webStyles.flexBox}>
                        <MoreVertIcon data-test-id={`report-comments${index}`} style={webStyle.pointerClass}
                          onClick={(event) => this.showReportComment(event, data?.id)}
                        ></MoreVertIcon>
                        {this.commentReportMenu(data, index)}
                      </Grid>
                    </Grid>
                    <Typography style={webStyle.commentValue}>{data?.attributes?.comment}</Typography>
                  </div>
                )
              })}
              {this.state.commentsloading &&
                <CircularProgress />}
              {!this.state.commentsloading && this.state.currentCommentPage !== Math.ceil(this.state.totalComment / 5) &&
                <Typography align="center" style={webStyle.loadMore} data-test-id={`load-more${index}`} onClick={() => this.getPostComment(postData?.attributes?.id)}>Load More
                  <img src={refreshImg} style={webStyle.refreshIcon} />
                </Typography>}
            </Box>
          )
          }
        </Box>
      </Box >
    )
  }

  commentReportMenu = (data: CommentData, index: number) => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={this.state.anchorElReportComment}
        open={this.state.reportCommentOption}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={() => this.showReportComment({ currentTarget: null }, data?.id)}
        className='reportmenu'
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        data-test-id={`menu-btn${index}`}
        style={{ width: '110px' }}
      >
        <MenuItem
          data-test-id={`report-comment-btn${index}`}
          style={webStyles.reportMenuItem}
          onClick={() => this.openReportCommnetModal()}
        >
          {configJSON.ReportText}
        </MenuItem>
      </Menu>
    )
  }

  render() {
    const isPhone = window.innerWidth < 600;
    const isWide = !isPhone;

    const sideBarItems = [
      {
        img: imgh,
        name: "Home",
        link: "Dashboard",
      },
      {
        img: posts,
        name: "My Posts",
      },
      {
        img: account,
        name: "Account",
        link: "EditProfile",
      },
      {
        img: help,
        name: "Help",
      },
    ];
    const { editProfileData, percentageProfile } = this.state;
    const inputDate = new Date(editProfileData?.attributes?.date_of_birth);
    const formattedDate = inputDate.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    return (
      this.state.isLoading ? (
        <Backdrop style={webStyles.loaderBox} open={this.state.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="my-profile">
          <style>
            {`.my-profile * {
              font-family: 'ClotherRegular';
          }`}
          </style>
          <Box>
            <Box style={webStyles.bgColor}>
              <Grid>
                <Grid>
                  <Box p={{ sm: 4 }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      style={
                        {
                          ...webStyles.profileContainer,
                          ...(isWide && { backgroundColor: "#fff" }),
                        } as React.CSSProperties
                      }
                    >
                      {isWide && (
                        <IconButton
                          style={webStyles.dotMenu as React.CSSProperties}
                        >
                          <MoreVertIcon
                            onClick={this.handleClick}
                            data-id="handleClick"
                          />
                          <CustomMenu
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                            data-id="handleClose"
                            style={webStyles.customMenu as React.CSSProperties}
                          >
                            <MenuItem
                              onClick={this.handleMenuItemClick}
                              data-test-id="handleMenuClick"
                            >
                              Edit Profile
                            </MenuItem>
                          </CustomMenu>
                        </IconButton>
                      )}
                      <Grid item>
                        <Box
                          position="relative"
                          display="inline-flex"
                          width="6.8rem"
                        >
                          <CircularProgress
                            variant="determinate"
                            value={percentageProfile}
                            size="6.8rem"
                            style={webStyles.innerCircle as React.CSSProperties}
                          />
                          <CircularProgress
                            variant="determinate"
                            value={100 - percentageProfile}
                            size="6.8rem"
                            style={
                              webStyles.backgroundCircle as React.CSSProperties
                            }
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <div
                              style={webStyles.percentDiv as React.CSSProperties}
                            >
                              {percentageProfile}
                            </div>
                            <Avatar
                              src={editProfileData?.attributes?.profile_image.length !== 0 ?
                                editProfileData?.attributes?.profile_image : profileIcon}
                              alt="User"
                              style={webStyles.avatarStyle}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item style={webStyles.avatarWrapper}>
                        <div style={webStyles.basicHeader}>
                          {editProfileData?.attributes?.full_name}
                        </div>
                        <div style={webStyles.avatarList as React.CSSProperties}>
                          <span
                            style={
                              webStyles.userPropertyName as React.CSSProperties
                            }
                          >
                            Role:
                          </span>
                          <span
                            style={
                              webStyles.userPropertyValue as React.CSSProperties
                            }
                          >
                            {editProfileData?.attributes?.role_name}
                          </span>
                        </div>
                        <div style={webStyles.avatarList as React.CSSProperties}>
                          <span
                            style={
                              webStyles.userPropertyName as React.CSSProperties
                            }
                          >
                            Employee Code:
                          </span>
                          <span
                            style={
                              webStyles.userPropertyValue as React.CSSProperties
                            }
                          >
                            {editProfileData?.attributes?.employee_id}
                          </span>
                        </div>
                      </Grid>
                      <Box marginTop="1.25rem">
                        <Grid container spacing={3}>
                          <Grid item xs={6} sm={4}>
                            <AccountTile
                              data-test-id="navigateToFollowers"
                              name="Followers"
                              bottomContent={editProfileData?.attributes?.followers}
                              img={iconPlus}
                              onClick={this.navigateToFollowers}
                            />
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <AccountTile
                              data-test-id="navigateToFollowing"
                              name="Following"
                              bottomContent={editProfileData?.attributes?.followings}
                              img={iconTick}
                              onClick={this.navigateToFollowing}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <AccountTile
                              name="Safety Score"
                              bottomContent="Coming Soon"
                              img={iconSafety}
                              inRow={isPhone}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <AccountTile
                              name="Health Score"
                              bottomContent="Coming Soon"
                              img={iconTick}
                              inRow={isPhone}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <AccountTile
                              name="Environment Score"
                              bottomContent="Coming Soon"
                              img={iconPlus}
                              inRow={isPhone}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <AccountTile
                              data-test-id="navigateToHSEpassport"
                              name="HSE passport"
                              bottomContent="HSE passport"
                              img={svgcloud}
                              inRow={isPhone}
                              onClick={this.navigateToHSEpassport}
                            />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <AccountTile
                              name="Contribution"
                              bottomContent={
                                <div style={webStyles.bottomContent}>
                                  <span style={webStyles.bottomSpan}>
                                  {i18n.t('Level Coming Soon')}                                  </span>
                                  <img
                                    src={star}
                                    alt="star"
                                    style={webStyles.starImg}
                                  />
                                </div>
                              }
                              img={heartHands}
                              addTop={
                                <div>
                                  <span
                                    style={
                                      webStyles.addTop as React.CSSProperties
                                    }
                                  >
                            {i18n.t(' Coming Soon')}   </span>
                                  <span>Points</span>
                                </div>
                              }
                              addBottom={
                                <div style={webStyles.addBottomCont}>
                                  <span
                                    style={
                                      webStyles.addBottom as React.CSSProperties
                                    }
                                  >
                                    Need
                                  </span>
                                  <span
                                    style={
                                      webStyles.addBottomHigh as React.CSSProperties
                                    }
                                  >
                                {i18n.t(' Coming Soon')}        </span>
                                  <span
                                    style={
                                      webStyles.addBottom as React.CSSProperties
                                    }
                                  >
                             { i18n.t(' For Level Coming Soon')}    </span>
                                </div>
                              }
                              extended
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    {isWide && (
                      <Grid item style={webStyles.contentContainer}>
                        {this.state.dashboardData.length > 0 ?
                          <Grid
                            item
                            xs={8}
                            style={webStyles.headBBox}
                          >
                            <Box style={webStyles.headB}>
                              <h3>Recent Posts</h3>
                              <p style={webStyles.headp}>
                                View All
                              </p>
                            </Box>
                            {this.state.dashboardData.map((postData: PostData, index: number) => (
                              this.postsCard(postData, index)
                            ))}
                          </Grid>
                          :
                          <Grid item xs={8} style={webStyles.headBBox}>
                            <CustomMessage message="Looks like you don't have any post" />
                          </Grid>
                        }

                        <Grid item xs={4}>
                          <Box style={webStyles.listBox}>
                            <Box style={webStyles.headB}>
                              <h3>Contribution Points</h3>
                              <p
                                style={webStyles.headp}
                              >
                                See All
                              </p>
                            </Box>
                            <Box padding="1rem">
                              <Box
                                borderRadius="8px"
                                style={{
                                  ...webStyles.contributionBox as React.CSSProperties,
                                  background: `url(${cardGroup}) right bottom no-repeat, linear-gradient(104deg, #8735E1 0.93%, #C53AAF 54.04%, #8735E1 100%)`,
                                }}
                              >
                                <img
                                  src={heartHandsGrad}
                                  alt="card icon"
                                  style={webStyles.HandsIcon}
                                />
                                <Typography>Your Contribution Points</Typography>
                                <Typography
                                  style={webStyles.pointsText}
                                >
                                  0
                                </Typography>
                                <Typography>
                                  Need{" "}
                                  <span style={webStyles.pointsText}>+ 0</span>{" "}
                                  points for level 20
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box data-test-id="riskProfileCard"
                            style={webStyles.pointslistBox} onClick={() => this.navigateToRiskProfileCard()}
                          >
                            <Box id="subh" style={webStyles.headB}>
                              <h3>Risk Profile Card</h3>
                              <p
                                style={webStyles.headp}
                              >
                                Learn More
                              </p>
                            </Box>
                            <Box padding="1rem">
                              <Grid
                                container
                                style={{
                                  ...webStyles.cardsGrp,
                                  background: `url(${cardGroup}) right bottom no-repeat, linear-gradient(104deg, #8735E1 0.93%, #C53AAF 54.04%, #8735E1 100%)`,
                                }}
                              >
                                <Grid
                                  item
                                  style={webStyles.FunBox as React.CSSProperties}
                                  xs={12}
                                  sm={6}
                                  lg={7}
                                >
                                  <Typography style={webStyles.userNameText}>
                                    {editProfileData?.attributes?.full_name}
                                  </Typography>
                                  <Typography style={webStyles.detailsHeading}>Function:{" "}
                                    <span style={webStyles.detailsText}>
                                      {editProfileData?.attributes?.function_name}
                                    </span>
                                  </Typography>
                                  <Typography style={webStyles.detailsHeading}>
                                    Location:{" "}
                                    <span style={webStyles.detailsText}>
                                      {editProfileData?.attributes?.location_name}
                                    </span>
                                  </Typography>
                                  <Typography style={webStyles.detailsHeading}>
                                    Role:{" "}
                                    <span style={webStyles.detailsText}>
                                      {editProfileData?.attributes?.role_name}
                                    </span>
                                  </Typography>
                                  <Typography style={webStyles.detailsHeading}>
                                    Date:{" "}
                                    <span style={webStyles.detailsText}>
                                      {formattedDate}
                                    </span>
                                  </Typography>
                                  <Typography style={webStyles.detailsHeading}>
                                    Emp Code:{" "}
                                    <span style={webStyles.detailsText}>
                                      {editProfileData?.attributes?.employee_id}
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  lg={5}
                                  style={webStyles.Containers}
                                >
                                  <img
                                    src={editProfileData?.attributes?.profile_image.length !== 0 ?
                                      editProfileData?.attributes?.profile_image : profileIcon
                                    }
                                    alt="user photo"
                                    style={webStyles.profileImage}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                    {isPhone && (
                      <MobileFooterMenu
                        navigation={this.props.navigation}
                        items={sideBarItems}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {this.state.reportPostModal &&
            <Dialog open={this.state.reportPostModal} data-id="congrats-dialog" fullWidth>
              <Box
                style={webStylesLocal.dialogContainer}
                justifyContent="space-between"
                flexDirection="column"
                display="flex"
              >
                <Box justifyContent={"space-between"} display={"flex"}>
                  <Typography style={webStylesLocal.noteFont}>
                    {this.state.reportCommentModal ? configJSON.ReportCommentText : configJSON.ReportPostText}
                  </Typography>
                  <CloseIcon
                    onClick={() => this.closeReportModal()}
                    style={webStylesLocal.closeIcon}
                    data-test-id="reportcloseBtn"
                  />
                </Box>
                <Typography style={webStylesLocal.subHeader}>
                  {configJSON.ReportSubHeaderText}
                </Typography>
                <Grid container>
                  {this.state.reportTags.map((tag: TagsData, index: number) => {
                    return (
                      <Button
                        variant="outlined"
                        data-test-id={`tagBtn${index}`}
                        key={index}
                        onClick={() => this.addSelectedTags(parseInt(tag.attributes.id))}
                        style={this.getTagColor(parseInt(tag.id)) ? webStylesLocal.SelectedTagBtn as React.CSSProperties : webStylesLocal.TagBtn as React.CSSProperties}
                      >
                        <Typography style={webStylesLocal.addIcon}>
                          {this.getTagColor(parseInt(tag.id)) ? <img src={checkImg} /> : <img src={addImg} />}</Typography>
                        <Typography style={this.getTagColor(parseInt(tag.id))
                          ? webStylesLocal.selectedTagName as React.CSSProperties :
                          webStylesLocal.tagName as React.CSSProperties}>{tag.attributes.name}</Typography>
                      </Button>
                    )
                  })}
                </Grid>
                <Box>
                  <Typography style={webStylesLocal.label}>{configJSON.ReportDescriptionLable}</Typography>
                  <textarea
                    placeholder="Description"
                    style={webStylesLocal.textareaStyle}
                    onChange={(e: { target: { value: string } }) => this.handledescriptionChange(e)}
                    value={this.state.reportdescription}
                    className="textarea"
                    data-test-id="handleTextAreaChange"
                  />
                  {
                    this.state.isDescriptionErr && (
                      <Typography style={webStylesLocal.errorText}>{configJSON.errorTextMsg}</Typography>
                    )
                  }
                </Box>
                <Box justifyContent={"flex-end"} display={"flex"} >
                  <Button onClick={() => this.handleReportApiCall()} style={webStylesLocal.reportBtn as React.CSSProperties} data-test-id="reportbtn">
                    {configJSON.ReportBtnText}
                  </Button>
                </Box>
              </Box>
            </Dialog>
          }
          {this.state.postIsReported &&
            <Dialog fullWidth open={this.state.postIsReported} data-id="congrats-dialog">
              <Box
                style={webStylesLocal.dialogContainer}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Box justifyContent={"flex-end"} display={"flex"}>
                  <CloseIcon
                    onClick={() => this.closeReportModal()}
                    style={webStylesLocal.closeIcon}
                    data-test-id="reportedCloseBtn"
                  />
                </Box>
                <Box justifyContent={"center"} display={"flex"}>
                  <img style={{ width: "70px", height: "70px" }} src={SuccessImage} />
                </Box>
                <Typography align='center' style={webStylesLocal.ReportedFont}>
                  {this.state.reportCommentModal ? configJSON.CommentReportedText : configJSON.PostReportedText}
                </Typography>
                <Typography style={webStylesLocal.subHeader2}>
                  {configJSON.ReportedSubHeaderText}
                </Typography>
                <Button onClick={this.closeReportModal} style={webStylesLocal.okayBtn as React.CSSProperties} variant='outlined' data-test-id="okayBtn">
                  {configJSON.OkayBtnText}
                </Button>
              </Box>
            </Dialog>
          }
        </div>)
    );
  }
}
const CustomMenu = styled(Menu)({
  "& .MuiList-padding": {
    paddingBottom: "0px",
  },
});
