import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  Comment,
  ResponseJson,
  SetChildCommentFn,
  ParentComment,
  AttributesElement,
  ShowAllComment,
  Datum,
  PropPostData,
  SendChildCommentData,
} from "./types";
import { Alert } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  postData: PropPostData;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  postID: string;
  modalVisible: boolean;
  newCommentText: string;
  token: string | null;
  postBodyData: string;
  loader: boolean;
  allComment: Comment[];
  allChildComment: Array<Object>;
  isReplying: boolean;
  selectedParentReplyButton: string | number | null;
  selectedParentCommentButton: string;
  replyText: string;
  firstname: string;
  createdAt: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Conversationthreading2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCommentDataId: string = "";
  GetAllUserComentId: string = "";
  ShowSinglePostId: string = "";
  createChildCommentDataId: string = "";
  fetchChildCommentDataId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      postID: "",
      modalVisible: false,
      newCommentText: "",
      token: "",
      postBodyData: "",
      loader: true,
      allComment: [],
      allChildComment: [],
      isReplying: false,
      selectedParentReplyButton: "",
      selectedParentCommentButton: "",
      replyText: "",
      firstname: "",
      createdAt: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const idToFunctionMap = {
        [this.createCommentDataId]: this.onCreateCommentData,
        [this.GetAllUserComentId]: this.onGetAllUserComent,
        [this.ShowSinglePostId]: this.onShowSinglePost,
        [this.fetchChildCommentDataId]: this.onfetchChildCommentData,
        [this.createChildCommentDataId]: this.onCreateChildCommentData,
      };

      const selectedFunction = idToFunctionMap[apiRequestCallId];

      if (selectedFunction) {
        selectedFunction(responseJson);
      } else {
        Alert.alert(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    //Token can be set here as props
    let token = await getStorageData("authToken");
    let postID = this.props.navigation.getParam('postID')
    this.setState(
      {
        token: token,
        postID,
      },
      () => {
        this.showSinglePost();
      }
    );
  }

  onfetchChildCommentData = (responseJson: ParentComment) => {
    this.setState({
      loader: false,
    });
    if (responseJson != null) {
      if (!responseJson.parent_comment.data.attributes.child_comments) {
        return false;
      } else {
        let tempAllComment: Comment[] = [];
        responseJson.parent_comment.data.attributes.child_comments.forEach(
          (itemObject: AttributesElement, arrayIndes: number) => {
            let cmntobject: Comment = {
              commentbody: itemObject.comment,
              currentcommentID: itemObject.id,
              post_id: itemObject.post_id,
              createdAt: itemObject.created_at,
              childComment: [],
              image: "",
              firstname: itemObject.first_name,
              id: "",
            };

            tempAllComment.push(cmntobject);
          }
        );
        let reversecomment = [...tempAllComment].reverse();
        this.setState({ allChildComment: reversecomment });
        let commt = this.state.allComment;
        this.setChildComment(
          responseJson.parent_comment.data.id,
          commt,
          reversecomment
        );

        this.setState({ allComment: commt });
      }
    }
  };

  onCreateChildCommentData = (responseJson: SendChildCommentData) => {
    if (responseJson != null) {
      this.setState({
        loader: true,
      });
      this.fetchChildCommentsButton(
        responseJson.serializer.data.attributes.post_id,
        responseJson.serializer.data.attributes.parent_id
      );
    }
  };

  onShowSinglePost = (responseJson: ResponseJson) => {
    if (responseJson != null && !responseJson.errors) {
      this.GetAllUserComent();
      this.setState({ postBodyData: responseJson.data.attributes.body });
    }else{
      this.goToHome()
    }
  };

  onGetAllUserComent = (responseJson: ShowAllComment) => {
    if (responseJson != null) {
      let tempAllComment: Comment[] = [];
      responseJson.data.forEach((itemObject: Datum) => {
        let cmntobject: Comment = {
          commentbody: itemObject.attributes.comment,
          currentcommentID: itemObject.attributes.id,
          post_id: itemObject.attributes.post_id,
          firstname: itemObject.attributes.first_name,
          createdAt: itemObject.attributes.created_at,
          image: "",
          childComment: [],
          id: "",
        };
        tempAllComment.push(cmntobject);
      });
      let reverseComment = [...tempAllComment].reverse();
      this.setState({ allComment: reverseComment, loader: false });
    }
  };

  onCreateCommentData = (responseJson: ResponseJson) => {
    if (responseJson != null) {
      this.setState({ loader: true });
      this.GetAllUserComent();
    }
  };

  commentSectionFn = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  createComment = () => {
    this.setState(
      {
        postID: this.state.postID,
        modalVisible: !this.state.modalVisible,
      },
      () => {
        this.SendComment();
      }
    );
  };

  GetAllUserComent() {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllUserComentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetAllUserCommentApiEndPoint(this.state.postID)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetAllUserCommentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  SendComment = () => {
    let dataSendComment = {
      attributes: {
        comment: this.state.newCommentText,
      },
    };

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentDataId = requestMessage.messageId;

    const httpBody = {
      data: dataSendComment,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SendCommentApiEndPoint(this.state.postID)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SendCommentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ newCommentText: "" });
    return true;
  };

  SendChildComment = (currentcommentID: string | number) => {
    let dataSendChildComment = {};
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    if (currentcommentID) {
      dataSendChildComment = {
        attributes: {
          parent_id: currentcommentID,
          comment: this.state.replyText,
        },
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createChildCommentDataId = requestMessage.messageId;

    const httpBody = {
      data: dataSendChildComment,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SendChildCommentApiEndPoint(this.state.postID)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SendChildCommentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  goToHome() {
    const homeScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    homeScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(homeScreenMsg);
  }

  showSinglePost() {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ShowSinglePostId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showSinglePostApiEndPoint}${this.state.postID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.showSinglePostAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleReplyButton = (replyId: string | number) => {
    this.setState({
      selectedParentReplyButton: replyId,
      isReplying: !this.state.isReplying,
    });
  };

  handleReplyButtonWeb = (replyId: string | number) => {
    if (replyId !== this.state.selectedParentReplyButton) {
      this.setState({
        selectedParentReplyButton: replyId,
        isReplying: true,
        replyText: "",
      });
    } else {
      this.setState({
        isReplying: !this.state.isReplying,
        replyText: "",
      });
    }
  };

  handleSendButton = (currentcommentID: string | number) => {
    // Handle sending the reply text
    this.SendChildComment(currentcommentID);
    // Reset the reply text and close the input box
    this.setState({ replyText: "", selectedParentReplyButton: "" });
  };

  handleParentCommentsButton = (
    postid: string | number,
    parentid: number | string,
    isCommentShow: boolean
  ) => {
    if (isCommentShow) {
      this.fetchChildCommentsButton(postid, parentid);
    } else {
      let allComment = this.state.allComment;
      this.setChildComment(parentid, allComment, []);
      this.setState({ allComment });
    }
  };

  setChildComment: SetChildCommentFn = function setChildComment(
    parentid: number | string,
    tempAllComment,
    comments
  ) {
    let result = false;
    tempAllComment.forEach((itemObject: Comment) => {
      if (itemObject.currentcommentID == parentid) {
        itemObject.childComment = comments;
        result = true;
        return result;
      } else if (itemObject.childComment?.length > 0) {
        let respOnse = setChildComment(
          parentid,
          itemObject.childComment,
          comments
        );
        if (respOnse) {
          return true;
        }
      }
    });
    return false;
  };

  fetchChildCommentsButton = (
    postid: string | number,
    parentid: number | string
  ) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchChildCommentDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchChildCommentsButtonApiEndPoint(postid, parentid)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fetchChildCommentsButtonAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  replyTextEventInput = (event: {
    target: {
      value: string;
    };
  }) => {
    this.setState({ replyText: event.target.value });
  };

  newCommentTextInput = (text: string) => {
    this.setState({ newCommentText: text });
  };
  // Customizable Area End
}
