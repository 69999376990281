import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface MachineResponseData {
    data: {
        id: string;
        attributes: MachineData;
    }[];
    error: string;
}

interface MachineData {
    id: string;
    equipment_name?: string;
    equipment_number?: number;
    due_date?: string;
    location_id?: number;
    account_id?: number;
    audit_category_id?: number;
    category?: string;
    location?: string;
}

interface CheckpointResponseData {
    data: CheckpointData;
    error: string;
}

interface CheckpointData {
    id?: string;
    attributes?: {
        id?: string;
        name?: string;
        recommended_check?: string;
        inspection_frequency?: string;
        action_required?: string;
        standard_reference?: null;
        audit_sub_category_id?: string;
        referencestandard_id?: string;
        reference_image?: string;
        sub_category?: string;
        reference_standard?: string;
        unit_of_measurement: {
            id: number;
            name: string;
        };
        total_target: string;
        compiled: string;
        non_compiled: string;
        target_date: string;
        remark: string;
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    checkpointId: string;
    errorMsg: string;
    machineId: string;
    loading: boolean;
    assignPersonRadio: string;
    machineData: MachineData;
    checkpointData: CheckpointData;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WorkerCheckpointController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getWorkerMachineForSupervisorApiCallId: string = "";
    getShowCheckpointForSupervisorApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            checkpointId: "",
            errorMsg: "",
            loading: false,
            assignPersonRadio: "no",
            machineId: "",
            machineData: {id: ""},
            checkpointData: {}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getWorkerMachineForSupervisorApiCallId) {
                this.handleMachinesForSupervisorResponse(responseJson)
            }
            if (apiRequestCallId === this.getShowCheckpointForSupervisorApiCallId) {
                this.handleCheckpointForSupervisorResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        let checkpointId = await getStorageData("checkpointdetailid", false);
        let machineId = await getStorageData("machineid", false);
        this.setState({ token, checkpointId, machineId }, () => {
            this.getWorkerMachineForSupervisorApiCall();
            this.getShowCheckpointForSupervisorApiCall();
        })
    }

    handleMachinesForSupervisorResponse = (responseJson: MachineResponseData) => {
        if (responseJson && !responseJson.error) {
            const responseData = responseJson.data.filter((value: MachineData) => {
                return (parseInt(value.id) === parseInt(this.state.machineId))
            })[0]?.attributes;

            this.setState({ machineData: responseData })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleCheckpointForSupervisorResponse = (responseJson: CheckpointResponseData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ checkpointData: responseJson.data, loading: false})
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loading: false
            });
        }
    }
    
    navigateToNonCompiledAssignment = () => {
        const route = this.state.assignPersonRadio === "yes" ? "NonCompiledAssignment" : "Audit";
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToAuditScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToCheckpointScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    handleAssignPersonRadio = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        this.setState({ assignPersonRadio: (event.target.value) as string });
    }

    getWorkerMachineForSupervisorApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getWorkerMachineRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getWorkerMachineForSupervisorApiCallId = getWorkerMachineRequestMsg.messageId;
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMachinesApiEndPoint
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getWorkerMachineRequestMsg.id, getWorkerMachineRequestMsg);

    }

    getShowCheckpointForSupervisorApiCall = () => {
        this.setState({ loading: true})
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getAllRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getShowCheckpointForSupervisorApiCallId = getAllRequestMsg.messageId;
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getShowCheckpointApiEndPoint}?id=${this.state.checkpointId}`
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
    }

    // Customizable Area End
}
