import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


// Customizable Area Start
import StorageProvider from "../../../../framework/src/StorageProvider";
import { ApiCallInterface, AssessmentItemInterface } from '../IAssessment'
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  itemContent: AssessmentItemInterface,
  isLoading: boolean;
  isSuccess: boolean;
  isDetailError: boolean,
  courses_Id: number | string | null;
  token: string;
  Courses: object|any;
  // Customizable Area End
}

interface SS {
  id: any;

  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  apiGetAssessmentById: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    // Customizable Area Start


    this.state = {

      // Customizable Area Start
      itemContent: {},
      isDetailError: false,
      isLoading: true,
      isSuccess: false,
      courses_Id: null,
      token: "",
      Courses: []
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  async componentDidMount() {
    let courses_Id = await getStorageData("courses_Id", false);
    let token = await getStorageData("authToken", false);
    this.setState({ token, courses_Id });
    this.getAssessmentByIdApi(courses_Id)
  }

  apiCall = async (data: ApiCallInterface) => {

    const { contentType, method, endPoint, body } = data;

    let token = await StorageProvider.get("USER_TOKEN");
    const header = {
      "Content-Type": contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.apiGetAssessmentById) {
          this.getAssessmentByIdApiSuccesscallBack(responseJson.data);
        }

      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiGetAssessmentById) {
          this.getAssessmentByIdFailureCallBack();
        }

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  startAssessment() {
    this.props.navigation.navigate('AssessmentQuestions',
      { id: this.getId() })
  }

  getAssessmentByIdApi = async (id: number | string) => {
    this.apiGetAssessmentById = await this.apiCall({
      contentType: configJSON.assessmentByIdApiContentType,
      method: configJSON.getAssessementTestAPiMethod,
      endPoint: `${configJSON.getAssessementTestByIdUrl}/${id}`,
    });
  };

  getId() {
    return this.props.navigation.getParam('id');
  }


  getAssessmentByIdApiSuccesscallBack = async (data: AssessmentItemInterface) => {
    this.setState({
      isLoading: false,
      isDetailError: false,
      Courses: data
    })
  };

  getAssessmentByIdFailureCallBack = () => {
    this.setState({
      isLoading: false,
      isDetailError: true,
      isSuccess: false
    })
  };

  navigateToAssessmentTestScreen = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentTest");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  navigateToAssessmentQuestionScreen = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentQuestions");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  onReFetch(){

  }

  convertMinutesToHoursAndMinutes(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let result = hours + " hour";
    if (hours !== 1) {
      result += "s ";

    }
    result += remainingMinutes + " minute";
    if (remainingMinutes !== 1) {
      result += "s";
    }
    return result;
  }


  // Customizable Area End
}
