Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "story2";
exports.labelBodyText = "story2 Body";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.errorMsg = "This field is required";
exports.listUserStoriesApiEndPoint = "bx_block_story2/user_stories";
exports.detailUserStoriesApiEndPoint = "bx_block_story2/user_stories";
exports.createStoryApiEndPoint = "bx_block_story2/user_stories";

exports.btnExampleTitle = "CLICK ME";
exports.storyErrorMEssage = "Failed to load stories";
exports.noMoreStoriesMessage = "No more stories to load";
exports.imageErrorMEssage = "Unable to load story image";
// Customizable Area End