import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import AddCircleIcon from '@material-ui/icons/AddCircle';
// Customizable Area End 
import Emaillists2Controller, {
  Props,
  configJSON,
} from "./Emaillists2Controller";
import { searchIconLogo } from "./assets";
import { EmailListItem } from "./types";
export default class Emaillists2 extends Emaillists2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  LogOutButton = () => {
    return (
      <Box style={webStyle.logoutBox}>                                                              
        <Button variant="contained" style={webStyle.logoutBtn} color="primary" data-test-id="logoutBtn" onClick={this.Logoutcall.bind(this)}>{configJSON.LogoutBtnText}</Button>
      </Box>
    )
  }

  SearchFields = () => {
    return (
        <Box style={webStyle.searchContainer}>
              <input
                type="text"
                value={this.state.searchText}
                onChange={this.onchangeSearchText.bind(this)}
                style={webStyle.searchInput}
                data-test-id="txtInput"
              />
              <Button onClick={this.onPressSearch.bind(this)} data-test-id="searchBtn">
                <img
                  src={searchIconLogo}
                  width="45px"
                  height="35px"
                  style={webStyle.searchIcon}
                  data-test-id="searchBtnTestId"
                />
              </Button>
        </Box>
    )
  }

  AddButton = () => {
    return (
      <Button variant="outlined" data-test-id="addEmailListBtn" onClick={this.handleClickOpen.bind(this)}>
        <AddCircleIcon />
      </Button>
    )
  }

  DialogBoxData = () => {
    return (
      <Dialog open={this.state.open} onClose={this.handleClose} data-test-id="dialog">
        <DialogTitle data-test-id="dialog-title">{configJSON.addListText}</DialogTitle>
          <DialogContent>
            <TextField
              data-test-id="inputTitlefield"
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              onChange={this.gettingTitleData.bind(this)}
              value={this.state.emailListTitle}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} data-test-id="closeBtn">{configJSON.cancelBtnText}</Button>
            <Button onClick={this.emailListAPICalls.bind(this)} data-test-id="saveButton">{configJSON.SaveBtnText}</Button>
          </DialogActions>
      </Dialog>
    )
  }

  SearchResults = () => {
    return (
      <div className="search-results" data-test-id="search-results">
        <p>{this.state.searchResultMessage}</p>
    </div>
    )
  }

  ActionButtonList = (pageNo: number) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button variant="contained" data-test-id="previousAction" onClick={this.handlePreviousPage} disabled={pageNo === 1}>
          {configJSON.PreviousBtnText}
        </Button>
        <span style={webStyle.pageNo} data-test-id="pageNO">{configJSON.pageText} {pageNo}</span>
        <Button variant="contained" data-test-id="nextBtnEmailList" onClick={this.handleNextPage.bind(this)} disabled={this.state.emailListData.length < 10}>
          {configJSON.NextBtnText}
        </Button>
      </div>
    )
  }
  // Customizable Area End

  render() {
    const { pageNo} = this.state;
    return (
      <>
        <Box style={webStyle.header}>
          {this.LogOutButton()}
        </Box>
        <Box style={webStyle.container}>
          <div style={{display: 'flex'}}>
            {this.SearchFields()}
            {this.AddButton()}
          </div>
          <Box style={webStyle.addEmail}>
              {this.DialogBoxData()}
              {this.SearchResults()}
          </Box>
          <div data-test-id="mainBox">
                {this.state.emailListData.map((element: EmailListItem, index: number) => (
                <li key={element.attributes.id} style={webStyle.campaine} data-list-id="listItem">
                  <Box style={{ display: 'flex', width: '30%'}}>
                    <p onClick={this.handleTitleClick.bind(this, element.attributes)} style={webStyle.title} data-test-id="emailListTitleTestId">
                      {element.attributes.title}
                    </p>
                  </Box>
                  <Button color={"primary"} data-test-id="editBtnEmailList" onClick={this.toggleEditMode.bind(this, index)}>{configJSON.EditBtnText}</Button>
                  <Button style={webStyle.errorText} data-test-id="deleteBtnEmailList" onClick={this.deleteEmailListApiCall.bind(this, element.attributes.id)}>{configJSON.DeleteBtnText}</Button>
                  <Button style={{height: '34px'}} variant="contained" data-test-id="addEmailBtnEmailList" onClick={this.toggleDialog.bind(this, element.attributes.id)}>Add Email</Button>
                </li>
            ))}
          </div>
          <Dialog open={this.state.showDialog}>
            <div className="Dialog-Content" style={{padding: '20px', display: 'flex', flexDirection: 'column'}}>
              <TextField
                label="Email"
                name="emailText"
                value={this.state.emailText}
                data-test-id="emailTxt"
                onChange={this.handleInputChange.bind(this)} />
            <TextField
              label="Full Name"
              name="nameText"
              value={this.state.nameText}
              data-test-id="nameText"
              onChange={this.handleInputChange.bind(this)} />
            </div>
            <div style={{ margin: '20px', display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" data-test-id='submitBtnEmailList' onClick={this.submitEmailData.bind(this)} style={{ marginRight: '10px' }}>{configJSON.SubmitBtnText}
              </Button> 
              <Button variant="contained" data-test-id='cancelBtnEmailList' onClick={this.toggleDialog.bind(this, 0)}>
                {configJSON.cancelBtnText} 
              </Button>
            </div>
          </Dialog>
          {this.ActionButtonList(pageNo)}
        </Box>
      </>
    )
  }
}

// Customizable Area Start
const webStyle = {
  noBorder: {
    border: 'none'
  },
  listBox: {
    marginRight: '5px'
  },
  title: {
    padding: '3px',
    cursor: 'pointer',
    borderRadius: '50px',
  },
  SaveBtnBox: {
    width: '280px'
  },
  errorText: {
    color: 'red' 
  },
  submitBtn: {
    marginRight: '10px'
  },
  searchIcon: {
    borderRadius: '60px'
  },
  pageNo: {
    margin: '0 15px'
  },
  searchInput: {
    width: '100%', 
    border: 'none',
    borderRadius: '60px'
  },
  logoutBtn: {
    width: '70px', 
    height: '30px', 
    marginTop: '15px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'rgb(235,241,245)',
  },
  logoutBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center'
  },
  jumpToAddEmail: {
    width: '90%',
    display: 'flex',
    paddingTop: '5px',
    justifyContent: 'flex-end',
  },
  container: {
    width: '50%',
    height: 'auto',
    padding: '20px',
    margin: '0 auto',
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(to right, rgb(235,241,245), rgb(235,241,245))',
    marginTop: '100px',
  },
  Container: {
    display: 'flex',
    border: '1px solid black',
    borderColor: 'gray',
    borderRadius: '60px',
    margin: '0 auto',
    width: '90%',
    height: '40px',
    justifyContent: 'space-between',
    marginBottom: '20px',
    alignItem: 'center',
    paddingTop: '2px',
    backgroundColor: "white",
  },
  campaine: {
    borderBottom: '1px solid black',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    borderColor: 'gray',
    margin: '0 auto',
  },
  addEmail: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '80%',
    marginTop: '20px',
    height: "70px",
  },
  addEmailID: {
    paddingTop: '20px',
    PaddingBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '90%',
    margin: '0 auto',
  },
  addEmailIDd: {
    backgroundColor: 'white',
  },
  searchContainer: {
    display: 'flex',
    border: '1px solid black',
    borderColor: 'gray',
    borderRadius: '60px',
    width: '90%',
    height: '40px',
    justifyContent: 'space-between',
    marginBottom: '20px',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  containerr: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
// Customizable Area End
