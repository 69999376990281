export const imgPasswordVisible = require("../assets/visible_password.svg");
export const imgPasswordInVisible = require("../assets/invisible_password.svg");
export const logo = require("../assets/logo.png");
export const forgotPasswordBg = require("../assets/forgot-password-bg.svg");
export const forgotPasswordLeftLogo = require("../assets/forgot-password-left-logo.svg");
export const forgotPasswordLogo = require("../assets/image_forgot_password.svg")
export const otpLogo = require("../assets/image_otp.svg");
export const confirmPasswordLogo = require("../assets/confirm_password.svg");
export const rightImg = require("../assets/right.svg");
export const wrongImg = require("../assets/wrong.svg");
export const completedImg = require("../assets/completed.svg");
