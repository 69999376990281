import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { IExam } from './types/types';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End

import PtTestScoresForStudentScreenController, {
  Props,
  configJSON,
} from './PtTestScoresForStudentScreenController';

export default class PtTestScoresForStudentScreen extends PtTestScoresForStudentScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTestsForStudents = () => {
    const { testScores } = this.state;
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{configJSON.testNameText}</TableCell>
              <TableCell>{configJSON.creditsText}</TableCell>
              <TableCell>{configJSON.passPercentageText}</TableCell>
              <TableCell>{configJSON.studentScoreText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testScores &&
              testScores.map((exam: IExam, index: number) => (
              <TableRow key={`course-${index}`}>
                <TableCell>{exam.attributes.name}</TableCell>
                <TableCell>{exam.attributes.credits}</TableCell>
                <TableCell>{exam.attributes.pass_percentage}</TableCell>
                <TableCell>{exam.attributes.score_percentage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={'md'}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">
              {configJSON.studentIdText}
              {this.state.accountId}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <Box>
              {configJSON.courseIdText}
              {this.state.courseId}
              {this.renderTestsForStudents()}
            </Box>

            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
};
// Customizable Area End
