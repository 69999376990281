import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { sendOtpRequest } from "../../forgot-password/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  isFromForgotPassword: boolean;
  otpInfo: string[];
  hasInput: string;
  resendTimer: boolean;
  timer: number;
  error: string;
  loader: boolean;
  timerId: any;
  timerId2: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  verificationApiCallId: string = '';
  email: string = '';
  resendCodeApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      isFromForgotPassword: false,
      otpInfo: Array(6).fill(""),
      hasInput: "",
      timer: 60,
      resendTimer: true,
      error: "",
      loader: false,
      timerId: 0,
      timerId2: 0,
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;

    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.verificationApiCallId != null &&
      this.verificationApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ loader: false })
      const successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const isError = successResponse.message !== 'otp verified successfully';
      if (isError) {
        this.setState({ error: successResponse.message, otpInfo: Array(6).fill("") });
        return;
      } else {
        this.setState({ timer: 0 })
        this.timerFunction()
        this.goToNewPasswordScreen();
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.resendCodeApiCallId != null &&
      this.resendCodeApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ loader: false })
      const successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!successResponse) {
        this.setState({ error: 'An unexpected error occured', resendTimer: false });
        return;
      }
      if (successResponse.errors) {
        this.setState({ resendTimer: false });
      }
      if (successResponse.otp_id) {
        clearInterval(this.state.timerId)
        clearInterval(this.state.timerId2)
        this.handleResetTimer();
        return;
      }
      this.setState({ error: successResponse.message })
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );
      this.email = emailValue;

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;
      this.checkEmailOrphoneText(emailValue);
      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    clearInterval(this.state.timerId)
    clearInterval(this.state.timerId2)
    this.timerFunction();
  }

  async submitOtp() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: data,
      };


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  submitVerificationCode = () => {
    if (this.state.otpInfo.length < 4) {
      this.setState({ error: "Otp should have 6 characters" });
      return;
    }
    this.setState({ loader: true })
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType
    };
    const body = {
      type: 'email_accaunt',
      data: {
        email: this.email,
        otp: this.state.otpInfo.join(""),
      }
    }
    this.verificationApiCallId = message.messageId;

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiVerifyOtpMethod);
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/varify_email_otp'
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(message.id, message);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  handleEnterOTP = (event: any, index: number) => {
    const value = event.target?.value;
    if (event.target?.value.length === 1 && /^\d*$/.test(value)) {
      const form = event.target?.form;
      if (form.elements.length > index + 1) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
      let tempOtp = [...this.state.otpInfo];
      tempOtp[index] = event.target.value;
      this.setState({
        otpInfo: tempOtp,
        error: ""
      });
    } else {
      const form = event.target?.form;
      event.preventDefault();
      let tempOtp = [...this.state.otpInfo];
      if (index !== 0) {
        form?.elements[index - 1].focus();
      }
      tempOtp[index] = "";
      this.setState({
        otpInfo: tempOtp
      });
    }
  };

  onPaste = (event: any) => {
    event.preventDefault()
    const pasted = event.clipboardData?.getData("text/plain")
    if (pasted) {
      let value = pasted.split("").slice(0, 6)
      this.setState({ otpInfo: value.concat(Array(6 - value.length).fill("")) })
      const form = event?.target?.form;
      if (value.length !== 0) {
        form?.elements[value.length]?.focus();
      }
    }
  }

  checkEmailOrphoneText = (text: any) => {
    if (isNaN(text)) {
      if (text.includes("@")) {
        const [username, domain] = text.split("@");
        const usernameLength = username.length;
        let firstTwoLetters
        let middleAsterisks
        let hashText
        if (usernameLength > 3) {
          firstTwoLetters = username.slice(0, 3);
          middleAsterisks = "*".repeat(usernameLength - 3);
          hashText = `${firstTwoLetters}${middleAsterisks}@${domain}`;
        } else {
          hashText = text;
        }
        this.setState({ hasInput: hashText });
      } else {
        this.setState({ hasInput: "" });
      }
    } else {
      const hashTextNum = text.slice(0, 3) + "*".repeat(text.length - 5) + text.slice(-2);
      this.setState({ hasInput: hashTextNum });
    }
  };

  timerFunction = () => {
    let n = this.state.timer;
    let timers: any = 0
    if (n === 0) {
      clearInterval(this.state.timerId)
      clearInterval(this.state.timerId2)
    } else {
      timers = setInterval(() => {
        countDown()
      }, 1000);
      this.setState({ timerId: timers })
      let countDown = () => {
        n--;
        this.setState({ timer: n })
      }
      if (this.state.resendTimer) {
        let timer2 = setTimeout(() => {
          this.setState({ resendTimer: false });
        }, 60000);
        this.setState({ timerId2: timer2 })
      }
    }
  };

  converNumber = (number: number) => {
    return number > 9 ? "" + number : "0" + number;
  };

  handleResetTimer = () => {
    this.setState({ resendTimer: true, timer: 60 }, () => this.timerFunction());
  };

  resendVerificationCode = () => {
    this.setState({ loader: true })
    clearInterval(this.state.timerId)
    clearInterval(this.state.timerId2)
    sendOtpRequest(this.email, (id) => this.resendCodeApiCallId = id)
    this.setState({ error: "", otpInfo: Array(6).fill("") })
  }

  goToNewPasswordScreen = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationNewPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.otpAuthToken
    );
    sessionStorage.setItem('reset-email', this.email);
    this.send(msg);
  };

  handleSubmitCodeOnEnterKey = (event: { key: string }, index: number) => {
    if (event.key === 'Enter' && (index === 5 || index === 0)) {
      this.submitVerificationCode();
    }
  }
  // Customizable Area End
}
