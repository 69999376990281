export const imgPasswordVisible = require("../assets/visible_password.svg");
export const imgPasswordInVisible = require("../assets/invisible_password.svg");
export const logo = require("../assets/logo.png");
export const loginBg = require("../assets/login-bg.svg");
export const loginLeftLogo = require("../assets/login-left-logo.svg");
export const mircosoftLogo = require("../assets/ms-ic.svg");
export const calendarLogo = require("../assets/calendar.png");
export const DeletePopUp = require("../assets/image_forgot_password.png");
export const OTPAuth = require("../assets/image_OTP sent.png");
export const SadFace = require("../assets/image_sad.png");
export const MaleGenderLogo = require("../assets/image_Male.svg");
export const MaleGenderActiveLogo = require("../assets/image_Male_Active.svg");
export const FemaleGenderLogo = require("../assets/image_female.svg");
export const FemaleGenderLogoActive = require("../assets/image_female_active.svg");
export const NonBinayLogo = require("../assets/image_nonbinary.svg");
export const NonBinayLogoActive = require("../assets/image_nonbinary_active.svg");
export const OtherLogo = require("../assets/image_others.svg");
export const NOtherLogoActive = require("../assets/image_others_active.svg");

