import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from 'react';
import { SearchApiResponse, SearchResultsItemWeb } from "./types";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchText: string;
  searchResults: Array<{ tag_id: number, tag: string, no_of_images: number }>
  token: string;
  page: number;
  dataLength: number;
  rowsPerPage: number;
  loading: boolean;
  noData: boolean;
  errorText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchController extends BlockComponent<
  Props, S, SS> {
  // Customizable Area Start 
  searchTagApiCallId: string = '';
  // Customizable Area End 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start 
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ]
    // Customizable Area End

    this.state = {
      // Customizable Area Start 
      token: '',
      searchText: '',
      searchResults: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      loading: false,
      noData: false,
      errorText: configJSON.recordNotFound,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    // Customizable Area Start 

    // Customizable Area End 
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (message.id === getName(MessageEnum.RestAPIResponceMessage) && getName(MessageEnum.RestAPIResponceMessage) === message.id && responseJson) {
      this.setState({ loading: false, errorText: configJSON.recordNotFound })
      if (apiRequestCallId === this.searchTagApiCallId) {
        this.searchTagSuccessCallback(responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let token = await getStorageData("authToken");
    this.setState({ token: token })
    this.loadStoredData();
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      prevState.searchText !== this.state.searchText ||
      prevState.searchResults !== this.state.searchResults
    ) {
      this.clearAndStoreData();
    }
  }

  async clearAndStoreData() {
    await removeStorageData('searchText');
    await removeStorageData('searchResults');

    await setStorageData('searchText', this.state.searchText);
    await setStorageData('searchResults', JSON.stringify(this.state.searchResults));
  }

  async loadStoredData() {
    const storedSearchText = await getStorageData('searchText');
    const storedSearchResults = await getStorageData('searchResults');

    this.setState({
      searchText: storedSearchText || '',
      searchResults: storedSearchResults ? JSON.parse(storedSearchResults) : [],
    });
  }

  searchTagSuccessCallback = (response: SearchApiResponse) => {
    if (response && (response.success?.length === 0 || response.error)) {
      this.setState({ noData: true, searchResults: [] })
    } else {
      this.setState({ searchResults: response.success, noData: false })
    }
  }

  getSearchResult = async () => {
    if (this.state.searchText.length < 2) {
      this.setState({ noData: true, errorText: 'Tag must be of minimum 2 characters', searchResults: [] })
    }
    else {
      this.setState({ loading: true })
      this.searchTagApiCallId = await this.apiCallForSearch({
        contentType: configJSON.apiContentType,
        method: configJSON.getApiMethodType,
        endPoint: `${configJSON.searchTagEndpoint}?page=${this.state.page}&title=${this.state.searchText}`,
      });
    }
  }

  // Api Call Function
   apiCallForSearch = async (data: { contentType: string, method: string, endPoint: string, body?: object, type?: string }) => {
    const { contentType, method, endPoint, body, type } = data
    const header = {
      "Content-Type": contentType,
      token: this.state.token,
    };

    const reqMsgSearchTags = new Message(getName(MessageEnum.RestAPIRequestMessage))

    reqMsgSearchTags.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header)

    reqMsgSearchTags.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint)

    reqMsgSearchTags.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method)

    body && type != 'formData' ?
      reqMsgSearchTags.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      :
      reqMsgSearchTags.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body)

    runEngine.sendMessage(reqMsgSearchTags.id, reqMsgSearchTags)

    return reqMsgSearchTags.messageId
  }

  onPressBackBtn = () => {
    this.props.navigation.goBack()
  }

  handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value })
  }

  onPageChangeHandler = (event: ChangeEvent<unknown>, newPage: number) => {
    this.setState({ page: newPage - 1 }, () => {
      this.getSearchResult();
    });
  };

  onPressTags = (item: SearchResultsItemWeb) => {
    const message: Message = new Message(
      getName(MessageEnum.AssociatedImages)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.Props), item); // this line is only needed when props has to be sent
    this.send(message);
  }
  // Customizable Area End
}