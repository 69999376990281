import React from "react";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import AccountController, { Props, configJSON } from "./AccoutsController";
import { NavLink } from "react-router-dom";
import { webStyles } from "../../../components/src/styles";

export default class Account extends AccountController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Box className="my-profile">
        <Grid
          container
          style={{ background: "rgb(237, 238, 238)", height: "100vh" }}
        >
          <Grid item lg={12} sm={12}>
            <Box style={{ padding: '30px' }}>             
              <Box style={webStyles.breadcrumbWrapper}>
                <Typography style={webStyles.breadcrumbItem}>{configJSON.AccountText}</Typography>
              </Box>
              <Typography style={webStyle.accoundHeading} data-test-id="header">
                {configJSON.AccountText}
              </Typography>
              <NavLink aria-current="page" to={`/settings/account`} exact={true}>
                <Button style={{ ...webStyle.accountButtons, textTransform: "none" }} data-test-id="privacyPolicyBtn" onClick={this.goToPrivacySettings}>
                  {configJSON.PrivcyPolicyText}
                </Button>
              </NavLink>
              <NavLink aria-current="page" to={`/delete/account`} exact={true}>
                <Button style={{ ...webStyle.accountButtons, textTransform: "none" }} data-test-id="deleteAccountBtn" onClick={this.setNextToDeleteAcc}>
                  {configJSON.DeleteAccountText}
                </Button>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// Customizable Area Start
const webStyle = {
  accountText: {
    fontFamily: 'ClotherRegular'
  },
  accoundHeading: {
    fontFamily: "ClotherBold",
    fontSize: "24px",
    lineHeight: "24px",
    paddingBottom: "25px",
    marginTop: "17px",
  },
  accountButtons: {
    width: "100%",
    height: "52px",
    backgroundColor: "#FFFFFF",
    marginBottom: "18px",
    borderRadius: "8px",
    justifyContent: "left",
    textTransform: "none",
    padding: "18px 20px 18px 20px",
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    lineHeight: "20px"
  }
};
// Customizable Area End
