export const userProfileImg = require("../assets/user-profile.png");
export const location = require("../assets/user-profile.png");
export const Locations = require("../assets/image_location.png");
export const Email = require("../assets/Group.png");
export const Contact = require("../assets/image_call.png");
export const time = require("../assets/image_Time.png");
export const like = require("../assets/Vector.png");
export const Unlike = require("../assets/image_Like.png");
export const comment = require("../assets/image_comment.png");
export const share = require("../assets/image_Share.png");
export const HealthImg = require("../assets/image_Health & Safety Mission 1.png");
export const User = require("../assets/user.png");




