import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface CheckpointDetailResponseData {
    error: string;
    data: CheckpointDetailData;
}

interface CheckpointDetailData {
    id?: string;
    attributes?: {
        id?: string;
        name?: string;
        inspection_frequency?: string;
        recommended_check?: string;
        action_required?: string;
        standard_reference?: null;
        audit_sub_category_id?: string;
        referencestandard_id?: string;
        reference_image?: string;
        sub_category?: string;
        reference_standard?: string;
    }
}

interface SelectedMachineResponseData {
    error: string;
    data: {
        id: string;
        attributes: SelectedMachineData;
    }[];
}

interface SelectedMachineData {
    id: string;
    equipment_name?: string;
    equipment_number?: number;
    due_date?: string;
    account_id?: number;
    location_id?: number;
    audit_category_id?: number;
    category?: string;
    location?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    errorMsg: string;
    machineId: string;
    loading: boolean;
    selectedMachineData: SelectedMachineData;
    checkpointDetailData: CheckpointDetailData;
    checkpointDetailId: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CheckpointDetailController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getShowCheckpointApiCallId: string = "";
    getShowWorkerMachineApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            errorMsg: "",
            loading: false,
            checkpointDetailId: "",
            checkpointDetailData: {},
            machineId: "",
            selectedMachineData: {id: ""}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getShowCheckpointApiCallId) {
                this.handleShowCheckpointApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getShowWorkerMachineApiCallId) {
                this.handleShowMachinesApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        let checkpointDetailId = await getStorageData("checkpointdetailid", false);
        let machineId = await getStorageData("machineid", false);
        this.setState({ token, checkpointDetailId, machineId }, () => {
            this.getShowWorkerMachineApiCall();
            this.getShowCheckpointApiCall();
        });
    }

    handleShowCheckpointApiResponse = (responseJson: CheckpointDetailResponseData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ checkpointDetailData: responseJson.data, loading: false })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loading: false
            });
        }
    }

    handleShowMachinesApiResponse = (responseJson: SelectedMachineResponseData) => {
        if (responseJson && !responseJson.error) {
            const responseData = responseJson.data.filter((value: SelectedMachineData) => {
                return (parseInt(value.id) === parseInt(this.state.machineId))
            })[0]?.attributes;

            this.setState({ selectedMachineData: responseData })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }
    navigateToAuditScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToWorkerCheckpoint = () => {
        const messasge = new Message(getName(MessageEnum.NavigationMessage));
        messasge.addData(getName(MessageEnum.NavigationTargetMessage), "WorkerCheckpoint");
        messasge.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(messasge);
    }

    navigateTomachinecheckpointScreen = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    getShowWorkerMachineApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getWorkerMachineRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getShowWorkerMachineApiCallId = getWorkerMachineRequestMsg.messageId;
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMachinesApiEndPoint
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkerMachineRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getWorkerMachineRequestMsg.id, getWorkerMachineRequestMsg);

    }

    getShowCheckpointApiCall = () => {
        this.setState({ loading: true })
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getAllRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getShowCheckpointApiCallId = getAllRequestMsg.messageId;
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getShowCheckpointApiEndPoint}?id=${this.state.checkpointDetailId}`
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
    }

    // Customizable Area End
}
