// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import React from 'react';
import DashboardController, { Props } from './DashboardController.web';
import { checkImg } from './assets';
// Customizable Area End
export default class ReportPostModal extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Typography style={webStylesLocal.subHeader}>
          Why are you reporting this post?
        </Typography>
        <Grid container>
          {this.state.reportTags.map((tagData: any, index: number) => {
            return (
              <Button
                data-test-id={`tagBtn2${index}`} variant="outlined"
                style={webStylesLocal.TagBtn as React.CSSProperties}
              >
                <Typography style={webStylesLocal.addIcon}>
                  <img src={checkImg} /></Typography>
                <Typography style={
                  webStylesLocal.tagName as React.CSSProperties}>{tagData.attributes.name}</Typography>
              </Button>
            )
          })}
        </Grid>
        <Box>
          <Typography style={webStylesLocal.label}>Couldn’t find reason? Describe your reason here</Typography>
          <textarea
            style={webStylesLocal.textareaStyle}
            placeholder="Description"
            value={this.state.reportdescription}
            onChange={(e: any) => this.handledescriptionChange(e)}
            data-test-id="handleTextArea"
            className="textarea"
          />
          {
            <Typography style={webStylesLocal.errorText}>Please enter description or at least one tag.</Typography>
          }
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} >
          <Button data-test-id="reportbtn2" style={webStylesLocal.reportBtn as React.CSSProperties} onClick={() => this.reportPost()}>
            Report
          </Button>
        </Box>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export const webStylesLocal = {
  subHeader2: {
    fontFamily: 'ClotherRegular',
    fontSize: '18px',
    lineHeight: '20px',
    color: '#828282',
    marginBottom: '34px',
  },
  okayBtn: {
    width: '100%',
    height: '54px',
    borderRadius: '8px',
    border: '1px solid #8735E1',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    color: '#8735E1',
    textTransform: 'none',
    marginBottom: '20px',
  },
  dialogContainer: {
    padding: "30px",
    borderRadius: "8px",
  },
  noteFont: {
    fontFamily: 'ClotherBold',
    fontSize: '24px',
    lineHeight: '24px',
    marginBottom: "30px",
  },
  subHeader: {
    fontFamily: 'ClotherBold',
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: '20px',
  },
  TagBtn: {
    height: '39px',
    padding: '14px 12px 14px 12px',
    borderRadius: '8px',
    border: '1px solid #DCDCDC',
    marginRight: '13px',
    marginBottom: '10px',
    textTransform: "none",
  },
  SelectedTagBtn: {
    height: '39px',
    padding: '14px 12px 14px 12px',
    borderRadius: '8px',
    border: '1px solid #8735E1',
    marginRight: '13px',
    marginBottom: '10px',
    textTransform: "none",
    background: '#8735E1',
  },
  tagName: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#A1A1A1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
  },
  tagIcon: {
    height: '10px',
    width: '10px'
  },
  selectedTagName: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#FFFFFF',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
  },
  addIcon: {
    width: '10px',
    height: '10px',
    marginRight: '8px',
    color: '#A1A1A1',
    // marginTop: '4px',
  },
  label: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#2B2A29',
    margin: '10px 0px'
  },
  reportBtn: {
    width: '160px',
    height: '50px',
    borderRadius: '8px',
    background: '#F30202',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    color: '#FFFFFF',
    marginTop: '30px',
    textTransform: 'none'
  },
  textareaStyle: {
    padding: '15px',
    borderRadius: '8px',
    width: " 100%",
    minHeight: "178px",
    border: "1px solid #DCDCDC",
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '18px',
  },
  closeIcon: {
    cursor: "pointer",
    fontSize: "20px",
    color: "#787878",
  },
  errorText: {
    color: 'rgb(243, 2, 2)',
    marginBottom: '4px',
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
  },
  ReportedFont: {
    fontFamily: 'ClotherBold',
    fontSize: '24px',
    lineHeight: '24px',
    marginBottom: "15px",
    marginTop: '25px'
  },
};
// Customizable Area End
