import React from "react";

import {
    // Customizable Area Start
    Box,
    Button,
    Typography,
    Modal
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { amazonIcon, warningIcon, closeIcon, modalBackground, giftIcon } from "./assets";
import i18n from "i18next"

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import VoucherDescriptionController, { Props } from "./VoucherDescriptionController";
import { configJSON } from "./Karmapoints2Controller";
// Customizable Area End

export default class VoucherDescription extends VoucherDescriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.wrapper}>
                    <Box style={webStyle.myProfileWrapper}>
                        <Typography style={webStyle.myProfileText}>{configJSON.ProfileText}</Typography>
                        <NavigateNext style={webStyle.navigateIcon} />
                        <Typography style={webStyle.myRewardsText}>   {i18n.t(' Rewards')}  </Typography> </Box>
                    <Typography style={webStyle.voucherHeading}>{configJSON.AmazonVoucherText}</Typography>
                    <Box sx={webStyle.mainAutoBox}>
                        <Box>
                            <Box sx={webStyle.voucherHeader}>
                                <img src={amazonIcon} style={webStyle.myContributionBoxImage} alt="amazon-icon" />
                                <Box style={webStyle.voucherWrapper}>
                                    <Typography style={webStyle.contriText}>Voucher valued at ₹200 or 5% off on Amazon</Typography>
                                    <Button style={webStyle.pointsBtn}>500 points</Button>
                                </Box>
                                <Typography style={webStyle.voucherDate}>Expires on 30 Nov 2023</Typography>
                            </Box>
                            <Box style={webStyle.voucherBody}>

                                <Typography style={webStyle.termsTitle}>Terms & Conditions</Typography>
                                <ul style={webStyle.termsDetailsWrapper}>
                                    <li style={webStyle.termsDetails}>Gift vouchers cannot be redeemed for cash, returned for a refund, have their balances consolidated to a new voucher or be replaced after expiry and are not legal tender, account cards, credit or debit cards or securities.
                                    </li>
                                    <li style={webStyle.termsDetails}>If your purchase exceeds the value of the gift voucher, balance must be paid by cash, debit or credit card.
                                    </li>
                                    <li style={webStyle.termsDetails}>U1st reserves the right to cancel a gift voucher if we deem such action necessary. The gift voucher remains our property.
                                    </li>
                                    <li style={webStyle.termsDetails}>U1st can vary or replace these conditions from time to time </li>
                                    <li style={webStyle.termsDetails}>U1st reserves the right to cancel a gift voucher if we deem such action necessary. The gift voucher remains our property.
                                    </li>
                                    <li style={webStyle.termsDetails}>U1st can vary or replace these conditions from time to time </li>
                                    <li style={webStyle.termsDetails}>U1st reserves the right to cancel a gift voucher if we deem such action necessary. The gift voucher remains our property.
                                    </li>
                                </ul>
                            </Box>
                            <Box style={webStyle.termsFooter}>
                                <Button style={webStyle.AZButton}>
                                    <span style={webStyle.AZButtonBefore} />
                                    AZJOHN200
                                    <span style={webStyle.AZButtonAfter} />

                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={webStyle.redeemBtnWrapper}>
                        <Button style={webStyle.copyCodeBtn as React.CSSProperties} variant="outlined">
                            Copy Code
                        </Button>
                        <Button style={webStyle.redeemBtn} onClick={this.openConfirmationModal}>
                            Redeem Points
                        </Button>
                    </Box>

                    <Modal open={this.state.openModal} onClose={this.closeConfirmationModal}>
                        <Box style={webStyle.confirmationModal}>
                            <img src={closeIcon} alt="close" onClick={this.closeConfirmationModal} style={webStyle.closeBtn} />
                            <img src={warningIcon} alt="warning" style={webStyle.warningImg} />
                            <h1 style={webStyle.ConfirmationModalTitle}>Redeem Points Confirmation</h1>
                            <p style={webStyle.ConfirmationModalDetail}>Are you sure want to Redeem 500 points for Amazon Voucher valued at ₹ 200? Once redeemed you won’t get your points back</p>
                            <Button style={webStyle.redeemPointsBtn} onClick={this.openSuccessModal}>Yes, Redeem 500 Points</Button>
                            <Button style={webStyle.noRedeemPointsBtn}>No, Do not Redeem 500 Points</Button>
                        </Box>
                    </Modal>
                    <Modal open={this.state.openSuccessModal} onClose={this.closeSuccessModal}>
                        <Box sx={webStyle.giftModal}>
                            <img src={giftIcon} alt="warning" style={webStyle.warningImg} />
                            <h1 style={webStyle.giftModalTitle}>Amazon Voucher Available to Redeem</h1>
                            <p style={webStyle.giftModalDetail}>Congratulations John! <br></br>
                                You have successfully redeemed contribution points for Amazon Voucher. You can copy code or tap on Redeem Voucher to avail benefits.</p>
                            <Button style={webStyle.okayBtn} onClick={this.closeSuccessModal}>Okay</Button>
                        </Box>
                    </Modal>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    navigateIcon: {
        fill: '#DCDCDC'
    },
    copyCodeBtn: {
        color: "#8735E1",
        textAlign: "center" as "center",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #8735E1',
        textTransform: "capitalize" as "capitalize",
        fontFamily: "ClotherBold",
        width: '200px',
        marginRight: '20px'
    },
    pointsBtn: {
        color: "#2B2A29",
        textAlign: "center" as "center",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        padding: '10px',
        borderRadius: '8px',
        background: '#FFD541',
        textTransform: "capitalize" as "capitalize",
        fontFamily: "ClotherBold",
        width: '200px'
    },
    wrapper: {
        width: '100%',
        backgroundColor: '#F7F1F1',
        padding: '30px',
        boxSizing: 'border-box',
        height: 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    voucherHeading: {
        margin: '0px 0px 20px 0px',
        color: "#2B2A29",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "26px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        fontFamily: "ClotherBold"
    },
    mainAutoBox: {
        background: '#fff',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '8px',
        paddingBottom: '30px'
    },
    voucherHeader: {
        padding: '30px',
        display: 'block'
    },
    voucherBody: {
        padding: '30px',
        display: 'block'
    },
    mainWrapperAuto: {
        padding: '0 !important'
    },
    termsFooter: {
        padding: '30px',
        display: 'block',
        borderTop: '1px solid #F8F8F8',
    },
    termsTitle: {
        color: "#2B2A29",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: "ClotherBold"
    },
    termsDetails: {
        color: "#828282",
        textAlign: "justify" as "justify",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",
        marginBottom: '10px',
        fontFamily: "ClotherRegular",
        "&::marker": {
            width: '5px',
            height: '5px',
            display: 'block',
            content: '""',
            background: 'rgba(135, 53, 225, 0.10)'
        }
    },
    termsDetailsWrapper:
    {
        paddingLeft: '20px'
    },
    AZButton: {
        borderRadius: "8px",
        border: "1px dashed #AEF5FF",
        background: "#AEF5FF",
        padding: '15px 25px',
        position: 'relative' as 'relative',
        color: "#2B2A29",
        textAlign: "center" as "center",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "10px",
        fontFamily: "ClotherBold",
        height: '72px'

    },
    AZButtonAfter: {
        position: 'absolute' as 'absolute',
        left: '-12.5px',
        content: '0',
        width: '25px',
        height: '25px',
        background: '#fff',
        borderRadius: '100%'
    },
    AZButtonBefore: {
        position: 'absolute' as 'absolute',
        right: '-12.5px',
        content: '0',
        width: '25px',
        height: '25px',
        background: '#fff',
        borderRadius: '100%'

    },
    redeemBtnWrapper: {
        display: 'flex',
        justifyContent: 'end',
        margin: '30px 0'
    },
    redeemBtn: {
        color: "#FFF",
        textAlign: "center" as "center",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        padding: '10px',
        borderRadius: '8px',
        background: '#8735E1',
        textTransform: "capitalize" as "capitalize",
        fontFamily: "ClotherBold",
        width: '200px'

    },
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    myProfileWrapper: {
        display: 'flex',
        gap: '10px'
    },
    myProfileText: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        paddingBottom: '17px',
        color: '#18181E',
    },
    myRewardsText: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        paddingBottom: '17px',
        color: '#18181E',
    },
    myContributionBox: {
        borderRadius: "8px",
        background:
            "#AEF5FF",
        padding: '16px',
        position: 'relative',
        height: '150px',
        borderRedius: '8px'
    },
    myContributionBoxImage: {
        height: '30px'
    },
    myContributionBoxYellow: {
        borderRadius: "8px",
        background:
            "#FEFFB7",
        padding: '16px',
        position: 'relative',
        height: '150px',
        borderRedius: '8px'
    },

    myContributionBoxGreen: {
        borderRadius: "8px",
        background:
            "#C1FFE5",
        padding: '16px',
        position: 'relative',
        height: '150px',
        borderRedius: '8px'
    },
    myContributionBoxImg: {
        position: "absolute",
        right: "0",
        bottom: "0",
        height: "170px"
    },
    starImg: {
        position: "absolute", right: "20px", top: "20px"
    },
    contriText: {
        color: "#2B2A29",
        leadingTrim: "both",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "40px",
        marginTop: '12px',
        fontFamily: "ClotherBold"


    },
    contriDate: {
        color: "#2B2A29",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        marginBottom: '10px'

    },
    contiCount: {
        color: "#FFF",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "46px",
        textTransform: "capitalize",
        margin: '15px 0',
        display: 'block'
    },
    voucherWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: 'center'
    },
    voucherDate: {
        color: "#2B2A29",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        fontFamily: "ClotherRegular"

    },
    confirmationModal: {
        background: 'white',
        padding: '30px',
        position: 'relative' as 'relative',
        width: '500px',
        margin: '0 auto',
        top: '20%',
        borderRadius: '10px'
    },
    closeBtn: {
        position: 'absolute' as 'absolute',
        right: '30px'
    },
    ConfirmationModalTitle: {
        color: "#2B2A29",
        textAlign: "center" as "center",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        marginTop: '20px',
        fontFamily: "ClotherBold"


    },
    giftModalTitle: {
        color: "#2B2A29",
        textAlign: "center" as "center",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        marginBottom: '0px',
        fontFamily: "ClotherBold"

    },
    ConfirmationModalDetail: {
        color: "#18181E",
        textAlign: "center" as "center",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        marginBottom: '50px',
        fontFamily: "ClotherRegular"

    },
    giftModalDetail: {
        color: "#18181E",
        textAlign: "center" as "center",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        marginBottom: '50px',
        marginTop: '0',
        fontFamily: "ClotherRegular"

    },
    warningImg: {
        margin: '0 auto',
        marginTop: '30px',
        display: 'block'
    },
    redeemPointsBtn: {
        borderRadius: "8px",
        background: "#F30202",
        color: "#FFF",
        textAlign: "center" as "center",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        padding: "10px",
        width: '100%',
        textTransform: 'capitalize' as 'capitalize',
        fontFamily: "ClotherBold"

    },
    noRedeemPointsBtn: {
        borderRadius: "8px",
        border: "1px solid #8735E1",
        background: "#FFF",
        padding: "10px",
        color: "#8735E1",
        textAlign: "center" as "center",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        width: '100%',
        marginTop: '15px',
        textTransform: 'capitalize' as 'capitalize',
        fontFamily: "ClotherBold"

    },
    okayBtn: {
        borderRadius: "8px",
        border: "1px solid #8735E1",
        background: "#FFF",
        padding: "10px",
        color: "#8735E1",
        textAlign: "center" as "center",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        width: '100%',
        textTransform: 'capitalize' as 'capitalize',
        fontFamily: "ClotherBold"

    },
    giftModal: {
        background: `url(${modalBackground})`,
        padding: '30px',
        position: 'relative',
        width: '500px',
        margin: '0 auto',
        top: '20%',
        borderRadius: '10px',
        backgroundColor: '#fff',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    }
};

// Customizable Area End