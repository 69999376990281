import React from "react";
// Customizable Area Start
import { Typography, Box } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController";
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box sx={webStyle.container}>
        <Typography variant="h5">Welcome to ApolloHSEapp!</Typography>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    textAlign: "center",
    marginTop: "10px"
  }
};

// Customizable Area End
