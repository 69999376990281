// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Snackbar
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./css/BroadcastMessageWeb.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import BroadcastMessageController, {
  Props,
  configJSON,
} from "./BroadcastMessageController.web";

export default class BroadcastMessage extends BroadcastMessageController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Snackbar
              anchorOrigin={{vertical:"top",horizontal:"right"}}
              open={this.state.isTokenFound}
              onClose={this.handleClose}
              message={<Typography variant="body1">{this.state.notificationTitle}:{this.state.notificationBody}</Typography>}
              autoHideDuration={6000}
              key={'top'+'right'}
            />
          <div className="loginForm">
              <div className="formGroup">
                <label htmlFor="loginEmail">Email</label>
                <input
                  type="text"
                  id="loginEmail"
                  name="loginEmail"
                  data-test-id="email-id"
                  value={this.state.email}
                  onChange={(eventEmail) => {
                    this.setEmail(eventEmail.target.value);
                  }}
                />
              </div>
              <div className="formGroup">
                <label htmlFor="loginPassword">Password</label>
                <input
                  type="password"
                  id="loginPassword"
                  data-test-id="pass-field"
                  name="loginPassword"
                  value={this.state.pass}
                  onChange={(eventPass) => {
                    this.setPassword(eventPass.target.value);
                  }}
                />
              </div>
              <Button
                style={{
                  width: "50%",
                  borderRadius: "30px",
                  backgroundColor:"#006633",
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                data-test-id="register-button"
                variant="contained"
                color="primary"
                onClick={this.onRegister}
              >
                Register
              </Button>
            </div>
            </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

// Customizable Area End
