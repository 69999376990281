import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import StorageProvider from "../../framework/src/StorageProvider";

interface myProps {
  history: History;
}
const useStyles = makeStyles(() => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftAlign: {
    textAlign: "left",
  },
  rightAlign: {
    textAlign: "right",
  },
  button: {
    color: "#fff",
    backgroundColor: "6200EE",
    marginLeft: "10px",
    marginRight: "10px",
    "&:hover": {
      color: "#fff",
      backgroundColor: "6200EE",
    },
  },
}));

const AppSecondaryHeader = (props: myProps) => {
  const classes = useStyles();
  const [token, setToken] = useState<string>("");
  useEffect(() => {
    let checkToken = async () => {
      let tokenInfo = await StorageProvider.get("authToken");
      setToken(tokenInfo);
    };
    checkToken()
  }, []);

  return (
    <div className={classes.row}>
      {token && (
        <div className={classes.leftAlign}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => props.history.push("/conversationthreading2/1")}
          >
            Conversation Threading
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => props.history.push("/emalillist2")}
          >
            Email List
          </Button>
        </div>
      )}

      <div className={classes.rightAlign}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            if (token) {
              StorageProvider.remove("authToken");
            }
            props.history.push("/Login");
          }}
        >
          {token ? "Log Out" : "Log In"}
        </Button>
      </div>
    </div>
  );
};

export default withRouter(AppSecondaryHeader);
