// Customizable Area Start
import React from "react";

import {
  Avatar,
  Grid,
  Typography,
  Box,
  Button,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Breadcrumbs,
  Paper,
  styled,
  Dialog,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { NavigateNext } from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import {
  buttonFilter,
  buttonFilterActive,
  buttonSort,
  locationIcon,
  clockIcon,
  optionsIcon,
  closeIcon,
  profileIcon,
  trueIcon,
  plusIcon,
  selectedTrue,
  selectRemoveIcon,
  singleSelectIcon,
  MultiSelectIcon,
  closePostIcon,
} from "./assets";

import PublishPostController, { Props } from "./PublishPostController";
import { Filters, Sorting } from "./styles";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import { webStyles } from "../../../components/src/styles";
// Customizable Area End

export default class PublishPost extends PublishPostController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  checkBorderColor = (itemRiskLevel: string) => {
    let colorChoose = "#DCDCDC";
    this.state.RisklevelFilter.forEach((item: any) => {
      if (item.name === itemRiskLevel) {
        colorChoose = item.color;
      }
    });
    return colorChoose;
  };

  getBorderStyle = (postData: any) => {
    let postDatas = this.setSelectedData(postData)
    if (this.state.selectedPost.includes(postData) || postDatas.isSelected) {
      return "1px solid #8623f2";
    } else {
      return `1px solid ${this.checkBorderColor(
        postData.attributes.risk_level_name
      )}`;
    }
  };

  renderSelectedTrue = (postData: any) => {
    let postDatas = this.setSelectedData(postData)
    return (
      (this.state.selectedPost.includes(postData) || postDatas.isSelected) && (
        <>
          <img
            src={selectedTrue}
            style={webStyle.selectedPostImg as React.CSSProperties}
          />
        </>
      )
    );
  }

  renderImage = (postData: any) =>
    postData &&
    postData.attributes &&
    (postData.attributes.image_url || postData.attributes.image_url_when_published) && (
      <a href={postData.attributes.image_url || postData.attributes.image_url_when_published} target="blank">
        <img
          data-test-id="profile-img"
          src={postData.attributes.image_url ? postData.attributes.image_url : postData.attributes.image_url_when_published}
          alt="Your Image"
          style={webStyle.postImg as React.CSSProperties}
        />
      </a>
    );

  renderPostCardContent = (postData: any, index: number) => (
    <>
      {this.renderSelectedTrue(postData)}
      <Box className="card_title_wrapper inner-options">
        <Box data-test-id={`user-profile${index}`} className="post-profile-left" onClick={() => this.goToUserProfile(postData.attributes.account_id)}>
          <Avatar
            className="Post-profile-img"
            data-test-id="profile-icon"
            src={postData.attributes?.profile_pic || profileIcon}
            alt="Custom Icon"
          />
          <Typography className="card-post-name">
            {postData.attributes.user_name || "No Name"} created this post
          </Typography>
        </Box>
        <img src={optionsIcon} />
      </Box>
      {this.renderImage(postData)}
      <Typography className="card-post-detail">
        {postData.attributes.description || "No Description"}
      </Typography>
      <Button className="Floor_btn">
        <img src={locationIcon} alt="location" />
        <span>{postData.attributes.work_location ? postData.attributes.work_location : postData.attributes.location || "No Location"}</span>
      </Button>
      <Box className="Post-time-detail">
        <Box className="Post-time-inner">
          <Avatar
            className="Post-inner-profile-img"
            data-test-id="profile-icon"
            src={postData.attributes.profile_pic || profileIcon}
            alt="Custom Icon"
          />
          <Typography className="Post-time-content">
            {postData.attributes.user_name || "No Name"}
          </Typography>
          <img src={clockIcon} alt="time" className="time-icon" />
          <Typography className="Post-time-content">
            {getTimeFromNow(postData.attributes.created_at)} ago
          </Typography>
        </Box>
      </Box>
    </>
  );

  renderPostCard = (postData: any, onClick: any, index: any) => (
    <Paper
      data-test-id="post-card"
      className={`card`}
      onClick={onClick}
      key={index}
      style={{
        border: this.getBorderStyle(postData),
      }}
    >
      {this.renderPostCardContent(postData, index)}
    </Paper>
  );

  renderPosts = (isLoader: boolean, publishPostData: any[]) => {
    if (isLoader) {
      return (
        <Grid container justifyContent="center">
          <CircularProgress style={webStyle.loader} />
        </Grid>
      );
    } else {
      if (publishPostData.length > 0) {
        return publishPostData.map((postData: any, index: number) =>
          this.renderPostCard(
            postData,
            this.state.activeButton === "Un Actioned"
              ? () => this.onClickPostSelect(postData)
              : undefined,
            index
          )
        );
      } else {
        if (this.state.published) {
          return (
            <Grid container justifyContent="center"
              style={webStyle.noData}
            >
              No Published Post
            </Grid>
          );
        } else {
          return (
            <Grid container justifyContent="center"
              style={webStyle.noData}
            >
              No Un Actioned Post
            </Grid>
          );
        }
      }
    }
  };

  renderSortedModal = () => {
    const { sortBy, openSortModal } = this.state;
    return (
      <Modal
        open={openSortModal}
        onClose={this.closeSortModal}
        data-test-id="closeSortModal"
      >
        <Sorting>
          <Box className="sorting-modal-header">
            <Typography>Sort By</Typography>
            <img
              data-test-id="close-sort"
              src={closeIcon}
              alt="close"
              style={webStyle.pointer}
              onClick={this.closeSortModal}
            />
          </Box>
          <Box className="sorting-modal-body">
            <RadioGroup
              data-test-id="sort-options"
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              value={sortBy}
              name="radio-buttons-group"
              onChange={this.handleSortingChange}
            >
              <FormControlLabel
                className={`radio-label ${sortBy === "" ? "selected-label" : ""
                  }`}
                value=""
                control={<Radio />}
                label="Default"
              />
              <FormControlLabel
                className={`radio-label ${sortBy === "newest" ? "selected-label" : ""
                  }`}
                value="newest"
                control={<Radio />}
                label="Newest First"
              />
              <FormControlLabel
                className={`radio-label ${sortBy === "oldest" ? "selected-label" : ""
                  }`}
                value="oldest"
                control={<Radio />}
                label="Oldest First"
              />
            </RadioGroup>
          </Box>
        </Sorting>
      </Modal>
    );
  };

  getSelectHazardBackgroundColor(hazrdSelect: number[], index: number): string {
    return hazrdSelect.includes(index) ? "#8735E1" : "";
  }

  getSelectHazardFontColor(hazrdSelect: number[], index: number): string {
    return hazrdSelect.includes(index) ? "#fff" : "#8b8787";
  }

  getIcon(hazrdSelect: number[], index: number): string {
    return hazrdSelect.includes(index) ? trueIcon : plusIcon;
  }

  renderSelectedPostModal = () => {
    return (
      <Dialog
        open={this.state.openSelectedPostModal}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={webStyle.dialogs}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography
              variant="body1"
              style={webStyle.addDetails}
            >
              Add Details
            </Typography>
            <div>
              <img
                src={closeIcon}
                data-test-id="closeModal"
                onClick={this.closeSelectedPostModal}
                style={webStyle.pointer}
              />
            </div>
          </Grid>
          <div style={webStyle.selectedPostItemsList}>
            {this.renderSelectedPostItems()}
          </div>
          <Grid container justifyContent="flex-end">
            <Button
              data-test-id="alertBoxOkButton"
              variant="contained"
              style={webStyle.cancelButton as React.CSSProperties}
              onClick={this.closeSelectedPostModal}
            >
              cancel
            </Button>
            <Button
              data-test-id="nextButton"
              variant="contained"
              style={webStyle.nextButton as React.CSSProperties}
              onClick={this.PostStepPage}
            >
              Next
            </Button>
          </Grid>
        </div>
      </Dialog>
    );
  };

  renderSelectedPostItems = () => {
    return (
      this.state.selectedPost.map((data) => {
        console.log(data, 'data')
        return (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={webStyle.selectedPost}
            container
            alignItems="center"
            key={data}
          >
            <img
              src={closePostIcon}
              data-test-id="removeQueuePost"
              onClick={() => this.removeQueuePost(data.attributes?.id)}
              style={webStyle.removeQueue as React.CSSProperties}
            />
            <Avatar
              src={data.attributes?.profile_pic}
              style={webStyle.profilePic}
            />
            <Typography
              style={webStyle.discription}
            >
              {data.attributes?.description
                ? `${data.attributes.user_name.slice(
                  0,
                  30
                )}${this.descriptionSlice(data.attributes.user_name)} created this post`
                : "No Name"}
            </Typography>
          </Grid>
        )
      })
    )
  };

  renderSelectSingleMultiPost = () => {
    let detailsText = "";
    let detailsIcon = "";

    if (this.state.selectedPost.length === 1) {
      detailsText = "Add Details";
      detailsIcon = singleSelectIcon;
    } else {
      detailsText = "Combine and Add Details";
      detailsIcon = MultiSelectIcon;
    }

    return (
      <Typography
        style={webStyle.selectedPostdetails as React.CSSProperties}
        onClick={this.openSelectedPostModal}
        data-test-id="openSelectedPostModal"
      >
        <img
          src={detailsIcon}
          alt={
            this.state.selectedPost.length === 1
              ? "Single Select Icon"
              : "Multi Select Icon"
          }
          style={webStyle.detailsText}
        />{" "}
        {detailsText}
      </Typography>
    );
  };

  renderFilteredModal = () => {
    const { hazardData, openFilterModal, selectedHazards } = this.state;
    return (
      <Modal
        open={openFilterModal}
        onClose={this.closeFilterModal}
        style={webStyle.overflow}
        data-test-id="filterClose"
      >
        <Filters>
          <Box className="Filters-modal-header">
            <Typography>Filters</Typography>
            <img
              data-test-id="close-filter"
              src={closeIcon}
              alt="close"
              style={webStyle.pointer}
              onClick={this.closeFilterModal}
            />
          </Box>

          <Box className="Filters-modal-body">
            <Box className="risk-card">
              <Typography className="risk-card-title">Hazard Risk</Typography>
              <Button
                data-test-id="clear-hazard"
                className="clear-btn"
                onClick={this.handleClear}
              >
                clear
              </Button>
            </Box>
            <div style={webStyle.hazardDataList as React.CSSProperties}>
              {hazardData.map((hazard, index) => (
                <div
                  style={{
                    ...webStyle.hazardBtn,
                    color: this.getSelectHazardFontColor(
                      selectedHazards,
                      hazard.attributes.id
                    ),
                    background: this.getSelectHazardBackgroundColor(
                      selectedHazards,
                      hazard.attributes.id
                    ),
                  }}
                  key={index}
                  onClick={() => this.hazardSelectFun(hazard.attributes.id)}
                  data-test-id="statusSelectFilter"
                >
                  <img
                    src={this.getIcon(selectedHazards, hazard.attributes.id)}
                  />
                  {" " + hazard.attributes.name}
                </div>
              ))}
            </div>
          </Box>
          <Box className="Filters-modal-body risk-level-body">
            <Box className="risk-card">
              <Typography className="risk-card-title">Risk Levels</Typography>
              <Button
                data-test-id="clear-risk"
                className="clear-btn"
                onClick={this.clearRiskLevel}
              >
                clear
              </Button>
            </Box>
            <div
              style={webStyle.selectedFilters as React.CSSProperties}
            >
              {this.state.RisklevelFilter.map((riskLevel, index) => (
                <div
                  style={{
                    border: this.state.selectedRiskLevel.includes(
                      riskLevel.name
                    )
                      ? "none"
                      : `1px solid ${riskLevel.color}`,
                    ...webStyle.selectedRiskChips,
                    color: this.state.selectedRiskLevel.includes(riskLevel.name)
                      ? "#fff"
                      : `${riskLevel.color}`,
                    background: this.state.selectedRiskLevel.includes(
                      riskLevel.name
                    )
                      ? "#8735E1"
                      : "",
                  }}
                  key={index}
                  onClick={() => this.handleRiskLevels(riskLevel.name)}
                  data-test-id="RisklevelFilter"
                >
                  {this.state.selectedRiskLevel.includes(riskLevel.name) ? (
                    <img src={trueIcon} className="selectedIcon" />
                  ) : (
                    <img src={`${riskLevel.icon}`} className="selectedIcon" />
                  )}
                  {" " + riskLevel.name}
                </div>
              ))}
            </div>
          </Box>

          <Box className="bottom-wrapper">
            <Button
              data-test-id="clear-all"
              variant="contained"
              className="clear-all-btn"
              onClick={this.handleClearAll}
            >
              Clear All
            </Button>
            <Button
              data-test-id="apply-filter"
              variant="contained"
              className="apply-btn"
              onClick={() => this.getFilteredPublishApostAPI(1)}
            >
              Apply
            </Button>
          </Box>
        </Filters>
      </Modal>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { activeButton, publishPostData, totalCount, currentPage, isLoader } =
      this.state;
    return (
      <ThemeProvider theme={theme}>
        <MainContainer>       
          <Box style={webStyles.breadcrumbWrapper}>
            <Typography style={webStyles.breadcrumbItem}> Publish a Post</Typography>
            <NavigateNext style={webStyles.navigateIcon} />
            <Typography style={webStyles.breadcrumbActiveItem}>{activeButton}</Typography>
          </Box>
          <Box className="myPostsTitle">
            <Typography className="mainHeading">Publish a Post</Typography>
            <Box style={webStyle.sortIconBox}>
              <img
                data-test-id="sort-icon"
                src={buttonSort}
                alt="sort"
                onClick={this.handleSorting}
                style={webStyle.sortIcon}
              />
              <img
                data-test-id="filter-modal"
                src={
                  this.state.activeFilter ? buttonFilterActive : buttonFilter
                }
                alt="filter"
                onClick={this.handleFilter}
                style={webStyle.filterIcon}
              />
            </Box>
            {this.renderFilteredModal()}
            {this.renderSortedModal()}
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <div>
              <Button
                data-test-id="published-post"
                onClick={() => this.handlePublishButtonClick("Published")}
                className={`buttonStyle ${activeButton === "Published" ? "buttonStyleActive" : ""
                  }`}
              >
                Published
              </Button>
              <Button
                data-test-id="unactioned-post"
                onClick={() => this.handlePublishButtonClick("Un Actioned")}
                className={`buttonStyle ${activeButton === "Un Actioned" ? "buttonStyleActive" : ""
                  }`}
              >
                Un Actioned
              </Button>
            </div>
            {this.state.activeButton == "Un Actioned" &&
              this.state.selectedPost.length > 0 && (
                <div style={webStyle.selectedPostDiv}>
                  <Typography
                    style={webStyle.selectedPosts}
                  >
                    <img
                      src={selectRemoveIcon}
                      alt="Select Remove Icon"
                      style={webStyle.removeIcon}
                    />
                    {`${this.state.selectedPost.length} ${this.state.selectedPost.length !== 1 ? "posts" : "post"
                      } selected`}
                  </Typography>
                  {this.renderSelectSingleMultiPost()}
                </div>
              )}
          </Box>

          <Grid>{this.renderPosts(isLoader, publishPostData)}</Grid>
          <Grid container justifyContent="flex-end">
            <Pagination
              count={Math.ceil(totalCount / 10)}
              page={currentPage}
              variant="outlined"
              style={webStyle.pagination}
              data-test-id="handlePageChange"
              onChange={this.pageChange}
            />
          </Grid>
          <Dialog open={this.state.isSafetyIncharge}>
            <div style={webStyle.popUpBox}>
              <Typography
                variant="body1"
                style={webStyle.errorText}
              >
                You are not a safety in charge.
              </Typography>
              <div style={webStyle.OkBtnBox as React.CSSProperties}>
                <Button
                  data-test-id="alertBoxOkButton"
                  onClick={this.alertBoxOkButton}
                  variant="contained"
                  style={webStyle.OkBtn}
                >
                  OK
                </Button>
              </div>
            </div>
          </Dialog>
        </MainContainer>
        {this.renderSelectedPostModal()}
      </ThemeProvider>
    );
  }
}

const MainContainer = styled(Box)({
  width: "100%",
  padding: "30px",
  backgroundColor: "#f7f5f5",
  boxSizing: "border-box",
  "& .navigateNext": {
    color: "#DCDCDC",
  },

  "& .post-profile-left": {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },

  "& .time-icon": {
    width: "15px",
    height: "15px",
  },
  "& .buttonStyle": {
    background: "#EEEEEE",
    padding: "10px 12px",
    fontSize: "14px",
    textTransform: "capitalize",
    fontWeight: 400,
    height: "30px",
    color: "#828282",
    borderRadius: "8px",
    marginRight: "10px",

    "&.buttonStyleActive": {
      background: "#8735E1",
      color: "#fff",
      fontWeight: 700,
    },
  },
  "& .myPostsTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 0 10px 0",
  },
  "& .mainHeading": {
    margin: "20px 0",
    color: "#2B2A29",
    textEdge: "cap",
    fontSize: "26px",
    leadingTrim: "both",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    fontFamily: "ClotherBold",
  },
  "& .card": {
    padding: "20px",
    background: "#fff",
    border: "1px solid #EEE",
    marginTop: "20px",
    borderRadius: "8px",
    marginBottom: "24px",
    cursor: "pointer",
    borderColor: "lightgrey",
  },
  "& .Post-time-detail": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .Post-time-detail .Floor_btn": {
    marginTop: "0px",
  },

  "& .filter-wrapper": {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  "& .card-post-name": {
    color: "#2B2A29",
    fontFamily: "ClotherBold",
    fontWeight: 700,
    fontSize: "18px",
    fontStyle: "normal",
    textTransform: "capitalize",
  },

  "& .Post-time-inner": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
  },
  "& .card-post-detail": {
    color: "#828282",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    marginTop: "15px",
  },
  "& .card_title_wrapper": {
    display: "flex",
    gap: 10,
    alignItems: "center",
    "&.inner-options": {
      justifyContent: "space-between",
    },
  },
  "& .Floor_btn": {
    borderRadius: "4px",
    background: "#EAE8E8",
    color: "#A1A1A1",
    fontWeight: 400,
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    padding: "5px",
    fontStyle: "normal",
    marginTop: "15px",
    textTransform: "capitalize",
  },

  "& .Post-profile-img": {
    width: "46px",
    height: "46px",
    content: "",
    borderRadius: "46px",
    marginRight: 8,
    cursor: "pointer",
  },
  "& .Post-inner-profile-img": {
    width: "26px",
    height: "26px",
    content: "",
    borderRadius: "46px",
    border: "1px solid #8735E1",
  },
  "& .Post-time-content": {
    color: "#A1A1A1",
    fontFamily: "ClotherRegular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px"
  },
});

const webStyle = {
  hazardDataList: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
  },
  overflow: {
    overflow: "auto"
  },
  hazardBtn: {
    border: "1px solid #bbb9b9",
    padding: "13px",
    borderRadius: "8px",
    font: "Clother",
    cursor: "pointer",
  },
  detailsText: {
    marginRight: "6px",
    height: '14px',
    width: '14px'
  },
  selectedFilters: {
    marginTop: "10px",
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
  },
  selectedRiskChips: {
    padding: "13px",
    borderRadius: "8px",
    font: "Clother",
    cursor: "pointer",
    minWidth: "40px",
  },
  discription: {
    marginLeft: "12px",
    fontSize: "16px",
    fontFamily: "ClotherBold",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.41px",
  },
  profilePic: {
    width: "46px",
    height: "46px",
    borderRadius: "50%",
    marginLeft: "-5px",
  },
  addDetails: {
    fontSize: "20px",
    fontFamily: "clotherBold",
  },
  removeQueue: {
    position: "relative",
    bottom: "33px",
    left: "100%",
    cursor: "pointer",
  },
  selectedPostItemsList: {
    paddingTop: "20px"
  },
  dialogs: {
    width: "550px",
    padding: "20px"
  },
  fillColor: {
    color: "#DCDCDC"
  },
  publishPostBtn: {
    textAlign: "left",
    color: '#18181E',
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherRegular",
  },
  activeBtn: {
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "left",
  },
  sortIconBox: {
    display: 'flex',
    gap: '7px'
  },
  sortIcon: {
    cursor: "pointer",
    width: "28px",
    height: "28px"
  },
  filterIcon: {
    cursor: "pointer",
    width: "28px",
    height: "28px"
  },
  selectedPosts: {
    fontFamily: "clotherRegular",
    display: "flex",
    alignItems: "center",
    fontSize: '14px',
    lineHeight: '22px',
    color: '#2B2A29',
  },
  removeIcon: {
    marginTop: "3px",
    height: '10px',
    width: '10px',
    marginRight: '6px'
  },
  pagination: {
    marginTop: "20px"
  },
  popUpBox: {
    padding: "20px"
  },
  errorText: {
    fontSize: "20px",
    fontFamily: "clotherRegular"
  },
  OkBtnBox: {
    marginTop: "20px",
    textAlign: "center"
  },
  OkBtn: {
    color: "#fff",
    backgroundColor: "#6827db",
    fontFamily: "Clotherbold",
  },
  pointer: {
    cursor: "pointer"
  },
  noData: {
    fontSize: "18px",
    fontFamily: 'ClotherBold',
  },
  loader: {
    color: "#8735e1"
  },
  postImg: {
    width: "100%",
    height: 300,
    objectFit: "cover",
    marginTop: 8,
    borderRadius: "8px",
    cursor: "pointer",
  },
  selectedPostImg: {
    position: "relative",
    top: "-2em",
    left: "100%"
  },
  selectedPostdetails: {
    color: "#8735E1",
    fontFamily: "clotherBold",
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "-0.40799999237060547px",
    textAlign: "center",
    marginLeft: '24px',
    alignItems: 'center',
    display: 'flex',
  },
  selectedPostDiv: {
    display: "flex",
    justifyContent: "space-around",
    background: "#fff",
    padding: "10px 15px",
    alignItems: "center",
  },
  nextButton: {
    width: "160px",
    height: "50px",
    padding: "10px",
    borderRadius: "8px",
    background: "#8735E1",
    color: "white",
    boxShadow: "none",
    marginLeft: "16px",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    letterSpacing: "0px",
    textTransform: "capitalize",
  },
  cancelButton: {
    width: "160px",
    height: "50px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #8735E1",
    color: "#8735E1",
    boxShadow: "none",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    letterSpacing: "0px",
    textTransform: "capitalize",
    background: "#fff",
  },
  mainGrid: {
    height: "350px",
    overflowY: "scroll",
    padding: "10px 30px 30px",
    position: "relative",
  },
  selectedPost: {
    minHeight: "70px",
    padding: "12px 12px 12px 0px",
    borderRadius: "8px",
    border: "2px solid #8735E1",
    marginBottom: "20px",
  },
  addDetailsTitle: {
    fontFamily: "ClotherBold",
    fontSize: "24px",
    lineHeight: "24px",
  },
  header: {
    padding: "20px 30px",
    position: "static",
    top: 0,
  },
};
// Customizable Area End
