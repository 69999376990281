import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef } from 'react';

// Customizable Area Start
import { AnswerSummitI, QuestionAnswerAttributesI, AnswerI, Question, ApiCallInterface } from '../IAssessment'
import { Message } from "../../../../framework/src/Message";
import { FlatList, } from "react-native";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  questionsList?: Question[];
  currentQuestionIndex: number;
  answerQuestions: AnswerSummitI | {};
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isAssessmentSubmitted: boolean;
  answerList: QuestionAnswerAttributesI[];
  letters: string[];
  seconds: number;
  descriptiveValue: string | undefined;
  value: string | any;
  numPages: number | any;
  pageNumber: any;
  selectedAnswers: any;
  score: any;
  submitted: any;
  lessons_all_data: any;
  lessons_page_sidebar: any;
  token: string;
  courses_Id: any;
  Finishpayload: any;
  open: boolean;
  congratulationsData: any;
  isFinish:boolean;
  toggleSidebar:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  
  // Customizable Area End
}



export default class AssessmentQuestionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  private readonly errorTitle = "Error";
  flatListRef = createRef<FlatList<Question>>();
  interval: number | undefined;
  apiGetAssessmentQuestions: string = "";
  markascompletedlessons: string = "";
  questionApiCallId: string = "";
  // Customizable Area End    




  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    let authToken: any = localStorage.getItem('authToken') || "{}";

    this.state = {
      questionsList: [],
      currentQuestionIndex: 0,
      isError: false,
      isLoading: true,
      isSuccess: false,
      answerList: [],
      answerQuestions: {},
      seconds: 0,
      isAssessmentSubmitted: false,
      letters: 'abcdefghijklmnopqrstuvwxyz'.split(''),
      descriptiveValue: '',
      selectedAnswers: {},
      score: 0,
      submitted: false,
      token: authToken,
      value: 0,
      numPages: null,
      pageNumber: 1,
      lessons_all_data: [],
      lessons_page_sidebar: "",
      courses_Id: "",
      Finishpayload: {},
      open: false,
      congratulationsData: [],
      isFinish: false,
      toggleSidebar:false
    };

     // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.apiGetAssessmentQuestions) {
          this.getAsssessmentQuestionSuccesscallBack(responseJson.data);
        }
        else if (apiRequestCallId === this.questionApiCallId) {
          this.questionApiCallIdSQ(responseJson)
        }
      }
      else if (responseJson && responseJson.message) {
        if (apiRequestCallId === this.questionApiCallId) {
          this.setState({ isLoading: false }, () => { this.questionApiCallIdSQ(responseJson) })
        }
        else if (apiRequestCallId === this.markascompletedlessons) {
          this.mark_as_completed_lessonsSQ(responseJson.data)
        }
      }
    }
    // Customizable Area End
  }


   // Customizable Area Start

   async componentDidMount() {
    let courses_Id = await getStorageData("courses_Id", false);
    this.setState({ courses_Id ,isLoading: false, }, () => { this.getAsssessmentQuestionAPI(courses_Id) });
  }

  async componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  // get answered question
  getAnsweredQuestionConstant() {
    const { currentQuestionIndex, questionsList } = this.state;
    const prevDisable = currentQuestionIndex == 0
    const nextDiable = questionsList?.length === currentQuestionIndex + 1
    const totalQuestions = questionsList?.length

    let list = this.state.questionsList?.filter((objData: Question) => {
      return objData?.SelectedAnswerIndex !== undefined || objData?.description_text !== undefined
    })
    let AnsweredQuestionCount = list?.length
    return {
      prevDisable,
      nextDiable,
      totalQuestions,
      AnsweredQuestionCount
    }
  }

  // API  Respones



  apiCallAssessmentQuestions = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body, } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    // apiCallAssessmentQuestions

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // get assessment question for 

  getAsssessmentQuestionAPI = async (courses_Id: any) => {
    this.apiGetAssessmentQuestions = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: `/bx_block_assessmenttest/courses/${courses_Id}`
    });
  };

  getAsssessmentQuestionSuccesscallBack = async (data: any) => {
    const lessons = data.attributes.lessons.data.map((lesson: any) => {
      return {
        id: lesson.id,
        type: lesson.type,
        attributes: lesson.attributes
      };
    });

    const quizzes = data.attributes.quizzes.data.map((quiz: any) => {
      return {
        id: quiz.id,
        type: quiz.type,
        attributes: quiz.attributes
      };
    });

    this.setState({
      lessons_all_data: data,
      lessons_page_sidebar: [...lessons, ...quizzes]
    });

  };


  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

  handleOptionChange = (questionId: any, answer: any) => {
    this.setState(prevState => ({
      selectedAnswers: {
        ...prevState.selectedAnswers,
        [questionId]: answer,
      },
    }));
  };

  mark_as_completed_lessons = async (data: any) => {
    const lesson_id = data[0].attributes.id;
    const course_id = data[0].attributes.course_id;
    this.markascompletedlessons = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.submitAssessementTestAPiMethod,
      endPoint: `bx_block_assessmenttest/courses/mark_as_completed`,
      body: {
        "course_id": course_id,
        "lesson_id": lesson_id
      }
    });
  }


  questionApiCall = async (payload: any) => {
    this.questionApiCallId = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.submitAssessementTestAPiMethod,
      endPoint: `bx_block_assessmenttest/courses/quiz_next_or_submit`,
      body: payload
    });
  }

  mark_as_completed_lessonsSQ = (responseJson: any) => {
    this.getAsssessmentQuestionAPI(this.state.courses_Id);
  }

  navigateToAssessmentTestScreen = () => {
    this.setState({open:false ,isFinish:false , congratulationsData:[] })
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentTest");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  navigateToAssessmentDetail = () => {
    const messasge = new Message(getName(MessageEnum.NavigationMessage));
    messasge.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentDetail");
    messasge.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messasge);
  }


  handleNext = () => {
    if (this.state.value === this.state.lessons_page_sidebar.length - 1) {
      const updatedFinishpayload = {
        ...this.state.Finishpayload,
        submit: true
      };
      delete updatedFinishpayload.next;

      this.setState({
        Finishpayload: updatedFinishpayload, isLoading: true
      }, () => {
        this.questionApiCall(this.state.Finishpayload);
      });
    }
    else {
      this.setState(prevState => {
        const newValue = prevState.value + 1;
        return { value: newValue };
      });
    }
  };

  questionApiCallIdSQ = (responseJson: any) => {
    this.setState({
      isLoading: false,
      open: (this.state.value === this.state.lessons_page_sidebar.length - 1 && this.state.isFinish) ? true : false,
      congratulationsData: responseJson
    })
  }

  handleOpencongratulationsModel = (value:any)=>{
    this.setState({isFinish:value})
  }

  handleCheckboxChange = ({ questionId, selectedOption, courseId, quizId, submit = false, selectedOptionKey }: any) => {
    this.setState((prevState) => {
      const payload = {
        course_id: courseId,
        quiz_id: quizId,
        next: true,
        attempted_answer_option: selectedOptionKey,
        question_id: questionId
      };

      return {
        selectedAnswers: {
          ...prevState.selectedAnswers,
          [questionId]: selectedOption,
        },
        Finishpayload: payload,
      };
    }, () => {
      this.questionApiCall(this.state.Finishpayload);
    });
  };

  handletoggleSidebar = () => {
    this.setState({ toggleSidebar: !this.state.toggleSidebar });
  }


  handlePrevious = () => {
    this.setState(prevState => {
      const newValue = prevState.value - 1;
      return { value: newValue };
    });
  };

  handleMarkAsComplete = () => {
    const data = this.state.lessons_page_sidebar.filter((item: any, index: any) => index === this.state.value);
    const type = data[0]?.type
    switch (type) {
      case "lessons":
        this.mark_as_completed_lessons(data)
        break;
      case "quizzes":
        console.log(data);
        break;
      default:
        console.log(type);
    }
  };

  // Customizable Area End
}

