import { createTheme } from '@material-ui/core';
import { Shadows } from '@material-ui/core/styles/shadows';
import { calendarLogo, loginBg } from './assets';

export const theme = createTheme({
    shadows: [...createTheme().shadows].map(() => 'none') as Shadows,
    palette: {
        action: {
            selected: '#F9F3FF',
            hover: '#F9F3FF'
        },
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        }
    },
    typography: {
        fontFamily: "ClotherRegular",
        h2: {
            fontSize: "28px",
            lineHeight: "24px",
            fontWeight: "bold",
            marginBottom: "15px",
            color: "#18181E",
            fontFamily: "ClotherBold",
        },
        body2: {
            color: "#A1A1A1",
            fontSize: "18px",
            lineHeight: "18px",
        },
        caption: {
            "&::after": {
                position: "absolute",
                left: "0",
                right: "0",
                height: "1px",
                background: "#DCDCDC",
                content: "''",
                top: "50%",
                transform: "translateY(-50%)",
            },
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                height: "54px",
                border: "1px solid #DCDCDC",
                padding: "15px 15px",
                fontSize: "16px",
                lineHeight: "18px",
                color: "#2B2A29",
                width: "100%",
                fontWeight: 400,
                letterSpacing: "0.5px",
                textOverflow: "ellipsis",
                justifyContent: "flex-start",
                borderRadius: "8px",
                "&.Mui-disabled": {
                    cursor: "no-drop",
                },
                "&:hover, &.Mui-focused": {
                    border: `1px solid #8735E1`,
                },
                "&.date-input": {
                    background: `url(${calendarLogo}) no-repeat`,
                    backgroundPosition: "right 15px center",
                    borderWidth: "thin",
                    paddingRight: "15px",
                },
            },
            input: {
                padding: "0px 17px 5px 0px",
            },
        },
        MuiButton: {
            root: {
                textTransform: "none",
                fontFamily: "ClotherBold",
                height: "54px",
                border: "none",
                boxShadow: "none",
                width: "100%",
                lineHeight: "22px",
                "&.microsoft-btn": {
                    background: "#fff",
                    border: "1px solid #DCDCDC",
                    marginBottom: "18px",
                },
            },
            contained: {
                boxShadow: "none",
                background: "#8735E1",
                backgroundColor: "#8735E1",
                borderRadius: "8px",
                fontFamily: "ClotherBold",
                fontWeight: 700,
                color: "#fff",
                "&:hover, &:focus": {
                    boxShadow: "none",
                    background: "#8735E1",
                },
                "&.microsoft-btn": {
                    background: "#fff",
                    backgroundColor: "#fff",
                    color: "#18181E",
                    "&i": {
                        marginRight: "4px",
                    },
                    "&:hover, &:focus": {
                        boxShadow: "none",
                        background: "#fff",
                    },
                },
            },
            outlined: {
                textAlign: "center",
                backgroundColor: "#fff",
                color: "#A1A1A1",
                "& svg path": {
                    fill: "#A1A1A1",
                },
                "& i": {
                    marginRight: "4px",
                },
                "&:hover": {
                    boxShadow: "none",
                    border: "1px solid #8735E1",
                    backgroundColor: "#fff",
                    color: "#8735E1",
                    "& svg path": {
                        fill: "#8735E1",
                        transition: "all 0.5s ease-in-out",
                    },
                },
                "&.active": {
                    boxShadow: "none",
                    border: "1px solid #8735E1",
                    backgroundColor: "#fff",
                    color: "#8735E1",
                    "& svg path": {
                        fill: "#8735E1",
                        transition: "all 0.5s ease-in-out",
                    },
                },
            },
        },
        MuiSvgIcon: {
            root: {
                '&.MuiSelect-icon': {
                    marginRight: '1rem'
                }
            }
        },
        MuiFormControlLabel: {
            root: {
                marginRight: 0,
                marginLeft: 0
            }
        },
        MuiButtonBase: {
            root: {
                marginRight: '0.5rem',
            }
        },
        MuiPaper: {
            root: {
                marginTop: '2.7rem',
                marginLeft: '-1rem',
                width: '28.6%',
            }
        },
        MuiList: {
            root: {
                '&-padding': {
                    padding: 0
                }
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: '#fff'
                }
            }
        },
        MuiMenu: {
            paper: {
                border: '1px solid #DCDCDC',
                borderRadius: '0.5rem',
            },
            list: {
                padding: 0
            }
        },
        MuiOutlinedInput: {
            root: {
                border: '1px solid #DCDCDC',
                borderRadius: '0.5rem',
            }
        },
        MuiMenuItem: {
            root: {
                height: '2.5rem',
                fontFamily: 'ClotherRegular',
                '&:hover': {
                    color: '#8735E1'
                },
                '&:focus': {
                    backgroundColor: '#fff'
                }
            },
        },
        MuiRadio: {
            root: {
                padding: 0
            },
            colorSecondary: {
                '&$checked': {
                    color: '#8735E1',
                },
            },
        },
        MuiInput: {
            multiline: {
                padding: '1rem'
            }
        }
    }
});

export const webStyles = {
    flexBox: {
        justifyContent: 'flex-end',
        display: 'flex'
    },
    orSignUpText: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        margin: '0px',
        color: '#DCDCDC'
    },
    detailsText: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#FFFFFF'
    },
    detailsHeading: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#FFFFFF'
    },
    userNameText: {
        fontFamily: 'ClotherBold',
        fontSize: '22px',
        lineHeight: '46px',
        color: '#FFFFFF'
    },
    countList: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: '5px',
    },
    profileImage: {
        width: "100%",
        maxWidth: "112px",
        height: "124px",
        margin: "0px",
    },
    Containers: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FunBox: {
        fontSize: "0.875rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        gap: "0.5rem",
    },
    headBBox: {
        borderRadius: "0.5rem",
        backgroundColor: "#fff",
        marginRight: "1.2rem",
    },
    customMenu: {
        position: "absolute",
        top: "35px",
        left: "-35px",
    },
    bgColor: {
        backgroundColor: "#eee"
    },
    loaderBox: {
        color: "#fff", zIndex: 1
    },
    cardsGrp: {
        borderRadius: "8px",
        color: "#fff",
        padding: "1rem",
    },
    pointsText: {
        fontWeight: 700,
        fontSize: "2.5rem",
        marginBottom: "0.5rem",
    },
    HandsIcon: {
        width: "2.375rem",
        height: "2.375rem",
    },
    contributionBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        maxWidth: "100%",
        height: "auto",
        color: "#fff",
        padding: "1rem",
        fontSize: "0.875rem",
    },
    closeModalIcon: {
        color: "#A1A1A1",
        paddingRight: "10px"
    },
    okayBtn: {
        width: '90px',
        height: '40px',
        borderRadius: '8px',
        background: '#8735E1',
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '22px',
        color: '#FFFFFF',
        marginTop: '30px',
        textTransform: 'none'
    },
    errorMsgText: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        color: 'red'
    },
    dialogBox: {
        minWidth: '300px',
        padding: "30px",
        borderRadius: "8px",
    },
    reportMenuItem: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#2B2A29',
        width: 'max-content',
    },
    commentInput: {
        width: '100%',
        height: '50px',
        padding: '10px 15px 10px 15px',
        borderRadius: '8px',
        background: '#F8F8F8',
        border: '1px solid #F8F8F8',
    },
    postImage: {
        width: '100%',
        height: 300,
        objectFit: 'cover',
        marginTop: 8,
        borderRadius: "8px",
        cursor: 'pointer'
    },
    postUserName: {
        fontSize: "18px",
        fontFamily: "ClotherBold"
    },
    customIcon: {
        marginRight: 8,
        cursor: "pointer"
    },
    attachments: {
        paddingRight: "5px"
    },
    iconBtn: {
        position: 'absolute',
        right: "16px"
    },
    textArea: {
        flex: 1,
        padding: 14,
        borderRadius: 4,
        border: '1px solid #f5f5f5',
        background: "#f5f5f5",
        fontSize: '0.875rem',
        resize: 'none',
        height: '3rem',
        paddingRight: '3.5rem',
        color: '#A1A1A1',
        fontFamily: 'ClotherRegular',
        overflow: 'hidden'
    },
    userName: {
        color: '#A1A1A1',
        fontFamily: 'ClotherRegular'
    },
    profilePic: {
        fontSize: "14px",
        width: "26px",
        height: "26px",
        border: "1px solid #8735E1",
    },
    locationText: {
        marginRight: 75,
        fontSize: "16px",
        color: '#A1A1A1',
        fontFamily: 'ClotherRegular'
    },
    locationBox: {
        margin: '11.5px 5px',
        background: "#f5f5f5",
        width: "fit-content",
        borderRadius: "4px",
        cursor: 'pointer'
    },
    genderImages: { marginRight: '8px' },
    topbar: { height: "100px", padding: "0 40px" },
    sidebar: {
        background: "#FFF",
        boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)"
    },
    icon: {
        fontFamily: "clotherbold",
        backgroundColor: "#eee",
        borderRadius: "50%",
        padding: "9px",
        color: "#A1A1A1",
        fontSize: "12px"
    },
    menuText: {
        color: '#727272',
        fontFamily: 'Clother',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '8px',
    },
    descrp: {
        fontSize: "18px",
        fontFamily: "clotherRegular",
        color: "#828282"
    },
    active: {
        color: '#8735E1',
        fontSize: "20px",
        fontFamily: 'Clother',
        fontWeight: 700,
        lineHeight: "8px",

    },
    menuButton: {
        paddingTop: 0
    },
    listBox: {
        borderRadius: "8px",
        background: "#fff",
        marginBottom: "25px",
    },
    pointslistBox: {
        borderRadius: "8px",
        background: "#fff",
        marginBottom: "25px",
        marginTop: "1.2rem",
        cursor: 'pointer',
    },
    headB: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #eee",
        lineHeight: '20px',
        fontSize: "18px",
        fontFamily: "clotherbold",
        color: "#18181E",
        alignItems: "center",
        padding: '0 1rem'
    },
    headp: {
        fontFamily: 'ClotherBold',
        lineHeight: '24px',
        fontSize: "12px",
        color: "#8735E1",
        cursor: "pointer"
    },
    customButton: {
        display: 'flex',
        width: '100%',
        height: '46px',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '8px',
        background: 'linear-gradient(45deg, #9716e5 30%, #a102b6 90%)',
        marginBottom: "20px",
        marginTop: "20px",
    },
    customButtonText: {
        color: '#FFF',
        fontFamily: 'Clotherbold',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '-0.408px',
        textTransform: "none"
    },
    logout: {
        position: 'fixed',
        bottom: 0,
        color: '#F30202'
    },
    rightCornerPos: {
        position: 'absolute',
        right: '-1rem',
        bottom: '0.5rem',
        cursor: 'pointer'
    },
    editButton: {
        margin: 0,
        padding: 0,
        width: 0,
        height: 0
    },
    stepIdentifier: {
        display: 'flex',
        width: '8vw',
        height: '8vw',
        padding: '10px 15px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '8px',
        background: '#FAF3FD'
    },
    numberBullet: {
        color: '#fff',
        fontWeight: 700,
        textAlign: 'center'
    },
    bottomSpan: {
        fontSize: '0.875rem'
    },
    addBottomCont: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    starImg: {
        height: '1rem',
        width: '1rem',
    },
    addTop: {
        fontSize: '1.25rem',
        fontWeight: '700',
        marginRight: '0.3rem'
    },
    addBottom: {
        fontSize: '0.875rem',
        color: '#727272',
        marginRight: '0.3rem'
    },
    addBottomHigh: {
        fontWeight: '700',
        fontSize: '0.875rem',
        marginRight: '0.3rem'
    },
    bottomContent: {
        display: 'flex',
        gap: '0.5rem',
    },
    upTile: {
        display: 'flex',
        gap: '0.5rem',
        color: '#727272'
    },
    downTile: {
        fontWeight: '700',
        fontSize: '1.4rem'
    },
    downTileExtended: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    profileBox: {
        height: '5.75rem',
        padding: '1rem',
        borderRadius: '0.5rem',
        background: 'var(--Gray-4, #F8F8F8)',
        cursor: 'pointer',
    },
    innerCircle: {
        color: '#8735E1'
    },
    backgroundCircle: {
        color: '#EAEAEA',
        position: 'absolute',
        transform: 'scaleX(-1)',
        rotate: '90deg'
    },
    profileContainer: {
        borderRadius: '0.5rem',
        padding: '1.25rem',
        position: 'relative',
        margin: 0,
        width: 'auto'
    },
    avatarWrapper: {
        height: 'min-content'
    },
    avatarList: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    avatarStyle: {
        height: '5.3rem',
        width: '5.3rem',
        cursor: 'pointer',
    },
    removePhotoText: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '22px',
        color: '#F30202',
        paddingLeft: '10px',
        cursor: 'pointer'
    },
    percentDiv: {
        position: 'absolute',
        bottom: '-2px',
        width: '1.6rem',
        height: '0.75rem',
        backgroundColor: '#8735E1',
        borderRadius: '5px',
        zIndex: 1,
        color: '#fff',
        fontSize: '0.5rem',
        textAlign: 'center'
    },
    basicHeader: {
        fontSize: '18px',
        fontFamily: 'clotherbold'
    },
    userPropertyName: {
        color: '#828282',
        marginRight: '2px',
        width: 'min-content',
        whiteSpace: 'nowrap'
    },
    userPropertyValue: {
        marginTop: 0,
        fontFamily: 'ClotherRegular',
        fontWeight: '700',
        whiteSpace: 'nowrap'
    },
    contentContainer: {
        display: 'flex',
        marginTop: '1.2rem'
    },
    dotMenu: {
        position: 'absolute',
        right: '1.25rem',
        top: '1.25rem'
    },
    loginSec: {
        height: "100vh",
        overflow: "hidden"
    },
    loginLeft: {
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${loginBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    loginRight: {
        background: "#fff",
        width: "100%",
        display: "flex",
        height: '100vh',
    },
    loginRightInner: {
        width: "100%",
        margin: "60px 13.5%",
        fontFamily: "ClotherRegular",
    },
    loiginHeader: { marginBottom: "42px", textAlign: "center" },
    loginLogo: {
        marginBottom: "16px",
    },
    textFieldLabel: {
        display: "block",
        marginBottom: "8px",
        color: "#18181E",
        fontSize: "16px",
        lineHeight: "18px",
    },
    loginWith: {
        width: "100%",
        padding: "1rem 0",
    },
    microSoftBtn: {
        paddingBottom: "30px",
        width: "100%",
        textAlign: "center",
    },
    linkLableStyle: {
        color: "#A1A1A1",
        fontSize: "16px",
        lineHeight: "18px",
        marginBotton: "18px",
    },
    linkStyle: {
        fontWeight: 700,
        color: "#8735E1",
        marginLeft: "4px",
        cursor: "pointer",
    },
    errorStyle: {
        color: "#F30202",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "18px",
        marginBottom: "18px",
    },
    emailerrorStyle: {
        color: "#F30202",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "18px",
        marginBottom: "18px",
        marginTop: '-15px',
    },
    lineStyle: {
        display: "inline-block",
        position: "relative",
        zIndex: "2",
        padding: "0 12px",
    },
    radioButtonContainer: {
    },
    loginWithContainer: {
        color: "#DCDCDC",
        fontSize: "14px",
        lineHeight: "18px",
        position: "relative",
        textAlign: "center",
    },
    dateFieldWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    iconStyle: {
        marginRight: "4px",
    },
    formButton: {
        fontSize: "1.25rem",
        width: '9rem',
        height: '3rem',
        fontWeight: 600
    },
    fullButton: {
        color: '#fff',
        backgroundColor: '#8735E1',
        fontFamily: 'ClotherBold',
        fontSize: '20px'
    },
    transButton: {
        color: '#8735E1',
        borderRadius: '8px',
        border: '1px solid var(--Purple, #8735E1)',
        fontFamily: 'ClotherBold',
        fontSize: '20px'
    },
    label: {
        color: 'var(--Black, #18181E)',
        fontSize: '1rem',
        fontFamily: 'ClotherRegular'
    },
    input: {
        borderRadius: '0.5rem',
        border: '1px solid #DCDCDC',
        padding: '0.5rem 1rem',
        fontFamily: 'ClotherRegular',
    },
    inputStd: {
        height: '3.5rem',
        width: '100%',
    },
    inputDescNoLabel: {
        height: '5rem',
        width: '100%',
        paddingTop: '4rem',
        marginTop: '20px'
    },
    customTopIndent: {
        marginTop: '1rem',
    },
    footer: {
        marginTop: '4rem'
    },
    signInButton: {
        fontSize: "20px",
        fontFamily: 'ClotherBold'
    },
    genderBtn: {
        margin: 0,
        borderRadius: '8px',
    },
    signupWelcomeText: {
        fontFamily: "ClotherBold",
        fontSize: "26px",
        lineHeight: "24px",
        color: '#18181E',
        marginBottom: '16px',
    },
    signupSubHeader: {
        fontFamily: "ClotherRegular",
        fontSize: "18px",
        lineHeight: "18px",
        color: '#A1A1A1',
    },
    calendarStyle: {
        display: 'flex',
        width: '24px',
        height: '24px',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer'
    },
    buttonStyle: {
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: "22px",
        letterSpacing: "-0.40799999237060547px",
        textAlign: "center"
    },
    AiTagText: {
        fontSize: "10px",
        color: "#a8a8a8",
        marginBottom: "10px",
        marginTop: "8px",
        textAlign: "right",
        fontWeight: 600,
        fontStyle: 'italic'
    } as const,
    iconCustomPadding: {
        marginLeft: "12px",
        padding: "8px",
    },
    breadcrumbWrapper: {
        display: 'flex',
    },
    breadcrumbItem: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#A1A1A1',
        fontWeight: 600,
        cursor: 'pointer',
    },
    navigateIcon: {
        fill: '#DCDCDC'
    },
    breadcrumbActiveItem: {
        color: "#8735E1",
        fontSize: "16px",
        lineHeight: "22px",
        fontFamily: 'ClotherBold',
        fontWeight: 700,
        cursor: "pointer",
    },
};