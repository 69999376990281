export const locationIcon = require("../assets/location3.png");
export const timeIcon = require("../assets/image_Time.png");
export const calendarIcon = require('../assets/calanderLogo.png');
export const totalAssignedActions = require('../assets/totalAssignedActions.png');
export const completed = require('../assets/completed.png');
export const overdueActions = require('../assets/overdueActions.png');
export const dueActions = require('../assets/dueActions.png');
export const cancelledActions = require('../assets/cancelledActions.png');
export const moreVert = require('../assets/moreVerticle.png');
export const notMyDomain = require('../assets/notMyDomain.png');
export const profileDefaultImage = require('../assets/profile.svg');
