import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";

// Customizable Area Start
import { ApiResponseForImg, TagsItem } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tagsArray: TagsItem[];
  imageUrl: string;
  loading: boolean;
  imageId: string;
  token: string;
  noRecordFound: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImageScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start 
  getTagsApiCallId: string = '';
  // Customizable Area End 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start 
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)

    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      token: '',
      loading: false,
      imageId: '',
      tagsArray: [],
      imageUrl: '',
      noRecordFound: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    // Customizable Area Start 

    // Customizable Area End 
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(getName(MessageEnum.Props));
      this.setState({ imageId: responseData.id });
      setStorageData('imageId', responseData.id);
      this.getAssociatedTags();
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage) && getName(MessageEnum.RestAPIResponceMessage) === message.id && responseJson) {
      this.setState({ loading: false })
      if (apiRequestCallId === this.getTagsApiCallId) {
        this.associatedTagsSuccessCallback(responseJson)
      }
    }


    // Customizable Area End 
  }

  // Customizable Area Start
  async componentDidMount() {
    let token = await getStorageData("authToken");

    const storedImageId = await getStorageData('imageId');
    this.setState({
      token: token,
      imageId: storedImageId || '',
    });

    this.getAssociatedTags();
  }

  associatedTagsSuccessCallback(response: ApiResponseForImg) {
    if (response?.error) {
      this.setState({ noRecordFound: true })
    } else {
      this.setState({ tagsArray: response?.meta?.tags!, imageUrl: response?.data?.attributes?.image!, noRecordFound: false })
    }
  }

  getAssociatedTags = async () => {
    this.setState({ loading: true })
    this.getTagsApiCallId = await this.apiCallForImage({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.getTags}?image_id=${this.state.imageId}`,
    });
  }

  // Api Call Function
  apiCallForImage = async (data: { contentType: string, method: string, endPoint: string, body?: object, type?: string }) => {
    const { contentType, method, endPoint, body, type } = data
    const header = {
      "Content-Type": contentType,
      token: this.state.token,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header)

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint)

    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method)

    body && type != 'formData' ?
      reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      :
      reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body)

    runEngine.sendMessage(reqMessage.id, reqMessage)

    return reqMessage.messageId
  }
  onPressBackBtn = () => {
    this.props.navigation.goBack()
  }

  onPressTags = (item: TagsItem) => {
    const tagsData = {
      tag: item.title,
      tag_id: item.id,
    }
    const message: Message = new Message(
      getName(MessageEnum.AssociatedImages)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.Props), tagsData  ); // this line is only needed when props has to be sent
    this.send(message);
  }
  // Customizable Area End 
}