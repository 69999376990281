import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import { errorIcon } from "./assets";
import i18n from "i18next"
// Customizable Area End

import CustomMessageController, {
  Props,
} from "./CustomMessageController";

export default class CustomMessage extends CustomMessageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { message, boxHeight } = this.props;
    return (
      <Box style={{ ...webStyle.mainContainer, ...{ height: boxHeight ? boxHeight : '55vh' } }}>
        <img src={errorIcon} />
        <Typography style={webStyle.messageText} component={'p'}>{i18n.t(message)}</Typography>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#fff',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
  },
  messageText: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: '12px',
    color: '#ababab',
  }
} as const;
// Customizable Area End
