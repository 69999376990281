import { FormikErrors, FormikTouched } from 'formik';
import { ChangeEvent, FocusEvent } from 'react';

export enum EditSteps {
    Step1,
    Step2,
    Step3,
    Step4,
    StepComp
}

type formValues = {
    photo: string;
    employee_id: string,
    full_name: string,
    mobile_number: string,
    country_code:string,
    email_address: string,
    Official_email_address: string,
    dob: null | Date,
    gender: string,
    employee_type: string,
    contractor_name: string,
    role: string,
    Countrie:string,
    location: string,
    function: string,
    subFunction: string,
    work_location: string,
    work_location_desc: string,
    icf_Country_code: string,
    ice_name: string,
    ice_number: string,
    blood_group: string,
    fileUpload: null | File
}

export type ChildProps = {
    handleChange: { (e: ChangeEvent<any>): void; <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void; };
    handleBlur: { (e: FocusEvent<any>): void; <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void; };
    submitForm: () => Promise<void>;
    errors: FormikErrors<formValues>;
    touched: FormikTouched<formValues>;
    values: formValues;
    setStep: (step: EditSteps) => void;
    setFieldValue: (field: string, date: null | Date | string | []) => void,
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    selectEmployeType: any[],
    selectLocation: any[],
    selectRole:any[],
    selectCountrie:any[],
    selectFunction:any[],
    selectSubFunction:any[],
    selectWorkLocation:any[],
    selectBloodGroup:any[],
    countrieCode:any[],
    changleScemaBack?:(id:number)=> void
    functionAPI:(id:number)=> void
    subFunctionAPI:(id:number)=> void
    subWorkLocationAPI:(id:number)=> void
    locationAPI:(id:number)=> void
};