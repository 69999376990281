// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.userName = "User name"
exports.location = "Location"
exports.url = "Location"
exports.educationList = "/profile/educational_qualifications"
exports.projectList = "/profile/projects"
exports.awardList = "/profile/awards"
exports.patentList = "/profile/publication_patents"

exports.educationalTitle = "Educational Qualification";
exports.projectLabel = "Project";
exports.awardsLabel = "Awards";
exports.publicationPatentLabel = "Publication Patents";
exports.showMore = "Show More";
exports.noProjectText = "No project...";
exports.noQualiText = "No qualifications";
exports.noAwards = "No Award...";
exports.noPublicationPatents = "No Publication Patents...";
exports.closeText = "Close";

exports.getEducationContentType = "application/json";
exports.getEducationApiMethod = "GET";
exports.getEducationApiEndPoint = "profile/educational_qualifications";

exports.getProjectContextType = "application/json";
exports.getProjectApiMethod = "GET";
exports.getProjectApiEndPoint = "profile/projects";

exports.getAwardsContextType = "application/json";
exports.getAwardsApiMethod = "GET";
exports.getAwardsApiEndPoint = "profile/awards";

exports.getPatentsContextType = "application/json";
exports.getPatentsApiMethod = "GET";
exports.getPatentsApiEndPoint = "profile/publication_patents";
exports.callTypeApiValidateMobileNo = "POST";
exports.FollowUserAPIEndPoint = "bx_block_followers/follow";
exports.RiskDetailsAPIEndPoint = "bx_block_profile/profiles/get_user_content_data";
exports.accountDetailsAPIEndPoint = "/account_block/accounts/";
exports.HomeText = "Home";
exports.ProfileText = "Profile";
exports.riskProfileCardText = "Risk Profile Card";
exports.healthMissionText = "Health & Safety Mission";
exports.safetyText = "Always Healthy & Absolute Safety";
exports.MyDetailsText = "My Details";
exports.NameText = "Name";
exports.FunctionText = "Function";
exports.LocationText = "Location";
exports.DateText = "Date";
exports.RoleText = "Role";
exports.EmpCodeText = "Emp Code";
exports.MyGoalsText = "My Goals";
exports.HealthGoalText = "Health Goal";
exports.healthGoalValue = "Will lose 5kg weight so that I can be strong and healthier";
exports.SafetyGoalText = "Safety Goal";
exports.safetyGoalValue = "Safety check any machine before starting my work";
exports.EnvironmentGoalText = "Environment Goal";
exports.envGoalValue = "Will use public transport at least 2 days a week";
exports.RiskLevelsText = "Risk Levels";
exports.HeyText = "Hey";
exports.likeText = "would you like to be an";
exports.influencerText = "influencer";
exports.influencerText = "influencer";
exports.forText = "for";
exports.TransformText = "U1st culture transformation?";
exports.yesText = "Yes, I would";
exports.NoText = "No, I wouldn’t";
exports.DownloadText = "Download";
exports.ShareText = "Share";
exports.NoDescrpitionText = "No Descrpition";
exports.NoLocationText = "No Location";
exports.CommentsText = "Comments";
exports.LikesText = "Likes";
exports.agoText = "ago";
exports.createdText = "created this post";
exports.NoNameText = "No Name";
exports.NoPostsText = "No Posts";
exports.ContactDetailsText = "Contact Details";
exports.WorkEmailText = "Work Email";
exports.PersonalEmailText = "Personal Email";
exports.PhoneNumberText = "Phone Number";
exports.RecentPostsText = "Recent Posts";
exports.FollowingText = "Following";
exports.FollowText = "Follow";
exports.RankText = "Rank";
exports.PostText = "Post";
exports.FollowersText = "Followers";
exports.safetyAbsoluteText = "Safety Absolutes";
exports.noGoParam = "No Go Parameters";
exports.mandatoryCoursesText = "Mandatory Courses";
exports.PPEText = "PPE";
exports.RiskExposureText = "Risk Exposure";
exports.workText = "Work";
exports.RoadText = "Road";
exports.HomeText = "Home";
exports.LikePostApiEndPoint = "bx_block_like_a_post/like_posts";
exports.ReportText = "Report";
exports.ReportCommentText = "Report Comment";
exports.ReportSubHeaderText = "Why are you reporting this post?";
exports.ReportDescriptionLabel = "Couldn’t find reason? Describe your reason here";
exports.errorTextMsg = "Please enter description or at least one tag.";
exports.ReportedSubHeaderText = "We will look into this matter and take necessary actions within 2 working days. Thank you for raising your concern.";
exports.CommentReportedText = "Comment Reported";
exports.tagsAPIEndPoint = "bx_block_posts/report_posts/report_tags";
exports.reportCommentsAPIEndPoint = "bx_block_comments/report_comments";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.riskProfileGoalsEndpoint = "bx_block_profile/profiles/get_goals";
exports.riskLevelsEndpoint = "bx_block_profile/profiles/get_risk_levels";
exports.createRiskLevelEndpoint = "bx_block_profile/profiles/create_risk_level";
exports.statusJustStarted = "Just Started";
exports.statusOnTrack = "On Track";
exports.statusExceeding = "Exceeding";
// Customizable Area End