export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const starIcon = require("../assets/image_star.png");
export const sprinterIcon = require("../assets/sprinter.png");
export const avidLearner = require('../assets/avid-learner.png');
export const teamPlayer = require('../assets/team-player.png');
export const rankOneIcon = require("../assets/rank1.png");
export const rankTwoIcon = require("../assets/rank2.png");
export const rankThreeIcon = require("../assets/rank3.png");
export const poleOne = require("../assets/pole1.png");
export const poleTwo = require("../assets/pole2.png");
export const poleThree = require("../assets/pole3.png");
export const rewardCard = require("../assets/card.png");
export const amazonIcon = require("../assets/amazon.png");
export const myntraIcon = require("../assets/myntra.png");
export const flipkartIcon = require("../assets/flipkart.png");
export const warningIcon = require("../assets/warning.png");
export const closeIcon = require("../assets/close.png");
export const modalBackground = require("../assets/modal_background.png");
export const giftIcon = require("../assets/gift.png");
export const arrowDownIcon = require("../assets/drop-down.png");