import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";

export interface RiskLevel {
  name: string;
  color: string;
}

export interface RiskLevelBG {
  name: string;
  backgroundColor: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  actionPostData: any;
  selectedDate: "",
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewPostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCouncilDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      loading: false,
      actionPostData: {},
      selectedDate: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && responseJson) {
      if (apiRequestCallId === this.getCouncilDetailsApiCallId) {
        this.setState({ loading: false });
        this.setCouncilDetails(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let token = await getStorageData("authToken", false);
    this.setState({ token });
    this.getCouncilTrackerData()
  }

  setCouncilDetails = (response: any) => {
    const actionPostData = response.data;
    let selectedDate = response.data.due_date
    this.setState({ actionPostData, selectedDate });
  }

  // Api Call Function
  getCouncilTrackerData = async () => {
    this.setState({ loading: true });
    let postId = await getStorageData("postId", false)
    const header = {
      "Content-Type": configJSON.postsContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCouncilDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCouncilDataAPIEndPoint + `?id=${JSON.parse(postId)}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  goToListing = () => {
    this.props.navigation.navigate("SafetyCouncilPost")
  }

  redirectToSafetyCouncil = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SafetyCouncilPost");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  redirectBackToPost = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SafetyCouncilPostDetail");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
