import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area Start
export interface FollowingData {
  id: number,
  type: string,
  attributes: {
    following: {
      data: {
        attributes: {
          profile_image: string,
          full_name: string,
        }
      }
    };
    id: number,
    current_user_id: number,
    account_id: number,
    you_also_following_to_him: boolean,
    created_at: string,
    account: {
      data: {
        id: number,
        type: string,
        attributes: {
          full_name: string,
          profile_image: string,
        }
      }
    }
  },
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean;
  followings: FollowingData[];
  totalCount: number;
  currentPage: number;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FollowingController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  FollowersApiCallId: string = "";
  unfollowUserAPI: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loader: false,
      followings: [],
      totalCount: 0,
      currentPage: 1,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.FollowersApiCallId) {
          this.setState({
            followings: responseJson.followings.data,
            totalCount: responseJson.followings.meta.pagination.total_count,
            loader: false
          })
        }
        if (apiRequestCallId === this.unfollowUserAPI) {
          this.setState({ loader: false })
          this.getFollowingAPICall();
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getFollowingAPICall();
  }

  getFollowingAPICall = async () => {
    this.setState({ loader: true })
    let token = await getStorageData("authToken", false);
    let SignUpID = await getStorageData("SignUpID", false);
    SignUpID = JSON.parse(SignUpID)
    const headers = {
      token: token,
      "Content-Type": configJSON.validationApiContentType
    };

    const getFollowingMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FollowersApiCallId = getFollowingMsg.messageId;

    getFollowingMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FollowingAPIEndPoint + `?account_id=${SignUpID}&per_page=${5}&page=${this.state.currentPage}`
    );

    getFollowingMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getFollowingMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getFollowingMsg.id, getFollowingMsg);
  }

  handlePageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage }, () => {
      this.getFollowingAPICall();
    });
  }

  goToHome = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MyProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }
  

  unFollowUser = async (userData: { attributes: { current_user_id: number } }) => {
    this.setState({ loader: true })
    let token = await getStorageData("authToken", false);

    const payload = {
      "follow_user_id": userData.attributes.current_user_id
    }

    const headers = {
      token: token,
      "Content-Type": configJSON.validationApiContentType
    };

    const getFollowersMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FollowUserAPIEndPoint
    );

    this.unfollowUserAPI = getFollowersMsg.messageId;
    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(getFollowersMsg.id, getFollowersMsg);
  }
  // Customizable Area End

}
