// Customizable Area Start
import React from "react";
import {
    Grid,
    Typography,
    Divider,
    Paper,
    Button,
    Input,
    FormHelperText,
    Box,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import { NavigateNext } from "@material-ui/icons";
import ViewPostController, { Props } from "./ViewPostController";
import { configJSON } from "./AnalyticsController";
import { webStyles } from "../../../components/src/styles";

export default class SafetyCouncilForm extends ViewPostController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const {
            actionPostData,
            loading,
            correctiveAction,
            preventiveAction,
        } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Grid style={webStyle.mainContainer}>
                    <Box style={webStyles.breadcrumbWrapper}>
                        <Typography data-test-id="council-text" style={webStyles.breadcrumbItem} onClick={() => this.redirectToSafetyCouncil()}>{configJSON.safetyCouncilText}</Typography>
                        <NavigateNext style={webStyles.navigateIcon} />
                        <Typography style={webStyles.breadcrumbActiveItem}>{configJSON.FormText}</Typography>
                    </Box>
                    <Typography style={webStyle.headerText}>{configJSON.FormText}</Typography>
                    {!loading && (
                        <Paper style={webStyle.papers}>
                            <Grid container spacing={3}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.followDetailsBox}>
                                        <Typography style={webStyle.label2}>{configJSON.StatutoryConcernText}</Typography>
                                        <Divider variant="inset" component="div" style={webStyle.noMargin} />
                                        <Typography style={webStyle.details as React.CSSProperties}>{actionPostData?.attributes.statutory_regulatory_legal_concern || "No Data"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.followDetailsBox}>
                                        <Typography style={webStyle.label2}>{configJSON.StandardComplianceText}</Typography>
                                        <Divider variant="inset" component="div" style={webStyle.noMargin} />
                                        <Typography style={webStyle.details as React.CSSProperties}>{actionPostData?.attributes.standard_sop_compliance || "No Data"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.followDetailsBox}>
                                        <Typography style={webStyle.label2}>{configJSON.NoViolationText}</Typography>
                                        <Divider variant="inset" component="div" style={webStyle.noMargin} />
                                        <Typography style={webStyle.details as React.CSSProperties}>{actionPostData?.attributes.no_go_parameter_violation || "No Data"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.followDetailsBox}>
                                        <Typography style={webStyle.label2}>{configJSON.SafetyAbsoluteViolationText}</Typography>
                                        <Divider variant="inset" component="div" style={webStyle.noMargin} />
                                        <Typography style={webStyle.details as React.CSSProperties}>{actionPostData?.attributes.safety_absolute_violation || "No Data"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.followDetailsBox}>
                                        <Typography style={webStyle.label2}>{configJSON.CorrectiveActionText}</Typography>
                                        <Divider variant="inset" component="div" style={webStyle.noMargin} />
                                        <Typography style={webStyle.details as React.CSSProperties}>{correctiveAction || "No Data"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.followDetailsBox}>
                                        <Typography style={webStyle.label2}>{configJSON.PreventiveActionText}</Typography>
                                        <Divider variant="inset" component="div" style={webStyle.noMargin} />
                                        <Typography style={webStyle.details as React.CSSProperties}>{preventiveAction || "No Data"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormHelperText style={webStyle.RiskCountlabel}>
                                                {configJSON.RiskCountText}
                                            </FormHelperText>
                                            <Input
                                                fullWidth={true}
                                                placeholder={configJSON.RiskCountText}
                                                disableUnderline
                                                name="risk_count"
                                                disabled
                                                value={actionPostData?.attributes.risk_count}
                                                data-test-id="risk_count"
                                                style={webStyle.labelStyle}
                                                inputMode="numeric"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    <div style={webStyle.btnBox}>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={webStyle.nextBtn as React.CSSProperties}
                            data-test-id="next"
                            onClick={() => this.props.navigation.navigate("SafetyCouncilAssignForm")}
                        >
                            {configJSON.NextBtnText}
                        </Button>
                    </div>
                </Grid>
                <Loader loading={this.state.loading} />
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    noMargin: {
        margin: '0px'
    },
    headerTexts: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#A1A1A1'
    },
    headerIcon: {
        fill: '#DCDCDC'
    },
    mainContainer: {
        padding: "30px",
        background: "rgb(238, 238, 238)"
    },
    RiskCountlabel: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#18181E',
        marginBottom: '8px',
        marginTop: '0px'
    },
    details: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#828282',
        padding: '15px',
        maxHeight: '50px',
        overflowY: 'scroll',
    },
    label2: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#2B2A29',
        padding: '15px'
    },
    labelStyle: {
        border: "1px solid #DCDCDC",
        borderRadius: "8px",
        fontFamily: "ClotherRegular",
        padding: "7px",
    },
    followDetailsBox: {
        minHeight: '149px',
        borderRadius: '8px',
        border: '1px solid #DCDCDC',
    },
    nextBtn: {
        minWidth: "160px",
        height: "50px",
        backgroundColor: "#8735E1",
        textTransform: "none",
        color: "white",
        border: "1px solid #8735E1",
        padding: '10px',
        borderRadius: "8px",
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '22px',
    },
    btnBox: {
        padding: "30px 0px",
        display: "flex",
        justifyContent: "flex-end",
    },
    papers: {
        height: "auto",
        padding: "30px",
        background: "#fff",
        minHeight: "80vh",
        marginTop: '10px'
    },
    headerText: {
        fontFamily: 'ClotherBold',
        fontSize: '24px',
        lineHeight: '24px',
        color: '#2B2A29',
        padding: '10px 0px'
    },
    currentPageText: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        color: '#8735E1',
        marginLeft: '2px',
    },
    headers: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#18181E',
        margin: '0px',
    },
};
