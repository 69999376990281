import { Box, Dialog, useMediaQuery, useTheme, Typography, Button } from '@material-ui/core';
import React from 'react';
import { congrats, heartHands, spots, star } from './assets';
import { AccountTile } from './AccountTile';
import { webStyles } from './styles';

export const CongratsModal = ({ contribPoints='80', onClose, userName } : { contribPoints?: string, onClose?: () => void, userName: string}) => {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleClose = () => {
      setOpen(false);
      onClose?.();
    };

    return (
        <Dialog
            data-id='congrats-dialog'
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}            
            >
            <Box display='flex' flexDirection='column' justifyContent='space-between' style={webStylesLocal.dialogContainer}>
                <Box textAlign='center' fontFamily='ClotherRegular' mt={5} >
                    <img src={congrats} alt='congrats'/>
                    <h3 style={webStylesLocal.noteFont}>Congrats {userName}!</h3>
                    <Typography style={webStylesLocal.noteFont}>
                        {contribPoints} contribution points earned for creating New Post
                    </Typography>
                    <AccountTile
                        name='Contribution' 
                        bottomContent={
                            <div style={webStyles.bottomContent}>
                            <span style={webStyles.bottomSpan}>Level Coming Soon</span>
                            <img src={star} alt='star' style={webStyles.starImg} />
                            </div>
                        }
                        img={heartHands}
                        addTop={
                            <div>
                            <span style={webStyles.addTop as React.CSSProperties}>Coming Soon</span>
                            <span>Points</span>
                            </div>
                        } 
                        addBottom={
                            <div style={webStyles.addBottomCont}>
                            <span style={webStyles.addBottom as React.CSSProperties}>Need</span>
                            <span style={webStyles.addBottomHigh as React.CSSProperties}>Coming Soon</span>
                            <span style={webStyles.addBottom as React.CSSProperties}>For Level Coming Soon</span>
                            </div>
                        }
                        extended
                        customStyles={{
                            background: '#FAF3FD',
                            border: '1px solid var(--Purple, #8735E1)',
                            height: 'auto'
                        }} />
                </Box>
                <Box display='flex' gridGap='1rem' flexDirection='column'>
                    <Button style={
                            {...webStyles.fullButton, ...webStylesLocal.button} as React.CSSProperties
                        } onClick={handleClose}>
                        Add risk details and earn 50 points
                    </Button>
                    <Button style={
                            {...webStyles.transButton, ...webStylesLocal.button} as React.CSSProperties
                        } onClick={handleClose}>
                        Back to Home
                    </Button>
                </Box>
            </Box>
        </Dialog>)
};

const webStylesLocal = {
    dialogContainer: {
        minWidth: '32rem',
        minHeight: '36.5rem',
        padding: '1.9rem',
        borderRadius: '0.5rem',
        background: `url(${spots}) no-repeat`,
    },
    noteFont: {
        fontSize: '1.25rem',
        marginBottom: '1.25rem'
    },
    button: {
        width: '100%',
        height: '3.4rem',
        fontSize: '1.25rem',
        fontWeight: 700,
        textTransform: 'none'
    }
}