// Customizable Area Start
//@ts-nocheck
import React from "react";

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Avatar,
} from "@material-ui/core";

import { locationIcon, clockIcon, profileIcon, defaultPostImage } from "./assets";

import ViewPostController, { Props } from "./ViewPostController";
import { Wrapper } from "./styles";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import Breadcrumb from "../../../components/src/Breadcrumb.web";

export default class ViewPost extends ViewPostController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { post, breadcrumbData, notificationBreadcrumbData } = this.state;
    console.log(post, 'post')
    return (
      <Grid container>
        <Wrapper>
          <Container maxWidth={"xl"}>
            {
              this.state.From === "notifications" ?
                <Breadcrumb data={notificationBreadcrumbData} navigation={this.props.navigation} />
                :
                <>
                  <Breadcrumb data={breadcrumbData} navigation={this.props.navigation} />
                  <Box className="myPostsTitle">
                    <Typography className="mainHeading">Post</Typography>
                  </Box>
                </>
            }

            <Box className="card">
              <Box data-test-id="user-profile" className="card_title_wrapper" onClick={() => this.goToUserProfile(post?.account_id)}>
                <Avatar
                  className="Post-profile-img"
                  data-test-id="profile-icon"
                  src={post?.profile_pic || profileIcon}
                  alt="Custom Icon"
                />
                <Typography className="card-post-name">
                  {post?.user_name || "No Name"} created this post
                </Typography>
              </Box>
              <img className="card-image" src={post?.output_image_url ? post?.output_image_url : post?.image_url} alt="post" />
              <Typography className="card-post-detail">
                {post?.description || "No Descrpition"}
              </Typography>

              <Box className="Post-time-detail">
                <Box className="Post-time-inner">
                  <Button className="Floor_btn" style={{ maxWidth: "50%" }}>
                    <img src={locationIcon} alt="location" />
                    <span className="trimWords">{(post?.work_location) || "No Location"}</span>
                  </Button>
                  <Avatar
                    className="Post-inner-profile-img"
                    data-test-id="profile-icon"
                    src={post?.profile_pic || profileIcon}
                    alt="Custom Icon"
                  />
                  <Typography className="Post-time-content">
                    {post?.user_name || "No Name"}
                  </Typography>
                  <img src={clockIcon} alt="time" className="time-icon" />
                  <Typography className="Post-time-content">
                    {getTimeFromNow(post?.created_at)} ago
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!post?.actioned_or_unactioned_value && (
              <Box className="process-wrapper">
                <Button onClick={this.navigateToMyPosts} variant="contained" className="Ignore-btn">
                  Ignore
                </Button>
                <Button
                  data-test-id="proceed-btn"
                  variant="contained"
                  className="process-btn"
                  onClick={this.proceedPostHandler}
                >
                  Proceed
                </Button>
              </Box>
            )
            }
          </Container>
        </Wrapper>
      </Grid>
    );
  }
}

// Customizable Area End
