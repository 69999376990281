import React from "react";
import {
    Box, Button, Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import EmailListDataListingController, {Props, configJSON} from "./EmailListDataListingController";
import { searchIconLogo } from "./assets";
import { AssociatedEmail } from "./types";
export default class EmailListDataListing extends EmailListDataListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    SearchTextField = () => {
        return (
        <input
            type="text"
            value={this.state.searchText}
            onChange={this.onChangeSearch.bind(this)}
            style={{ width: '100%', border: 'none', borderRadius: '60px' }}
            data-test-id="searchTxt"
        />
        )
    }

    SearchButton = () => {
        return (
            <Button onClick={this.onEmailSearch.bind(this)} data-test-id="searchIcon">
                <img src={searchIconLogo} width="40px" height="35px" style={{ borderRadius: '60px' }} data-test-id="searchIconImg"/>
            </Button>
        )
    }

    render() {
        return (
            <>
                <Box style={webStyle.container}>
                    <Box style={webStyle.searchContainer} data-test-id="searchContainer">
                        {this.SearchTextField()}
                        {this.SearchButton()}
                    </Box>
                    <Box sx={webStyle.campaine}>
                    <div data-test-id="mainBox" className="listDiv">
                        {this.state.associatedEmailsList.length === 0 && (
                             <p data-test-id="nodata">{configJSON.NoEmailText}</p>
                        )}
                        {this.state.associatedEmailsList.map((data: AssociatedEmail, index: number) => (
                            <li key={data.id} data-list-id="listItem">
                                    <Typography>
                                        <span data-test-id="fullEmailText">{configJSON.EmailText}:</span><input data-test-id="emailTxt" type="text" value={data.email || data.attributes?.email } onChange={this.handleChangeEmail.bind(this, index)} />
                                    </Typography>
                                    <Typography>
                                    <span data-test-id="fullNameText">{configJSON.FullNameText}</span>: <input data-test-id="nameTxt" type="text" value={data.full_name || data.attributes?.email} onChange={this.handleNameChange.bind(this, index)}/>
                                    </Typography>
                                <Button color={"primary"} onClick={this.updateEmailData.bind(this, index)} data-test-id="editEmailDataBtn">{configJSON.SaveBtnText}</Button>
                                <Button style={{ color: 'red' }} data-test-id='deleteBtnEmailList' onClick={this.onDelete.bind(this, data?.id)}>Delete</Button>
                            </li>)
                        )}
                         <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button variant="contained" onClick={this.handlePreviousPage.bind(this)} disabled={this.state.pageNum === 1} data-test-id="previousBtn">
                                Previous
                            </Button>
                            <span style={{ margin: '0 15px' }}>Page {this.state.pageNum}</span>
                            <Button variant="contained" onClick={this.handleNextPage.bind(this)} disabled={this.state.associatedEmailsList.length < this.state.pageSize} data-test-id="nextBtn">
                                Next
                            </Button>
                        </div>
                    </div>
                    </Box>
                </Box>
            </>
        );
    }
}

const webStyle = {
    container: {
        width: '50%',
        height: 'auto',
        padding: '20px',
        margin: '0 auto',
        borderRadius: '20px',
        backgroundImage: 'linear-gradient(to right, rgb(235,241,245), rgb(235,241,245))',
        marginTop: '100px',
    },
    searchContainer: {
        display: 'flex',
        border: '1px solid black',
        borderColor: 'gray',
        borderRadius: '60px',
        margin: '0 auto',
        width: '90%',
        height: '40px',
        justifyContent: 'space-between',
        marginBottom: '20px',
        alignItem: 'center',
        paddingTop: '2px',
        backgroundColor: 'white',
    },
    campaine: {
        width: '90%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        borderColor: 'gray',
        margin: '0 auto',
    },
};