export const locationIcon = require("../assets/location.png");
export const filterIcon = require("../assets/button_image_filter.png");
export const timeIcon = require("../assets/image_Time.png");
export const postMainImage = require("../assets/post_main_image.png");
export const cancleIcon = require("../assets/crossicon.png");
export const plusIcon = require("../assets/plusIcon.png");
export const trueIcon = require("../assets/view_mark.png");
export const userIcon = require("../assets/image_user.png");
export const SpeackIcon = require("../assets/image_text-to-speak.png");
export const calendarIcon = require('../assets/calanderLogo.png');
export const CompletedIcon = require('../assets/Icon.svg');
export const ActiveFilter = require('../assets/active-filter.png')
export const micStart = require("../assets/mic_Start.png");
export const mic = require("../assets/mic.png");







