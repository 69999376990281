import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { ImageResponse, ImageSaveResponse } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from 'react';
import { Platform } from 'react-native'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  uploadedImageData: ImageResponse;
  tagTextValue: string;
  tagsArray: Array<string>;
  uploadedImageModalVisible: boolean;
  selectedImage: File | string;
  selectedImagePath: string;
  token: string;
  loading: boolean;
  isError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MachinelearningmlimagecategorisationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadImageAndTagsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      uploadedImageData: {base64: "",uri: "",width: 0,height: 0,fileSize: 0,type: '',fileName: '',duration: 0,bitrate: 0,timestamp: '',id: ''},
      tagTextValue: '',
      tagsArray: [],
      uploadedImageModalVisible: false,
      token: '',
      selectedImage: '',
      selectedImagePath: '',
      loading: false,
      isError: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (message.id === getName(MessageEnum.RestAPIResponceMessage) && getName(MessageEnum.RestAPIResponceMessage) === message.id && responseJson) {
      if (apiRequestCallId === this.uploadImageAndTagsApiCallId) {
        this.setState({ loading: false })
        this.uploadImageAndTagsSuccessCallback(responseJson)
      }
    }

    // Customizable Area End
  }


  // Customizable Area Start
async componentDidMount() {
    let token = await getStorageData('Token', false)
    let webtoken = await getStorageData('authToken', false)
    this.setState({ token: Platform.OS == 'web' ? webtoken : token })
  }
  uploadPhoto = () => {
    this.setState({ uploadedImageModalVisible: true })
  }

  closeUploadImageModal = () => {
    this.setState({ uploadedImageModalVisible: false })
  }

  onPressCrossIcon = () => {
    this.setState({ uploadedImageData: {base64: "",uri: "",width: 0,height: 0,fileSize: 0,type: '',fileName: '',duration: 0,bitrate: 0,timestamp: '',id: ''}, tagsArray: [] })
  }

  onChangeTagsText = (text: string) => {
    this.setState({ tagTextValue: text })
  }

  onSubmitEditing = () => {
    if (this.state.tagTextValue != '') {
      this.setState({ tagsArray: [...this.state.tagsArray, this.state.tagTextValue] }, () => {
        this.setState({ tagTextValue: '' })
      })
    }
  }

  onPressTagItemCrossBtn = (tagName: string) => {
    let tempArr = this.state.tagsArray.filter(item => item !== tagName)
    this.setState({ tagsArray: tempArr })
  }

  onPressSubmitBtn = async () => {
    let formdata = new FormData();
    if(Platform.OS == 'web') {
      formdata.append("image", this.state.selectedImage)
      formdata.append("tags", JSON.stringify(this.state.tagsArray));
    } else {
      let imageData: { uri: string | undefined,type: string, name: string } | Blob = {
        uri: this.state.uploadedImageData.uri,
        type: "image/png",
        name: "image"
      }
      formdata.append("image", imageData as unknown as Blob)
      formdata.append("tags", JSON.stringify(this.state.tagsArray));
    }
    this.uploadImageAndTagsApiCallId = await this.apiCall({
      contentType: configJSON.formDataApiMethodType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.uploadImageAndTagsApiEndPoint}`,
      body: formdata,
      type: 'formData'
    });

  }

  onPressSearchBar = () => {
    this.setState({ uploadedImageData: {base64: "",uri: "",width: 0,height: 0,fileSize: 0,type: '',fileName: '',duration: 0,bitrate: 0,timestamp: '',id: ''}, tagsArray: [] })
    const message: Message = new Message(
      getName(MessageEnum.Search)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  uploadImageAndTagsSuccessCallback = (response: ImageSaveResponse) => {
    if (response?.error) {
      this.setState({ isError: true })
    } else if (response?.success) {
      this.setState({ uploadedImageData: {base64: "",uri: "",width: 0,height: 0,fileSize: 0,type: '',fileName: '',duration: 0,bitrate: 0,timestamp: '',id: ''}, tagsArray: [],uploadedImageModalVisible:false }, () => {
        alert(response.success[0].message)
      })
    }
  }

  apiCall = async (data: {"contentType": string, method: string, endPoint : string, body?:object, type?: string}) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = { token: this.state.token,
      'Content-Type': contentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage))
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header))
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method)
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body))
      : 
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body)
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }   
  onUploadPhoto = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const filePath = URL.createObjectURL(file);
      this.setState({
        selectedImagePath: filePath,
        selectedImage: file,
      });
    }
  }

  removeImage = () => {
    this.setState({
      selectedImage: '',
      isError: false
    });
  };

  handleTagsText = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ tagTextValue: event.target.value })
  }
  // Customizable Area End
}
