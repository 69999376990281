import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  activeStep: () => void;
  activeStepState: number;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  openModal: boolean;
  accountData: any;
  // Customizable Area End
}
interface SS {}

export default class CongratsModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAccountDataAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      openModal: true,
      accountData: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAccountInfoDetailAPI();
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAccountDataAPICallID) {
      this.setState({
        accountData: responseJson?.data || {},
      });
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleClose = () => {
    this.setState({ openModal: false });
  };

  handleNext = () => {
    this.props.activeStep();
    this.handleClose();
    if (this.props.activeStepState === 4) {
      this.props.navigation.navigate("MyProfile");
      window.location.reload();
    }
  };
  getAccountInfoDetailAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${SignUpID}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getAccountDataAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  // Customizable Area End
}
