import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  CircularProgress
  // Customizable Area Start
  ,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { AssessmentDetailCard, AssessmentDetailheadingWrapper, AssessmentDetailOverview } from "../../../../components/src/SidebarMenu.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import AssessmentDetailController, {
  Props,
} from "./AssessmentDetailController";

export default class AssessmentDetail extends AssessmentDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area End

  renderAssessmentDetailWebLoader() {
    if (this.state.isLoading) return (
      <Box
        data-test-id={"loaderContainer"}
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }



  renderAssessmentDetailWebError() {
    if (this.state.isDetailError) return (

      <Box
        className=""
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          height: '100vh',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',

        }}
      >
        <Typography
          variant="subtitle1"
          component="h1"
          className=""
          data-test-id={"errorMessageId"}
        >
          Something went wrong, please try again {/*UI Engine::From Sketch*/}
        </Typography>
        <Button
          color="primary"
          style={{
            backgroundColor: '#210042'
          }}
          data-test-id={"retryButtonId"}
          variant="contained"

          onClick={() => this.onReFetch()}
        >
          Retry

        </Button>
      </Box>
    )
  }



  renderContent() {
    if (this.state.isSuccess) return (
      <ThemeProvider
        theme={theme}>

        <Box
          data-test-id={"AssessmentDetailContainer"}
          style={{
            position: "relative",
            padding: "12px 30px",
            background: "rgb(37, 0, 65)",
            color: "rgb(255, 255, 255)",
          }}>
          <Typography
            style={{
              fontSize: '1.3rem'
            }}
            variant="subtitle1" component="h2">
          </Typography>
        </Box>
        <Container
          style={{
            minHeight: "100vh",
            paddingTop: "1rem",
            padding: "2rem",
          }}
        >
          <Box sx={{
            ...webStyle.card
          }}>
            <Box
              style={{
                position: "relative",
                height: "250px",
                background: "rgb(81, 81, 81)",
                overflow: "hidden",
                borderRadius: "6px",
              }}
            >
              <img
                style={{
                  width: "100%"
                }}
                src={this.state.itemContent.attributes?.image} alt="" />
            </Box>
            <Box >

              <Typography
                data-test-id={"assessmentName"}
                style={{
                  marginBottom: '1rem'
                }}
                component="p">
                {this.state.itemContent?.attributes?.name}
              </Typography>

              <Typography
                style={{
                  marginBottom: '1rem'
                }}
                component="p">
                Assessment instruction:
              </Typography>
              <Typography
                style={{
                  marginBottom: '1rem'
                }}
                component="p">
                {this.state.itemContent.attributes?.description}
              </Typography>

              <Typography
                style={{
                  marginBottom: '1rem'
                }}
                
                component="p">
                Total Question:
                {this.state.itemContent?.attributes?.number_of_questions}
              </Typography>
              <Typography
                style={{
                  marginBottom: '1rem'
                }}
                component="p">
                {this.convertMinutesToHoursAndMinutes(this.state.itemContent.attributes?.duration ? this.state.itemContent.attributes?.duration : 0)}
              </Typography>
              <Button
                data-test-id={"startTestBtn"}
                style={{
                  backgroundColor: '#210042'
                }}
                variant="contained"
                color="primary"
                onClick={() => this.startAssessment()}
              >
                Start Test {/*UI Engine::From Sketch*/}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
  // Customizable Area End


  render() {
    // Customizable Area Start

    const { Courses } = this.state;
    return (
      <>
        <Grid container>
          <Wrapper>
            <AssessmentDetailheadingWrapper
              Courses={Courses}
              navigateToAssessmentTestScreen={this.navigateToAssessmentTestScreen}
            />

            <Box className="mainBox">
              <AssessmentDetailCard
                Courses={Courses}
                navigateToAssessmentQuestionScreen = {this.navigateToAssessmentQuestionScreen}
              />

              <Grid style={{marginTop:"18px"}}>
                <AssessmentDetailOverview
                  Courses={Courses}
                />
              </Grid>
            </Box>
          </Wrapper>
        </Grid>
      </>
    )
    // Customizable Area End

  }
}

// Customizable Area Start
const webStyle = {
  card: {
    position: "relative",
    overflow: "hidden",
    padding: "12px",
    width: "50%",
    background: "linear-gradient(60deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6))",
    borderRadius: "8px",
    color: " rgb(255, 255, 255)",
    boxShadow: " 5px 5px 15px rgb(43, 43, 43)",

  }

};

export const Wrapper = styled(Box)({
  padding: "30px",
  width: "100%",
  backgroundColor: "rgb(238, 238, 238)",
  height: "100%",
  position: "relative",
  boxSizing: "border-box",
  "& .learningHeadingWrapper": {
    display: "flex",
    gap: "10px",
    marginTop: '10px'
  },
  "& .courseCard": {
    width: "100%",
  },

  "& .learningSubHeadingText": {
    color: 'gray',
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "21px",
    fontFamily: "ClotherRegular",
  },
  "& .cardMainHeading": {
    fontSize: "16px",
    fontFamily: "ClotherBold",
  },
  "& .mainBox": {
    backgroundColor: 'white',
    maxWidth: "100%",
    padding: "16px"
  },
  "& .courseCardStyle": {
    cursor: "pointer",
    background: "#fff",
    border: "1px solid #DCDCDC",
    boxShadow: "none",
    borderRadius: "8px",
    display: 'flex',
    padding: "22px 17px 20px 17px",
    marginBottom: "12px",
    '& .courseCard': {
      padding: '0px',
    },
    "& .MuiCardMedia-img": {

      flex: "1 0 25%",
      maxWidth: "25%",
      borderRadius: "8px",
      height: "230px"
    }
  },
  "& .cardContentMainBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0px',
    },
    '&:last-child p': {
      marginBottom: '0px',
    }
  },
  "& .courseCardMainHeading": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "ClotherRegular",
    color: "#2B2A29",
    display: "flex",
    alignItems: "center"
  },
  "& .coursecarddetail": {
    display: "flex",
    color: "#A1A1A1",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "ClotherRegular",
  },
  "& .mainHeading": {
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "25px",
    fontFamily: "ClotherBold",
    width: "300px",
  },
  "& .cardDescription": {
    fontFamily: "ClotherRegular",
    color: "#828282",
    fontSize: "18px",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    lineHeight: "24px",
    marginBottom: "4px",
    fontWeight: '400',
  },
  "& .trimWords": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%"
  },
  "& .imageAndText": {
    gap: "5px",
    display: "flex",
    padding: "5px 10px",
    marginTop: "8px",
    alignItems: "center",
    paddingLeft: "0"
  },
  "& .lessons_count": {
    "& .cardSubText": {
      borderRight: "1px solid #EEEEEE",
      paddingRight: "10px",


    }
  },
  "& .firetext": {
    backgroundColor: "#F0E5FF",
    padding: "8px 14px",
    fontSize: '14px !important',
    fontWeight: 400,
    color: "#8735E1 !important",
    borderRadius: "25px",

  },
  "& .firewrapper": {
    display: "flex",
    gap: "5px"
  },

  "& .cardSubText": {
    fontFamily: "ClotherRegular",
    color: "#A1A1A1",
    fontSize: "18px",
    lineHeight: "1.2"
  },
  "& .loader-inner": {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "40%",
    left: "50%",
  },
  "& .loader-outer": {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "100%",
    minHeight: "100vh",
    top: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: "rgb(0, 0, 0, .4)",
  },
 "& .subHeadingText": {
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    lineHeight: "22px",
    color: '#A1A1A1',
    fontWeight: "600",
    cursor: "pointer",
  },
  "& .headingWrapper": {
    display: "flex",
  },
  "& .addCheckpointText": {
    color: '#2B2A29',
    fontSize: '20px',
    fontWeight: 400,
    fontFamily: 'ClotherBold',
  },
  "& .addCheckpointButtonStyle": {
    border: "1px solid #8735E1",
    textTransform: "none",
    padding: "20px 12px",
    fontSize: "16px",
    fontWeight: 700,
    width: "195px",
    height: "30px",
    color: "#8735E1",
    borderRadius: "8px",
    fontFamily: "ClotherBold",
    "@media(max-width:601px)": {
      lineHeight: "15px",
      height: "45px"
    },
  },
  "& .activeHeadingText": {
    lineHeight: "22px",
    color: "#8735E1",
    fontSize: "14px",
    fontFamily: "ClotherBold",
    cursor: "pointer",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  "& .navigateNext": {
    color: "#DCDCDC",
  },
  "& .CardContent": {

    flex: "1 0 75%",
    maxWidth: "75%",
    paddingTop: "0"
  },
  "& .LinearProgress": {
    maxWidth: "40%",
    margin: "10px",
    display: "flex",
    alignItems: 'center',
  },
  "& .completed-text": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#8735E1"
  },
  "& .LinearProgressbox": {
    width: 'calc(100% - 130px)',
    paddingRight: '9px',
  },
  "& .resume-btn": {
    color: "#8735E1",
    fontWeight: 700,
    fontSize: "18px",
    border: "1px solid #8735E1",
    borderRadius: "8px",
    width: "225px",
    textTransform: "capitalize",
  },
  "& .course-detail": {
    fontSize: "22px",
    fontweight: 400,
    marginBottom: "10px"
  },
  "& .analyticTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0px",
  },
});
// Customizable Area End
