import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Chip,
  TextField,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import { closeIcon, uploadImageIcon } from "./assets";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import MachinelearningmlimagecategorisationController, {
  Props,
  configJSON,
} from "./MachinelearningmlimagecategorisationController";

export default class Machinelearningmlimagecategorisation extends MachinelearningmlimagecategorisationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"}>
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.topBackHearder}>
              <Box sx={webStyle.ArrowBackIcon}><ArrowBackIcon /></Box>
              <Box sx={webStyle.ImgText}>{configJSON.labelTitleText}</Box>
              <Box sx={webStyle.topMlauto}></Box>
            </Box>
            <Box sx={webStyle.topHeader}>
              <TextField
                data-test-id="search-tags"
                id="outlined-multiline-flexible"
                onFocus={this.onPressSearchBar}
                placeholder="Search..."
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>,
                }}
              />
            </Box>
            {this.state.selectedImage ?
              <Box sx={webStyle.selectedImage}>
                <img className="fullImg" src={this.state.selectedImagePath} />
                <img
                  id="removeImage"
                  className="closeBtn"
                  src={closeIcon}
                  alt="close"
                  onClick={this.removeImage}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '20px'
                  }}
                />
              </Box> :
              <Button style={{ width: '100%' }} component="label">
                <Box sx={webStyle.uploadImgBox}>
                  <Box sx={webStyle.uploadImage}>
                    <img src={uploadImageIcon} alt="upload image" />
                    <input
                      data-id='upldFileId'
                      type="file"
                      id="user-image"
                      name="user-image"
                      accept="image/png, image/jpeg"
                      onChange={this.onUploadPhoto}
                    />
                    {configJSON.labelUploadImage}
                  </Box>
                </Box>

              </Button>
            }
            {this.state.selectedImage && <Box sx={webStyle.selectedImageTag}>
              <Typography variant="h6">{configJSON.tags}</Typography>
              <div style={{ marginTop: '10px' }}>
                {this.state.tagsArray?.map((tagValue, index) => (
                  <Chip
                    data-test-id="image-tags"
                    key={index}
                    label={tagValue}
                    onDelete={() => this.onPressTagItemCrossBtn(tagValue)}
                    style={{ margin: '5px' }}
                  />
                ))}
              </div>
              <Typography variant="h5">{configJSON.addTagText}</Typography>
              <Box className="textFildsMain">
                <Box className="textFilds-tag">
                  <TextField
                    value={this.state.tagTextValue}
                    data-test-id="add-tags"
                    id="outlined-multiline-flexible"
                    onChange={this.handleTagsText}
                    InputProps={{
                      endAdornment: <InputAdornment onClick={this.onSubmitEditing} position="end">
                        <ArrowForwardIosIcon />
                      </InputAdornment>,
                    }}
                  />
                </Box>
                <Button id="submit-data" onClick={this.onPressSubmitBtn}>Submit</Button>
              </Box>
            </Box>}
            {this.state.loading && <Box data-test-id={"activityLoader"} sx={webStyle.loaderWrapper}>
              <Box sx={webStyle.circularContainer}>
                <CircularProgress />
              </Box>
            </Box>}
            {this.state.isError && <Typography style={webStyle.errorStyle}>There must be alteast 1 tag.</Typography>}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  topBackHearder: {
    borderBottom: 'solid 1px #cbd5d5',
    width: '100%',
    textAlign: 'center',
    fontSize: '22px',
    color: '#000',
    fontWeight: ' 700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px'
  },
  errorStyle: {
    color: 'red',
    marginTop: '25px',
    fontSize: '18px'
  },
  topHeader: {
    padding: '10px 15px',
    width: '100%',
    position: 'relative',
    '& .MuiFormControl-root.MuiTextField-root': {
      width: '100%',
      paddingBottom: '20px',
      marginBottom: '20px',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: '0px',
    },
    '& svg': {
      position: 'absolute',
      top: '8px',
      left: '16px',
      width: '20px',
    },
    '& input': {
      border: 'none',
      width: '100%',
      backgroundColor: '#a3a4a6',
      padding: '10px 15px',
      borderRadius: '4px',
      fontSize: '16px',
      color: '#000',
      paddingLeft: '35px',
    },
  },

  uploadImgBox: {
    width: '100%',
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    border: 'dashed 2px #c9c9c9',
  },
  uploadImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    color: '#000',
    fontSize: '14px'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  topMlauto: {
    marginLeft: 'auto',
  },
  ImgText: {
    margin: '0 auto',
  },
  ArrowBackIcon: {
    marginRight: 'auto'
  },
  selectedImage: {
    width: "100%",
    position: 'relative',
    marginBottom: '30px',
    border: 'solid 4px #afafaf',
    boxSizing: 'border-box',
    minHeight: '340px',
    '& .fullImg': {
      maxWidth: '100%',
    },
    '& .closeIcon': {
      maxWidth: '100%',
    },
  },
  loaderWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  selectedImageTag: {
    width: "100%",
    textAlign: 'center',
    '& h6': {
      width: "100%",
      fontSize: '20px',
      fontWeight: '600',
    },
    '& h5': {
      width: "100%",
      fontSize: '18px',
      fontWeight: '500',
    },
    '& button': {
      minWidth: "100px",
      fontSize: '15px',
      fontWeight: '500',
      backgroundColor: '#000 !important',
      color: '#fff',
      textAlign: 'center',
      padding: '8px 15px',
      border: 'none',
      borderRadius: '6px',
      marginLeft: '17px',
      // marginTop: '8px',
    },
    '& .textFildsMain': {
      maxWidth: "530px",
      margin: "0 auto",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '23px',
    },
    '& .textFilds-tag': {
      backgroundColor: '#e0e0e0',
      border: 'solid 1px #b3b3b3',
      padding: '0px 10px 6px 10px',
      borderRadius: '10px',
    },
    '& .textFilds-tag .MuiInputBase-root': {
      marginTop: '12px',
    },

  },
};
// Customizable Area End