export const getTimeFromNow = (timeBeforeNow: string | undefined): string | undefined => {
        if (timeBeforeNow) {
        const secondsNumber = new Date().valueOf() - new Date(timeBeforeNow).valueOf();

        const secondsFromNow = Math.round(secondsNumber / 1000);
        const minutesFromNow = Math.round(secondsFromNow / 60);
        const hoursFromNow = Math.round(minutesFromNow / 60);
        const daysFromNow = Math.round(hoursFromNow / 24);

        const seconds = secondsFromNow < 60 && `${secondsFromNow} seconds`;
        const minutes = minutesFromNow < 60 && `${minutesFromNow} minutes`;
        const hours = hoursFromNow < 60 && `${hoursFromNow} hours`;
        const days = hoursFromNow > 24 && `${daysFromNow} days`;

        return seconds || minutes || hours || days || '';
    }
}
