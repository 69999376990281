//@ts-nocheck
import { LogLevel } from "@azure/msal-browser";
import AADServerParamKeys from '@azure/msal-browser';

export const b2cPolicies:any = {
    names: {
        signUpSignIn: 'B2C_1_susi_v2',
        forgotPassword: 'B2C_1_reset_v3',
        editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://apollohseapp-299776-react.b299776.dev.eastus.az.svc.builder.cafe',
        },
        forgotPassword: {
            authority: 'https://apollohseapp-299776-react.b299776.dev.eastus.az.svc.builder.cafe',
        },
        editProfile: {
            authority: 'https://apollohseapp-299776-react.b299776.dev.eastus.az.svc.builder.cafe',
        },
    },
    authorityDomain: 'https://apollohseapp-299776-react.b299776.dev.eastus.az.svc.builder.cafe',
};



export const msalConfig:any = {
    auth: {
        clientId: "a3fe40dd-330e-4e40-8745-7cf86f9f78ef",
        authority: "https://login.microsoftonline.com/f7920029-39f9-41b7-9c94-ec73395d184d", 
        redirectUri: "https://apollohseapp-299776-react.b299776.dev.eastus.az.svc.builder.cafe", 
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources:any = {
    apiTodoList: {
        endpoint: 'https://apollohseapp-299776-react.b299776.dev.eastus.az.svc.builder.cafe',
        scopes: {
            read: ['https://apollohseapp-299776-react.b299776.dev.eastus.az.svc.builder.cafe'],
            write: ['https://apollohseapp-299776-react.b299776.dev.eastus.az.svc.builder.cafe'],
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

export const loginRequest = {
    scopes:['user.read'],
    // scopes: [...protectedResources.apiTodoList.scopes.read, ...protectedResources.apiTodoList.scopes.write],
};