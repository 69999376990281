Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "karmapoints2";
exports.labelBodyText = "karmapoints2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.ProfileText = "Profile";
exports.RewardsText = "Rewards";
exports.ContributionPointsText = "Your Contribution Points";
exports.NeedText = "Need";
exports.PointsLevelText = "points for level 20";
exports.RedeemPointsText = "Redeem Points";
exports.TopContributorsText = "Top Contributors";
exports.pointsText = "points";
exports.MyBadgesText = "My Badges";
exports.ViewAllText = "View All";
exports.SprinterText = "Sprinter";
exports.TeamPlayerText = "Team Player";
exports.WarriorText = "Warrior";
exports.HighAchieverText = "High Achiever";
exports.AvidLearnerText = "Avid Learner";
exports.NewcomerText = "Newcomer";
exports.PointsHistoryText = "Points History";
exports.reportedConcernText = "Reported a Fire Concern";
exports.RedemptionHistoryText = "Redemption History";
exports.BULevelText = "BU Level";
exports.CountryLevelText = "Country Level";
exports.AmazonVoucherText = "Amazon Voucher";
// Customizable Area End