import { Typography } from "@material-ui/core";
import React from "react";

const errorMessageStyle = {
  color: "#F30202",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "18px",
  marginBottom: "18px",
};

export default function errorMessage(props: { error: string }) {
  if (!props.error) {
    return null;
  }

  return <Typography style={errorMessageStyle}>{props.error}</Typography>;
}
