import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Modal,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  withStyles,
  Input,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DeletePopUp, OTPAuth, SadFace } from "./assets";
import AccountController, { Props, configJSON } from "./AccoutsController";
import { NavigateNext } from '@material-ui/icons';
import Loader from "../../../components/src/Loader";
import "./Accounts.css";
import { webStyles } from "../../../components/src/styles";

export default class DeleteProfile extends AccountController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Loader loading={this.state.loader} />
        <Grid container style={webStyle.mainContainer}>
          <Grid item lg={12} sm={12}>
            <Box style={{ padding: '30px' }}>
              <Box style={webStyles.breadcrumbWrapper}>
                <Typography style={webStyles.breadcrumbItem} onClick={this.goToAccount.bind(this)} data-test-id="account-text">{configJSON.AccountText}</Typography>
                <NavigateNext style={webStyles.navigateIcon} />
                <Typography style={webStyles.breadcrumbActiveItem} data-test-id="delete-text">{configJSON.DeleteAccountText}</Typography>
              </Box>
              <Typography style={webStyle.accoundHeading} data-test-id="account-delete-header">{configJSON.DeleteAccountText}</Typography>
              <Typography style={webStyle.deleteSureText} data-test-id="sure-text-header">{configJSON.SurelyDeleteText}</Typography>
              <Typography style={webStyle.deleteSubHeader} data-test-id="text-header-note">{configJSON.DeleteAccNote}</Typography>
              <Button data-test-id="delete-button" style={{ ...webStyle.delectButton, textTransform: 'none' }} onClick={this.ProceedToDelete.bind(this)}>Yes, Delete My Account</Button>
              <Modal open={this.state.isVisibleDeleteAccountModal} className="modalBox">
                <CustomBox sx={webStyle.modalStyle}>
                  <Loader loading={this.state.loader} data-test-id="modal-loader" />
                  <CloseIcon data-test-id="closeIcon" style={{ ...webStyle.closeIconStyle, float: "right" }} onClick={this.hideDeleteAccountModal.bind(this)}></CloseIcon>
                  <Box sx={webStyle.modalRowViewStyle}>
                    {
                      this.state.isCodeSent ?
                        <>
                          <Box style={webStyle.IconBg} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <img src={OTPAuth} />
                          </Box>
                          <Typography style={webStyle.DeleteAccountPopUPHeader}>{configJSON.VerificationCodeHeader}</Typography>
                          <Typography align="center" style={webStyle.selectedAccountText}>{configJSON.OtpDigitMsg} <span style={webStyle.emailIdText}>{this.maskText(this.state.selectedEmail, 2, 6)}</span></Typography>
                        </>
                        :
                        <>
                          <Box style={webStyle.IconBg} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <img data-test-id="deletepopup-icon" src={DeletePopUp} />
                          </Box>
                          <Typography style={webStyle.DeleteAccountPopUPHeader}>{configJSON.verifiCationSubHeaderText}</Typography>
                          <Typography align="center" style={!this.state.emailError ? webStyle.chooseAccountText : webStyle.chooseAccountError}>{configJSON.EmailOtionsText}</Typography>
                          {this.state.emailAPiError && (
                            <Typography align="center" style={webStyle.chooseAccountError}>{this.state.EmailErrorMsg}</Typography>
                          )}
                        </>
                    }
                  </Box>
                  {
                    !this.state.isCodeSent ?
                      <List>
                        <ListItem
                          style={this.state.emailIdisSelected ? webStyle.selectedListOption : webStyle.dropdownListContainer}
                          data-test-id="deleteListItem"
                          dense
                          button
                          onClick={this.handleAccounToDelete.bind(this, this.state.accountData)}
                        >
                          <ListItemIcon style={webStyle.radioButtons}>
                            <CustomRadio
                              edge="start"
                              checked={this.state.emailIdisSelected}
                              tabIndex={1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            data-test-id="listItemtext1"
                            id={this.state.accountData.id}
                            style={webStyle.UserAccountName}
                            primary={this.maskText(this.state.accountData.email, 3, 6)}
                          />
                        </ListItem>
                        <ListItem
                          style={this.state.isOfficialIdSelected ? webStyle.selectedListOption : webStyle.dropdownListContainer}
                          data-test-id={"deleteListItem2"}
                          dense
                          button
                          onClick={this.handleOfficialAccounToDelete.bind(this)}
                        >
                          <ListItemIcon style={webStyle.radioButtons}>
                            <CustomRadio
                              edge="start"
                              checked={this.state.isOfficialIdSelected}
                              tabIndex={1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            data-test-id="listItemtext2"
                            id={this.state.accountData.id}
                            style={webStyle.UserAccountName}
                            primary={this.maskText(this.state.accountData.official_email, 3, 6)}
                          />
                        </ListItem>
                      </List> :
                      <Box component={"form"} data-test-id="mainBox" alignItems="center" justifyContent="center" style={webStyle.form}>
                        {this.state.otpValues.map((data: any, index) => (
                          <InputField
                            key={`'opt'${index}`}
                            disableUnderline
                            type="text"
                            value={this.state.otpValues[index]}
                            data-test-id={`otpField${index}`}
                            inputProps={{ maxLength: 1 }}
                            onChange={(event) => this.EnterOtpValues(event, index)}
                            onPaste={this.onPasteOTP}
                          />
                        )
                        )}
                        {this.state.otpError && <Typography style={webStyle.errorText}>{this.state.otpErrorMsg}</Typography>}
                      </Box>
                  }
                  <Box sx={webStyle.modalButtonViewStyle}>
                    <Button
                      style={{ ...webStyle.sendCodeBtn, textTransform: 'none' }}
                      data-test-id="btnHandleRemoveAccounts"
                      variant="contained"
                      color="primary"
                      onClick={this.sendCodeToDeleteAcc.bind(this)}
                    >
                      {this.state.isCodeSent ? "Verify Code" : "Send Code"}
                    </Button>
                  </Box>
                  {(this.state.isCodeSent && !this.state.setTimer) && <Typography style={webStyle.resendCodeBtn} onClick={this.setTimerToSendCode.bind(this)} data-test-id="sendCodeBtn">{configJSON.ResendOTPBtnText}</Typography>}
                  {this.state.setTimer && <Typography style={webStyle.resendText}>{configJSON.ResendText} <span style={webStyle.counterText}>in 0:{this.converNumber(this.state.counter)}</span></Typography>}
                </CustomBox>
              </Modal>

              <Modal open={this.state.confirmationPopUp} className="modalBox">
                <Box sx={webStyle.modalStyle}>
                  <Loader loading={this.state.loader} />
                  <CloseIcon data-test-id="closeIcon2" style={{ ...webStyle.closeIconStyle, float: "right" }} onClick={this.AccountDeleted.bind(this)}></CloseIcon>
                  <Box sx={webStyle.modalRowViewStyle}>
                    <>
                      <Box style={webStyle.IconBg} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <img src={SadFace} />
                      </Box>
                      <Typography style={webStyle.DeleteAccountPopUPHeader}>Code Verified! We are sad to see you go!</Typography>
                      <Typography align="center" style={webStyle.chooseAccountText}>Your account will get permanently deleted in 24hours. Thank you for giving your valuable time to this app.</Typography>
                    </>
                  </Box>
                  <Box sx={webStyle.modalButtonViewStyle}>
                    <Button
                      style={{ ...webStyle.okayButton, textTransform: 'none' }}
                      data-test-id="btnToCloseConfirmPopUp"
                      variant="outlined"
                      color="primary"
                      onClick={this.AccountDeleted.bind(this)}
                    >
                      Okay
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Grid >
        </Grid >
      </>
    );
  }
}

// Customizable Area Start

const webStyle = {
  navigateIcon: {
    fill: '#DCDCDC'
  },
  emailIdText: {
    color: '#2B2A29'
  },
  form: {
    marginLeft: '5px',
    marginBottom: '0px'
  },
  mainContainer: {
    background: 'rgb(237, 238, 238)',
    height: '100%'
  },
  googleStyle: {
    width: '95%'
  },
  errorText: {
    color: 'red',
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    paddingTop: '6px'
  },
  counterText: {
    color: '#18181E'
  },
  selectedListOption: {
    width: 400,
    height: 50,
    border: '1px solid #8735E1',
    color: '#8735E1',
    marginTop: '12px',
    borderRadius: '8px',
    padding: '15px',
  },
  radioButtons: {
    minWidth: '34px',
  },
  closeIconStyle: {
    alignSelf: 'flex-end',
    fill: '#A1A1A1',
    cursor: 'pointer',
  },
  okayButton: {
    fontFamily: "ClotherBold",
    fontSize: "20px",
    lineHeight: "22px",
    width: "400px",
    height: "54px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #8735E1",
    color: "#8735E1",
  },
  resendText: {
    fontFamily: 'ClotherBold',
    fontSize: "18px",
    lineHeight: "16px",
    color: '#8735E1',
    marginBottom: '5px',
  },
  resendCodeBtn: {
    fontFamily: 'ClotherBold',
    fontSize: "18px",
    lineHeight: "16px",
    color: '#8735E1',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  UserAccountName: {
    fontFamily: "ClotherRegular",
    fontSize: "14px",
    color: "#18181E",
    top: '24%',
    left: '13%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    bgcolor: "background.paper",
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
  },
  modalRowViewStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center'
  },
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  dropdownListContainer: {
    width: 400,
    height: 50,
    border: '1px solid #DCDCDC',
    marginTop: '12px',
    borderRadius: '8px',
    padding: '15px',
  },
  accoundHeading: {
    fontFamily: "ClotherBold",
    fontSize: '24px',
    lineHeight: "24px",
    paddingBottom: '11px',
    marginTop: '17px',
  },
  delectButton: {
    width: "260px",
    height: "46px",
    borderRadius: "8px",
    fontFamily: 'ClotherBold',
    fontWeight: 700,
    fontSize: "18px",
    background: "#F30202",
    marginTop: '20px',
    color: "#FFFFFF"
  },
  deleteSubHeader: {
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#828282",
    paddingTop: '16px'
  },
  deleteSureText: {
    fontFamily: "ClotherBold",
    fontSize: "20px",
    lineHeight: "18px",
    paddingTop: '25px'
  },
  currentPage: {
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "14px",
    lineHeight: "22px",
    cursor: 'pointer'
  },
  sendCodeBtn: {
    width: "400px",
    height: "54px",
    padding: "10px",
    borderRadius: "8px",
    background: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    textTransform: 'none',
  },
  selectedAccountText: {
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    fontWeight: 400,
    color: '#A1A1A1',
    width: '352px',
    paddignTop: '15px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  chooseAccountText: {
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    fontWeight: 400,
    color: '#A1A1A1',
    width: '352px',
    paddignTop: '15px',
  },
  chooseAccountError: {
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    fontWeight: 400,
    color: 'red',
    width: '352px',
    paddignTop: '15px',
  },
  DeleteAccountPopUPHeader: {
    fontFamily: 'ClotherBold',
    fontSize: "20px",
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  IconBg: {
    width: "70px",
    height: "30px",
    background: 'rgba(135,53,225,0.1)',
    borderRadius: '50%'
  },
  accountText: {
    cursor: "pointer",
    fontFamily: 'ClotherRegular'
  }
};

const InputField = withStyles({
  root: {
    width: '50px',
    height: '50px',
    marginRight: '15px',
    border: '1px solid #DCDCDC',
    borderRadius: '8px',
    padding: "15px 10px",
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
    '& .PrivateNotchedOutline-root-14': {
      border: '1px solid #DCDCDC',
      borderRadius: '8px',
    },
  }
})(Input)

const CustomRadio = withStyles({
  root: {
    '&.MuiRadio-root': {
      "&.MuiRadio-colorSecondary.Mui-checked": {
        color: "#8735E1"
      },
      color: '#DCDCDC',
      '& .MuiIconButton-label': {
        width: '10%',
      }
    }
  }
})(Radio)

const CustomBox = withStyles({
  root: {
    '& .MuiTypography-displayBlock': {
      display: 'inline list-item',
    },
  }
})(Box)
// Customizable Area End

