import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface SelectedMachineResponseData {
    data: {
        id: string;
        attributes: SelectedMachineData;
    }[];
    error: string;
    designation: string;
}
interface SelectedMachineData {
    id: string;
    equipment_name?: string;
    equipment_number?: number;
    due_date?: string;
    account_id?: number;
    location_id?: number;
    audit_category_id?: number;
    category?: string;
    location?: string;
}

interface CheckpointDataForEditResponse {
    data: CheckpointDataForEdit;
    error: string;
}
interface CheckpointDataForEdit {
    id?: string;
    attributes: {
        id?: string;
        name: string;
        recommended_check: string;
        inspection_frequency: string;
        action_required: string;
        standard_reference?: null;
        audit_sub_category_id: string;
        referencestandard_id: string;
        reference_image: string;
        sub_category?: string;
        reference_standard?: string;
        unit_of_measurement?: {
            id: number;
            name: string;
        };
        total_target: string;
        compiled: string;
        non_compiled: string;
        target_date: string;
        remark: string;
    }
}

interface SubCategoriesResponse {
    data: SubCategories[];
    error: string;
}

interface SubCategories {
    id: string;
    name: string;
    audit_category_id: string;
}

interface ReferenceStandardResponse{
    data: ReferenceStandard[];
    error: string;
}

interface ReferenceStandard {
    id: string;
    name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    errorMsg: string;
    loading: boolean;
    role: string;
    saveClicked: boolean;
    checkpoint: string;
    selectedInspectionFrequency: string;
    uploadedImagePreview: string | ArrayBuffer | null;
    referenceImage: File | string;
    errorMessageReferenceImage: string;
    recommendedChecks: string;
    actionsRequired: string;
    machineId: string;
    checkpointId: string;
    editCheckpoint: boolean | null;
    disableSaveButton: boolean;
    selectedMachineData: SelectedMachineData;
    checkpointDataForEdit: CheckpointDataForEdit;
    subCategories: SubCategories[];
    selectedSubCategory: string;
    referenceStandard: ReferenceStandard[];
    selectedReferenceStandard: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ManagerCheckpointController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getWorkerMachinesApiCallId: string = "";
    getSubCategoryApiCallId: string = "";
    getReferenceStandardApiCallId: string = "";
    createCheckpointApiCallId: string = "";
    getShowCheckpointForEditApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            errorMsg: "",
            loading: false,
            role: "",
            saveClicked: false,
            disableSaveButton: false,
            checkpoint: "",
            selectedInspectionFrequency: "",
            uploadedImagePreview: "",
            referenceImage: "",
            errorMessageReferenceImage: "",
            recommendedChecks: "",
            actionsRequired: "",
            machineId: "",
            checkpointId: "",
            editCheckpoint: null,
            subCategories: [],
            selectedMachineData: { id: "" },
            checkpointDataForEdit: {
                attributes: {
                    name: "",
                    recommended_check: "",
                    inspection_frequency: "",
                    action_required: "",
                    audit_sub_category_id: "",
                    referencestandard_id: "",
                    total_target: "",
                    compiled: "",
                    non_compiled: "",
                    target_date: "",
                    remark: "",
                    reference_image: ""
                }
            },
            referenceStandard: [],
            selectedSubCategory: "",
            selectedReferenceStandard: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getWorkerMachinesApiCallId) {
                this.handleMachinesApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getSubCategoryApiCallId) {
                this.handleSubCategoryApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getReferenceStandardApiCallId) {
                this.handleReferenceStandardApiResponse(responseJson)
            }
            if (apiRequestCallId === this.createCheckpointApiCallId) {
                this.handleCreateCheckpointdApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getShowCheckpointForEditApiCallId) {
                this.handleShowCheckpointForEditApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        let machineId = await getStorageData("machineid", false);
        let checkpointId = await getStorageData("checkpointdetailid", false);
        let editCheckpoint = await getStorageData("editcheckpoint", false);
        this.setState({ token, machineId, checkpointId, editCheckpoint }, () => {
            this.getWorkerMachinesForManagerApiCall();
            if (this.state.editCheckpoint !== null) {
                this.getShowCheckpointForEditApiCall();
            }
        });
        this.getReferenceStandardApiCall();
    }

    handleShowCheckpointForEditApiResponse = (responseJson: CheckpointDataForEditResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                loading: false,
                checkpoint: responseJson.data.attributes.name,
                selectedInspectionFrequency: responseJson.data.attributes.inspection_frequency,
                uploadedImagePreview: responseJson.data.attributes.reference_image,
                referenceImage: responseJson.data.attributes.reference_image,
                recommendedChecks: responseJson.data.attributes.recommended_check,
                actionsRequired: responseJson.data.attributes.action_required,
                selectedSubCategory: responseJson.data.attributes.audit_sub_category_id,
                selectedReferenceStandard: responseJson.data.attributes.referencestandard_id,
            });
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loading: false
            });
        }
    }

    handleCreateCheckpointdApiResponse = (responseJson: { error: string }) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                loading: false,
                disableSaveButton: false,
            });

            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        }
        else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loading: false,
                disableSaveButton: false,
            });
        }
    }

    handleMachinesApiResponse = (responseJson: SelectedMachineResponseData) => {
        if (responseJson && !responseJson.error) {
            const selectedMachine = responseJson.data.filter((value: SelectedMachineData) => {
                return (parseInt(value.id) === parseInt(this.state.machineId))
            })[0]?.attributes;

            this.setState({ selectedMachineData: selectedMachine, role: responseJson.designation }, () => {
                this.getSubCategoryApiCall();
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleSubCategoryApiResponse = (responseJson: SubCategoriesResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ subCategories: responseJson.data })

        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleReferenceStandardApiResponse = (responseJson: ReferenceStandardResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ referenceStandard: responseJson.data })

        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleSaveButton = () => {
        this.setState({ saveClicked: true })

        const { checkpoint, selectedInspectionFrequency, selectedSubCategory, selectedReferenceStandard, referenceImage, recommendedChecks, actionsRequired } = this.state;
        const errorIncheckpoint = !checkpoint;
        const errorInInspectionFrequency = !selectedInspectionFrequency;
        const errorInRecommendedChecks = !recommendedChecks;
        const errorInActionsRequired = !actionsRequired;
        const errorInReferenceImage = !referenceImage;
        const errorInReferenceStandard = !selectedReferenceStandard;
        const errorInSelectedSubCategory = !selectedSubCategory

        if (errorIncheckpoint ||
            errorInInspectionFrequency ||
            errorInRecommendedChecks ||
            errorInActionsRequired ||
            errorInReferenceImage ||
            errorInReferenceStandard ||
            errorInSelectedSubCategory) {
            return;
        }

        this.createCheckpointApiCall()
    }

    createCheckpointApiCall = () => {
        this.setState({
            loading: true,
            disableSaveButton: true
        });
        const headers = {
            token: this.state.token,
        };

        let endPoint = "";
        let methodType = "";

        if (this.state.editCheckpoint !== null) {
            endPoint = `${configJSON.editCheckpointApiEndpoint}?checkpoint_id=${this.state.checkpointId}`;
            methodType = configJSON.patchApiMethodType;
        } else {
            endPoint = `${configJSON.createCheckpointApiEndPoint}?id=${this.state.selectedMachineData?.id}`;
            methodType = configJSON.postApiMethodType;
        }

        let formData = new FormData();
        formData.append("name", this.state.checkpoint);
        formData.append("inspection_frequency", this.state.selectedInspectionFrequency);
        formData.append("recommended_check", this.state.recommendedChecks);
        formData.append("action_required", this.state.actionsRequired);
        formData.append("audit_sub_category_id", this.state.selectedSubCategory);
        formData.append("referencestandard_id", this.state.selectedReferenceStandard);

        if (typeof (this.state.referenceImage) === "object") {
            formData.append("reference_image", this.state.referenceImage);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createCheckpointApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getShowCheckpointForEditApiCall = () => {
        this.setState({ loading: true })
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getAllRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getShowCheckpointForEditApiCallId = getAllRequestMsg.messageId;
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getShowCheckpointApiEndPoint}?id=${this.state.checkpointId}`
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
    }

    getReferenceStandardApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getReferenceStandardRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getReferenceStandardApiCallId = getReferenceStandardRequestMsg.messageId;
        getReferenceStandardRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getReferenceStandardApiEndPoint
        );
        getReferenceStandardRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getReferenceStandardRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getReferenceStandardRequestMsg.id, getReferenceStandardRequestMsg);

    }

    getSubCategoryApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getSubCategoryRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSubCategoryApiCallId = getSubCategoryRequestMsg.messageId;
        getSubCategoryRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSubCategoryApiEndPoint}?id=${this.state.selectedMachineData.audit_category_id}`
        );
        getSubCategoryRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getSubCategoryRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getSubCategoryRequestMsg.id, getSubCategoryRequestMsg);

    }

    getWorkerMachinesForManagerApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json",
        };
        const getWorkerMachinesForManagerRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getWorkerMachinesApiCallId = getWorkerMachinesForManagerRequestMsg.messageId;
        getWorkerMachinesForManagerRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMachinesApiEndPoint
        );
        getWorkerMachinesForManagerRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkerMachinesForManagerRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getWorkerMachinesForManagerRequestMsg.id, getWorkerMachinesForManagerRequestMsg);

    }

    handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = event.target.value as string;
        this.setState({ selectedSubCategory: newValue });
    };
    handleChangeReferenceStandard = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = event.target.value as string;
        this.setState({ selectedReferenceStandard: newValue });
    };

    handleChangeCheckpoint = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        this.setState({ checkpoint: newValue });
    };

    handleChangeInspectionFrequency = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = event.target.value as string;
        this.setState({ selectedInspectionFrequency: newValue });
    };

    handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        const acceptedFormats = ['image/png', 'image/jpeg'];

        if (file) {
            if (acceptedFormats.includes(file.type)) {
                this.setState({ referenceImage: event.target.files?.[0] || "" });
                this.changeImg(event);
                this.setState({ errorMessageReferenceImage: '' });
            } else {
                this.setState({ errorMessageReferenceImage: 'Please select a valid image file (PNG, JPG or JPEG).' });
            }
        }
    }

    changeImg(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            this.setState({ uploadedImagePreview: fileReader.result });

        });
        files?.length && fileReader.readAsDataURL(files[0]);
    }

    handleChangeRecommendedChecks = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        this.setState({ recommendedChecks: newValue });
    };

    handleChangeActionsRequired = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        this.setState({ actionsRequired: newValue });
    };

    handlePreviousButton = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    navigateToAudit = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Audit");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}
