export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const buttonFilter = require("../assets/button-filter.png");
export const Profile = require("../assets/Profile.png");
export const stepLadder = require("../assets/step-ladder.png");
export const Location = require("../assets/location.png");
export const Calender = require("../assets/calender.png");
export const calendarLogo = require("../assets/calendarLogo.png");
export const upload = require("../assets/upload.svg");
export const closeIcon = require("../assets/close-icon.png");
export const micStart = require("../assets/mic_Start.png");
export const mic = require("../assets/mic.png");
export const buttonFilterActive = require("../assets/active-filter.png");