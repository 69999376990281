import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { webStyles } from './styles';

export const AccountTile = ({ onClick, name, bottomContent, img, addTop, addBottom, extended, inRow, customStyles = {} }: {
  name: string,
  bottomContent: string | ReactElement,
  img: string,
  addTop?: ReactElement,
  addBottom?: ReactElement,
  extended?: boolean,
  inRow?: boolean,
  customStyles?: React.CSSProperties,
  onClick?: () => void,
}) =>
  <Box style={{
    ...webStyles.profileBox,
    ...customStyles,
    ...(inRow && {
      display: 'flex',
      justifyContent: 'space-between',
      height: 'auto',
    }),
    ...(extended && webStyles.downTileExtended)
  } as React.CSSProperties}
    onClick={onClick}
  >
    <Box display='flex' justifyContent='space-between'>
      <div style={webStyles.upTile}>
        <img src={img} width='28' height='28' alt='profile preferences icon' />
        <div>{name}</div>
      </div>
      {addTop}
    </Box>
    <Box display='flex' justifyContent='space-between' {...(!inRow && { marginTop: '1.2rem' })}>
      <div style={webStyles.downTile as React.CSSProperties}>
        {bottomContent}
      </div>
      {addBottom}
    </Box>
  </Box>;
