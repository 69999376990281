import { IBlock } from '../../../framework/src/IBlock';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { Message } from '../../../framework/src/Message';

interface IPostlist {
  status: string;
  location1: string;
  location2: string;
  heading: string;
  description: string;
  action_taken?: string;
  id: string;
  }
interface IPostDetails {
  heading: string;
  location1: string;
  location2: string;
  content: string;
  status: string;
  concerned_location: string,
  assigned_by: string,
  assigned_to: string,
  employee_id: string,
  corrective_action_to_take: string,
  assigned_due_date: string,
  remark?: string,
  action_taken?: string,
  manager_is_following_with_you?: string,
  reply?: string,
  your_reply?: string,
  follow_up?: string,
}
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  statusList: string[];
  inputFieldsArray: [{ remarks: string; reply: string; action_taken: string }];
  phase: number;
  managerReply: string;
  postListData: IPostlist[];
  postDetails: IPostDetails;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ActionTrackerControllerForWorker extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getActionTrackerDataCallID: string = "";
  getPostDetailsCallID: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      inputFieldsArray: [{ remarks: '', reply: '', action_taken: '' }],
      statusList: ["Planned", "Cancelled", "Completed", "Not my domain"],
      phase: 1,
      managerReply: 'This is reply from manager for your concern',
      postListData: [],
      postDetails: {
        status: 'overdue',
        location1: 'london',
        location2: 'paris',
        heading: 'My post 1',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        concerned_location: "India",
        assigned_by: "user 1",
        assigned_to: "user 2",
        employee_id: "123456",
        corrective_action_to_take: 'Correct the action',
        assigned_due_date: '2024-01-24'
    }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token });
    }

    const webApiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let webResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (webApiRequestCallId === this.getActionTrackerDataCallID) {
      console.log("Worker post data", webResponseJson)
      let filterData = webResponseJson.action_trackers.data.map((item: any)=>{
        return {
          status: item.attributes.status,
          location1: item.attributes.business_unit_location,
          location2: item.attributes.post.location,
          heading: item.attributes.post.title,
          description: item.attributes.description,
          concerned_location: item.attributes.post.location,
          assigned_by: item.attributes.account.first_name,
          assigned_to: item.attributes.assigned_to,
          corrective_action_to_take: item.attributes.action_type,
          assigned_due_date: item.attributes.due_date,
          id: item.id
      };
    });
    console.log({filterData})
      this.setState({
        postListData: filterData,
        // totalCount:
        //   webResponseJson.action_trackers.meta.pagination?.total_count,
        // loaderData: false,
      });
    } else if(webApiRequestCallId === this.getPostDetailsCallID){
      let {action_type,account, description,post, due_date, status, business_unit_location, remark, action_taken, manager_is_following_with_you, reply, your_reply,assigned_to, follow_up } = webResponseJson.action_tracker.data.attributes;
      let postData = {
        status: status,
        location1: business_unit_location,
        location2: business_unit_location,
        heading: post.title,
        content: description,
        concerned_location: business_unit_location,
        assigned_by: assigned_to,
        assigned_to: assigned_to,
        employee_id: account.employee_id,
        corrective_action_to_take: action_type,
        assigned_due_date: due_date,
        remark,
        action_taken,
        manager_is_following_with_you,
        reply,
        your_reply,
        follow_up,
    }
      this.setState({
        postDetails: postData
      })
    }
  }

  getActionTrackerAPI = (curruntPage: any) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/action_trackers`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getActionTrackerDataCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getPostDetailsAPI = (id: any) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };

    const getPostDetailsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getPostDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/action_trackers/${id}`
    );
    getPostDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPostDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getPostDetailsCallID = getPostDetailsRequestMsg.messageId;
    runEngine.sendMessage(getPostDetailsRequestMsg.id, getPostDetailsRequestMsg);
  };

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getActionTrackerAPI(1);
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  onPostClick = (id: string) => {
      this.getPostDetailsAPI(id)
      this.setState({phase: 2})
  }

  updateBtnClicked = () => {
    this.setState({phase: 3})
  }
  // Customizable Area End
}
