import React from "react";

// Customizable Area Start
import { Box, CircularProgress, Grid, styled, Card, Typography, CardContent } from "@material-ui/core";
import { NavigateNext, Close } from "@material-ui/icons";
import { completed, totalAssignedActions, overdueActions, dueActions } from "./assets";
import { webStyles } from "../../../components/src/styles";
// Customizable Area End

import HsePassportController, {
  Props,
  configJSON,
} from "./HsePassportController.web";
import CustomMessage from "../../../components/src/CustomMessage.web";

export default class HsePassport extends HsePassportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  coursesCountCards = () => {
    const { tableText, coursesCounts } = this.state;
    return (
      <Grid spacing={2} container justifyContent="flex-start" alignItems="stretch">
        <Grid item xs={12} sm={6} md={4}>
          <Card
            data-test-id="total_assigned_courses"
            className={`${tableText === "Total Assigned Courses" ? "selectedCardStyle" : "cardStyle"}`}
            onClick={() => this.handleTableandText("Total Assigned Courses")}
          >
            <CardContent>
              <Box>
                <img
                  className={`${tableText === "Total Assigned Courses" ? "activeIconStyle" : "iconStyle"}`}
                  src={totalAssignedActions}
                />
                <Typography className="cardText1">Total Assigned Courses</Typography>
                <Typography className="cardText2">{coursesCounts?.total_courses_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            data-test-id="completed_courses"
            onClick={() => this.handleTableandText("Completed Courses")}
            className={`${tableText === "Completed Courses" ? "selectedCardStyle" : "cardStyle"}`}
          >
            <CardContent>
              <Box>
                <img
                  className={`${tableText === "Completed Courses" ? "activeIconStyle" : "iconStyle"}`}
                  src={completed}
                />
                <Typography className="cardText1">Completed Courses</Typography>
                <Typography className="cardText2">{coursesCounts?.completed_course_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid >

        <Grid item xs={12} sm={6} md={4}>
          <Card
            onClick={() => this.handleTableandText("Pending Courses")}
            data-test-id="pending_courses"
            className={`${tableText === "Pending Courses" ? "selectedCardStyle" : "cardStyle"}`}
          >
            <CardContent>
              <Box>
                <img
                  className={`${tableText === "Pending Courses" ? "activeIconStyle" : "iconStyle"}`}
                  src={overdueActions}
                />
                <Typography className="cardText1">Pending Courses</Typography>
                <Typography className="cardText2">{coursesCounts?.pending_course_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid >

        <Grid item xs={12} sm={6} md={4}>
          <Card
            data-test-id="total_points_earned"
            onClick={() => this.handleTableandText("Total points earned")}
            className={`${tableText === "Total points earned" ? "selectedCardStyle" : "cardStyle"}`}
          >
            <CardContent>
              <Box>
                <img className={`${tableText === "Total points earned" ? "activeIconStyle" : "iconStyle"}`} src={dueActions} />
                <Typography className="cardText1">Total Points Earned (through courses)</Typography>
                <Typography className="cardText2">0</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid >
      </Grid>
    )
  }

  categoryWiseCourses = () => {
    const columnTitles = [
      {
        id: 1,
        title: "Sr No",
        width: '8%',
      },
      {
        id: 2,
        title: "Category",
        width: '77%',
      },
      {
        id: 3,
        title: "Progress",
        width: '15%',
      }
    ];
    const courses = this.state.categoryWiseCourses;
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} className="analyticTitle analyticFilterTitle" justifyContent="space-between">
          <Typography className="mainHeading">Category wise Courses </Typography>
          {this.state.loadSeeAllView ?
            <Typography className="seeAllStyle" component={"p"} onClick={() => this.closeTableFullViewMode()}><Close /></Typography>
            :
            <Typography className="seeAllStyle" component={"p"} onClick={() => this.openTableFullViewMode(configJSON.categoryWiseCourses)}>See All </Typography>
          }
        </Grid>
        <Grid item className="tableScroll" xs={12}>
          <Box className="tableStyle">
            <Box className="rowStyle">
              {columnTitles.map((item, index) => (
                <Box key={item.id} width={item.width} className={`headerCellStyle ${index == 0 ? ' first' : ''}`}>{item.title}</Box>
              ))}
              <Box className="headerCellStyle last"></Box>
            </Box>
            {courses.length > 0 && courses.map((item: any, index: number) => (
              <Box key={index} className="rowStyle">
                <Box className="cellStyle trimWords">{index + 1 + '.'}</Box>
                <Box className="cellStyle trimWords">{item.category}</Box>
                <Box className="cellStyle trimWords">{item.progress}</Box>
              </Box>
            ))}
          </Box>
        </Grid>
        {courses.length === 0 && this.noDataMessageBox()}
      </Grid>
    )
  }

  pendingCourses = () => {
    const columnTitles = [
      {
        id: 1,
        title: "Sr No",
        width: '8%',
      },
      {
        id: 2,
        title: "Title",
        width: '62%',
      },
      {
        id: 2,
        title: "Category",
        width: '15%',
      },
      {
        id: 3,
        title: "Due Date",
        width: '15%',
      }
    ];
    const pendingCoursesData = this.state.pendingCourses;
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} className="analyticTitle analyticFilterTitle" justifyContent="space-between">
          <Typography className="mainHeading">Pending Courses </Typography>
          {this.state.loadSeeAllView ?
            <Typography className="seeAllStyle" component={"p"} onClick={() => this.closeTableFullViewMode()}><Close /></Typography>
            :
            <Typography component={"p"} className="seeAllStyle" onClick={() => this.openTableFullViewMode(configJSON.pendingCourses)}>See All </Typography>
          }
        </Grid>
        <Grid xs={12} item className="tableScroll">
          <Box className="tableStyle">
            <Box className="rowStyle">
              {columnTitles.map((item, index) => (
                <Box key={item.id} width={item.width} className={`headerCellStyle ${index == 0 ? ' first' : ''}`}>{item.title}</Box>
              ))}
              <Box className="headerCellStyle last"></Box>
            </Box>
            {pendingCoursesData.length > 0 && pendingCoursesData.map((data: any, index: number) => (
              <Box key={index} className="rowStyle">
                <Box className="cellStyle trimWords">{index + 1 + '.'}</Box>
                <Box className="cellStyle trimWords">{data.title}</Box>
                <Box className="cellStyle trimWords">{data.category.join(" , ")}</Box>
                <Box className="cellStyle trimWords">{data.dueDate}</Box>
              </Box>
            ))}
          </Box>
        </Grid>
        {pendingCoursesData.length === 0 && this.noDataMessageBox()}
      </Grid>
    )
  }

  recentlyCompletedCourses = () => {
    const columnTitles = [
      {
        id: 1,
        title: "Sr No",
        width: '8%',
      },
      {
        id: 2,
        title: "Title",
        width: '62%',
      },
      {
        id: 2,
        title: "Category",
        width: '15%',
      },
      {
        id: 3,
        title: "Points Earned",
        width: '15%',
      }
    ];
    const completedCourses = this.state.completedCourses;
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} className="analyticTitle analyticFilterTitle" justifyContent="space-between">
          <Typography className="mainHeading">Recently Completed Courses </Typography>
          {this.state.loadSeeAllView ?
            <Typography className="seeAllStyle" component={"p"} onClick={() => this.closeTableFullViewMode()}><Close /></Typography>
            :
            <Typography className="seeAllStyle" component={"p"} onClick={() => this.openTableFullViewMode(configJSON.recentlyCompletedCourses)}>See All </Typography>
          }
        </Grid>
        <Grid item className="tableScroll" xs={12}>
          <Box className="tableStyle">
            <Box className="rowStyle">
              {columnTitles.map((item, index) => (
                <Box key={item.id} width={item.width} className={`headerCellStyle ${index == 0 ? ' first' : ''}`}>{item.title}</Box>
              ))}
              <Box className="headerCellStyle last"></Box>
            </Box>
            {completedCourses.length > 0 && completedCourses.map((rowData: any, index: number) => (
              <Box key={index} className="rowStyle">
                <Box className="cellStyle trimWords">{index + 1 + '.'}</Box>
                <Box className="cellStyle trimWords">{rowData.title}</Box>
                <Box className="cellStyle trimWords">{rowData.category.join(" , ")}</Box>
                <Box className="cellStyle trimWords">{rowData.pointsEarned}</Box>
              </Box>
            ))}
          </Box>
        </Grid>
        {completedCourses.length === 0 && this.noDataMessageBox()}
      </Grid>
    )
  }

  noDataMessageBox = () => {
    return (
      <Box width={"99%"} marginTop={"-20px"} justifyContent={"center"} alignItems={"center"} height={"100%"} overflow={"hidden"}>
        <CustomMessage message="No data available." boxHeight="200px" />
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid
          container
          className="firstGridContainer"
        >
          <Wrapper>
            <Box style={webStyles.breadcrumbWrapper}>
              <Typography style={webStyles.breadcrumbItem}>Profile</Typography>
              <NavigateNext style={webStyles.navigateIcon} />
              <Typography style={webStyles.breadcrumbActiveItem}>HSE Passport</Typography>
            </Box>
            {!this.state.loadSeeAllView &&
              <Box className="analyticTitle">
                <Typography className="mainHeading">HSE Passport</Typography>
              </Box>
            }
            {!this.state.loading && !this.state.isWorker &&
              <Box>
                {!this.state.loadSeeAllView && this.coursesCountCards()}
                {this.state.currentSeeAllTable === configJSON.categoryWiseCourses && this.state.loadSeeAllView && this.categoryWiseCourses()}
                {!this.state.loadSeeAllView && this.categoryWiseCourses()}
                {this.state.currentSeeAllTable === configJSON.pendingCourses && this.state.loadSeeAllView && this.pendingCourses()}
                {!this.state.loadSeeAllView && this.pendingCourses()}
                {this.state.currentSeeAllTable === configJSON.recentlyCompletedCourses && this.state.loadSeeAllView && this.recentlyCompletedCourses()}
                {!this.state.loadSeeAllView && this.recentlyCompletedCourses()}
              </Box>
            }
            {this.state.loading && (
              <Grid justifyContent="center" container>
                <CircularProgress className="loader" />
              </Grid>
            )}
          </Wrapper>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
  width: "100%",
  backgroundColor: "rgb(238, 238, 238)",
  padding: "30px",
  boxSizing: "border-box",
  height: "100%",
  position: "relative",
  overflowX: "hidden",
  overflowY: "auto",
  "& .analyticTitle": {
    display: "flex",
    margin: "16px 0px",
    justifyContent: "space-between",
  },
  "& .loader": {
    color: "#8735e1"
  },
  "& .analyticFilterTitle": {
    margin: "30px 0px 10px 0px",
  },
  "& .mainHeading": {
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontStyle: "normal",
    fontSize: "26px",
    fontWeight: 700,
    fontFamily: "ClotherBold",
    lineHeight: "25px",
    width: "80%"
  },
  "& .cardText2": {
    fontFamily: "ClotherRegular",
    fontWeight: 700,
    fontSize: "30px"
  },
  "& .cardText1": {
    fontFamily: "ClotherRegular",
    fontWeight: 400,
    fontSize: "18px"
  },
  "& .cardStyle": {
    cursor: "pointer",
    margin: 1,
    border: "1px solid #FFFFF",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "8px",
    },
    "& .MuiCardContent-root": {
      padding: "8px 16px 16px 16px"
    }
  },
  "& .selectedCardStyle": {
    cursor: "pointer",
    background: "#F7F0FF",
    margin: 0,
    border: "1px solid #8735E1",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: "8px",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "8px",
    },
    "& .MuiCardContent-root": {
      padding: "8px 16px 16px 16px"
    }
  },
  "& .cardStyle:hover": {
    backgroundColor: "#F7F0FF",
    border: "1px solid #8735E1",
    margin: 0
  },
  "& .rowStyle": {
    display: 'table-row'
  },
  "& .tableStyle": {
    display: 'table',
    width: '100%',
    backgroundColor: 'white',
  },
  "& .headerCellStyle": {
    display: 'table-cell',
    backgroundColor: "#8735E1",
    padding: '10px',
    color: 'white',
    textAlign: 'left',
    fontSize: "16px",
    fontFamily: "ClotherRegular",
    fontWeight: 700,
  },

  "& .headerCellStyle.last": {
    borderRadius: "0px 8px 0px 0px"
  },
  "& .headerCellStyle.first": {
    minWidth: "50px",
    borderRadius: "8px 0px 0px 0px"
  },
  "& .cellStyle": {
    display: 'table-cell',
    padding: '10px',
    fontSize: "14px",
    textAlign: 'left',
    fontWeight: 400,
  },
  "& .tableScroll": {
    overflowX: "auto"
  },
  "& .firstGridContainer": {
    height: "100%",
  },
  "& .trimWords": {
    overflow: 'hidden',
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  "& .activeIconStyle": {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    padding: "9px",
    boxShadow: "-2px 2px 8px 0px #8735e11f",
    marginBottom: "8px",
    marginTop: "8px",
  },
  "& .iconStyle": {
    width: "40px",
    height: "40px",
    border: "1px solid #F7F0FF",
    padding: "9px",
    borderRadius: "8px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  "& .seeAllStyle": {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#8735E1",
    cursor: "pointer",
  }
})
const webstyle = {
  selectedValue: {
    background: "#EEDEFF",
    color: "#2B2A29",
  },
  nonSelectedValue: {
    background: "#FFF",
    color: "#888888",
  }
}
// Customizable Area End
