import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  is_privacy_policy: boolean;
  loader: boolean;
  is_terms_condition: boolean;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HelpController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  privacyPolicyApiCallId: any;
  TermsConditionApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      data: {},
      is_privacy_policy: false,
      loader: false,
      is_terms_condition: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.privacyPolicyApiCallId) {
          this.setState({ data: responseJson.description, loader: false })
        }
        if (apiRequestCallId === this.TermsConditionApiCallId) {
          this.setState({ data: responseJson.description, loader: false })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let paths = window.location.pathname.split('/')
    if (paths[1] === "policy") {
      this.getPrivacyPolicy();
    } else if (paths[1] === "conditions") {
      this.getTermsCondition();
    }
  }

  getPrivacyPolicy = () => {
    this.setState({ is_privacy_policy: true, is_terms_condition: false })
    this.privacyPolicyAPICall()
  }

  privacyPolicyAPICall = () => {
    this.setState({ loader: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getPrivacyPolicyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiCallId = getPrivacyPolicyMsg.messageId;

    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyAPIEndPoint
    );

    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPrivacyPolicyMsg.id, getPrivacyPolicyMsg);
  }

  getTermsCondition = () => {
    this.termsConditionsAPICall()
    this.setState({ is_terms_condition: true, is_privacy_policy: false })
  }

  termsConditionsAPICall = () => {
    this.setState({ loader: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getPrivacyPolicyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.TermsConditionApiCallId = getPrivacyPolicyMsg.messageId;

    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsConditionAPIEndPoint
    );

    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPrivacyPolicyMsg.id, getPrivacyPolicyMsg);
  }
  // Customizable Area End

}
