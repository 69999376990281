import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Account } from "./types";
import { getStorageData } from "framework/src/Utilities";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  name: string;
  createdAt: string;
  updatedAt: string;
  editMode: boolean;
  token: string;
  isVisibleDeleteAccountModal: boolean;
  accountsData: Account[];
  modalAccData: Account[];
  dropdownAccountStatus: boolean;
  fieldError: boolean;
  isDeleteAcc: boolean;
  isCodeSent: boolean;
  confirmationPopUp: boolean;
  accountData: any;
  isGoogleAuthSelected: boolean;
  emailIdisSelected: boolean;
  otpValues: any[];
  isOfficialIdSelected: boolean;
  counter: number;
  loader: boolean;
  otpError: boolean;
  googleAuthEmail: string;
  otpErrorMsg: string;
  inputRef: any;
  emailError: boolean;
  setTimer: boolean;
  selectedEmail: string;
  EmailErrorMsg: string;
  emailAPiError: boolean;
  setCodeCount: number;
  timer: any;
  timer2: any;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  yesDeleteAccApiCallId = "";
  sendCodeApiCallId = "";
  verifyOtpAPICallId = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.sendCodeToEmail = this.sendCodeToEmail.bind(this);
    this.yesDeleteAcc = this.yesDeleteAcc.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.EnterOtpValues = this.EnterOtpValues.bind(this);
    this.setVerifyOtpRes = this.setVerifyOtpRes.bind(this);
    this.verifyOtpRes = this.verifyOtpRes.bind(this);
    this.sendCodeToDeleteAcc = this.sendCodeToDeleteAcc.bind(this);
    this.setConfirmationPop = this.setConfirmationPop.bind(this);
    this.setNextToDeleteAcc = this.setNextToDeleteAcc.bind(this);
    this.maskText = this.maskText.bind(this);
    this.sendCodeEmailAPICall = this.sendCodeEmailAPICall.bind(this);
    this.onPasteOTP = this.onPasteOTP.bind(this);
    this.handleOfficialAccounToDelete = this.handleOfficialAccounToDelete.bind(this);
    this.AccountDeleted = this.AccountDeleted.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      name: "",
      createdAt: "",
      updatedAt: "",
      editMode: false,
      token: "",
      isVisibleDeleteAccountModal: false,
      accountsData: [],
      modalAccData: [],
      dropdownAccountStatus: false,
      confirmationPopUp: false,
      fieldError: false,
      isDeleteAcc: false,
      isCodeSent: false,
      accountData: {},
      isGoogleAuthSelected: false,
      emailIdisSelected: false,
      otpValues: Array(6).fill(""),
      isOfficialIdSelected: false,
      counter: 60,
      loader: false,
      otpError: false,
      googleAuthEmail: "",
      otpErrorMsg: "",
      inputRef: createRef(),
      emailError: false,
      setTimer: false,
      selectedEmail: '',
      EmailErrorMsg: '',
      emailAPiError: false,
      setCodeCount: 0,
      timer: 0,
      timer2: 0,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    let token = await getStorageData("authToken");
    this.setState({ token: token })
    window.addEventListener('popstate', () => {
      if(this.state.confirmationPopUp) {
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }
    });
  }

  receive = async (_from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.setState({ loader: false })
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.yesDeleteAccApiCallId != null && this.yesDeleteAccApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (!responseJson.errors && responseJson.data) {
        responseJson.data.attributes.isSelected = false
        this.setState({ accountData: responseJson.data.attributes, loader: false });
      }
    }
    this.setVerifyOtpRes(responseJson, message)
    this.verifyOtpRes(responseJson, message)
  };

  setVerifyOtpRes = (responseJson: any, message: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.sendCodeApiCallId != null && this.sendCodeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (responseJson.message === "otp sent successfully") {
        this.setState({ loader: false, isCodeSent: true, otpError: false, otpErrorMsg: "", EmailErrorMsg: "" })
        this.setState({ setTimer: true, setCodeCount: this.state.setCodeCount + 1, counter: 60 }, () => {
          this.calculate()
        })
        this.setState({ setCodeCount: this.state.setCodeCount + 1 })
      }
      if (responseJson.errors) {
        this.setState({ emailAPiError: true, EmailErrorMsg: responseJson.errors, emailError: false, setTimer: false, isCodeSent: false })
      }
    }
  }

  verifyOtpRes = (responseJson: any, message: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.verifyOtpAPICallId != null && this.verifyOtpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (responseJson.success === "Account Successfully Deleted") {
        this.setState({ otpError: false, otpErrorMsg: "" })
        this.setConfirmationPop()
      } else {
        this.setState({ loader: false, otpError: true, otpErrorMsg: responseJson.errors})
      }
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  hideDeleteAccountModal = () => {
    this.setState({
      isVisibleDeleteAccountModal: false,
      isCodeSent: false,
      isOfficialIdSelected: false,
      emailIdisSelected: false,
      isGoogleAuthSelected: false,
      EmailErrorMsg: "",
      emailError: false,
      emailAPiError: false,
      otpValues: Array(6).fill(""),
      otpError: false,
      otpErrorMsg: "",
      setTimer: false,
      setCodeCount: 0,
      counter: 0,
    }, () => {
      this.calculate()
      clearInterval(this.state.timer)
      clearInterval(this.state.timer2)
    });
  };

  setNextToDeleteAcc = () => {
    this.setState({ isDeleteAcc: !this.state.isDeleteAcc })
  }

  ProceedToDelete = () => {
    this.setState({ isVisibleDeleteAccountModal: !this.state.isVisibleDeleteAccountModal })
    this.yesDeleteAcc(this.state.token)
  }

  sendCodeToDeleteAcc = () => {
    if (this.state.isCodeSent) {
      this.verifyCode(this.state.token)
    } else {
      this.setState({
        setCodeCount: this.state.setCodeCount + 1,
        counter: 60,
      })
      this.sendCodeToEmail(this.state.token, this.state.googleAuthEmail);
    }
  }

  calculate = () => {
    let n = this.state.counter;
    let timer: any = 0
    if (n === 0) {
      clearInterval(this.state.timer)
    } else {
      timer = setInterval(() => {
        countDown()
      }, 1000);
      this.setState({ timer: timer })
      let countDown = () => {
        n--;
        this.setState({ counter: n })
      }
      if (this.state.setTimer) {
        let timer2 = setTimeout(() => {
          this.setState({ setTimer: false });
        }, 60000);
        this.setState({ timer2: timer2 })
      }
    }
  }

  converNumber = (number: number) => {
    return number > 9 ? "" + number : "0" + number;
  };

  setConfirmationPop = () => {
    this.setState({
      confirmationPopUp: !this.state.confirmationPopUp,
      isVisibleDeleteAccountModal: false
    })
  }

  yesDeleteAcc = (token: string) => {
    this.setState({ loader: true })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.yesDeleteAccApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.yesDeleteMyAccAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendCodeToEmail = (token: string, emailId: string) => {
    this.setState({ loader: true })
    if (this.state.otpError) {
      this.setState({
        otpValues: new Array(6).fill("")
      })
    }
    if (this.state.emailIdisSelected || this.state.isGoogleAuthSelected || this.state.isOfficialIdSelected) {
      this.sendCodeEmailAPICall(token, emailId)
    } else {
      this.setState({
        emailError: true,
        loader: false
      })
    }
  }

  sendCodeEmailAPICall = (token: string, emailId: string) => {
    this.setState({ otpError: false, emailError: false, otpErrorMsg: "" })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    let email = this.state.emailIdisSelected ? this.state.accountData.email : this.state.accountData.official_email
    const httpBody = {
      email: this.state.isGoogleAuthSelected ? emailId : email
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendCodeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendCodeAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyCode = (token: string) => {
    this.setState({ loader: true })
    let code = this.state.otpValues.join("")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const httpBody = {
      otp: code
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.verifyOTPAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAccounToDelete = (accountData: any) => {
    this.setState({
      emailIdisSelected: !this.state.emailIdisSelected,
      isGoogleAuthSelected: false,
      isOfficialIdSelected: false,
      emailError: false,
      emailAPiError: false,
      selectedEmail: accountData?.email
    })
  }

  EnterOtpValues = (event: { [x: string]: any; type: any, target: { form: any; value: any; }; }, index: number) => {
    if (event?.target?.value?.length === 1 && /^\d*$/.test(event?.target?.value)) {
      const form = event.target.form;
      if (form.elements.length > index + 1) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
      let tempOtp = [...this.state.otpValues];
      tempOtp[index] = event.target.value;
      this.setState({
        otpValues: tempOtp
      });
    } else {
      const form = event?.target?.form;
      event.preventDefault();
      let tempOtp = [...this.state.otpValues];
      if (index !== 0) {
        form?.elements[index - 1].focus();
      }
      tempOtp[index] = "";
      this.setState({
        otpValues: tempOtp
      });
    }
  }

  onPasteOTP = (event: any) => {
    event.preventDefault()
    let value = []
    const pasted = event.clipboardData?.getData("text/plain")
    if (pasted) {
      value = pasted.split("").slice(0, 6)
      this.setState({ otpValues: value.concat(Array(6 - value.length).fill("")) })
      const form = event?.target?.form;
      if (value.length !== 0) {
        form?.elements[value.length].focus();
      }
    }
  }

  handleOfficialAccounToDelete = () => {
    this.setState({
      isOfficialIdSelected: !this.state.isOfficialIdSelected,
      emailIdisSelected: false,
      isGoogleAuthSelected: false,
      emailError: false,
      emailAPiError: false,
      selectedEmail: this.state.accountData.official_email
    })
  }

  AccountDeleted = () => {
    this.setState({
      confirmationPopUp: !this.state.confirmationPopUp,
      isDeleteAcc: false,
    })
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  maskText = (text: string, start: number, end: number) => {
    if (text !== undefined) {
      start = Math.max(0, start);
      end = Math.min(end, text?.length - 1);
      const maskedPart = '*'.repeat(end - start + 1);
      const maskedText = text?.substring(0, start) + maskedPart + text?.substring(end + 1);
      return maskedText;
    } else {
      return ""
    }
  }

  goToAccount = () => {
    this.props.navigation.navigate("Account")
  }

  setTimerToSendCode = () => {
    clearInterval(this.state.timer)
    clearInterval(this.state.timer2)
    this.sendCodeToEmail(this.state.token, this.state.selectedEmail)
  }

  goToPrivacySettings = () => {
    this.props.navigation.navigate("UserProfileBasic")
  }

  // Customizable Area End
}
