import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CircularProgress,
  Grid,
  Avatar,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Comment } from "./types";
import { noUserPicture } from "./assets"

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196F3",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    button: {
      textTransform: "none",
    },
  },
});
// Customizable Area End

import Conversationthreading2Controller, {
  Props,
  configJSON,
} from "./Conversationthreading2Controller";

export default class Conversationthreading2 extends Conversationthreading2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderComments = (commentsData: Comment[]) => {
    return (
      <Container data-testid="commentlistblock">
        {commentsData.map((item, index) => {
          return (
            <Box
              key={item.currentcommentID.toString()}
              data-test-id={`commentComponent-${index}`}
            >
              <Box>
                <Box style={webStyle.commentContainer}>
                  <Box style={webStyle.userContainer}>
                    <Avatar
                      alt="EmployeeName"
                      src={item.image ?? noUserPicture}
                      data-test-id={`commentImage-${index}`}
                    />
                    <Typography
                      data-test-id="profilename"
                      style={webStyle.firstname}
                    >
                      {item.firstname}
                    </Typography>
                  </Box>
                  {/* Comment content */}
                  <Typography
                    data-test-id="commentText"
                    style={webStyle.commentBodyStyle}
                  >
                    {item.commentbody}
                  </Typography>
                </Box>
                <Typography
                  data-test-id="commentCreatedAt"
                  style={webStyle.createdAt}
                >
                  {item.createdAt}
                </Typography>
                {this.state.selectedParentReplyButton ===
                  item.currentcommentID &&
                  this.state.isReplying && (
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Input
                          data-test-id={"replyInput"}
                          placeholder={configJSON.labelsCreateFormFields.reply.placeHolder}
                          value={this.state.replyText}
                          onChange={this.replyTextEventInput}
                          style={webStyle.inputStyle}
                          fullWidth={true}
                          disableUnderline={true}
                          multiline={true}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Box  sx={webStyle.sendBtnCotainer}>
                          <Button
                            data-test-id="sendButton"
                            onClick={() => {
                              if (this.state.replyText) {
                                this.handleSendButton(item.currentcommentID);
                              }
                            }}
                            variant="contained"
                            color="primary"
                          >
                          {configJSON.labelsCreateFormFields.reply.label}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Typography
                      id={item.currentcommentID.toString()}
                      data-test-id={`${item.currentcommentID}replyButton`}
                      onClick={() =>
                        this.handleReplyButtonWeb(item.currentcommentID)
                      }
                      style={webStyle.linkButtonStyle}
                    >
                      {configJSON.labelReplyText}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      data-test-id={`${item.currentcommentID}showMoreButton`}
                      onClick={() => {
                        this.handleParentCommentsButton(
                          item.post_id,
                          item.currentcommentID,
                          item.childComment.length === 0
                        );
                      }}
                      style={webStyle.linkButtonStyle}
                    >
                      {item.childComment.length === 0
                        ?  configJSON.labelShowMoreText
                        : configJSON.labelShowLessText}
                    </Typography>
                  </Grid>
                </Grid>

                {this.renderComments(item.childComment)}
              </Box>
            </Box>
          );
        })}
      </Container>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box
          style={webStyle.mainContainer}
        >
          <Container maxWidth={"lg"}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6" style={webStyle.titleStyle}>
                {this.state.postBodyData}
              </Typography>
            </Box>

            <Box sx={webStyle.addCommentContainer}>
              <Typography
                data-test-id="commentButton"
                style={webStyle.linkButtonStyle}
                onClick={this.commentSectionFn}
              >
                Comment
              </Typography>
            </Box>

            {this.state.modalVisible && (
              <Box>
                <Input
                  data-test-id={"newCommentInput"}
                  placeholder={"create a new comment"}
                  fullWidth={true}
                  disableUnderline={true}
                  value={this.state.newCommentText}
                  multiline={true}
                  onChange={(event) =>
                    this.newCommentTextInput(event.target.value)
                  }
                  style={webStyle.inputStyle}
                />

                <Box
                  sx={webStyle.submitBtnContainer}
                >
                  <Button
                    data-test-id="submitButton"
                    onClick={() => {
                      if (this.state.newCommentText) {
                        this.createComment();
                      }
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add Comment
                  </Button>
                </Box>
              </Box>
            )}
            {this.state.loader && (
              <Box data-test-id={"activityLoader"} sx={webStyle.loaderWrapper}>
                <Box sx={webStyle.circularContainer}>
                  <CircularProgress />
                </Box>
              </Box>
            )}
            {this.renderComments(this.state.allComment)}
          </Container>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    paddingBottom: "30px",
    background: "#fff",
    border: "1px solid #e1e1e1",
    borderRadius: "18px",
  },
  titleStyle: {
    padding: 10,
    backgroundColor: "#fff",
    marginTop: "20px",
    marginBottom: "20px",
  },
  inputStyle: {
    flex: 1,
    padding: 10,
    backgroundColor: "#fff",
    color: "#424242",
    marginTop: "40px",
    border: "1px solid #e1e1e1",
    borderRadius: "10px",
  },
  commentContainer: {
    backgroundColor: "#fff",
    padding: "14px",
    border: "1px solid #e1e1e1",
    borderRadius: "18px",
    marginTop: "10px",
  },
  firstname: {
    marginLeft: "8px",
  },
  createdAt: {
    marginLeft: "8px",
    fontSize: "12px",
    color: "hsl(210,8%,35%)",
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
  },
  commentBodyStyle: { padding: "14px" },
  linkButtonStyle: {
    textDecoration: "underline",
    color: "rgb(82, 89, 96)",
    cursor: "pointer",
  },
  addCommentContainer: {
    textAlign: "right",
    marginTop: 10,
  },
  loaderWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  mainContainer:{
    paddingTop: "20px",
  },
  submitBtnContainer:{
    textAlign: "right",
    marginTop: "20px",
  },
  sendBtnCotainer:{
    textAlign:"left"
  }
};
// Customizable Area End
