export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Like = require("../assets/storyLike.png");
export const Share = require("../assets/Share.png");
export const View = require("../assets/storyView.png");
export const Close = require("../assets/storyClose.png");
export const addUserStory = require("../assets/addUserStory.png");
export const closeIcon = require("../assets/close-icon.png");
export const micStart = require("../assets/mic_Start.png");
export const mic = require("../assets/mic.png");
export const upload = require("../assets/upload.svg");
export const plusIcon = require("../assets/plusIcon.png");
export const profileDefaultImage = require("../assets/profileDefaultImage.png");

