import React from "react";

// Customizable Area Start
import { Box, Button, Input, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  logo,
  forgotPasswordBg,
  forgotPasswordLeftLogo,
  otpLogo,
} from "../../forgot-password/src/assets";
// Customizable Area End

import OTPInputAuthController, {
  Props,
} from "../../otp-input-confirmation/src/OTPInputAuthController";
import ErrorMessage from "../../forgot-password/src/ErrorMessage";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "ClotherRegular",
    h2: {
      fontSize: "28px",
      lineHeight: "24px",
      fontFamily: "ClotherBold",
      fontWeight: 700,
      marginBottom: "15px",
      color: "#18181E",
    },
    body2: {
      color: "#A1A1A1",
      fontSize: "18px",
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        height: "54px",
        width: "54px",
        border: "1px solid #DCDCDC",
        padding: "10px 15px",
        textAlign: "center",
        fontSize: "24px",
        lineHeight: "18px",
        color: "#2B2A29",
        fontWeight: 700,
        letterSpacing: "0.5px",
        textOverflow: "ellipsis",
        justifyContent: "flex-start",
        borderRadius: "8px",
        "&.border-input": {
          border: `1px solid #8735E1`,
        },
        "&.Mui-disabled": {
          cursor: "no-drop",
        },
        "&:hover, &.Mui-focused": {
          border: `1px solid #8735E1`,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "54px",
        border: "none",
        boxShadow: "none",
        width: "100%",
        lineHeight: "22px",
      },
      contained: {
        boxShadow: "none",
        background: "#8735E1",
        backgroundColor: "#8735E1",
        borderRadius: "8px",
        fontWeight: 700,
        color: "#fff",
        "&:hover, &:focus": {
          boxShadow: "none",
          background: "#8735E1",
        },
      },
    },
  },
});
export default class ForgotPasswordOTP extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.submitVerificationCode = this.submitVerificationCode.bind(this);
    // Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.loginSec}>
          <Box sx={webStyle.loginLeft}>
            <figure>
              <img width={500} src={forgotPasswordLeftLogo} alt="" />
            </figure>
          </Box>
          <Box sx={webStyle.forgotPasswordOTPRight}>
            <Box sx={webStyle.forgotPasswordOTPRightInner}>
              <Box sx={webStyle.forgotPasswordOTPHeader}>
                <Box sx={webStyle.forgotPasswordOTPLogo}>
                  <figure>
                    <img src={logo} alt="" />
                  </figure>
                </Box>
              </Box>

              <Box sx={webStyle.textCenter}>
                <Box
                  sx={webStyle.otpImgContainer}
                  style={{
                    background: "rgba(135,53,225,0.1)",
                  }}
                >
                  <figure>
                    <img src={otpLogo} alt="" />
                  </figure>
                </Box>
                <Typography variant="h2">Verification Code</Typography>
                <Typography variant="body2">
                  Please enter 6 digit code sent to {this.state.hasInput}
                </Typography>
                <Box sx={webStyle.mb18}></Box>
                <Box sx={webStyle.formContainer} component={"form"}>
                  {this.state.otpInfo.map((s, i) => (
                    <Input
                      key={i}
                      disableUnderline
                      data-test-id={`txtEmailPhoneOTP${i}`}
                      className={
                        this.state.otpInfo[i] !== "" ? "border-input" : ""
                      }
                      onKeyPress={(e) => this.handleSubmitCodeOnEnterKey(e, i)}
                      onChange={(event) => this.handleEnterOTP(event, i)}
                      value={this.state.otpInfo[i]}
                      type="text"
                      inputProps={{
                        maxLength: "1",
                      }}
                      style={{
                        ...(this.state.otpInfo[i] && {
                          color: '#21C274',
                          borderColor: '#21C274'
                        }),
                        ...(this.state.error && {
                          borderColor: '#F30202',
                          color: '#F30202'
                        })
                      }}
                      onPaste={this.onPaste.bind(this)}
                    />
                  ))}
                </Box>
                <ErrorMessage error={this.state.error} />
                <Button
                  onClick={this.submitVerificationCode.bind(this)}
                  variant="contained"
                  data-test-id="btnSubmitOTP"
                >
                  Verify
                </Button>
                {this.state.resendTimer ? (
                  <Box>
                    <Typography style={webStyle.linkTitle}>
                      Resend OTP in{" "}
                      <Typography
                        component={"span"}
                        style={webStyle.timerStyle}
                      >
                        {" "}
                        0:{this.converNumber(this.state.timer)}
                      </Typography>
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    data-test-id="resend-link"
                    style={webStyle.linkTitle}
                    onClick={this.resendVerificationCode.bind(this)}
                  >
                    Resend Code
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Loader loading={this.state.loader} />
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
const webStyle = {
  loginSec: {
    width: "100%",
    display: "flex",
    height: "100vh",
    overflow: "hidden",
  },
  loginLeft: {
    maxwidth: "60%",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${forgotPasswordBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    maxWidth: "60%",
  },
  forgotPasswordOTPRight: {
    background: "#fff",
    maxWidth: "40%",
    width: "100%",
    padding: "20px 0",
    display: "flex",
    overflowY: "auto",
    alignItems: 'center',
  },
  forgotPasswordOTPRightInner: {
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
    fontFamily: "ClotherRegular",
  },
  forgotPasswordOTPHeader: { textAlign: "center" },
  forgotPasswordOTPLogo: {
    display: "inline-block",
    marginBottom: "15px",
    textAlign: "Center",
    width: "100%",
  },
  otpImgContainer: {
    margin: "0 auto",
    marginBottom: "20px",
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textCenter: {
    textAlign: "Center",
  },
  mb18: {
    marginBottom: "18px",
  },
  formContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  linkTitle: {
    color: "#8735E1",
    fontSize: "18px",
    fontWeight: 700,
    marginTop: "18px",
    cursor: 'pointer',
  },
  timerStyle: {
    color: "#18181E",
    fontSize: "18px",
    fontWeight: 700,
  },
};
// Customizable Area End
