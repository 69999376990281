// Customizable Area Start
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { HomeIcon, RoadIcon, selectLanguage, tabIconBag } from "./assets";
import { webStyles } from "../../../components/src/styles";
import RiskController, { Props } from "./RiskPageController.web";
import CongratsModalStep from "./CongratesModalStep.web";
// Customizable Area End
export default class RiskStep extends RiskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderVehical = (name: any) => {
    return name.length < 15 ? "" : "...";
  };

  HomeEnviromentData = (EnvHome: any) => {
    return EnvHome.length < 15 ? "" : "...";
  };

  HomeHelthDataFun = (HelthHome: any) => {
    return HelthHome.length < 15 ? "" : "...";
  };

  HOmeSafetyFunc = (HomeSafety: any) => {
    return HomeSafety.length < 15 ? "" : "...";
  };

  workDataEnvFun = (HomeEnv: any) => {
    return HomeEnv.length < 15 ? "" : "...";
  };

  getBorderStyle = (isSelected: number[], index: number): string => {
    return isSelected?.includes(index)
      ? "2px solid var(--Purple, #8735E1)"
      : "1px solid #cbc5c5";
  };

  firstStepRoadData = (isSelected: any, vehical: any) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        ...(webStyle.BoxStyle as React.CSSProperties),
        border: this.getBorderStyle(isSelected, vehical.id),
      }}
      key={vehical.id}
      onClick={() => this.handleSelect(vehical.id)}
      data-test-id="HandleSelect"
    >
      {isSelected?.includes(vehical.id) ? (
        <img
          src={selectLanguage}
          style={webStyle.selectedStyle as React.CSSProperties}
        />
      ) : (
        ""
      )}
      <img src={vehical.icon_url} style={{ height: "25px", width: "26px" }} />
      <Typography style={webStyle.TitleName as React.CSSProperties}>
        {vehical.name.slice(0, 15)}
        {this.renderVehical(vehical.name)}
      </Typography>
    </Box>
  );

  secondStepData = (homeDataEnviroment: any, enviromentData: any) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        ...(webStyle.BoxStyle as React.CSSProperties),
        border: this.getBorderStyle(homeDataEnviroment, enviromentData.id),
      }}
      key={enviromentData.id}
      onClick={() => this.handleSelectHomeEnvirenmentData(enviromentData.id)}
      data-test-id="handleSelectEnvirenmentData"
    >
      {homeDataEnviroment?.includes(enviromentData.id) ? (
        <img
          src={selectLanguage}
          style={webStyle.selectedStyle as React.CSSProperties}
        />
      ) : (
        ""
      )}
      <img
        src={enviromentData.icon_url}
        style={{ height: "25px", width: "26px" }}
      />

      <Typography style={webStyle.TitleName as React.CSSProperties}>
        {enviromentData.name.slice(0, 15)}{" "}
        {this.HomeEnviromentData(enviromentData.name)}
      </Typography>
    </Box>
  );

  thirdStepData = (homeDataHelth: any, HomeHelthdata: any) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        ...(webStyle.BoxStyle as React.CSSProperties),
        border: this.getBorderStyle(homeDataHelth, HomeHelthdata.id),
      }}
      key={HomeHelthdata.id}
      onClick={() => this.handleSelectHomeHelthData(HomeHelthdata.id)}
      data-test-id="handleSelectHomeHelthData"
    >
      {homeDataHelth?.includes(HomeHelthdata.id) ? (
        <img
          src={selectLanguage}
          style={webStyle.selectedStyle as React.CSSProperties}
        />
      ) : (
        ""
      )}
      <img
        src={HomeHelthdata.icon_url}
        style={{ height: "25px", width: "26px" }}
      />

      <Typography style={webStyle.TitleName as React.CSSProperties}>
        {HomeHelthdata.name.slice(0, 15)}{" "}
        {this.HomeHelthDataFun(HomeHelthdata.name)}
      </Typography>
    </Box>
  );

  stepFourData = (homeDataSafety: any, homeSafetyData: any) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        ...(webStyle.BoxStyle as React.CSSProperties),
        border: this.getBorderStyle(homeDataSafety, homeSafetyData.id),
      }}
      key={homeSafetyData.id}
      onClick={() => this.handleSelectHomeSafetyData(homeSafetyData.id)}
      data-test-id="handleSelectHomeSafetyData"
    >
      {homeDataSafety?.includes(homeSafetyData.id) ? (
        <img
          src={selectLanguage}
          style={webStyle.selectedStyle as React.CSSProperties}
        />
      ) : (
        ""
      )}
      <img
        src={homeSafetyData.icon_url}
        style={{ height: "25px", width: "26px" }}
      />

      <Typography style={webStyle.TitleName as React.CSSProperties}>
        {homeSafetyData.name.slice(0, 15)}{" "}
        {this.HOmeSafetyFunc(homeSafetyData.name)}
      </Typography>
    </Box>
  );

  stepFiveData = (WorkEnvirmentState: any, workDataEnv: any) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        ...(webStyle.BoxStyle as React.CSSProperties),
        border: this.getBorderStyle(WorkEnvirmentState, workDataEnv.id),
      }}
      key={workDataEnv.id}
      onClick={() => this.handleSelectWorkEnviromentData(workDataEnv.id)}
      data-test-id="handleSelectWorkEnviromentData"
    >
      {WorkEnvirmentState?.includes(workDataEnv.id) ? (
        <img
          src={selectLanguage}
          style={webStyle.selectedStyle as React.CSSProperties}
        />
      ) : (
        ""
      )}
      <img
        src={workDataEnv.icon_url}
        style={{ height: "25px", width: "26px" }}
      />

      <Typography style={webStyle.TitleName as React.CSSProperties}>
        {workDataEnv.name.slice(0, 15)} {this.workDataEnvFun(workDataEnv.name)}
      </Typography>
    </Box>
  );

  stepSixData = (WorkHelthState: any, workDataHelth: any) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        ...(webStyle.BoxStyle as React.CSSProperties),
        border: this.getBorderStyle(WorkHelthState, workDataHelth.id),
      }}
      key={workDataHelth.id}
      onClick={() => this.handleSelectWorkHelthData(workDataHelth.id)}
      data-test-id="handleSelectWorkHelthData"
    >
      {WorkHelthState?.includes(workDataHelth.id) ? (
        <img
          src={selectLanguage}
          style={webStyle.selectedStyle as React.CSSProperties}
        />
      ) : (
        ""
      )}

      <img
        src={workDataHelth.icon_url}
        style={{ height: "25px", width: "26px" }}
      />

      <Typography style={webStyle.TitleName as React.CSSProperties}>
        {workDataHelth.name.slice(0, 15)}{" "}
        {workDataHelth.name.length < 15 ? "" : "..."}
      </Typography>
    </Box>
  );

  stepSevenData = (WorkSafetyState: any, workDataSafety: any) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        ...(webStyle.BoxStyle as React.CSSProperties),
        border: this.getBorderStyle(WorkSafetyState, workDataSafety.id),
      }}
      key={workDataSafety.id}
      onClick={() => this.handleSelectWorkSafetyData(workDataSafety.id)}
      data-test-id="handleSelectWorkSafetyData"
    >
      {WorkSafetyState?.includes(workDataSafety.id) ? (
        <img
          src={selectLanguage}
          style={webStyle.selectedStyle as React.CSSProperties}
        />
      ) : (
        ""
      )}

      <img
        src={workDataSafety.icon_url}
        style={{ height: "25px", width: "26px" }}
      />

      <Typography style={webStyle.TitleName as React.CSSProperties}>
        {workDataSafety.name.slice(0, 15)}{" "}
        {workDataSafety.name.length < 15 ? "" : "..."}
      </Typography>
    </Box>
  );

  PersonalRenderStepFunc = (stepNumber: number, title: any, icon: any) => {
    const isStepfirst = this.state.stepValue === 1;
    const isStepsecond1 = this.state.stepValue === 2;
    const isStepsecond2 = this.state.stepValue === 3;
    const isStepsecond3 = this.state.stepValue === 4;
    const isStepthird1 = this.state.stepValue === 5;
    const isStepthird2 = this.state.stepValue === 6;
    const isStepthird3 = this.state.stepValue === 7;
    const isActivestep =
      (isStepfirst && stepNumber === 1) ||
      (isStepsecond1 && stepNumber === 2) ||
      (isStepsecond2 && stepNumber === 2) ||
      (isStepsecond3 && stepNumber === 2) ||
      (isStepthird1 && stepNumber === 3) ||
      (isStepthird2 && stepNumber === 3) ||
      (isStepthird3 && stepNumber === 3);
    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            ...(webStyles.stepIdentifier as React.CSSProperties),
            border: isActivestep ? `2px solid var(--Purple, #8735E1)` : "none",
          }}
        >
          <img src={icon} alt={`${title} icon`} />
          <Typography
            style={{
              fontWeight: isActivestep ? 700 : 400,
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Box>
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      isSelected,
      stepValue,
      homeDataEnviroment,
      homeDataHelth,
      homeDataSafety,
      WorkEnvirmentState,
      WorkHelthState,
      WorkSafetyState,
    } = this.state;

    return this.state.isLoadingData ? (
      <Backdrop
        style={{ color: "#fff", zIndex: 1 }}
        open={this.state.isLoadingData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : (
      <div style={{ width: "100%" }}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: " -20px",
          }}
        >
          {this.PersonalRenderStepFunc(1, "Road", RoadIcon)}
          <div
            style={{
              width: "3.5rem",
              height: "1px",
              backgroundColor: "rgb(135 53 225)",
              marginTop: "56px",
            }}
          />
          {this.PersonalRenderStepFunc(2, "Home", HomeIcon)}
          <div
            style={{
              width: "3.5rem",
              height: "1px",
              backgroundColor: "rgb(135 53 225)",
              marginTop: "56px",
            }}
          />
          {this.PersonalRenderStepFunc(3, "Work", tabIconBag)}
        </Grid>
        {stepValue === 1 && (
          <Grid>
            <p style={{ fontSize: "18px", fontWeight: 600 }}>
              Mode used for Commuting to and for office.
            </p>
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {this.state.roadData?.map((vehical: any) =>
                this.firstStepRoadData(isSelected, vehical)
              )}
            </div>
          </Grid>
        )}
        {stepValue === 2 && (
          <Grid>
            <p style={{ fontSize: "18px", fontWeight: 600 }}>Environment</p>
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {this.state.HomeEnviromentData.map((enviromentData: any) =>
                this.secondStepData(homeDataEnviroment, enviromentData)
              )}
            </div>
          </Grid>
        )}
        {stepValue === 3 && (
          <Grid>
            <p style={{ fontSize: "18px", fontWeight: 600 }}>Health</p>
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {this.state.HomeHelthData?.map((HomeHelthdata: any) =>
                this.thirdStepData(homeDataHelth, HomeHelthdata)
              )}
            </div>
          </Grid>
        )}
        {stepValue === 4 && (
          <Grid>
            <p style={{ fontSize: "18px", fontWeight: 600 }}>Safety</p>
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {this.state.HomeSafetyData.map((homeSafetyData: any) =>
                this.stepFourData(homeDataSafety, homeSafetyData)
              )}
            </div>
          </Grid>
        )}
        {stepValue === 5 && (
          <Grid>
            <p style={{ fontSize: "18px", fontWeight: 600 }}>Environment</p>
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {this.state.WorkDataEnviroment.map((workDataEnv: any) =>
                this.stepFiveData(WorkEnvirmentState, workDataEnv)
              )}
            </div>
          </Grid>
        )}
        {stepValue === 6 && (
          <Grid>
            <p style={{ fontSize: "18px", fontWeight: 600 }}>Health</p>
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {this.state.WorkDataHelth.map((workDataHelth: any) =>
                this.stepSixData(WorkHelthState, workDataHelth)
              )}
            </div>
          </Grid>
        )}
        {stepValue === 7 && (
          <Grid>
            <p style={{ fontSize: "18px", fontWeight: 600 }}>Safety</p>
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {this.state.WorkDataSafety.map(
                (workDataSafety: any, index: any) =>
                  this.stepSevenData(WorkSafetyState, workDataSafety)
              )}
            </div>
          </Grid>
        )}
        <div
          style={{
            marginBottom: "10px",
            borderBottom: "1px solid #edebeb",
            paddingBottom: "90px",
          }}
        ></div>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          padding="0.75rem"
          style={{ marginTop: "1rem", marginLeft: "-9px" }}
        >
          <Button
            style={{
              ...webStyles.transButton,
              width: "15%",
              borderRadius: "8px",
            }}
            data-test-id="backToPrevStep"
            onClick={this.previousStep}
          >
            <Typography style={webStyles.buttonStyle as React.CSSProperties}>
              {" "}
              Previous
            </Typography>
          </Button>
          <Button
            style={{
              ...webStyles.fullButton,
              width: "15%",
              borderRadius: "8px",
            }}
            data-test-id="nextBtnStep2"
            variant="contained"
            type="submit"
            onClick={this.SubmitButton}
          >
            <Typography style={webStyles.buttonStyle as React.CSSProperties}>
              {" "}
              Next
            </Typography>
          </Button>
        </Box>
        {stepValue === 8 && (
          <CongratsModalStep
            navigation={undefined}
            activeStep={this.props.activeStep}
            activeStepState={3}
          />
        )}
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const webStyle = {
  selectedStyle: {
    position: "absolute",
    left: "73%",
    top: "4px",
  },
  BoxStyle: {
    display: "flex",
    width: "7vw",
    height: "7vw",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",
  },
  TitleName: {
    fontWeight: 400,
    textAlign: "center",
  },
};
// Customizable Area End
