// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import {
  EnvironmentIcon,
  ExceedingIcon,
  HelthIcon,
  InfoIcon,
  JustStartIcon,
  OnTrackIcon,
  safetyIcon,
  selectLanguage,
} from "./assets";
import { webStyles } from "../../../components/src/styles";
import PersonalGoalController, {
  Props,
  configJSON,
} from "./PersonalGoalController.web";
import CongratsModalStep from "./CongratesModalStep.web";
// Customizable Area End
export default class PersonalGoal extends PersonalGoalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderStepFunc = (StepCount: number, StepName: any, icon: any) => {
    const firstStep = this.state.setStep === 1;
    const secondStep = this.state.setStep === 2;
    const ThirdStep = this.state.setStep === 3;
    const ActiveStep =
      (firstStep && StepCount === 1) ||
      (secondStep && StepCount === 2) ||
      (ThirdStep && StepCount === 3);
    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            ...(webStyles.stepIdentifier as React.CSSProperties),
            border: ActiveStep ? `2px solid var(--Purple, #8735E1)` : "none",
          }}
        >
          <img src={icon} alt={`${StepName} icon`} />
          <Typography
            style={{
              fontWeight: ActiveStep ? 700 : 400,
              textAlign: "center",
            }}
          >
            {StepName}
          </Typography>
        </Box>
      </>
    );
  };

  getColorAndFont = (monthData: any, month: any) => {
    const findMonthType = monthData.find((x: any) => x.month === month)?.type;

    let backgroundColor, fontColor;

    switch (findMonthType) {
      case "Just Started":
        backgroundColor = "#F30202";
        fontColor = "#fff";
        break;
      case "On Track":
        backgroundColor = "rgb(247 203 47)";
        fontColor = "#fff";
        break;
      case "Exceeding":
        backgroundColor = "#21C274";
        fontColor = "#fff";
        break;
      default:
        backgroundColor = "rgb(243 237 237)";
        fontColor = "#A1A1A1";
        break;
    }

    return { backgroundColor, fontColor };
  };

  backgroundColorHealth = (monthHealth: any) => {
    return this.getColorAndFont(this.state.monthHelthSelect, monthHealth)
      .backgroundColor;
  };

  fontColorHealth = (month: any) => {
    return this.getColorAndFont(this.state.monthHelthSelect, month).fontColor;
  };

  backgroundColorSafety = (monthSafety: any) => {
    return this.getColorAndFont(this.state.monthSafetySelect, monthSafety)
      .backgroundColor;
  };

  fontColorSafety = (month: any) => {
    return this.getColorAndFont(this.state.monthSafetySelect, month).fontColor;
  };

  backgroundColorEnvironment = (monthEnvironment: any) => {
    return this.getColorAndFont(
      this.state.monthEnviromentSelect,
      monthEnvironment
    ).backgroundColor;
  };

  fontColorEnvironment = (month: any) => {
    return this.getColorAndFont(this.state.monthEnviromentSelect, month)
      .fontColor;
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { setStep, personalGoalInput } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: " -20px",
          }}
        >
          {this.renderStepFunc(1, "Health Goals", HelthIcon)}
          <div style={webStyle.renderStepUnderlineStyle} />
          {this.renderStepFunc(2, "Safety Goals", safetyIcon)}
          <div style={webStyle.renderStepUnderlineStyle} />
          {this.renderStepFunc(3, "Environment Goals", EnvironmentIcon)}
        </Grid>
        {setStep === 1 && (
          <Grid>{ console.log("this.state.personalGoalInput.helthGoalInput",this.state.personalGoalInput.helthGoalInput)}
            <Grid>
              <p>Write Down Your Health Goals</p>
              <textarea
                placeholder="Health Goals"
                name="helthGoalInput"
                style={webStyle.textareaInputStyle}
                value={personalGoalInput.helthGoalInput}
                onChange={this.handleChange}
                data-test-id="handleChnagefirststep"
              />
            </Grid>
            <Grid>
              <div style={{ display: "flex" }}>
                <p>Self appraisal per month </p>
                <img src={InfoIcon} style={webStyle.infoIconStyle} />
              </div>
              <div style={{ display: "flex" }}>
                {configJSON.MonthGoalObj.map(
                  (monthData: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        ...webStyle.monthDiv,
                        background: this.backgroundColorHealth(monthData.month),
                        border:
                          this.state.monthHelthSelect.find(
                            (month) => month.month === monthData.month
                          ) && "1px solid rgb(197 191 191)",
                        cursor: "pointer",
                      }}
                      onClick={() => this.slectMonth(monthData.month, index)}
                      data-test-id="slectMonth"
                    >
                      <span
                        style={{
                          color: this.fontColorHealth(monthData.month),
                          fontWeight: 600,
                        }}
                      >
                        {monthData.value}
                      </span>
                    </div>
                  )
                )}
              </div>
            </Grid>
            <Grid>
              <p style={{}}>Progress</p>
              <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                {[
                  { name: "Just Started", Icon: JustStartIcon },
                  { name: "On Track", Icon: OnTrackIcon },
                  { name: "Exceeding", Icon: ExceedingIcon },
                ].map((GoalDatastep1: any, index: any) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    style={{
                      ...(webStyle.BoxStyle as React.CSSProperties),
                      border:
                        this.state.selectHelthGoal === GoalDatastep1.name
                          ? `2px solid var(--Purple, #8735E1)`
                          : `1px solid #cbc5c5`,
                    }}
                    key={index}
                    onClick={() =>
                      this.handleSelecthelthGoal(GoalDatastep1.name)
                    }
                    data-test-id="handleSelecthelthGoal"
                  >
                    {this.state.selectHelthGoal === GoalDatastep1.name && (
                      <img
                        src={selectLanguage}
                        style={webStyle.selectedStyle as React.CSSProperties}
                      />
                    )}
                    <img src={GoalDatastep1.Icon} alt={GoalDatastep1.name} />
                    <Typography
                      style={webStyle.TitleName as React.CSSProperties}
                    >
                      {GoalDatastep1.name}
                    </Typography>
                  </Box>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {setStep === 2 && (
          <Grid>
            <Grid>
              <p>Write Down Your Safety Goals</p>
              <textarea
                placeholder="Safety Goals"
                name="SafetyGoalInput"
                style={webStyle.textareaInputStyle}
                value={personalGoalInput.SafetyGoalInput}
                onChange={this.handleChange}
                data-test-id="handleChnagesecondstep"
              />
            </Grid>
            <Grid>
              <div style={{ display: "flex" }}>
                <p>Self appraisal per month </p>
                <img src={InfoIcon} style={webStyle.infoIconStyle} />
              </div>
              <div style={{ display: "flex" }}>
                {configJSON.MonthGoalObj.map(
                  (monthDataSte2: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        ...webStyle.monthDiv,
                        background: this.backgroundColorSafety(
                          monthDataSte2.month
                        ),
                         border:this.state.monthSafetySelect.find(
                            (month) => month.month === monthDataSte2.month
                          ) && "1px solid rgb(197 191 191)",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.selectMonthSafety(monthDataSte2.month, index)
                      }
                      data-test-id="selectMonthSafety"
                    >
                      <span
                        style={{
                          color: this.fontColorSafety(monthDataSte2.month),
                          fontWeight: 600,
                        }}
                      >
                        {monthDataSte2.value}
                      </span>
                    </div>
                  )
                )}
              </div>
            </Grid>
            <Grid>
              <p style={{}}>Progress</p>
              <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                {[
                  { name: "Just Started", Icon: JustStartIcon },
                  { name: "On Track", Icon: OnTrackIcon },
                  { name: "Exceeding", Icon: ExceedingIcon },
                ].map((GoalDatastep2: any, index: any) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    style={{
                      ...(webStyle.BoxStyle as React.CSSProperties),
                      border:
                        this.state.selectSafetyGoal === GoalDatastep2.name
                          ? `2px solid var(--Purple, #8735E1)`
                          : `1px solid #cbc5c5`,
                    }}
                    key={index}
                    onClick={() =>
                      this.handleSelectSafetyData(GoalDatastep2.name)
                    }
                    data-test-id="handleSelectSafetyData"
                  >
                    {this.state.selectSafetyGoal === GoalDatastep2.name && (
                      <img
                        src={selectLanguage}
                        style={webStyle.selectedStyle as React.CSSProperties}
                      />
                    )}
                    <img src={GoalDatastep2.Icon} />
                    <Typography
                      style={webStyle.TitleName as React.CSSProperties}
                    >
                      {GoalDatastep2.name}
                    </Typography>
                  </Box>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {setStep === 3 && (
          <Grid>
            <Grid>
              <p>Write Down Your Environment Goals</p>
              <textarea
                placeholder="Environment Goals"
                name="EnviormentGoalInput"
                style={webStyle.textareaInputStyle}
                value={personalGoalInput.EnviormentGoalInput}
                onChange={this.handleChange}
                data-test-id="handleChnagethirdstep"
              />
            </Grid>
            <Grid>
              <div style={{ display: "flex" }}>
                <p>Self appraisal per month </p>
                <img src={InfoIcon} style={webStyle.infoIconStyle} />
              </div>
              <div style={{ display: "flex" }}>
                {configJSON.MonthGoalObj.map(
                  (MonthStep3: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        ...webStyle.monthDiv,
                        background: this.backgroundColorEnvironment(
                          MonthStep3.month
                        ),
                        border:this.state.monthEnviromentSelect.find(
                          (month) => month.month === MonthStep3.month
                        ) && "1px solid rgb(197 191 191)",
                      cursor: "pointer",
                      }}
                      onClick={() =>
                        this.selectMonthEnviroment(MonthStep3.month, index)
                      }
                      data-test-id="selectMonthEnviroment"
                    >
                      <span
                        style={{
                          color: this.fontColorEnvironment(MonthStep3.month),
                          fontWeight: 600,
                        }}
                      >
                        {MonthStep3.value}
                      </span>
                    </div>
                  )
                )}
              </div>
            </Grid>
            <Grid>
              <p style={{}}>Progress</p>
              <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                {[
                  { name: "Just Started", Icon: JustStartIcon },
                  { name: "On Track", Icon: OnTrackIcon },
                  { name: "Exceeding", Icon: ExceedingIcon },
                ].map((GoalDatastep3: any, index: any) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    style={{
                      ...(webStyle.BoxStyle as React.CSSProperties),
                      border:
                        this.state.selectEnivormentGoal === GoalDatastep3.name
                          ? `2px solid var(--Purple, #8735E1)`
                          : `1px solid #cbc5c5`,
                    }}
                    key={index}
                    onClick={() =>
                      this.handleSelectEnivormentData(GoalDatastep3.name)
                    }
                    data-test-id="handleSelectEnivormentData"
                  >
                    {this.state.selectEnivormentGoal === GoalDatastep3.name && (
                      <img
                        src={selectLanguage}
                        style={webStyle.selectedStyle as React.CSSProperties}
                      />
                    )}
                    <img src={GoalDatastep3.Icon} />
                    <Typography
                      style={webStyle.TitleName as React.CSSProperties}
                    >
                      {GoalDatastep3.name}
                    </Typography>
                  </Box>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        <div
          style={{
            marginBottom: "10px",
            borderBottom: "1px solid #edebeb",
            paddingBottom: "90px",
          }}
        ></div>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          padding="0.75rem"
          style={{ marginTop: "1rem", marginLeft: "-9px" }}
        >
          <Button
            style={{...webStyles.transButton,width:'15%',borderRadius:'8px' }}
            data-test-id="backToPrevStep"
            onClick={this.StepMinusFunction}
          >
           <Typography
            style={webStyles.buttonStyle as React.CSSProperties}
          > Previous</Typography>
          </Button>
          <Button
            style={{ ...webStyles.fullButton,width:'15%',borderRadius:'8px' }}
            data-test-id="nextBtnStep2"
            variant="contained"
            type="submit"
            onClick={this.submitData}
          >
          <Typography
            style={webStyles.buttonStyle as React.CSSProperties}
          >
            {" "}
            Next
          </Typography>
          </Button>
        </Box>
        {setStep === 4 && (
          <CongratsModalStep
            navigation={this.props.navigation}
            activeStep={this.props.activeStep}
            activeStepState={3}
          />
        )}
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const webStyle = {
  selectedStyle: {
    position: "absolute",
    left: "73%",
    top: "4px",
  },
  infoIconStyle: {
    marginTop: "20px",
    marginLeft: "10px",
    height: "20px",
    width: "20px",
  },
  monthDiv: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "4px",
  },
  BoxStyle: {
    display: "flex",
    width: "7vw",
    height: "7vw",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",
  },
  TitleName: {
    fontWeight: 400,
    textAlign: "center",
  },
  textareaInputStyle: {
    margin: 8,
    width: "98%",
    height: "123px",
    border: "1px solid #d1c9c9",
    borderRadius: "8px",
    outline: "none",
    padding: "15px",
  },
  renderStepUnderlineStyle: {
    width: "3.5rem",
    height: "1px",
    backgroundColor: "rgb(135 53 225)",
    marginTop: "56px",
  },
};
// Customizable Area End
