Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Image Categorisation";
exports.labelBodyText = "machinelearningmlimagecategorisation Body";
exports.labelUploadImage = "Upload Image";
exports.labelTitleTextSearch = "Search Tags";
exports.tags = "Tags"; 
exports.searchResults = "Here are your search results";
exports.relatedTag = "Images related to particular tags";
exports.addTagText = "Add Tags to this image";
exports.imageScreenTitle = "Image Screen";
exports.recordNotFound = "Record Not Found.";

exports.uploadImageAndTagsApiEndPoint = "bx_block_machinelearningmlimagecategorisation/machinelearningmlimagecategorisation";
exports.searchTagEndpoint = "bx_block_machinelearningmlimagecategorisation/machinelearningmlimagecategorisation/search_tags_and_images"
exports.getImagesEndpoint = "bx_block_machinelearningmlimagecategorisation/machinelearningmlimagecategorisation/search_images"
exports.getTags = "bx_block_machinelearningmlimagecategorisation/machinelearningmlimagecategorisation/search_tags"
exports.apiContentType = "application/json"
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";




exports.btnExampleTitle = "CLICK ME";
// Customizable Area End